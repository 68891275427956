import { css } from "emotion";
import React from "react"
import moment from "moment"
import Api from "../../api/Api";
import { useHistory } from "react-router-dom";

const Component =  ({item}) => {
    const history = useHistory()

    const onClick = () => {
        Api().post("eventos/somavisualizacao/"+item.id)
        history.push("evento/apresentacao/"+item.id)
    }

    return <div className={css(styles.container)} onClick={onClick}>
        <div className={css(styles.flexImage)}>
            <img src={item.photo} className={css(styles.photoEventCard)}/>
        </div>
        <div className={css(styles.flexContent)}>
            <div className={css(styles.date)}>
                <span> {moment(item.dateStart).format("DD MMM - HH:mm")} </span>
            </div>
            <div className={css(styles.title)}>
                <span>{item.name}</span>
            </div>
            <div className={css(styles.end)}>
                <span>{item.city} - {item.state}</span>
            </div>
        </div>
    </div>
}

const styles = {
    container: {
        display: "flex",
        width: "100%",
        height: 70,
        marginBottom: 15

    },
    photoEventCard: {
        width: "100%",
        height: 70,
        borderRadius: 15
    },
    flexImage: {
        flex: 1,
    },
    flexContent: {
        flex: 3,
        paddingLeft: 10
    },
    date: {
        color: "#6B69CF",
        fontFamily: "Roboto",
        fontSize: 11
    },
    title: {
        marginTop: 10,
        fontWeight: "bold",
        fontFamily: "Roboto",
        color: "#040404",
        fontSize: 12
    },
    end: {
        color: "#8E8E92",
        fontSize: 10
    }

}

export default Component;