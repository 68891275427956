import React, { useState } from "react"
import { Row, Col } from "react-materialize"
import {Button} from "../../../../components/Form"

import { Modal } from 'react-responsive-modal';

const Component = (props) => {

    const [loading, setLoading] = useState(false)

    const onClick = async () => {
        setLoading(true)
        if(props.onClick) {
            await props.onClick();
        }
        setLoading(false)
    }

    return <Modal
    open={props.open}
    center
    type={"button"}
    onClose={props.onClose}
  >
        <h5 className="grey-text text-darken-3 center-align">Compra</h5>
        <Row>
            <Col s={12}>
                <span>Tem Certeza que deseja realizar a compra da cronometragem?</span>
            </Col>
        </Row>
        <Row>
            <Col s={6}>
                <Button loading={loading} onClick={onClick}>Sim</Button>
            </Col>
            <Col s={6}>
                <Button onClick={props.onClose}>Não</Button>
            </Col>
        </Row>
    </Modal>
}

export default React.memo(Component)