import circlePlus from "../../../assets/icons/circle-plus.svg"

export default {
    tickets: [],
    picture: null,
    totalPrice: 0,
    name: "",
    dateStart: "", 
    city: "", 
    state: "",
    nameCard: "",
    numberCard: "",
    cpfCard: "",
    valDateCard: null,
    cvvCard: "",
    typePag: "credit",
    installmentQuantity: 1,
    installmentValue: 1,
    birthDateCard: null,
    currentPage: "inscricao"
}

