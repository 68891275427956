import React, { useState, useEffect } from "react"
import Api from "../api/Api"
import { toast } from "react-toastify"

export default  (args = {}, onComplete = () => {}) => {
    const [sportList, setListSports] = useState([])
    console.log(args)
    useEffect(() => {
        (async function findSports(){
            try {
                const result = await Api().get("/esportes/listar",{
                    params: {
                        evento_id: args.evento_id ? args.evento_id : "",
                        torneio_id: args.torneio_id ? args.torneio_id : ""
                    }
                }) 
                if(result.data.esportes){
                    let sports = []
                    result.data.esportes.map((data) => {
                        sports.push({value: data.id, label: data.nome})
                    })
                    setListSports(sports)
                    onComplete(sports)
                }
            } catch (e){
                console.log(e)
                const response = e.response ? e.response : {};
                const data = response.data ? response.data : {};
                const msg = data.msg ? data.msg[0] : "Ocorreu um problema para retorna os esportes";
                toast.error(msg)
            }
        })()
     

    },[args.evento_id, args.torneio_id])

 
    return sportList;
}
