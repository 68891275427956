import React, {  useState } from "react"
import { css } from "emotion";
import { ButtonClean } from "../../../components/Form";
import DataTable from 'react-data-table-component';
import ModalAtletas from "../ModalAtletas";
import {BarLoader} from "react-spinners";
import ModalDelete from "../../../components/ModalDelete";
import ModalDeleteMobile from "../../../components/ModalDeleteMobile";

 const Component = ({
    sameCoach,
    setAtleDelete,
    members,
    coaches,
    openModalDeleteAtl,
    setOpenModalDeleteAtl,
    handleDelEquipe,
    onDelete,
    handleEditEquipe,
    loadingTable
 }) => {
    const [openModalAtletas, setOpenModalAtletas] = useState()
    const [openModalDeleteTeam, setOpenModalDeleteTeam] = useState(false)


    const handleAddAtleta = () => {
        setOpenModalAtletas(!openModalAtletas)
    }

    const customStyles = {
        rows: {
            style: {
                marginTop: 30,
            }
        },

    };

    const columns = [
        {
            selector: 'image',
            sortable: true,
            width: "35px",
            cell: row => <>{row.image && <img src={row.image} className={css(styles.imageMember)}/>}</>
        },
        {
            selector: 'name',
            width: "100px",
            sortable: true,
            // cell: row => <span className={css(styles.textTable)}>{row.name}</span>
        },
        {
            selector: 'tipo',
            sortable: true,
            width: "78px",
            cell: row => <span>{row.tecnico ? "Técnico" : "Atleta"}</span>
        },
        {
            selector: 'pontos',
            sortable: true,
            width: "70px",
            cell: row =>  row.pontos + " pts"
        },
        {
            selector: 'acoes',
            sortable: true,
            cell: row =>  
            <>
           {!row.aceite && <span  className={"material-icons modal-trigger "+css(styles.farol)}>error_outline</span>}
           {sameCoach && <span href="#modal1" id={row.id} onClick={ () => { setAtleDelete(row.user_id); setOpenModalDeleteAtl(true) }} 
            className={"material-icons modal-trigger "+css(styles.iconDelete)}>delete</span>}
            </>
        },
    ];

    return <div>
        <ModalAtletas open={openModalAtletas} toggle={setOpenModalAtletas}/>
        <ModalDeleteMobile open={openModalDeleteTeam} onDelete={handleDelEquipe} onClose={() => setOpenModalDeleteTeam(false)}>
            Você realmente deseja excluir essa equipe?
        </ModalDeleteMobile>
        <ModalDeleteMobile open={openModalDeleteAtl} onDelete={onDelete} onClose={() => setOpenModalDeleteAtl(false)}>
            Você realmente deseja excluir essa atleta?
        </ModalDeleteMobile>
        <DataTable
            progressComponent={<BarLoader css={styles.loadingCss} color={"#6B69CF"}/>}
            progressPending={loadingTable}
            data={Object.values(members)}
            className={css(styles.table)}
            customStyles={customStyles}
            columns={columns}
            noDataComponent={""}
            noHeader
            noTableHead
        />
        <div className={css(styles.footer)}>
            {sameCoach && <div className={css(styles.painelFooter)}>
                <>
                  <><ButtonClean icon={"plus"} cssSpan={styles.itemBtnSpan} cssImg={styles.itemBtnImg} onClick={handleAddAtleta} className={css(styles.itemBtnTitle)}>
                        Adicionar atleta
                    </ButtonClean>
                    <ButtonClean icon={"edit"} cssSpan={styles.itemBtnSpan} cssImg={styles.itemBtnImg} onClick={handleEditEquipe} className={css(styles.itemBtnTitle)}>
                        Editar
                    </ButtonClean>
                    <ButtonClean icon={"del"} cssSpan={styles.itemBtnSpan} cssImg={styles.itemBtnImg} onClick={() => setOpenModalDeleteTeam(true)} className={css(styles.itemBtnTitle)}>
                        Excluir
                    </ButtonClean></>
                </>
            </div>}
        </div>
    </div>
}

const styles = {
    painelFooter: {
        display: "flex",
        backgroundColor: "#fff",
        paddingTop: 5,
        marginRight: 20,
        marginLeft: 20,
        height: 55,
        borderRadius: 10,
        boxShadow: " 0 0 2px 2px rgba(0, 0, 0, 0.15)",
    },
    itemBtnTitle: {
        flexDirection: "column",
        flex: 1
    },
    itemBtnSpan: {
        marginTop: 5,
        marginLeft: 0,
        fontSize: 12
    },
    itemBtnImg: {
        width: 25
    },
    footer: {
        position: "fixed",
        height: 65,
        width: "100%",
        backgroundColor: "#fff",
        bottom: 0,
        left: 0
    },
    textTable: {
        overflow: 'hidden',
        whiteSpace: "nowrap",
        textOverflow: "ellipsis"
    },
    imageMember: {
        width: 25,
        height: 25,
        borderRadius: "50%"
    },
    loadingCss: {
        width: "100%"
    },
    iconDelete: {
        cursor:'pointer',
        color:'red'
    },
    farol: {
        cursor:'pointer',
        color:'yellow'
    },
    table: {
        marginBottom: 30
    }
}

export default Component;