import React from "react"
import InputMask from 'react-input-mask';
import { css } from 'emotion'

const Component =({maskChar,min,max,id,alwaysShowMask,messageError,label,onChange, onBlur,className,classNameInput,color, required,type,value,placeholder, mask,disabled,maxLength,pattern,title,backgroundColor, radius,inputLabel,onEnter, ...rest}) => {

    const divComponent = {
        ...style.divcomponent, 
        "& label": {
            color: color ? color : "#000" 
        }
    };
    
    return  <div className={css(divComponent)+" "+className}> 
    <div className={css(style.inputField)+ (inputLabel ? " input-field" : "")}>
        <label 
        htmlFor={id} 
        className={css(style.label)+" left-align"} 
       >{label}</label>
            <InputMask 
                {...rest}
                    id={id}
                    type={type ? type : "text"}
                    name={id}
                    value={value}
                    mask={mask}
                    min={min}
                    max={max}
                    maskChar={maskChar ? maskChar : null}
                    pattern={pattern}
                    alwaysShowMask = {alwaysShowMask}
                    disabled={disabled}
                    maxLength={maxLength}
                    title={title}
                    onKeyDown={(e)=> {if(e.keyCode == 13){
                        if(onEnter){
                            onEnter()
                        }
                    }}}
                    className={css(style.input) + "  left-align "+classNameInput + " " + 
                     css({
                         backgroundColor: backgroundColor ? backgroundColor+" !important" : "",
                         borderRadius: radius ? "10px !important" : 0
                    })}
                    onChange={onChange}
                    onBlur={onBlur}
                    required={required}
                placeholder={placeholder}/>
    </div>
    {messageError && <label className={css(style.messageError)}>{messageError}</label>}

    </div>
}

const style = {
    label : {
        color: "#000" 
    },
    divcomponent : {
        width: "100%",
        whiteSpace: "break-spaces"
    },
    inputField: {
        marginBottom: 0,
        position: "relative"
    },
    input: {
        width: "100%",
        textIndent: 10
    },
    messageError: {
        
        color: "#f00 !important"
    }
}

export default React.memo(Component)
