import React, { useContext } from "react"
import { Row, Col } from "react-materialize"
import { Checkbox, ImageUploader, DateTimePicker, RichTextEditor, Input, Select, Button } from "../../../../components/Form"
import FormTorneioContext, {actions} from "../../FormTorneioContext"
import { css } from "emotion"
import { toast } from "react-toastify"

export default (props) => {

    const optionsPrivacy = [{"value" : 1, label: "Público"},{"value": 2, label: "Privado"}]
    const {state, dispatch, ref_form} = useContext(FormTorneioContext)

    const handleChange =  (name, value) => {
        return dispatch({type: actions.HANDLE_CHANGE,
            payload: {name, value}})
    }
    
    const onDrop = (picture,pictureDataURLs) => {
        dispatch({type: actions.HANDLE_CHANGE,
            payload: {name: 'picture', value: picture}})
        dispatch({type: actions.HANDLE_CHANGE,
            payload: {name: 'pictureUrl', value: pictureDataURLs}})
    }

    const nextPage = (e) => {
        e.preventDefault()
        if(!ref_form.current.reportValidity()){
            return
        }
        dispatch({type: actions.CHANGE_PAGE,payload: 1})
    }

    return <div>
                <Row>
                    <Col s={12} l={4}>
                            <ImageUploader
                                buttonText='Escolher Imagem'
                                defaultImages={state.pictureUrl}
                                onChange={onDrop}
                            />
                    </Col>
                    <Col  s={12} l={8}>
                        <Row>
                            <Col s={12}>
                                <Input
                                        id="name"
                                        label={"Nome do torneio*:"}
                                        value={state.name}
                                        required={true}
                                        onChange={(e) => handleChange('name',  e.target.value)}
                                    />
                            </Col>
                        </Row>
                        <Row>
                            <Col s={12} l={6} >
                                <Select
                                    label={"Privacidade*:"}
                                    options={optionsPrivacy}
                                    value={optionsPrivacy.filter(option => option.value == state.privacy)}
                                    required={true}
                                    form="required"
                                    onChange={({value}) => handleChange('privacy',  value)}

                                />
                            </Col>
                            <Col s={12} l={6}>
                                <Input
                                    id="edition"
                                    label={"Edição / Ano*:"}
                                    type={"number"}
                                    value={state.edition}
                                    required={true}
                                    onChange={(e) => handleChange('edition',  e.target.value)}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col s={12} l={12}>
                        <RichTextEditor
                            label={"Política*:"}
                            value={state.policy}
                            required={true}
                            onChange={(value) => handleChange('policy',  value)}

                        />
                    </Col>
                </Row>
                <Row className={css(styles.btnProxDiv)}>
                    <Col m={12} s={12} l={6} className="offset-l3">
                        <Button onClick={nextPage}>Próximo</Button>
                    </Col>
                </Row>
        </div>
}
const styles={
    btnProxDiv: {
        marginTop: 55
    },
}