import React, { useEffect, useState, useContext } from "react"
import {Button, Input, ImageUploader, Select} from "../../components/Form"
import {Card, Row, Col } from "react-materialize"
import ApiCep from "../../api/ApiCep"
import Api from "../../api/Api"
import { toast } from 'react-toastify';
import { useCookies} from "react-cookie"
import LoadingOverlayContext from "../../components/LoadingOverlayContext/LoadingOverlayContext"
import config from "../../config.js"
import listUf from "../../constants/listUf"
import useIdOrganizer from "../../hooks/useIdOrganizer"

export default () => {

    // const cookies = new Cookies();
    const [,setCookie] = useCookies(['id','nome',"imagem"]);

    const user_id = useIdOrganizer();

    const [id,setId] = useState("")
    const [email,setEmail] = useState("")
    const [name,setName] = useState("")
    const [picture,setPicture] = useState();
    const [pictureUrl, setPictureUrl] = useState([])
    const [cpf_cnpj, setCpfCnpj] = useState("")
    const [typeDoc, setTypeDoc] = useState("cpf")
    const [phone,setPhone] = useState("")
    const [cellphone,setCellphone] = useState("")
    const [zipCode,setZipCode] = useState("")
    const [street,setStreet] = useState("")
    const [number,setNumber] = useState("")
    const [complement,setComplement] = useState("")
    const [neighborhood,setNeighborhood] = useState("")
    const [city,setCity] = useState("")
    const [state,setState] = useState("")
    const [loading,setLoading] = useState(false)

    const loadingOverlay = useContext(LoadingOverlayContext)

    useEffect(() => {
        getData()
    },[])

    const getData = async () => {
        try {
            let id = user_id 
          
            if(id){
                loadingOverlay.activeLoading(true,"Buscando conteúdo")

                const result = await Api().get(`users/getinfo/${id}`)
                if(result.data.infos){
                    const data = result.data.infos;
                 
                    setId(data.id)
                    setName(data.name ? data.name : data.nome_empresa)
                    setTypeDoc(data.name ? "cpf" : "cnpj")       
                    setCpfCnpj(data.cpf_cnpj) 
                    setEmail(data.email)
                    setPhone(data.telefone ? data.telefone : "")
                    setCellphone(data.celular ? data.celular : "")
                    setZipCode(data.cep)
                    setStreet(data.endereco)
                    setNumber(data.numero)
                    setComplement(data.complemento)
                    setNeighborhood(data.bairro)
                    setCity(data.cidade)
                    setState(data.uf)

                    if(data.imagem){
                        setPictureUrl([config.storage_link+`/users/${data.imagem}`])
                    }

                                
                }
                loadingOverlay.activeLoading(false)

            }
        } catch(e) {
            loadingOverlay.activeLoading(false)
            
            console.log(e)
        }
    }


    const onSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)
        try {
            const data = {
                name,
                email,
                nome_empresa: typeDoc != "cpf" ? name : null,
                telefone: phone ? phone.replace(/\(|\)|\s|-/g,"") : "",
                celular: cellphone ? cellphone.replace(/\(|\)|\s|-/g,"") : "",
                cpf_cnpj: cpf_cnpj ? cpf_cnpj.replace(/\.|-|\//g,"") : "",
                endereco : street,
                cep: zipCode ? zipCode.replace(/-/g,"") : "",
                bairro: neighborhood,
                complemento: complement,
                cidade: city,
                numero: number,
                uf: state,
                ativo: true
            }
            const result = await Api().post("organizador/editar/"+id,data)
            if(result.data['error']){
                toast.error(result.data.msg[0] ? result.data.msg[0] : "Erro")
                setLoading(false)
            }else {
                toast.success("Usuário atualizado com sucesso")
                setCookie('nome', name);

                if(picture){
                    uploadimagem(id)
                }
                setLoading(false)
            }

        } catch (e){
            console.log(e)
            setLoading(false)
            const response = e.response ? e.response : {};
            const data = response.data ? response.data : {};
            const msg = data.msg ? data.msg[0] : "Ocorreu um erro";
            toast.error(msg)
            return false;
        }
        
    }

    const uploadimagem = async (id) => {
        var formData = new FormData();
        formData.append("id_usuario",id)
        formData.append("file", picture);
        try {
            const resultImage = await Api().post('/usuario/uploadimagem', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            console.log(resultImage)
            if(resultImage.data['error']){
                toast.error(resultImage.data.msg[0])
            }else {
                setCookie('imagem', resultImage.data['imagem']);

                
            }
        } catch(e) {
            console.log(e)
            const response = e.response ? e.response : {};
            const data = response.data ? response.data : {};
            const msg = data.msg ? data.msg[0] : "Ocorreu um erro ao enviar a imagem do organizador";
            toast.error(msg)
        }
        
    }

    const onDrop = (picture) => {
        setPicture(picture[0])
    }
    
    const  handleZipCode = async (value) => {
        setZipCode(value)
        if(value && value.length == 9){
            try {
                const address = await ApiCep.get(`/${value}/json`);
                const data = address.data
                setStreet(data.logradouro)
                setNeighborhood(data.bairro)
                setCity(data.localidade)
                setState(data.uf)

            } catch {
              
            }
        }else {
            setStreet("")
            setNeighborhood("")
            setCity("")
            setState("")
        }
    }

 


    return <div>
                <form onSubmit={onSubmit}>
                    <div style={styles.main}>
                    <h4 className="grey-text text-darken-4 center-align">Edição de Organizador</h4>
                    <Card>
                        <Row>
                            <Col s={12} l={6}>
                                <ImageUploader
                                    buttonText='Escolher Imagem'
                                    defaultImages={pictureUrl}
                                    onChange={onDrop}
                                />
                            </Col>
                            <Col s={12} l={6}>
                                <Row>
                                    <Col s={12}>
                                        <Input
                                            id="cpf_cnpj"
                                            label={"CPF/CNPJ*:"}
                                            title="CPF ou CNPJ está incorreto"
                                            value={cpf_cnpj}
                                            disabled={true}
                                        />
                                            
                                    </Col>
                                </Row>
                                <Row>
                                    <Col s={12}>
                                        <Input
                                            id="email"
                                            label={"E-mail*:"}
                                            value={email}
                                            title={"E-mail incorreto"}
                                            required={true}
                                            disabled={true}
                                            type="email"
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row> 
                        <Row>
                            <Col s={12}>
                                    <Input
                                            id="name"
                                            label={"Nome*"}
                                            value={name}
                                            maxLength={100}
                                            required={true}
                                            onChange={(e) => setName(e.target.value)}
                                        />
                            </Col>
                        </Row> 
                        <Row>
                            <Col s={12} l={6}>
                                    <Input
                                            id="cellphone"
                                            label={"Celular*:"}
                                            pattern=".{0}|.{15,}"
                                            title="O celular está incorreto"
                                            value={cellphone}
                                            required={true}
                                            mask={"(99) 99999-9999"}
                                            onChange={(e) => setCellphone(e.target.value)}
                                        />
                            </Col>
                            <Col s={12} l={6}>
                                    <Input
                                            id="phone"
                                            label={"Telefone:"}
                                            pattern=".{0}|.{14,}"
                                            title="O telefone está incorreto"
                                            value={phone}
                                            mask={phone.length >= 15 ? "(99) 99999-9999" : "(99) 9999-99999"}
                                            onChange={(e) => setPhone(e.target.value)}
                                        />
                            </Col>
                        </Row> 
                        <Row>
                            <Col s={12} l={6}>
                                    <Input
                                            id="cep"
                                            label={"Cep*:"}
                                            value={zipCode}
                                            mask={"99999-999"}
                                            required={true}
                                            onChange={(e) => handleZipCode(e.target.value)}
                                        />
                            </Col>
                        </Row> 
                        <Row>
                            <Col s={12} l={9}>
                                    <Input
                                            id="street"
                                            label={"Logradouro*:"}
                                            value={street}
                                            required={true}
                                            maxLength={100}
                                            onChange={(e) => setStreet(e.target.value)}
                                        />
                            </Col>
                            <Col s={12} l={3}>
                                    <Input
                                            id="number"
                                            label={"Número*:"}
                                            value={number}
                                            type={"number"}
                                            required={true}
                                            onChange={(e) => setNumber(e.target.value)}
                                        />
                            </Col>
                        </Row> 
                        <Row>
                            <Col s={12} l={6}>
                                    <Input
                                            id="complement"
                                            label={"Complemento:"}
                                            maxLength={100}
                                            value={complement}                                                
                                            onChange={(e) => setComplement(e.target.value)}
                                        />
                            </Col>
                            <Col s={12} l={6}>
                                    <Input
                                            id="neighborhood"
                                            label={"Bairro*:"}
                                            value={neighborhood}
                                            maxLength={100}
                                            required={true}
                                            onChange={(e) => setNeighborhood(e.target.value)}
                                        />
                            </Col>
                        </Row> 
                        <Row>
                            <Col s={12} l={9}>
                                    <Input
                                            id="city"
                                            label={"Cidade*:"}
                                            value={city}
                                            maxLength={100}
                                            required={true}
                                            onChange={(e) => setCity(e.target.value)}
                                        />
                            </Col>
                            <Col s={12} l={3}>
                                    <Select 
                                            label={"UF*:"}
                                            menuPlacement={"top"}
                                            value={listUf.filter(option => option.value == state)}
                                            onChange={(data) => setState(data.value)}
                                            required={true}
                                            options={listUf}
                                        />
                                    
                            </Col>
                        </Row>
                        <Row>
                            <Col m={12} s={12} l={6} className="offset-l3">
                                <Button loading={loading}>Salvar</Button>
                            </Col>
                        </Row>
                    </Card>
                </div>
            </form>
        </div> 
   
}

const styles =  {
   main : {
    paddingBottom: 20,
   },
    
    spanesqueceusenha: {
        cursor: "pointer"
    },
   
}