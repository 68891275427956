import React,{ useRef, useState, useCallback } from "react"
import {Col, Row} from "react-materialize"
import AliceCarousel from 'react-alice-carousel'
import useResize from "../hooks/useResize"
import { useEffect } from "react"
import {BeatLoader} from "react-spinners";
import { css } from "emotion"

const Component = (props) => {

    const carouselRef = useRef(null)
    const [mobile,setMobile] = useState(false)
    const [currentIndex, setCurrentIndex] = useState(0)
    const [items,setItems] = useState([])
    const [width] = useResize();

    
    const responsive = {
        0: { items: 1 },
        992: { items: 3 },
    }


    useEffect(() => {
        if(width >= 992){
            setMobile(false)
        }else{
            setMobile(true)
        }
    },[width])

    useEffect(() => {
        let itemsProp = props.items ? [...props.items] : [];
        itemsProp = itemsProp.map((item,i) => {
            return <div key={i}><props.template extraParamsItem={props.extraParamsItem} item={item}/></div>
        })
        if(width >= 992){
            if(itemsProp.length == 1){
                itemsProp.push("","")
            }
            else if(itemsProp.length == 2){
                itemsProp.push("")
            }
        }

        setItems([...itemsProp])
        
    },[mobile])

    useEffect(() => {
        let itemsProp = props.items ? [...props.items] : [];
        itemsProp = itemsProp.map((item,i) => {
            return <div key={i}><props.template  item={item} extraParamsItem={props.extraParamsItem}/></div>
        })
        if(width >= 992){
            if(itemsProp.length == 1){
                itemsProp.push("","")
            }
            else if(itemsProp.length == 2){
                itemsProp.push("")
            }
        }

        setItems([...itemsProp])
        
    },[props.items])

    function onSlideChanged (e)  {
        setCurrentIndex(e.item)
    }

    
    return <div>
            <Row className={css(styles.carouselGeral)}>
                {props.loading ? <BeatLoader css={styles.loadingCss} color={"#047AB2"} size={25} /> :
                <>
                    {props.controls !== false &&<Col s={1} >
                        {((mobile && props.items && props.items.length > 1) || (props.items && props.items.length > 3)) && <button className={"btn"} onClick={() => carouselRef.current.slidePrev()}
                            style={styles.btnPrev}>{"<"}
                        </button>}
                    </Col>}
                    <Col s={props.controls !== false ? 10 : 12}>
                        <AliceCarousel
                            key={"carousel"}
                            mouseTrackingEnabled={false}
                            playButtonEnabled={false}
                            dotsDisabled={true}
                            items={items}
                            startIndex={currentIndex}
                            onSlideChanged={onSlideChanged}
                            responsive={responsive}
                            infinite={props.infinite}
                            buttonsDisabled={true}
                            ref={carouselRef}
                   
                        />
                    </Col>
                    {props.controls !== false && <Col s={1}>
                        {((mobile && props.items && props.items.length > 1) || (props.items && props.items.length > 3)) &&<button 
                            className={"btn"}
                            style={styles.btnNext}
                            onClick={() => carouselRef.current.slideNext()}>{">"}
                            </button>}
                    </Col>}
                </>
                }
            </Row>
            {((props.controls !== false && props.items.length > 3) || props.dots) && <ul className={css(styles.dots)+" alice-carousel__dots"}>
                {items.map((item,i) => {
                    return <li  key={i} className={"alice-carousel__dots-item "+(currentIndex==i && "__active")} onClick={() => setCurrentIndex(i)}></li>
                })}
            </ul>}
        </div>
}

const styles = {
    dots: {
        marginTop: 0
    },
    carouselGeral: {
        position: "relative",
        marginLeft: -20,
        marginRight: -20,
        "& .alice-carousel__stage-item": {
            paddingLeft: 20,
            paddingRight: 20
        },
        "@media(max-width: 992px)": {
           paddingBottom: 0
        },
    },
    btnPrev: {
        float: "right",
        backgroundColor: "#6B69CF",
        position: 'absolute',
        top: '50%',
        left: 0,
    },

    btnNext: {
        backgroundColor: "#6B69CF",
        position: 'absolute',
        top: '50%',
        right: 0,
    },
    loadingCss: {
        width: "100%",
        textAlign: "center",
        marginTop: 100,
    },
}

export default React.memo(Component)

