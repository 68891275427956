import React, { useState, useEffect } from 'react'
import { Card } from 'react-materialize'
import { css } from 'emotion'
import { useHistory, useLocation } from "react-router-dom";
const Component =  (props) => {
    const history = useHistory();
    const {search} = useLocation()
    const query = new URLSearchParams(search)

    const pages = props.pages ? props.pages : []
    const [currentPage, setCurrentPage] = useState(0)

    useEffect(() => {
        setCurrentPage(props.currentPage)
        history.push({"search": "?page="+(props.currentPage+1)})
    },[props.currentPage])

    useEffect(() => {
        
        if(props.autoOnLoadPage){
            const page = query.get("page")
           
           if(page){
                setCurrentPage(page-1)
           }
        }
    },[])
    

    useEffect(() => {
        if(props.onChange && currentPage != props.currentPage) {
            props.onChange(currentPage);
            history.push({"search": "?page="+(currentPage+1)})
        }
    },[currentPage])
    
    const numberStepsPerRow = props.numberStepsPerRow ? props.numberStepsPerRow : pages.length
    const numberStepsPerRowMobile = props.numberStepsPerRowMobile ? props.numberStepsPerRowMobile : numberStepsPerRow;

    const styleStep = {
        flex: (100/numberStepsPerRow)+"%",
        "@media(max-width: 700px)": {
            flex: (100/numberStepsPerRowMobile)+"%",
        },
    }

    return <div className={css(styles.main)}>
        <div className={css(styles.menuTabs)}>
            {pages.map((item,index) => {
                return <div key={index} onClick={() => (props.navigable === undefined || props.navigable) && setCurrentPage(index)}
                 className={css(styles.tab,styleStep,index == currentPage && styles.tabActive)}> 
                    <div className={css(styles.dotsPage,index == currentPage && styles.dotsPageActive)}>
                        <span className={css(styles.dotsPageSpan)}>{index+1}</span>
                    </div>
                    <span className={css(styles.titleTabMenu,index == currentPage && styles.titleTabMenuActive)}>{item}</span> 
                </div>
            })}
        </div>
        <Card>
            {Array.isArray(props.children) && props.children[currentPage]}
            {!Array.isArray(props.children) && props.children}
        </Card>
    </div>
}

export default React.memo(Component)

const styles = {
    main: {
        minHeight: "100%"
    },
    menuTabs: {
        backgroundColor: "#fff",
        width: "100%",
        flexWrap: "wrap",
        display: "flex"
    },
    tabActive: {
        backgroundColor: "#6B69CF", 
    },
    tab: {
        flex: 1,   
        height: 42,
        display: "flex", 
        flexWrap: "wrap",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer"
    },
    dotsPageActive: {
        backgroundColor: "#fff",
        color: "#000",
    },
    dotsPage: {
        marginRight: "7%",
        backgroundColor: "#6B69CF",
        width: 17,
        height: 17,
        color: "#fff",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: 40,
        cursor: "pointer",
    },
    dotsPageSpan:{
        fontFamily: "Roboto",
        fontSize: 14,
        "-moz-user-select": 'none',
        "-khtml-user-select": 'none',
        "-webkit-user-select": 'none',
        '-ms-user-select': 'none',
        userSelect: 'none'
    },
    titleTabMenu: {
        color: "#000",
        fontFamily: "Roboto"
    },
    titleTabMenuActive: {
        color: "#fff",
    },
}