import React, { useEffect, useState } from 'react'
import { css } from 'emotion';
import Modal from 'react-responsive-modal';
import { Col, Row } from 'react-materialize';
import { Button, Input, TimePicker } from '../../components/Form';
import moment from "moment"
import { toast } from 'react-toastify';
import Api from '../../api/Api';
import config from "../../config.js"
import { useLocation } from 'react-router-dom';
import { BrowserView, MobileView } from 'react-device-detect';

import ModalInfoAtletaPontoBrowser from "./ModalInfoAtletaPontoLayout/ModalInfoAtletaPontoBrowser"
import ModalInfoAtletaPontoMobile from "./ModalInfoAtletaPontoLayout/ModalInfoAtletaPontoMobile"

const Component = (props) => {

    const [loadingPub, setLoadingPub] = useState(false)
    const [name, setName] = useState("")
    const [sport, setSport] = useState("")
    const [category, setCategory] = useState("")
    const [subcategory, setSubCategory] = useState("")
    const [subcription, setSubscription] = useState("")
    const [position, setPosition] = useState("")
    const [points, setPoints] = useState("")
    const [image, setImage] = useState("")
    const [rankingId, setRankingId] = useState("")
    const [time, setTime] = useState(null)
    
    useEffect(() => {
        if(props.open && props.cronometro_id){
            (async () => {
                try {
                    const result = await Api().post("ranking/info",{
                        cronometro_id: props.cronometro_id,
                        atleta_id: props.atleta_id,
                     });
                     const data = result.data
                     const infos = data.infos ? data.infos : []
                     const info = infos[0] ? infos[0] : {}  
                     setName(info.name)
                     setSport(info.esporte)
                     setSubscription(info.numero)
                     setPoints(info.pontuacao)
                     setPosition(info.posicao)
                     setSubCategory(info.subcategoria)
                     setCategory(info.categoria)
                     setImage([config.storage_link+`/users/${info.imagem}`])
                     setTime(moment(info.tempo,'HH:mm:ss').toDate())
                     setRankingId(info.ranking_id)
                } catch(e){
                    const response = e.response ? e.response : {};
                    const data = response.data ? response.data : {};
                    const msg = data.msg ? data.msg[0] : "Ocorreu um problema";
                    toast.error(msg)
                }
                
            })()
        }
    },[props.open, props.cronometro_id])

    const handleSave = async () => {

        try {
            if(!time){
                toast.warn("É necessario preencher o tempo")
                return;
            }
            setLoadingPub(true)
            const result = await Api().post("ranking/alterartempo", {
                ranking_id: rankingId,
                tempo: moment(time).format("HH:mm:ss")
            });
            if(!result.data.error){
                toast.success("Tempo alterado com sucesso")
                onClose()
            }
        } catch(e){
            const response = e.response ? e.response : {};
            const data = response.data ? response.data : {};
            const msg = data.msg ? data.msg[0] : "Ocorreu um problema";
            toast.error(msg)
        }
        setLoadingPub(false)


    }

    const clearState = () => {
        setName("")
        setSport("")
        setCategory("")
        setSubCategory("")
        setSubscription("")
        setPosition("")
        setPoints("")
        setTime(null)
      //  setTime(moment("20:20:20","HH:mm:ss").toDate())
    }

    const onClose = () => {
        props.toggle(false)
        clearState()
    }

    return <div>
        <BrowserView>
            <ModalInfoAtletaPontoBrowser 
               loadingPub={loadingPub}
               name={name}
               sport={sport}
               category={category}
               subcategory={subcategory}
               subcription={subcription}
               position={position}
               points={points}
               image={image}
               time={time}
               setTime={setTime}
               open={props.open} 
               onClose={onClose}
               handleSave={handleSave}
            />
        </BrowserView>
        <MobileView>
            <ModalInfoAtletaPontoMobile 
                loadingPub={loadingPub}
                name={name}
                sport={sport}
                category={category}
                subcategory={subcategory}
                subcription={subcription}
                position={position}
                points={points}
                image={image}
                time={time}
                setTime={setTime}
                open={props.open} 
                onClose={onClose}
                handleSave={handleSave}
                />
        </MobileView>
    </div>

}

const styles = {
    modal: {
        modal : {
            width: "100%"
        }
    },
    btnDivSalvar: {
        marginTop: 55
    }
}

export default Component;