import React from "react"
import { css } from "emotion";
import { BeatLoader } from "react-spinners";

const Component = ({data, loading, ImgTemplate}) => {
        return  <div className={css(styles.divMain)}>
            <div className={css(styles.listMiniCards)}>
            {loading && <BeatLoader css={styles.loadingCss} color={"#110F62"} size={25}/>}
            {!loading && data && data.map((item,i) =>
            <div key={i} className={css(styles.miniCard)} onClick={() => item.action && item.action()}>
                <div className={css(styles.imgCardDiv)}>
                    {(item.image !== undefined) && <div>
                        {ImgTemplate ? <ImgTemplate id={item.id} img={item.image }/> : 
                        <img src={item.image } className={css(styles.imgCard)} />}
                    </div>}
                </div>
                <div className={css(styles.nameDiv)}>
                    <span className={css(styles.nameSpan)}>{item.name || item.nome}</span>
                </div>
            </div>)}
        </div>
    </div>
}



const styles = {
    divMain: {
        position: "relative",
        "@media(max-width: 992px)": {
            width: "calc(100% + 20px)",
            overflowX: "auto"
        }
    },
    listMiniCards:{
        display: "flex",
        flexWrap: "wrap",
        width: "max-content"
    },
    loadingCss: {
        width: "100%",
        textAlign: "center",
        marginTop: 30,
    },
    miniCard: {
        cursor: "pointer",
        position: "relative",
        backgroundColor: "#fff",
        borderRadius: 9,
        marginRight: 25,
        marginTop: 20,
        boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.15)",
        height: 189,
        width: 170,
        marginBottom: 10,
        marginLeft: 5,
        "@media(max-width: 900px)": {
            boxShadow: " 0 0 2px 2px rgba(0, 0, 0, 0.15)",
            height: 140,
            width: 120,
        }
    },
    nameDiv: {
        position: "absolute",
        display: "flex",
        width: "100%",
        bottom: 10,
        "@media(max-width: 900px)": {
            bottom: 4,
        }
    },

    nameSpan: {
        fontFamily: "Roboto",
        fontSize: 15,
        textAlign: "center",
        overflowX: "hidden",
        textOverflow: "ellipsis",
        paddingRight: 7,
        paddingLeft: 7,
        whiteSpace: "nowrap",
        flex: 1,
    },
    imgCardDiv: {
        justifyContent: 'center',
        position: "relative",
        top: 35,
        "@media(max-width: 900px)": {
            top: 20,
        },
        display: "flex"
    },
    imgCard: {
        borderRadius: "50%",
        flex: 1,
        width: 80,
        height: 80
    }
}

export default React.memo(Component);