import React, {useContext, useState, useEffect} from "react"
// import ModalCategoria from "./CadastroEventoSteps/AdicionarCategoria/ModalCategoria"
import DataTable from 'react-data-table-component';
import FormEventoContext from "../../FormEventoContext/FormEventoContext" 
import { actions } from "../../FormEventoContext";
import { css } from "emotion";
import ModalCategoria from "./ModalCategoria";
import ModalDelete from "../../../../components/ModalDelete";
import Api from "../../../../api/Api";
import { toast } from "react-toastify";

const Categoria = (props) => {

    const {state, dispatch} = useContext(FormEventoContext);
    const [values,setValues] = useState([])
    const [idModal,setIdModal] = useState(0)
    const [openModal,setOpenModal] = useState(false)
    const [openDelete, setOpenDelete] = useState(false)
    const [idDelete, setIdDelete] = useState()

    const onDelete = async (id) => {
        try {
            const categories = state.categories;
            if(!categories[id].new){
                await Api().get("categorias/verificaVinculoCronometragem/"+id)
            }
            delete categories[id]
            dispatch({type: actions.ADD_CATEGORIES, payload: {...categories}})
        } catch (e){
            const response = e.response ? e.response : {};
            const data = response.data ? response.data : {};
            const msg = data.msg ? data.msg : "Ocorreu um problema";
            if(data.type == "cronometragem"){
                setIdDelete(id)
                setOpenDelete(true)
            }else {
                toast.error(msg)
            }
        }
    }

    const onDeleteComplete = () => {
        const categories = state.categories;
        delete categories[idDelete]
        dispatch({type: actions.ADD_CATEGORIES, payload: {...categories}})
        setIdDelete(false)
    }

    const openModalFunc = (state) => {
        setIdModal(state.id)
        setOpenModal(true)
    }

    useEffect(() => {
        setValues(Object.values(state.categories))
    },[state.categories])

    useEffect(() => {
        let lotsCategory = [];
        let categoriesWarning = [];

        Object.values(state.categories).map((category) => {
            let someLoteWithWarning = false;
            let lotes;
            if(category.lote){
                lotes = Object.values(category.lote)
                lotes.map((lote) => {
                    if(!category.gratuito && (lote.preco == 0 || !lote.preco)){
                        someLoteWithWarning = true
                    } else if(category.gratuito && !lote.dataInicio){
                        someLoteWithWarning = true;
                    }
                    lotsCategory.push({...lote})
                })
            }else {
                lotes = []
            }
           

            if((someLoteWithWarning || lotes.length == 0) ) {
                categoriesWarning.push(category.id)
            }
        })
        
     
        let dateLotsGreater;
        if(lotsCategory.length > 0){
            dateLotsGreater = (Math.max.apply(null, lotsCategory.map( e => {
                return isNaN(e.dataFim) ?  -Infinity : e.dataFim;})));
        }

        dispatch({type: actions.UPDATE_WARNING_CATEGORIES_END_SUBSCRIPTION, payload: {categoriesWarning, dateLotsGreater}})
    },[state.categories])
    
    const columns = [
        {
            name: '',
            selector: 'photo',
            cell:(row) => {
                return( 
                    row.pictureUrl && <img src={row.pictureUrl} className={css(styles.photo)}/>
                );
            }
        },
        {
            name: 'Nome da categoria',
            selector: 'name',
            sortable: true,
            style:{display: "inline-grid"},
            cell:(row) => {
                return(<div > 
                    <div><span className={css(styles.itemTableSpan)}>{(row.nome)}</span></div>
                </div>);
            }
        },
        {
            name: 'Distancia',
            selector: 'distancia',
            sortable: true,
            style:{display: "inline-grid"},
            cell:(row) => {
                return(<div > 
                    <div><span className={css(styles.itemTableSpan)}>{(row.distancia)}</span></div>
                </div>);
            }
        },
        {
            name: 'Esporte',
            selector: 'esporte',
            sortable: true,
            style:{display: "inline-grid"},
            cell:(row) => {
                let esporte = (state.listSports.filter(option => option.value == row.esporte))
                esporte = esporte ? (esporte[0] ? esporte[0].label : "") : ""
                return(<div > 
                    <div><span className={css(styles.itemTableSpan)}>{esporte}</span></div>
                </div>);
            }
        },
 
        {
            name: 'Editar',
            ignoreRowClick: true,
            cell:(row) => 
            <div>
                <span 
                href="#modal1" 
                id={row.id} 
                onClick={ () => openModalFunc({id: row.id}) } 
                className={"material-icons modal-trigger "+css(styles.iconEdit)}>edit</span>
            </div>,
        },
        {
            name: 'Farol',
            ignoreRowClick: true,
            cell:(row) => 
            <div>
               {state.categoriesWarning.includes(row.id) && <span  className={"material-icons modal-trigger "+css(styles.farol)}>error_outline</span>}
            </div>,
        },
        {
            name: '',
            ignoreRowClick: true,
            cell:(row) => 
            <div>
                {(row.torneio_id == null || row.torneio_id == undefined || !Object.keys(state.tournaments).includes(`${row.torneio_id}`)) && <span href="#modal1" id={row.id} 
                onClick={ () => onDelete(row.id) } className={"material-icons modal-trigger "+css(styles.iconDelete)}>delete</span>}
            </div>,
        }
        
    ]

    return  <div> 
    <DataTable
        columns={columns}
        data={values}
        highlightOnHover={true}
        pointerOnHover={true}
        noHeader={true}
        noDataComponent={""}
    />
    <ModalDelete 
        open={openDelete}
        onDelete={onDeleteComplete}
        onClose={() => setOpenDelete(false)}>
        Esta categoria está vinculada a uma cronometragem, realmente deseja exclui-la?
    </ModalDelete>
    <ModalCategoria id={idModal} open={openModal} toggle={setOpenModal} edit={true}/>
</div>
}

const styles={
    farol: {
        cursor:'pointer',
        color:'yellow'
    },
    newEvento : {
        float:"left"
    },
    iconDelete: {
        cursor:'pointer',
        color:'red'
    },
    iconEdit: { 
        cursor:'pointer',
        color:'"#000'
    },
    main: {
        paddingBottom: 20
    },
    photoEvent: {
        width: "100%",
        paddingTop: 20,
        position: "relative"
    },
    photo: {
        width: 40,
        height: 40,
        borderRadius: 250,
        marginTop: 5,
        marginBottom: 5
    },
    itemTableSpan: {
        fontSize: 14
    },
    iconBtn: {
        cursor:'pointer',
        color:'red !important'
   },
}

export default React.memo(Categoria);