import React from 'react';

import {
    BrowserRouter as Router,
    Switch,
    Route
    
  } from "react-router-dom";

  import App from "../layouts/App"
  import AppHome from "../layouts/AppHome"
  import LoginOrganizadorJuiz from "../containers/Login/LoginOrganizadorJuiz"
  import LoginAtleta from "../containers/Login/LoginAtleta"
  import EsqueceuSenha from "../containers/Login/EsqueceuSenha"
  import FormCreateUsuario from "../containers/Usuario/FormCreateUsuario"
  import FormCreateAtleta from "../containers/Atleta/FormCreateAtleta"
  import FormUpdateUsuario from "../containers/Usuario/FormUpdateOrganizador"
  import TableEventos from "../containers/Evento/TableEventos"
  import TableTorneios from "../containers/Torneio/TableTorneios"
  import FormEvento from "../containers/Evento/FormEvento"
  import FormTorneio from "../containers/Torneio/FormTorneio"
  import ConfiguracoesMenu from "../containers/Configuracoes/Menu"
  import ConfiguracoesMenuAtleta from "../containers/Configuracoes/MenuAtleta"
  import ApresentacaoEvento from "../containers/ApresentacaoEvento/ApresentacaoEvento"
  import ApresentacaoTorneio from "../containers/ApresentacaoTorneio/ApresentacaoTorneio"
  import Home from "../containers/Home/Home"
  import Busca from "../containers/Home/Busca"
  import HomeTorneio from "../containers/HomeTorneio/HomeTorneio"
  import AppAtleta from "../layouts/AppAtleta"
  import HallFama from "../containers/HallFama/HallFama"
  import MinhasEquipes from "../containers/Equipe/MinhasEquipes"
  import JuizCronometro from "../containers/JuizCronometro/JuizCronometro"
  import ResultadoEvento from "../containers/ApresentacaoEvento/ResultadoEvento"
  import InscricaoProvider from "../containers/Inscricao/InscricaoProvider"
  import TicketsComprados from "../containers/Inscricao/TicketsComprados"
  import CarteiraAtleta from "../containers/CarteiraAtleta/Carteira"
  import CarteiraOrganizador from "../containers/CarteiraOrganizador/Carteira"
  import ResetSenhaOrganizador from "../containers/Senha/ResetSenhaOrganizador"
  import ResetSenhaAtleta from "../containers/Senha/ResetSenhaAtleta"
  import PagamentoSucessoMobile from "../containers/Inscricao/PagamentoSucessoMobile"
  import ConviteEquipe from "../containers/Equipe/ConviteEquipeProvider"
  import AppAdmin from '../layouts/AppAdmin';
  import Organizadores from '../containers/Organizadores/Organizadores';
  import Acessos from '../containers/Acessos/Acessos';
  import FormAcesso from '../containers/Acessos/FormAcesso';
  import FormPoliticas from '../containers/Politica/FormPoliticas';
  import Relatorios from '../containers/Relatorios/Relatorios';

  const Routes = () => (
    <Router  basename="/">
        <Switch>
            <Route exact path="/" component={props => { return  ( <AppHome> <Home/> </AppHome>) } }/>
            <Route exact path="/busca" component={props => { return  ( <AppHome> <Busca/> </AppHome>) } }/>
            <Route exact path="/atleta/carteira" component={props => { return  ( <AppAtleta> <CarteiraAtleta/> </AppAtleta>) } }/>
            <Route exact path="/organizador/carteira" component={props => { return  ( <App> <CarteiraOrganizador/> </App>) } }/>
            <Route exact path="/atleta/hall-fama" component={props => { return  ( <AppAtleta> <HallFama/> </AppAtleta>) } }/>
            <Route exact path="/atleta/minhas-equipes" component={props => { return  ( <AppAtleta> <MinhasEquipes/> </AppAtleta>) } }/>
            <Route exact path="/atleta/convites" component={props => { return  ( <AppAtleta> <ConviteEquipe/> </AppAtleta>) } }/>
            <Route exact path="/atleta/cronometro" component={props => { return  ( <AppAtleta> <JuizCronometro {...props} type="atleta"/> </AppAtleta>)} }/>
            <Route exact path="/evento/apresentacao/:id/resultado" component={props => { return  (  <ResultadoEvento {...props}/> ) } }/>
            <Route exact path="/evento/apresentacao/:id/resultado_review" component={props => { return  (  <ResultadoEvento {...props}/> ) } }/>
            <Route path="/evento/apresentacao/:id" component={props => { return  (  <ApresentacaoEvento {...props}/> ) } }/>
            <Route exact path="/evento/inscricao/:id" component={props => { return  (  <InscricaoProvider {...props}/> ) } }/>
            <Route exact path="/evento/inscricao/:id/tickets" component={props => { return  (  <TicketsComprados {...props}/> ) } }/> 
            <Route exact path="/torneio/apresentacao/:id" component={props => { return  ( <ApresentacaoTorneio {...props}/> ) } }/>
            <Route exact path="/login" component={props => { return  ( <LoginOrganizadorJuiz {...props}/>)} }/>
            <Route exact path="/atleta/login" component={props => { return  ( <LoginAtleta {...props}/>)} }/>
            <Route exact path="/esqueceusenha" component={props => { return  ( <EsqueceuSenha {...props}/>)} }/>
            <Route exact path="/organizador/editar" component={props => { return  ( <App> <FormUpdateUsuario {...props}/> </App>)} }/>
            <Route exact path="/usuario/novo" component={props => { return  ( <FormCreateUsuario {...props}/>)} }/>
            <Route exact path="/atleta/novo" component={props => { return  ( <FormCreateAtleta {...props}/>)} }/>
            <Route exact path="/eventos" component={props => { return  ( <App> <TableEventos {...props}/> </App>)} }/>
            <Route exact path="/torneios" component={props => { return  ( <App> <TableTorneios {...props}/> </App>)} }/>
            <Route exact path="/eventos/:id/editar" component={props => { return  ( <App> <FormEvento {...props}/> </App>)} }/>
            <Route exact path="/eventos/novo" component={props => { return  ( <App> <FormEvento {...props}/> </App>)} }/>
            <Route exact path="/torneios/:id/editar" component={props => { return  ( <App> <FormTorneio {...props}/> </App>)} }/>
            <Route exact path="/torneios/novo" component={props => { return  ( <App> <FormTorneio {...props}/> </App>)} }/>
            <Route exact path="/organizador/trocarsenha" component={props => { return  ( <App> <ResetSenhaOrganizador {...props}/> </App>)} }/>
            <Route exact path="/atleta/trocarsenha" component={props => { return  ( <AppAtleta> <ResetSenhaAtleta {...props}/> </AppAtleta>)} }/>
            <Route exact path="/configuracoes/menu" component={props => { return  ( <App> <ConfiguracoesMenu {...props}/> </App>)} }/>
            <Route exact path="/configuracoes/menuatleta" component={props => { return  ( <AppAtleta> <ConfiguracoesMenuAtleta {...props}/> </AppAtleta>)} }/>
            <Route exact path="/home/torneios" component={props => { return  ( <AppHome> <HomeTorneio {...props}/> </AppHome>)} }/>
            <Route exact path="/juiz/cronometro" component={props => { return  ( <App> <JuizCronometro {...props} type="organizador"/> </App>)} }/>
            <Route exact path="/mobile/pagamento/sucesso" component={props => { return  (  <PagamentoSucessoMobile {...props}/> )} }/>
            <Route exact path="/admin/organizadores" component={props => { return  ( <AppAdmin> <Organizadores {...props}/> </AppAdmin>)} }/>
            <Route exact path="/admin/acessos" component={props => { return  ( <AppAdmin> <Acessos {...props}/> </AppAdmin>)} }/>
            <Route exact path="/admin/acessos/novo" component={props => { return  ( <AppAdmin> <FormAcesso {...props}/> </AppAdmin>)} }/>
            <Route exact path="/admin/acessos/:id/editar" component={props => { return  ( <AppAdmin> <FormAcesso {...props}/> </AppAdmin>)} }/>
            <Route exact path="/admin/relatorios" component={props => { return  ( <AppAdmin> <Relatorios {...props}/> </AppAdmin>)} }/>
            <Route exact path="/admin/politica" component={props => { return  ( <AppAdmin> <FormPoliticas {...props}/> </AppAdmin>)} }/>
        </Switch>
    </Router>
)
export default Routes