export default {
    ADD_TICKET: 'ADD_TICKET',
    SET_ATHLETE_TICKET: 'SET_ATHLETE_TICKET',
    DELETE_ATHLETE_TICKET: 'DELETE_ATHLETE_TICKET',
    SET_TEAM_TICKET: 'SET_TEAM_TICKET',
    DELETE_TEAM_TICKET: 'DELETE_TEAM_TICKET',
    SET_TERM_TICKET: 'SET_TERM_TICKET',
    SET_CLOTHES: 'SET_CLOTHES',
    SET_HEADER_INFO: 'SET_HEADER_INFO',
    SET_PAGE: 'SET_PAGE',
    HANDLE_CHANGE: "HANDLE_CHANGE",
    SET_PRE_ATHLETE_TICKET: "SET_PRE_ATHLETE_TICKET"
}