import React, { useEffect, useState, useRef } from "react"
import {
    useHistory, useLocation
} from "react-router-dom";
import Api from "../../api/Api"
import { toast } from "react-toastify";
import {useCookies} from "react-cookie"
import {
    BrowserView,
    MobileView,
} from "react-device-detect";
import LoginAtletaBrowser from "./LoginAtletaLayout/LoginAtletaBrowser";
import LoginAtletaMobile from "./LoginAtletaLayout/LoginAtletaMobile";

export default () => {
    const [, setCookie] = useCookies();

    const [email,setEmail] = useState("")
    const [password,setPassword] = useState("");
    const [loading,setLoading] = useState(false)
    const history = useHistory()

    const refForm = useRef(null);
    const location = useLocation()
    const locationState = location.state

    const onSubmit = async (e) => {
        e.preventDefault()
        if(!refForm.current.reportValidity()){
            toast.error("Campos obrigatórios não foram preenchidos");
            return
        }
        setLoading(true)
        try {

            const result = await Api().post("/login",{email,password,tipo:"at"},{"Content-Type" : "application/json"})
            login(result)
            
          
        } catch(e){
            toast.error("Não autorizado")
            setLoading(false)
        }
    }

    const login = (result) => {
        const access_token = result.data.access_token;
        const nome = result.data.nome
        const imagem = result.data.imagem
        const id = result.data.id
        if(access_token){
            setCookie('access_token', access_token, { path: '/' });
            setCookie('nome', nome, { path: '/' });
            setCookie('imagem', imagem, { path: '/' });
            setCookie('tipo', 'at', { path: '/' });
            setCookie('id', id, { path: '/' });
            console.log(locationState)
            setLoading(false)

            if(locationState && locationState.inscricao){
                console.log("Teste 12")
                history.replace("/evento/inscricao/"+locationState.inscricao.id, {inscricao: locationState.inscricao} )
                return;
            } else {
                console.log("Teste 33")
                history.replace("/")
            }

        }else {
            toast.error("Não autorizado")
        }
    }

    useEffect(() => {
        if(localStorage.getItem("access_token")){
            history.replace("eventos")
        }
    },[])

    const responseFacebook = async (response) => {
        history.replace("/atleta/login")
        const result = await Api().post("/atleta/loginfb",{ access: response.accessToken, fb_id: response.userID},{"Content-Type" : "application/json"})
        const data = result.data ? result.data : {};
        const info = data.info ? data.info : {};
        if(info.origin == "facebook"){
            history.push("novo",
            {"email": info.email, "name": info.name, 
            "fb_id": response.userID,
            "imagem": info.imagem, "origin": "facebook"})
        }else {
            login(result)
        }
    }

    const responseGoogle = async (response) => {
      
        const result = await Api().post("/atleta/logingo",{access: response.accessToken},{"Content-Type" : "application/json"})
        const data = result.data ? result.data : {};
        const info = data.info ? data.info : {};
        if(info.origin == "google"){
            history.push("novo",
            {"email": info.email, "name": info.name, 
            "imagem": info.imagem, "origin": "google"})
        }else {
            login(result)
        }
    }
    return <>
        <BrowserView>
            <LoginAtletaBrowser
                refForm={refForm}
                onSubmit={onSubmit}
                loading={loading}
                email={email}
                password={password}
                setEmail={setEmail}
                setPassword={setPassword}
                responseGoogle={responseGoogle}
                responseFacebook={responseFacebook}
            />
        </BrowserView>
        <MobileView>
            <LoginAtletaMobile
                 refForm={refForm}
                 onSubmit={onSubmit}
                 loading={loading}
                 email={email}
                 password={password}
                 setEmail={setEmail}
                 setPassword={setPassword}
                 responseGoogle={responseGoogle}
                 responseFacebook={responseFacebook}
            />
        </MobileView>
    </>
   
}