import React, { useState, useEffect } from "react"
import { toast } from "react-toastify";
import Api from "../../api/Api"
import { Row, Col, Container } from "react-materialize"
import {Button, Input, Select} from "../../components/Form"
import {
    Link 
} from "react-router-dom";
import config from "../../config.js"
import {BeatLoader} from "react-spinners";

export default () => {

    const [filterName, setFilterName] = useState("")
    const [filterEdition, setFilterEdition] = useState("")
    const [filterSport, setFilterSport] = useState({ value: '', label: 'Todos os Esportes' })
    const [filter, setFilter] = useState({})
    const [loadingTable, setLoadingTable] = useState(true)
    const [listSports,setListSports] = useState([])
    const [data, setData] = useState([])


    const onClickSearch = () => {
        setFilter({
            nome: filterName,
            edicao: filterEdition,
            esporte: filterSport.value,
            situacao: true,
        })
    }


    useEffect(() => {
        findData()
    },[filter])

    useEffect(() => {
        getSports()
    },[])

    const getSports = async () => {
        try {
            const result = await Api().get("/esportes/listar") 
            if(result.data.esportes){
                let sports = []
                sports.push({value: "", label: "Todos os Esportes"})
                result.data.esportes.map((data) => {
                    sports.push({ value: data.id, label: data.nome },)
                })
                setListSports(sports)
            }
        } catch(e){
            const response = e.response ? e.response : {};
            const data = response.data ? response.data : {};
            const msg = data.msg ? data.msg[0] : "Ocorreu um problema para retorna os esportes";
            toast.error(msg)
        }
    }

    const findData = async () => {
        try {
            setLoadingTable(true)
            const result = await Api().post("/torneios/listar",{...filter})
            console.log(result)
            const resultData = result.data.torneios ? result.data.torneios : []
            let internalData = [];
            resultData.map((value) => {
                internalData.push({
                    id: value.id,
                    name: value.nome,
                    photo: value.imagem ? config.storage_link+`/torneio/${value.id}/${value.imagem}` : "",
                    edition: value.edicao
                })
            })
            setData(internalData)
            setLoadingTable(false)
        }catch(e){
            const response = e.response ? e.response : {};
            const data = response.data ? response.data : {};
            const msg = data.msg ? data.msg[0] : "Ocorreu um erro ao buscar os eventos";
            toast.error(msg)
        }

    }

    return <Container style={styles.container} className={"containerMain"}>
        <div>
            <Row>
                <Col s={6} l={3}>
                    <Input
                        id="name"
                        label={"Nome do Torneio:"}
                        value={filterName}
                        onChange={(e) => setFilterName(e.target.value)}
                    />
                </Col>
                <Col s={6} l={3}>
                    <Input
                        id="edition"
                        label={"Edição:"}
                        value={filterEdition}
                        onChange={(e) => setFilterEdition(e.target.value)}
                    />
                </Col>
                <Col s={6} l={3}>
                    <Select
                        id="dates"
                        label={"Esporte:"}
                        isSearchable={false}
                        value={filterSport}
                        options={listSports}
                        onChange={setFilterSport}
                    />
                </Col>
                <Col s={6} l={3}>
                    <Button style={{marginTop: 30}} onClick={onClickSearch}>
                        Buscar
                    </Button>
                </Col>
            </Row>
        </div>
        <div>
            {loadingTable && <div>
                <BeatLoader css={styles.loadingCss} color={"#047AB2"} size={25}/>
            </div>}
            <Row style={styles.itens}>
                <div>
                {!loadingTable && data.map((row) => {
                    return <Col key={"tor"+row.id} s={12} style={styles.item} m={3} className={"box"}>
                        <div>
                            <img 
                                style={{height: 200, width: "100%", objectFit: "cover"}}
                                key={"1"} src={row.photo}
                            />
                            <h5 style={{color: "#000", overflow: "hidden", height: 50, overflowY: "hidden"}}>{row.name}</h5>
                            <p style={{fontSize: 16, lineHeight: 1, paddingTop: 0, color: "#000"}}>Edição: {row.edition}</p>
                            <Link to={"/torneio/apresentacao/"+row.id}>
                                <Button style={{width: "80%", marginLeft: "10%", marginTop: 10}}>Visualizar</Button>
                            </Link>
                        </div>
                    </Col>
                })}
                </div>
            </Row>
        </div>
    </Container>
}

const styles = {
    container: {
        marginTop: 30
    },
    loadingCss: {
        width: "100%",
        textAlign: "center",
        //marginLeft: "50%",
        marginTop: 100,
    },
    photoEvent: {
        maxWidth: "100%",
        height: 140,
        position: "relative",
        objectFit: "cover"
    },
    itens: {
        marginTop: 80,
       // height: "100%",
    },
    item: {
       // height: "100%",
        paddingTop: 10,
        marginTop: 30,
        display: "flex"
    },
    infoEvento: {
        fontSize: 16,
        display: "inline-block"
    },
    divInfoEvento: {
        whiteSpace: "normal"
    },
    spanNome: {
        fontWeight: 500
    },
    spanDia: {
        fontSize: 30,
        fontWeight: 500,
        display: "inline-block"
    },
    spanMes:{
        fontSize: 30,
        color: "rgb(4, 122, 178)"
    }
}