import React from "react"
import DatePicker from "react-datepicker";
import InputMask from 'react-input-mask';

const Component = (props) => {
    return <div style={style.divcomponent}> 
            <label 
            htmlFor={props.id} 
            className="left-align" 
            style={style.label}>{props.label}</label>
            <div style={style.divcomponent}>
            <DatePicker
                locale="pt-BR"
                timeIntervals={5}
                showTimeSelect
                timeFormat={"HH:mm"}
                dateFormat="dd/MM/yyyy HH:mm"
                selected={props.value}
                id={props.id}
                isClearable={true}
                style={style.input}
                onChange={date => props.onChange && props.onChange(date)}
                {...props}
                customInput={<InputMask
                    mask={"99/99/9999 99:99"}
                    placeholder="__/__/____ __:__"
                    style={style.input}
            />}
        /> 
            </div>
       
    </div>
}

const style = {
    label : {
        color: "#000" 
    },
    divcomponent : {
        width: "100%"
    },
    input: {
        width: "100%"
    }
}

export default React.memo(Component)
