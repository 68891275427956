import React from "react"
import {  useHistory } from "react-router-dom"
import {  CardPanel } from "react-materialize"
import { css } from "emotion"
import Api from "../../api/Api"


const Component =  ({item}) => {

    const history = useHistory()

    const onClick = () => {
        Api().post("eventos/somavisualizacao/"+item.id)
        history.push("evento/apresentacao/"+item.id)
    }
    return <CardPanel style={styles.card_panel} onClick={onClick}>
            <img src={item.photo} className={css(styles.photoEventCard)}/>
    </CardPanel>

}

const styles = {
    box: {
        borderRadius: 20,
        height: 275,
        paddingTop: 10,
        marginTop: 10
    },
    card_panel: {
        cursor: "pointer",
        padding: 0,
        height: 125,
        borderRadius: 10,
        display: 'block',
        overflow: "hidden"
    },
    photoEventCard: {
        width: "100%",
        borderRadius: 10,
        height: 125,
        objectFit: "cover",
        display: "block",
    },
    divInfoEvento: {
        whiteSpace: "normal"
    },
    infoEvento: {
        fontSize: 16,
        marginLeft: 5,
        marginTop: 10,
        color: "#6A6A6A",
        display: "flex"
    },
    spanNome: {
        fontSize: 18,
        fontWeight: 500
    },
    spanData: {
        fontWeight: 300,
        fontSize: 16,
    },
    spanEnd:{
        fontWeight: 300,
        fontSize: 16,
    },
}

export default React.memo(Component)