import React from "react" 
import logo from "../assets/img/logo.png"
import { css } from "emotion"
import evento_icon from "../assets/icons/evento.svg"
import sair_icon from "../assets/icons/sair.svg"
import carteira_icon from "../assets/icons/carteira.svg"
import equipe_icon from "../assets/icons/equipe.svg"
import hall_fama_icon from "../assets/icons/hall-fama.svg"
import cad_icon from "../assets/icons/cad.svg"
import entrar_icon from "../assets/icons/entrar.svg"
import juiz_icon from "../assets/icons/juiz.svg"
import { Link, useHistory } from "react-router-dom"
import { useCookies} from "react-cookie"
import { BrowserView, MobileView } from "react-device-detect"
import MenuHomeAtletaBrowser from "./MenuHomeAtletaLayout/MenuHomeAtletaBrowser"
import MenuHomeAtletaMobile from "./MenuHomeAtletaLayout/MenuHomeAtletaMobile"

const Component = () => {
    return  <>
        <BrowserView>
            <MenuHomeAtletaBrowser/>
        </BrowserView>
        <MobileView>
            <MenuHomeAtletaMobile/>
        </MobileView>
    </>
    
}

const styles = {
    sideMenu: {
        width: 180,
    },
    itemMenu: {
        marginTop: 3,
        paddingLeft: 5
    },
    itemMenuText: {
        fontSize: 20,
        fontWeight: 500
    },
    title: {
        fontFamily: "Roboto",
        marginLeft: 80,
        fontSize: 22,
        fontWeight: "normal"
    },
    itemMenuRight: {
        marginRight: 30,
        marginTop: 10,
        lineHeight: "normal !important",
        textAlign: "center"
    },
    navWrapper: {
        backgroundColor: "#6B69CF !important",
        boxShadow: "none"
    },
    logo : {
        marginTop: -10,
        borderRadius: 25,
    },
    footerMenu: {
        position: "fixed",
        height: 50,
        bottom: 0,
        width: "100%",
        zIndex: 10,
        backgroundColor: "#6B69CF",
        "@media(min-width: 850px)": {
            display: "none"
        }
    },
    itemsMenuFooter: {
        display: "flex",
        justifyContent: "space-evenly"
    },
    itemMenuIconFooter: {
        marginTop: 5,
        width: 40, 
        textAlign:"center",
    },
    itemMenuIconFooter: {
        marginTop: 5,
        width: 40, 
        textAlign:"center",
    },
}

export default (Component)