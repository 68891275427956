import { css } from "emotion"
import React, { useContext } from "react"
import HeaderViewEventoMobile from "../../../../layouts/HeaderViewEventoMobile"
import ApresentacaoEventoContext from "../../ApresentacaoEventoContext"
import clock_icon from "../../../../assets/icons/clock.svg"
import ItemPercursoLista from "./ItemPercursoLista"
import Carousel from "../../../../components/Carousel"
import { useHistory, useLocation, useParams } from "react-router-dom"
import { Button } from "../../../../components/Form"
import config from "../../../../config"
import moment from "moment"
export default () => {

    const apresentacaoContext = useContext(ApresentacaoEventoContext)
    const {id} = useParams(); 
    const {state} = useLocation()
    const history = useHistory()

    const openContact = () => {
        history.push(`/evento/apresentacao/${id}/faleconosco`)
    }

    const nextPage = () => {
        history.push(`/evento/apresentacao/${id}/categorias`)
    }

    const goToResult = () => {
        history.push(`/evento/apresentacao/${id}/resultado`)
    }

    return <div>
        <div className={css(styles.content)}>
            {!apresentacaoContext.state.loading && <><label className={css(styles.title)}>{apresentacaoContext.state.name}</label><br/>
            <div className={css(styles.flexHour)}>
                <img src={clock_icon} width={10} /> 
                <label className={css(styles.time)}>{apresentacaoContext.state.dateStart2}</label>
            </div>
            <div  className={css(styles.addressDiv)}>
                <label className={css(styles.address)}> {apresentacaoContext.state.street} {apresentacaoContext.state.number} - {apresentacaoContext.state.neighborhood} - {apresentacaoContext.state.zipCode}</label>
            </div>
            <div className={css(styles.addressDiv)}>
                <label className={css(styles.address)}>{apresentacaoContext.state.city} - {apresentacaoContext.state.state} </label>
            </div>
            <hr className={css(styles.hr)}/>
            <div>
                <label className={css(styles.subTitle)}>Descrição</label>
            </div>
            <div>
                <div dangerouslySetInnerHTML={{ __html: apresentacaoContext.state.description }}></div>
            </div>
            {apresentacaoContext.state.pictureRoute &&  apresentacaoContext.state.pictureRoute.length > 0 && <><div>
                <label className={css(styles.subTitle)}>Percurso</label>
            </div>
            <div className={css(styles.route)}>
                <Carousel 
                    infinite={false}
                    items={apresentacaoContext.state.pictureRoute}
                    controls={false}
                    dots={true}
                    extraParamsItem={{id}}
                    template={ItemPercursoLista}
                />
            </div></>}
            <div>
                <label className={css(styles.subTitle)}>Organizador</label>
            </div>
            <div className={css(styles.organizer)}>
                <div>
                    <label className={css(styles.organizerName)}>{apresentacaoContext.state.organizer}</label>
                </div>
                <div>
                    <Button onClick={openContact}>Contato</Button>
                </div>
            </div>
            {apresentacaoContext.state.patrocinadores.length > 0 && <><div>
                <label className={css(styles.subTitle)}>Patrocinadores</label>
            </div>
            <div  className={css(styles.sponsors)}>
                {apresentacaoContext.state.patrocinadores.map((item) => {
                    return <img src={config.storage_link+`/patrocinador/${item.imagem}`} className={css(styles.imgSponsor)} />
                    
                })}
            </div></>}
            <div className={css(styles.divSpacingBottom)}></div>
                {moment(apresentacaoContext.state.endDateSubscription,'DD/MM/YYYY') > moment() ? <Button
                    onClick={nextPage}
                    backgroundColor={"#6B69CF"}
                    className={css(styles.btnBuy)}>
                        Comprar
                </Button> : <Button
                    onClick={() => goToResult()}
                    backgroundColor={"#6B69CF"}
                    className={css(styles.btnBuy)}>
                        Resultado
                </Button>}
            </>}
        </div>
    </div>
}

const styles = {
    divSpacingBottom: {
        paddingBottom: 100

    },
    title: {
        fontFamily: "Roboto",
        fontWeight: "600",
        fontSize: 18,
        color: "#000"
    },
    content: {
        paddingLeft: 20,
        paddingRight: 20
    },
    time: {
        fontFamily: "Roboto",
        fontWeight: "500",
        marginLeft: 10,
        fontSize: 12
    },

    flexHour: {
        marginTop: 5,
        display: "flex"
    },
    address: {
        fontFamily: "Roboto",
        fontSize: 12,
        marginLeft: 20
    },
    addressDiv: {
        lineHeight: 0.95
    },
    hr: {
        marginTop: 20,
        border: "0.5px solid #C4C4C4"
    },
    subTitle: {
        fontFamily: "Roboto",
        fontSize: 16,
        color: "#000",
        fontWeight: "600",
        marginTop: 30,
    },
    route: {
        paddingTop: 20
    },
    organizer:{
        marginTop: 20,
        display: 'flex',
        justifyContent: "space-between",
        marginBottom: 20
    },
    organizerName: {
        marginTop: 10,
        fontSize: 14
    },
    sponsors: {
      //  display: "flex",
        marginTop: 20,
        height: "100%",
        objectFit: "cover",
       // flex: "50%"
    },
    imgSponsor: {
        borderRadius: 15,
        paddingRight: 10,
        paddingLeft: 10,
        width: "50%",
        height: "100%",
    },
    btnBuy: {
        marginTop: 10,
        borderBottomRightRadius: "0px !important",
        borderBottomLeftRadius: "0px !important",
        height: 50,
        position: "fixed",
        left: 0,
        bottom: 0
    }
}