import { css } from "emotion"
import React, { useContext } from "react"
import { Container } from "react-materialize"
import { Input, Select } from "../../components/Form"
import ModalMobileFullScreen from "../../components/ModalMobileFullScreen"
import useSports from "../../hooks/useSports"
import HomeContext from "./HomeContext"
import listUf from "../../constants/listUf"

export default () => {
    const contextHome = useContext(HomeContext)

    const sports = useSports()

    const theme =  (theme) => ({
        ...theme,
        borderRadius: 0,
        colors: {
            ...theme.colors,
            primary25: '#6B69CF',
            primary: '#6B69CF',
            neutral80: "#6B69CF",
            neutral20: "#6B69CF"
        },
    })
    return <div>
        <ModalMobileFullScreen 
            open={contextHome.filtering} 
            toggle={contextHome.setFiltering}
            title={"Filtro"}
            btnFooterText={"Aplicar"}
            onClickBtnFooter={() => {
                contextHome.setFiltering(false)
                contextHome.onClickSearch()
            }}
        >
            <Container>
                <div>
                    <span className={css(styles.text)}>Te ajudamos a descobrir para onde ir!</span>
                </div>
                <div className={css(styles.components)}>
                    <div className={css(styles.component)}>
                        <Select
                            id="esporte"
                            label={"Esporte:"}
                            // labelStyle={styles.labelStyle}
                            theme={theme}
                            // value={type}
                            value={sports.filter(option => option.value == contextHome.filterSport)}
                            isSearchable={false}
                            color="#fff"
                            borderRadius={10}
                            backgroundColor={"#fff"}
                            required={true}
                            onChange={(item) => contextHome.setFilterSport(item.value)}
                            options={sports}
                        /> 
                    </div>
                    {/* <div className={css(styles.component)}>
                        <Select
                            id="estado"
                            label={"Estado:"}
                            // labelStyle={styles.labelStyle}
                            theme={theme}
                            // value={type}
                            isSearchable={false}
                            color="#fff"
                            borderRadius={10}
                            backgroundColor={"#fff"}
                            required={true}
                            onChange={contextHome.setFilterName}
                            options={listUf}
                        /> 
                    </div> */}
                    <div className={css(styles.component)}>
                        <Input
                            id="cidade"
                            label={"Cidade:"}
                            placeholder={"Cidade:"}
                            radius={true}
                            classNameInput={css(styles.input)}
                            backgroundColor="#fff"
                            color="#fff"
                            value={contextHome.filterCity}
                            maxLength={100}
                            required={true}
                            onChange={(e) => contextHome.setFilterCity(e.target.value)}
                        />
                    </div>
                </div>
            </Container>
        </ModalMobileFullScreen>
    </div>
}

const styles = {
    text: {
        fontWeight: "bold",
        fontSize: 16
    },
    components: {
        marginTop: 10
    },
    component: {
        paddingTop: 10
    },
    input: {
        border: "0.5px solid #9e9e9e !important",
        boxShadow: "none"
    }
}