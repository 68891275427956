import React, { useContext,useState } from "react"
import { Card, Row, Col } from "react-materialize"
import { css } from "emotion"
import { ImageUploader, Input, Select, Button, DatePicker } from "../../components/Form"
import useSports from "../../hooks/useSports"
import MinhasEquipesContext, { actions } from "./MinhasEquipesContext"
import apicep from "../../api/ApiCep"
import { toast } from "react-toastify"
import listUf from "../../constants/listUf"
import Api from "../../api/Api"
import { useCookies } from "react-cookie"
import moment from "moment"
import { useEffect } from "react"
import config from "../../config.js"
import MainContext, { actionsMain } from "../MainContext"
import { BrowserView, MobileView } from "react-device-detect"
import FormEquipeBrowser from "./FormEquipeLayout/FormEquipeBrowser"
import FormEquipeMobile from "./FormEquipeLayout/FormEquipeMobile"
import LoadingOverlayContext from "../../components/LoadingOverlayContext/LoadingOverlayContext"

const Component = (props) => {

    const contextMain = useContext(MainContext)
    const loadingOverlay = useContext(LoadingOverlayContext)
    const {state, dispatch, openMembers} = useContext(MinhasEquipesContext)
    const [cookies] = useCookies(['id']); 
    const [loading, setLoading] = useState(false)
    const [name, setName] = useState("")
    const [foundation_data, setFoundationData] = useState(null)
    const [sport, setSport] = useState("")
    const [zipCode, setZipCode] = useState("")
    const [neighborhood, setNeightborhood] = useState("")
    const [city, setCity] = useState("")
    const [uf, setUf] = useState("")
    const [number, setNumber] = useState("")
    const [complement, setComplement] = useState("")
    const [picture, setPicture] = useState("")
    const [pictureUrl, setPictureUrl] = useState("")
    const [street, setStreet] = useState("")

    useEffect(() => {
        if(state.currentElement == "edit_form"){
            (async () => {
                loadingOverlay.activeLoading(true,"Buscando conteúdo")
                const result = await Api().get(`equipe/getinfo/${state.team_id}`)
                loadingOverlay.activeLoading(false)
                const data = result.data ? result.data : {}
              
                if(data.info){
                    const dataInfo = data.info[0]
        
                    setName(dataInfo.nome)
                    setFoundationData(moment(dataInfo.fundado).toDate())
                    if(dataInfo.imagem){
                        setPictureUrl([config.storage_link+`/equipe/${state.team_id}/${dataInfo.imagem}`])
                    }
                    setSport(dataInfo.esporte)
                    setZipCode(dataInfo.cep)
                    setStreet(dataInfo.endereco)
                    setNeightborhood(dataInfo.bairro)
                    setCity(dataInfo.cidade)
                    setNumber(dataInfo.numero)
                    setUf(dataInfo.uf)
                    setComplement(dataInfo.complemento)
        
                }
            })()
        
        }
    },[state.team_id,state.currentElement])

    const onSubmit = (e) => {
        e.preventDefault()
        setLoading(true)
        const formData = new FormData()
        formData.append("nome", name)
        formData.append("fundado", moment(foundation_data).format("YYYY-MM-DD"))
        formData.append("esporte", sport)
        formData.append("cep",zipCode)
        formData.append("endereco",street)
        formData.append("bairro",neighborhood)
        formData.append("cidade",city)
        formData.append("numero",number)
        formData.append("uf",uf)
        formData.append("complemento",complement)
        formData.append("user_id",cookies.id)
        if(state.currentElement == "edit_form"){
            updateApi(formData)
        }else{
            salvarApi(formData)
        }
     
    }

    const salvarApi = async (formData) => {
        try {
            const result = await Api().post("equipe/salvar",formData)
            console.log(result)
            if(result.data.msg && result.data.msg.id){
                toast.success("Equipe criada com sucesso!")
                toast.success("A imagem da equipe será atualizada posteriormente")

                uploadImageEquipe(result.data.msg.id)
                dispatch({type: actions.UPDATE_TEAMS_FORCE})
                openMembers(result.data.msg.id)
            }else {
              
                toast.error("Ocorreu um problema ao tentar salvar")
            }
            setLoading(false)
        }catch(e){
            console.log(e)
            setLoading(false)
            const response = e.response ? e.response : {};
            const data = response.data ? response.data : {};
            const msg = data.msg ? data.msg[0] : "Ocorreu um problema ao tentar salvar";
            toast.error(msg)
        }
       
    }

    const updateApi = async (formData) => {
        try {
            const result = await Api().post("equipe/editar/"+state.team_id,formData)

            if(!result.data.error){
                toast.success("Equipe atualizada com sucesso!")
                toast.success("A imagem da equipe será atualizada posteriormente")

                uploadImageEquipe(state.team_id)
                dispatch({type: actions.UPDATE_TEAMS_FORCE})
                openMembers(state.team_id)
            }else {
              
                toast.error("Ocorreu um problema ao tentar salvar")
            }
            setLoading(false)
        }catch(e){
            console.log(e)
            setLoading(false)
            const response = e.response ? e.response : {};
            const data = response.data ? response.data : {};
            const msg = data.msg ? data.msg[0] : "Ocorreu um problema ao tentar salvar";
            toast.error(msg)
        }
       
    }

    const uploadImageEquipe = async (id) => {
        let formData = new FormData();
        if(picture && picture.length > 0){
            formData.append("imagem",picture[0])

            formData.append("equipe_id",id)
       
            contextMain.dispatch({
                type: actionsMain.ADD_UPLOAD_IMAGES,
                payload: {
                    formData, id,
                    url: "equipes/uploadimagens",
                    type_key: "equipe_id"
            }})
        }
        
    }

 

    const onDrop = (picture,pictureDataURLs) => {
        setPicture(picture)
        setPictureUrl(pictureDataURLs)
    }

    const handleZipCode = async (value) => {
        setZipCode(value)
        if(value.length == 9){
            try {
                const address = await apicep.get(`/${value}/json`);
                const data = address.data
                setStreet(data.logradouro)
                setNeightborhood(data.bairro)
                setCity(data.localidade)
                setUf(data.uf)
            } catch {
                toast.error("Ocorreu um erro ao tentar buscar o endereço")
            }
        }else {
            setStreet("")
            setNeightborhood("")
            setCity("")
            setUf("")
        }
    }

    return <div>
       <BrowserView>
            <FormEquipeBrowser 
                onSubmit={onSubmit} 
                onDrop={onDrop}
                handleZipCode={handleZipCode}
                loading={loading}
                name={name}
                setName={setName}
                foundation_data={foundation_data}
                setFoundationData={setFoundationData}
                sport={sport}
                setSport={setSport}
                zipCode={zipCode}
                neighborhood={neighborhood}
                setNeightborhood={setNeightborhood}
                city={city}
                setCity={setCity}
                uf={uf}
                setUf={setUf}
                number={number}
                setNumber={setNumber}
                complement={complement}
                setComplement={setComplement}
                pictureUrl={pictureUrl}
                street={street}
                setStreet={setStreet}
            />
       </BrowserView>
       <MobileView>
            <FormEquipeMobile 
                onSubmit={onSubmit} 
                onDrop={onDrop}
                handleZipCode={handleZipCode}
                loading={loading}
                name={name}
                setName={setName}
                foundation_data={foundation_data}
                setFoundationData={setFoundationData}
                sport={sport}
                setSport={setSport}
                zipCode={zipCode}
                neighborhood={neighborhood}
                setNeightborhood={setNeightborhood}
                city={city}
                setCity={setCity}
                uf={uf}
                setUf={setUf}
                number={number}
                setNumber={setNumber}
                complement={complement}
                setComplement={setComplement}
                pictureUrl={pictureUrl}
                street={street}
                setStreet={setStreet}
            />
       </MobileView>
    </div>
}

const styles = {
    title: {
        paddingTop: 30,
    },
    titleSpan: {
       
        fontFamily: "Roboto",
        fontSize: 26
    },
    card: {
        width: "70%",
        "@media(max-width: 1205px)": {
            width: "100%",
        }
    }
}

export default  React.memo(Component);