import React, { useState } from "react"
import Cropper from 'react-easy-crop'

import { Modal } from 'react-responsive-modal';
import { Button } from "./Form";
import getCroppedImg from './getCroppedImg'
import { css } from "emotion";
import { Row, Col } from "react-materialize";

const Component = (props) => {
    // const [image, setImage] = useState("")
    const [croppedAreaPixels, setCroppedAreaPixel] = useState([])
    const [crop, setCrop] =  useState({ x: 0, y: 0 })
    const [zoom, setZoom] = useState(1)
    const aspect = props.aspect ? props.aspect : 16/9;

    const onCropChange = crop => {
        setCrop(crop)
    }
    
    const onCropComplete = (croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixel(croppedAreaPixels)
    }
    
    const  onZoomChange = zoom => {
        setZoom(zoom);
    }

    const toBase64 = file => {
        return new Promise(resolve => {
            const reader = new FileReader()
            reader.addEventListener('load', () => resolve(reader.result), false)
            reader.readAsDataURL(file)
          })
    };

    const onFinish = async () => {
        const croppedImage = await getCroppedImg(
            props.image[0],
            croppedAreaPixels
        )
        const imgBase64 = await toBase64(croppedImage)
        props.onClose([croppedImage],[imgBase64]);
    }


    return (
        <Modal 
        open={props.open}
        center
        styles={{
            modal: styles.modal
        }}
        type={"button"}
        onClose={onFinish}
        >
            <div className={css(styles.containerCrop)}>
                <Cropper
                        image={props.image}
                        crop={crop}
                        cropShape={props.rounded ? "round" : "rect"}
                        zoom={zoom}
                        aspect={aspect}
                        onCropChange={onCropChange}
                        onCropComplete={onCropComplete}
                        onZoomChange={onZoomChange}
                />
            </div>
            <Row>
                <Col m={12} s={12} l={6} className="offset-l3">
                    <Button onClick={onFinish} className={css(styles.btnCrop)}>Cortar</Button>
                </Col>
            </Row>
        </Modal>
       
    )
      

}

const styles = {
    btnCrop: {
        marginTop: 55,
    },
    modal: {
        width: "100%",
        position: "relative"
    },
    containerCrop: {
        backgroundColor: "transparent",
        width: "100%",
        height: 400,
        position: "relative"
    }
}

export default Component;