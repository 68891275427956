import React, { useContext, useState } from "react"
import { css } from "emotion";
import { Button, Input } from "../../../../components/Form";
import DataTable from 'react-data-table-component';
import moment from "moment"
import { toast } from 'react-toastify';
import JuizCronometroContext, { actions } from "../../JuizCronometroContext";
import { useHistory } from "react-router-dom";
import ModalLargadaCategoria from "./ModalLargadaCategoria"
import Api from "../../../../api/Api";
import { useCookies } from "react-cookie";
import ModalDelete from "../../../../components/ModalDelete";
import { BrowserView, MobileView } from "react-device-detect";
import CronometroBrowser from "./CronometroLayout/CronometroBrowser";
import CronometroMobile from "./CronometroLayout/CronometroMobile";
import useIdOrganizer from "../../../../hooks/useIdOrganizer";
const Component = () => {

    const {state, dispatch} = useContext(JuizCronometroContext)
    const [openDelete, setOpenDelete] = useState("")
    const [idDel, setIdDel] = useState("")
    const [openModalLargadaCategoria, setOpenModalLargadaCategoria] = useState(false)
    const [cookies] = useCookies(['id'])
    const user_id = useIdOrganizer();
    const history = useHistory()

    const handleClickNumberKeyboard = (value) => {
        dispatch({type: actions.HANDLE_NUMBER_KEYBOARD_TIMER, payload: `${state.numberAthTimer}${value}`})
    }

    const handleAdicionar = async () => {
        if(state.timers.length === 0){
            toast.warn("É necessario fazer uma largada primeiro")
            return;
        }
        else if(!state.numberAthTimer){
            toast.warn("O número do atleta deve ser digitado")
            return;
        }

        try {

            const numberAthTimer = state.numberAthTimer.padStart(4, '0')

            const result = await Api().post("cronometro/finalizar", {
                "evento_id": state.id_event_timer,
                "data_hora_finalizou": moment().format("DD/MM/YYYY HH:mm:ss"),
                "atleta_numero": numberAthTimer,
                "user_id_finalizou": cookies.id
            }) 
            const data = result.data ? result.data : {}
            if(!data.error){
                dispatch({type: actions.FORCE_TIMERS})
                dispatch({type: actions.HANDLE_NUMBER_KEYBOARD_TIMER, payload: ""})
                // dispatch({type: actions.ADD_ATH_TIMER, payload: newData}) // Retirar no futuro

            }

        } catch (e){
            const response = e.response ? e.response : {};
            const data = response.data ? response.data : {};
            const msg = data.msg ? data.msg[0] : "Ocorreu um problema";
            toast.error(msg)
        }


    }

    const handleClickStart = async () => {
        setOpenModalLargadaCategoria(true)
    }

    const returnScreen = () => {
        history.push({
            search: ""
        })
        dispatch({type: actions.SET_ID_EVENT_TIMER, payload: 0})
        dispatch({type: actions.SET_EVENT,payload: {}})
        dispatch({type: actions.RESET_TIMER})
    }

    const handleReview = () => {
        history.push(`/evento/apresentacao/${state.id_event_timer}/resultado_review?pub=0`)
    }

    const handlePub = async () => {
        try {
            const result = await Api().post("cronometro/publicar", {
                user_id_publicou: cookies.id,
                evento_id: state.id_event_timer
            })
    
            if(!result.data.error){
                toast.success("Cronometro publicado com sucesso")
                returnScreen()

            }
        } catch(e){
            console.log(e)
            const response = e.response ? e.response : {};
            const data = response.data ? response.data : {};
            const msg = data.msg ? data.msg[0] : "Ocorreu um problema ao publicar";
            toast.error(msg)
        }
       
    }

    const delTimer = async () => {
        try {
            const result = await Api().post("cronometro/deletar", {
                user_id,
                cronometro_id: idDel
            })
            
            if(!result.data.error){
                dispatch({type: actions.FORCE_TIMERS})
                dispatch({type: actions.FORCE_STARTS})
                setOpenDelete(false)
                toast.success("Cronômetro deletado com sucesso")

            }
        } catch (e) {
            console.log(e)
            const response = e.response ? e.response : {};
            const data = response.data ? response.data : {};
            const msg = data.msg[0] ? data.msg[0] : "Ocorreu um problema ao publicar";
            toast.error(msg)
        }
    }
    

    return <div>
        <BrowserView>
            <CronometroBrowser 
                 openDelete={openDelete} 
                 setOpenDelete={setOpenDelete}
                 setIdDel={setIdDel}
                 openModalLargadaCategoria={openModalLargadaCategoria} 
                 setOpenModalLargadaCategoria={setOpenModalLargadaCategoria}
                 handleClickNumberKeyboard={handleClickNumberKeyboard}
                 handleAdicionar={handleAdicionar}
                 handleClickStart={handleClickStart}
                 returnScreen={returnScreen}
                 handleReview={handleReview}
                 handlePub={handlePub}
                 delTimer={delTimer}
            />
        </BrowserView>
        <MobileView>
            <CronometroMobile
                    openDelete={openDelete} 
                    setOpenDelete={setOpenDelete}
                    setIdDel={setIdDel}
                    openModalLargadaCategoria={openModalLargadaCategoria} 
                    setOpenModalLargadaCategoria={setOpenModalLargadaCategoria}
                    handleClickNumberKeyboard={handleClickNumberKeyboard}
                    handleAdicionar={handleAdicionar}
                    handleClickStart={handleClickStart}
                    returnScreen={returnScreen}
                    handleReview={handleReview}
                    handlePub={handlePub}
                    delTimer={delTimer}
                />
        </MobileView>
    </div>
}

const styles = {
    btnInfo: {
        cursor: "pointer",
        paddingRight: 10,
    },
    btnDel: {
        cursor: "pointer",
        color: "red"
    },
    minhasLargadas: {
        flex: 1,
        margin: -24,
        border: "1px solid #C4C4C4",
        fontFamily: "Roboto",
        "@media(max-width: 500px)": {
            display: "none"
        },
       
    },
    lineSelected: {
        backgroundColor: "rgba(107, 105, 207, 0.8)",
        color: "#fff",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        paddingLeft: 0,
        fontSize: 16
    },
    lineLargadas: {
        height: 40,
        display: "flex",
        paddingLeft: 10,
        alignItems: "center",
        justifyContent: "space-between",
        paddingRight: 10,
        fontSize: 14
    },
    returnIcon: {
        position: "absolute",
        cursor: "pointer",
        left: 0,
        top: 0
      //  marginLeft: 170
    },
    btnsPub: {
        display: "flex",
        marginBottom: 25,
        marginRight: 25,
        marginLeft: 25,
        "@media(max-width: 992px)": {
            marginRight: 0,
            marginLeft: 0,
        },
    },
    btnPub: {
        marginRight: 5,
        marginLeft: 5,
        fontSize: 12
    },
    time: {
        border: "1px solid #606060",
        borderRadius: 9,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontFamily: "Roboto"
    },
    numberAth: {
      textAlign: "center",
      margin: "0 0 0 0 !important",
      height: "2rem !important",
      borderBottom: "0px !important",
      lineHeight: 2,
      fontSize: "16px !important",
      boxShadow: "none !important",
      letterSpacing: 10,
      textIndent: 10

    },
    numberAtlDiv: {
        border: "1px solid #606060",
        borderRadius: 9,
        height: 30,
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontFamily: "Roboto",
        marginTop: 15
    },
    btnLargada: {
        fontSize: 12
    },
    btnSalvar: {
        marginTop: 20
    },
    containers: {
        display: 'flex',
        flexWrap: "wrap"
    },
    keyboardNumbers: {
        marginTop: 10,
        width: "100%"
    },
    line: {
        display: "flex",
        justifyContent: "center",
        marginTop: 10,
        width: "100%"
    },
    topKeyboard: {
        display: "flex",
        width: "100%"
    },
    flex_normal: {
        flex: "33%",
        marginRight: 10,
        marginLeft: 10,
    },
    number: {
        cursor: "pointer",
        fontFamily: "Roboto",
        color: "#606060",
        flex: "33%",
        width: "100%",
        // maxWidth: 60,
        height: 35,
        borderRadius: 9,
        textAlign: "center",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginRight: 10,
        marginLeft: 10,
        border: "1px solid #606060",
        // height: "auto"
    },
    itemTopKeyboard: {
        marginRight: 5,
        marginLeft: 5,
        flex: 1
    },
    keyboard: {
        paddingTop: 40,
        "@media(min-width: 500px)": {
            marginRight: 40,
        },
        marginLeft: 40,
        "@media(max-width: 500px)": {
            marginLeft: -12,
        },
        flex: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        position: "relative"
    },
    table: {
        flex: 3,
        display: "flex",
        flexDirection: "column",
        paddingTop: 15,
    },
    result: {
        color: "#000000",
        fontFamily: "Roboto",
        fontSize: 18,
        paddingTop: 10,
        fontWeight: 600
    },
    tableDiv: {
        display: "inline-grid",
       
        height: 280
    },
    tableAth: {
        overflowY: "hidden",
        overflowX: "auto",
        marginBottom: 10,
    }
}

export default React.memo(Component);