import { css } from 'emotion'
import React, { useContext, useEffect, useState } from 'react'
import DataTable from 'react-data-table-component'
import { Col, Row } from 'react-materialize'
import Modal from 'react-responsive-modal'
import { BarLoader } from 'react-spinners'
import { Button, Checkbox } from '../../../../components/Form'
import moment from "moment"
import JuizCronometroContext, { actions } from '../../JuizCronometroContext'
import Api from '../../../../api/Api'
import { useCookies } from 'react-cookie'
import { toast } from 'react-toastify'
import ModalMobileFullScreen from '../../../../components/ModalMobileFullScreen'
import config from '../../../../config'

const Component = (props) => {

    const {state, dispatch} = useContext(JuizCronometroContext)
    const [cookies] = useCookies(['id'])
    const [data, setData] = useState([
        {
            name: "Categoria 1",
        }
    ])
    const [timeStart, setTimeStart] = useState("")
    const [categorieSelected, setCategorieSelected] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if(props.open){
            setTimeStart(moment())
            setCategorieSelected([])
            const event = state.event ? state.event : {};
            const categories = event.categorias ? event.categorias : []
            let idAddeds = []
            let internalData = [];
            categories.forEach((item) => {
                if(!idAddeds.includes(item.id)){
                    internalData.push({id: item.id, name: item.nome, imagem: config.storage_link + "/categoria/" +item.imagem})
                    idAddeds.push(item.id)
                }
            })
            setData(internalData)
        }
    },[props.open])

    const onClose = () => {
        props.toggle(false)
    }

    const onPressSave = async () => {
        try {
            setLoading(true)
            const result = await Api().post("cronometro/iniciar", {
                "evento_id": state.id_event_timer,
                "categorias": categorieSelected,
                "data_hora_inicio": timeStart.format("DD/MM/YYYY HH:mm:ss"),
                "user_id_iniciou": cookies.id
            }) 
            const data = result.data ? result.data : {}
            if(!data.error){
                dispatch({type: actions.FORCE_TIMERS})
                dispatch({type: actions.FORCE_STARTS})
                toast.success("Largada iniciada com sucesso")
            }

        } catch (e){
            const response = e.response ? e.response : {};
            const data = response.data ? response.data : {};
            const msg = data.msg ? data.msg[0] : "Ocorreu um problema";
            toast.error(msg)
        }
        setLoading(false)
        props.toggle(false)
    }

    const onChangeCheckbox = (id) => {
        let catSelectedCopy;
        
        if(categorieSelected.includes(id)){
            catSelectedCopy = categorieSelected.filter((item) => item != id)
        }else {
            catSelectedCopy = [...categorieSelected, id]

        }
        
        setCategorieSelected(catSelectedCopy)
    }

    const columns = [
        {
            selector: 'image',
            sortable: true,
            width: "60px",
            cell: row => <>{row.imagem && <img src={row.imagem} className={css(styles.image)}/>}</>
        },
        {
            name: 'Nome',
            selector: 'name',
        },
        {
            name: '',
            maxWidth:"10px",
            ignoreRowClick: true,
            cell:(row) => 
            <div>
                <Checkbox
                    id={"cat"+row.id}
                    label={""}
                    value={categorieSelected.includes(row.id)}
                    onChange={() => onChangeCheckbox(row.id)}
                />
            </div>,
        }
        
    ]

    return <ModalMobileFullScreen
    open={props.open} 
    // toggle={onClose}
     // contentStyle={styles.contentModal}
     onClose={onClose}
     title={"Largada"}
     btnFooterText={"Iniciar"}
     onClickBtnFooter={() => {
        onPressSave()
     }}
    >
        <form>
           
            <Row>
                <DataTable
                    columns={columns}
                    data={data}
                    highlightOnHover={true}
                    pointerOnHover={true}
                    noHeader={true}
                    noDataComponent={""}
                    noTableHead
                />
            </Row>
           
        </form>
    </ModalMobileFullScreen>
}

const styles = {
    image: {
        width: 40,
        height: 40,
        borderRadius: "50%"
    },
    photo: {
        width: 40,
        height: 40,
        borderRadius: 250,
        marginTop: 5,
        marginBottom: 5
    },
    itemTableSpan: {
        fontSize: 14
    },
    modal: {
        modal : {
            width: "80%"
        }
    },
    loadingCss: {
        width: "100%"
    },
    topKeyboard: {
        display: "flex",
        width: "100%"
    },
    time: {
        border: "1px solid #606060",
        borderRadius: 9,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontFamily: "Roboto",
        marginRight: 5,
        marginLeft: 5,
        width: 75,
        height: 40
    },
    containerTimeStart: {
        display: "flex"
    }
}

export default Component;