import React, { useState, useEffect, useContext } from "react"
import {Card, Row, Col } from "react-materialize"
import {Button, Input, InputSearch} from "../../components/Form"
import {
    Link, useHistory 
  } from "react-router-dom";

import DataTable from 'react-data-table-component';
import Api from "../../api/Api"
import config from "../../config.js"
import ModalDelete from "../../components/ModalDelete"
import { toast } from "react-toastify";
import {BarLoader, ClipLoader} from "react-spinners";
import {Cookies, useCookies} from "react-cookie"
import tournBanner from "../../assets/img/torneio-banner.svg"
import circlePlus from "../../assets/icons/circle-plus.svg"
import { css } from 'emotion'
import moment from "moment"
import MainContext from "../MainContext";
import useIdOrganizer from "../../hooks/useIdOrganizer";

export default () => {
    const [cookies] = useCookies(['id','nome']); 

    const user_id = useIdOrganizer();

    const [filter,setFilter] = useState("")
    const [data, setData] = useState([])
    const [dataFiltered, setDataFiltered] = useState([])
    const [openDelete, setOpenDelete] = useState(false)
    const [idDelete, setIdDelete] = useState(0)
    const [loadingTable, setLoadingTable] = useState(false);

    const history = useHistory();

    useEffect(() => {
        findData()
    },[])

    useEffect(() => {
        if(data){
            const newDate = data.filter((item) => item.name.match(`${filter}.*`))
            setDataFiltered(newDate)
        }
    },[filter])


    const findData = async () => {
        try {
            setLoadingTable(true)
            const result = await Api().post("/torneios/listar",{...filter,  user_id})
            const resultData = result.data.torneios ? result.data.torneios : []
            let internalData = [];
            resultData.map((value) => {
                internalData.push({
                    id: value.id,
                    edition: value.edicao,
                    name: value.nome,
                    photo: value.imagem ? config.storage_link+`/torneio/${value.id}/${value.imagem}` : "",
                })
            })
            setData(internalData)
            setDataFiltered(internalData)
            setLoadingTable(false)
        }catch(e){
            const response = e.response ? e.response : {};
            const data = response.data ? response.data : {};
            const msg = data.msg ? data.msg[0] : "Ocorreu um erro ao buscar os torneios";
            toast.error(msg)
        }
    }

    const deleteTourn = async () => {
        const torneios = data.filter((elem) => {
            return elem.id != idDelete
        })
        const torneiosFiltered = dataFiltered.filter((elem) => {
            return elem.id != idDelete
        })
        try {
            const result = await Api().delete("torneios/deletar/"+idDelete)
            if(!result.data.error){
                toast.success("Torneio deletado com sucesso")
                setData(torneios)
                setDataFiltered(torneiosFiltered)
                setOpenDelete(false)
            }
        } catch(e){
            const response = e.response ? e.response : {};
            const data = response.data ? response.data : {};
            const msg = data.msg ? data.msg[0] : "Ocorreu um erro ao tentar deletar o torneio";
            toast.error(msg)
            setOpenDelete(false)
        }

    }

    const onClickLineTable = (id) => {
        history.push(`/torneios/${id}/editar`)
    }


    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    const columns = [
        {
            name: '',
            selector: 'photo',
            cell:(row) => {
                return( 
                    <ImageTournTable row={row}/>
                );
            }
        },
        {
            name: 'Edição',
            selector: 'edition',
            sortable: true,
            style:{display: "inline-grid"},
            cell:(row) => {
                return(<div > 
                    <div><span className={css(styles.itemTableSpan)}>{(row.edition)}</span></div>
                </div>);
            }
        },
        {
            name: 'Nome',
            selector: 'name',
            sortable: true,
            style:{display: "inline-grid"},
            cell:(row) => {
                return(<div > 
                    <div><span className={css(styles.itemTableSpan)}>{(row.name)}</span></div>
                </div>);
            }
        },
        {
            name: 'Editar',
            ignoreRowClick: true,
            cell:(row) => 
            <div>
                <span className={"material-icons modal-trigger  "+css(styles.iconBtn2)} id={row.id} onClick={ () => { onClickLineTable(row.id)} } >edit</span>
            </div>,
        },
        {
            name: 'Excluir',
            ignoreRowClick: true,
            cell:(row) => 
            <div>
                <span className={"material-icons modal-trigger  "+css(styles.iconBtn)} href="#modal1" id={row.id} onClick={ () => { setIdDelete(row.id); setOpenDelete(true);} } >delete</span>
            </div>,
        }
        
    ]

   
    return <div style={styles.main}>
        <Row>
            <Col s={8} m={9}>
                    <h6 className={css(styles.titleTour)}>Meus Torneios</h6>
                    <div>
                        <span>{capitalizeFirstLetter(moment().format("dddd, DD [de] MMMM [de] YYYY"))}</span>
                    </div>
            </Col>
            <Col s={4} m={3}>
                <InputSearch 
                    id="name"
                    value={filter}
                    onChange={(e) => setFilter(e.target.value)}
                    label={"Buscar"} 
                />
            </Col>
        </Row>
        <Row>
            <div className={css(styles.boxTour)}>
                <div className={css(styles.titleBox)}>
                    <span className={css(styles.titleBoxSpan)}>Olá, {cookies.nome}</span>
                </div>
                <div className={css(styles.textBox)}>
                    <span className={css(styles.textBoxSpan)}>
                        Construimos uma plataforma pensando em você, vamos juntos transformar o Brasil no maior medalhista do mundo produzindo competições de alto nível para lapidar nossos atletas. 
                    </span>
                </div>
                <img src={tournBanner} className={css(styles.imgBanner)}/>
            </div>
        </Row>
        <Row className={css(styles.rowNovoEvento)}>
            <Col s={12} m={4}>
                <Link to={"torneios/novo"} className={css(styles.linkNovoEvento)}>
                    <div className={css(styles.divNovoEvento)}>
                        <img src={circlePlus}  />
                        <span class={css(styles.novoEventoSpan)}>Novo Torneio</span>
                    </div>
                </Link>
            </Col>
        </Row>
        <DataTable
            progressComponent={<BarLoader css={styles.loadingCss} color={"#110F62"}/>}
            progressPending={loadingTable}
            persistTableHead
            columns={columns}
            data={dataFiltered}
            noHeader={true}
            overflowY={true}    
            noDataComponent={"Não existem dados para mostrar"}
    
        />

        <ModalDelete 
            open={openDelete}
            onDelete={deleteTourn}
            onClose={() => setOpenDelete(false)}>
            Você realmente deseja excluir esse torneio?
        </ModalDelete>
    </div>
}

const ImageTournTable = ({row}) => {
    const contextMain = useContext(MainContext)
    let imageLoading = null;
    console.log(contextMain.state.images_upload)
    contextMain.state.images_upload.forEach((item) => {
       
        if(item.id == row.id && item.type_key == "torneio_id"){
            if(item.status == "UPLOADING" || item.status == "TO_UPLOAD"){
                imageLoading = <ClipLoader color={"#110F62"} css={css(styles.loadingImg)}/> 
            } else if(item.status == "SUCCESS"){
                row.photo = item.image ? config.storage_link+`/torneio/${row.id}/${item.image}` : ""
            } else if(item.status == "ERROR"){

            }
        }
    })

    return( row.photo ? 
        <img src={row.photo} className={css(styles.photoTourn)}/> : imageLoading
    );
}

const styles={
    loadingCss: {
        width: "100%"
    },
    titleTour: {
        fontWeight: "bold"
    },
    newEvento : {
        float:"left"
    },
    main: {
        paddingBottom: 20
    },
    photoTourn: {
        width: 40,
        height: 40,
        borderRadius: 250,
        marginTop: 5,
        marginBottom: 5
    },
    rowNovoEvento: {
        paddingTop: "20px"
    },
    linkNovoEvento: {
        color: "#000",
    },
    itemTableSpan: {
        fontSize: 14
    },
    novoEventoSpan: {
        fontFamily: "Roboto",
        fontSize: 14,
        marginLeft: 10,
    },
    itemTableSpan: {
        fontSize: 14
    },
    iconBtn: {
         cursor:'pointer',
         color:'red !important'
    },
    iconBtn2: {
        cursor:'pointer',
   },
    divNovoEvento: {
        display: "inline-flex",
        alignItems: "center"
    },
    boxTour: {
        position: "relative",
        width: "95%",
        fontFamily: "Roboto",
        height: 150,
        borderRadius: 20,
        backgroundColor: "#CFCEF2",
        boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.25)",
        "@media(max-width: 850px)": {
            display: "none"
        },
    },
    titleBox: {
        paddingTop: 20,
        paddingLeft: 20
    },
    titleBoxSpan: {
        color: "#110F62",
        fontFamily: "Roboto",
        fontSize: 20,
        marginTop: 20
    },
    textBox: {
        paddingLeft: 20,
        width: "65%"
    },
    textBoxSpan: {
        fontSize: 12,
        color: "#110F62"
    },
    imgBanner: {
        position: "absolute",
        //zIndex: 20,
        top: -10,
        right: 0
    },
}
