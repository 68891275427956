import { css } from "emotion"
import DataTable from "react-data-table-component"
import config from "../../config"
import useAdminAccess from "../../hooks/useAdminAccess"

export default () => {

    useAdminAccess(2)

    const download = (url) => {
        window.open(config.url+url,"_blank")
    }

    const columns = [
        {
            name: 'name',
            selector: 'name',
        },
        {
            name: 'download',
            cell: (row) => <span 
            onClick={() => download(row.url)}
            className={css(styles.iconDownload) + " material-icons"}>download</span>
        },
    ]

    const data = [
        {
            name: "Atletas inscritos no site",
            url: "exportar/atletas",
        },
        {
            name: "Organizadores inscritos no site",
            url: "exportar/organizadores",
        },
        {
            name: "Torneios inscritos no site",
            url: "exportar/torneios",
        },
        {
            name: "Equipes inscritas no site",
            url: "exportar/equipes",
        },
        {
            name: "Numero de vendas de ingressos",
            url: "exportar/vendasingressos",
        },
        {
            name: "Organizadores bloqueados",
            url: "exportar/organizadoresbloqueados",
        },
        {
            name: "Cronometragens vendidas",
            url: "exportar/cronometragens",
        },
    ]

    return <div>
        <div className={css(styles.title)}>
            Relatórios
        </div>
        <DataTable 
            columns={columns}
            data={data}
            noHeader
            noTableHead
        />
    </div>
}

const styles = {
    iconDownload: {
        cursor: "pointer"
    },
    title: {
        fontSize: 22,
        fontWeight: "bold"
    }
}