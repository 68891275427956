import React,{ useState, useEffect } from "react"
import { Row, Col, Container, Table } from "react-materialize"
import Api from "../../api/Api"
import config from "../../config.js"
import { Button, ButtonChecked } from "../../components/Form"
import Carousel from "../../components/Carousel"
import capa from '../../assets/img/capa.jpg'
import moment from "moment"
import { Link, useParams } from "react-router-dom"
import { useContext } from "react"
import LoadingOverlayContext from "../../components/LoadingOverlayContext/LoadingOverlayContext"
import ModalFaleConosco from "./ModalFaleConosco"
import { css } from "emotion"
import Footer from "../../layouts/Footer"
import ItemEventoLista from "./ItemEventoLista"

const Torneio = () => {

    const [picture,setPicture] = useState("")
    const [policy,setPolicy] = useState("")
    const [edition,setEdition] = useState("")
    const [name,setName] = useState("")
    const [id_organizer, setIdOrganizer] = useState("")
    const [organizer, setOrganizer] = useState("")
    const [categories,setCategories] = useState([])
    const [sports,setSports] = useState({})
    const [sportChecked,setSportChecked] = useState("")
    const [lots, setLots] = useState([])
    const [eventsLinkeds, setEventsLinkeds] = useState([])
    const [loadingOtherEvents,setLoadingOtherEvents] = useState(true)
    const [openModalFaleConosco, setOpenModalFaleConosco] = useState(false)
    const {id} = useParams(); 

    const loadingOverlay = useContext(LoadingOverlayContext)

    console.log(id)
    useEffect(() => {
        moment.locale("pt-BR")
    
        getEventsLinkeds()
        getInfo()
        
    },[id])

    useEffect(() => {
        let internalCats = [];
        categories.map((cat) => {
            if(sportChecked == ""){
                cat.visible = true
            }
            else if(cat.esporte == sportChecked){
                cat.visible = true
            }else {
                cat.visible = false
            }
            internalCats.push(cat)
        })
        setCategories(internalCats)
    },[sportChecked])

  

    useEffect(() => {
        const sports = []
        categories.map((category) => {
            sports[category.esporte] = ({id: category.esporte, name: category.esporte_nome})
        })
        setSports(sports)
    },[categories])

    const getInfo = async () => {
        if(id){
            loadingOverlay.activeLoading(true,"Buscando conteúdo")
            const result = await Api().get(`torneios/getinfo/${id}`)
            if(result.data.info){
                const data = result.data.info;
                setPolicy(data.politica)
                setCategories(data.categorias)
                setName(data.nome)
                setOrganizer(data.organizador)
                setEdition(data.edicao)
                setIdOrganizer(data.user_id)
                setPicture(config.storage_link+`/torneio/${data.id}/${data.imagem}`)
                loadingOverlay.activeLoading(false)
            }
        }
    }

    const getEventsLinkeds = async () => {
        setLoadingOtherEvents(true)
        const result = await Api().get(`/torneio/${id}/eventos`)
        if(result.data){
            const data = result.data;
            let otherEventos = []
            data.map((item) => {
                console.log(item)
                if(item.id != id){
                    const row = {
                        id: item.id,
                        name: item.nome,
                        photo: item.imagem ? config.storage_link+`/evento/${item.id}/${item.imagem}` : "",
                        dateStart: item.data_inicio,
                        street: item.logradouro,
                        city: item.cidade,
                        state: item.uf,
                        neighborhood: item.bairro,
                        number: item.numero
                    }
                   otherEventos.push(row)
                }
          
            })
            setEventsLinkeds(otherEventos)
            setLoadingOtherEvents(false)
        }

    }

    return <div>
    <div className={css(styles.main)}>
        <div className={css(styles.headerCapa)}>

        </div>
        <div className={css(styles.viewImageEvento)}>
            <Row >
                <Col className={css(styles.imgEventoDiv)} s={12} l={6}>
                    {picture != "" && <img src={picture} width={"100%"} height={"100%"} className={css(styles.imgEvento)}/>}
                </Col>
            </Row>
        </div>
        <div className={css(styles.conteudoEvento)}>
    
            <Row>
                <Col s={12} l={8} offset={"l4"} >
                    <Container>
                        <Row>
                            <h4 className={css(styles.titleEvento)}>{name}</h4>
                        </Row>
                        <Row>
                            <span className={css(styles.dateStart)}>{edition}</span>
                        </Row>
                    </Container>
                </Col>
            </Row>
        
            <Row>
                <Col s={12} l={6}>
                    <div className={css(styles.divDeskView)}>
                        <Row>
                            <Col>
                                <h5 className={css(styles.labelDescricao)}>POLITICA:</h5>
                            </Col>
                        </Row>
                        <Row >
                        <Col>
                                <div dangerouslySetInnerHTML={{ __html: policy }}></div>
                        </Col>
                        </Row>
                    </div>
                </Col>
                <Col s={12} l={6}>
                    <div>
                        <Container>
                            <Row>
                                <Table className={css(styles.tableCategories)} >
                                    <thead className={css(styles.headTableCat)}>
                                        <tr >
                                            <th data-field="categoria" className={css(styles.headTableCatCat)}>
                                                Categorias
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {categories.map((cat,i) => {
                                            return <tr key={"cat"+cat.id}>
                                                            <td data-field="cat">
                                                                {cat.nome}
                                                            </td>
                                                        </tr>
                                                    }
                                            )}
                                    </tbody>
                                </Table>
                            </Row>
                        </Container>
                    </div>
                </Col>
            </Row>
            <ModalFaleConosco event_id={id} open={openModalFaleConosco} toggle={setOpenModalFaleConosco}/>
            <div className={css(styles.organizador)}>
                <Row>
                    <Col>
                        <h5 className={css(styles.organizadorLabel)}>ORGANIZADOR:</h5>
                    </Col>
                </Row>
                <Row>
                    <Col s={12} m={12}>
                        <div className={css(styles.txtOrg)}>Nome: {organizer}</div>
                    </Col>
                </Row>
            </div>
            <div className={css(styles.maisEventos)}>
                <Row>
                    <Col>
                        <h5 className={css(styles.meusEventosLabel)}>EVENTOS RELACIONADOS:</h5>
                    </Col>
                </Row>
                <Container>
                <Carousel 
                    loading={loadingOtherEvents}
                    template={ItemEventoLista}
                    items={eventsLinkeds}/>
                </Container>
            
                </div>
            
                </div>
            <div>
            
            </div>

        </div>
        <Footer />
    </div>
}

const styles = {
    main: {
        position: "relative",
        backgroundColor: "#F2F2F2",
        minHeight: "100%"
    },
    acoesTable: {
        marginTop: "6%",
        float: "right"
    },
    divDeskView: {
        marginLeft: '8%',
        "@media(max-width: 992px)": {
            marginLeft: "7%",
            marginRight: "7%",
        },
        "@media(max-width: 700px)": {
            marginLeft: 7,
            marginRight: 7,
        },
    },
    maisEventos: {
        marginLeft: '4%',
        paddingBottom: 20,
        "@media(max-width: 992px)": {
            marginLeft: "7%",
            marginRight: "7%",
        },
        "@media(max-width: 700px)": {
            marginLeft: 15,
            marginRight: 15,
        },
    },
    organizador: {
        marginLeft: '4%',
        "@media(max-width: 992px)": {
            marginLeft: "7%",
            marginRight: "7%",
        },
        "@media(max-width: 700px)": {
            marginLeft: 15,
            marginRight: 15,
        },
    },
    percurso: {
        marginLeft: '4%',
        "@media(max-width: 992px)": {
            marginLeft: "7%",
            marginRight: "7%",
        },
        "@media(max-width: 700px)": {
            marginLeft: 15,
            marginRight: 15,
        },
    },
    nome_cat: {
        color: "#606060",
        fontFamily: "Roboto",
        fontSize: 18
    },
    valor_lote: {
        color: "#606060",
        fontFamily: "Roboto",
        fontSize: 14
    },
    parcela_lote: {
        color: "#19BC0B",
        fontFamily: "Roboto",
        fontSize: 14
    },
    labelDescricao: {
        color: "#606060",
        fontFamily: "Roboto",
        fontSize: 20
    },
    meusEventosLabel: {
        color: "#606060",
        fontFamily: "Roboto",
        fontSize: 20
    },
    organizadorLabel: {
        color: "#606060",
        fontFamily: "Roboto",
        fontSize: 20
    },
    percursoLabel: {
        color: "#606060",
        fontFamily: "Roboto",
        fontSize: 20
    },
    titleEvento: {
        "@media(max-width: 992px)": {
            marginLeft: -10,
            textAlign: 'center',
        },
        fontWeight: 600,
        fontFamily: "Roboto",
        color: "#606060"
    },
    headerCapa: {
        height: 160,
        width: "100%",
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundColor: "#2F2E41"
    },
    viewImageEvento: {
        position: "absolute",
        zIndex: 2,
        top: 80,
        left: "5%",
        width: "70%",
        "@media(max-width: 992px)": {
            left: "0%",
            width: "100%",
        },
    },
    conteudoEvento: {
        marginLeft: 10,
      
        "@media(max-width: 992px)": {
            marginTop: '18vh'

        },
    },
    imgEventoDiv: {
        height: "25vh"
    },
    imgEvento: {
        objectFit: 'cover',
        objectPosition: '0%',
        "@media(max-width: 992px)": {
            objectFit: 'contain',
            objectPosition: 'center center'
        },
    },
    dateStart: {
        color: "#606060",
        fontFamily: "Roboto",
        fontSize: 16,
    },
    dateEnd: {
        color: "#606060",
        fontSize: 16,
        fontFamily: "Roboto",
    },
    dataEndSub: {
        color: "#606060",
        fontFamily: "Roboto",
        fontSize: 14,
    },
    dataEndSubSpan: {
        color: "#606060",
        fontFamily: "Roboto",
        fontSize: 14,
    },
    txtOrg: {
        color: "#606060",
        fontFamily: "Roboto",
        fontSize: 18,
    },
    btnFaleConosco: {
        fontSize: 14, 
        width: "100%"
    },
    btnTodosEsportes: {
        marginTop: 10
    },
    btnEsporte: {
        marginTop: 10,
        overflow: "hidden"
    },
    tableCategories: {
        width: "98%"
    },
    headTableCat: {
        backgroundColor: "#6B69CF"
    },
    headTableCatCat: {
        fontSize: 18,
        fontWeight: 500,

        fontFamily: "Roboto",
        paddingBottom:8,
        paddingLeft: 20,
        paddingTop: 8,
        borderRadius: 0,
        color: "#fff"
    },
    headTableCatTotalPrice: {
        fontSize: 18,
        fontWeight: 500,
        paddingBottom:8,
        fontFamily: "Roboto",
        textAlign: "center",
        paddingTop: 8,
        borderRadius: 0,
        color: "#fff"
    },
    headDataEncerra: {
        fontSize: 14,
        fontWeight: 400,
        paddingBottom:8,
        paddingLeft: 20,
        fontFamily: "Roboto",
        textAlign: "left",
        paddingTop: 8,
        borderRadius: 0,
        color: "#fff"
    },
    btnComprarTR: {
        backgroundColor: "#6B69CF",
        cursor: "pointer"
    },
    btnComprarTD: {
        textAlign: "center",
        fontSize: 18,
        paddingBottom:8,
        paddingTop: 8,
        color: "#fff"
    },
    btnChangeQuantLote: {
        cursor: "pointer",
        fontSize: 24,
        paddingLeft: 10,
        paddingRight: 10,
        backgroundColor: "transparent",
        border: "none",
        ":hover": {
            backgroundColor: "transparent"
        },
        ":focus": {
            backgroundColor: "transparent"
        }
    },
    qtdLote: {
        fontFamily: "Roboto",
        fontSize: 20
    },
    encerrado: {
        color: "#f00",
        paddingLeft: 15
    }
}


export default Torneio;