import React from "react";
import { Card  } from 'react-materialize';

export default ({children, style}) => {
    
    return  <Card className={"card-custom"} style={{...styles.card,...style}}>
            {children}
    </Card>
}

const styles = {
    card: {
        backgroundColor: 'rgba(255,255,255,0.9)',
        borderRadius: 9,
        maxHeight: "90vh",
        overflowY: "auto",
        overflowX: "hidden"
    }
}