import React, { useEffect, useState } from "react"
import { useContext } from "react";
import { Col, Container, Row } from "react-materialize";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Api from "../../api/Api";
import { Button, DatePicker, Input, Select } from "../../components/Form";
import HeaderViewEvento from "../../layouts/HeaderViewEvento";
import InscricaoContext, { actions } from "./InscricaoContext";
import {css} from "emotion"
import moment from "moment"
import { setSessionId, getBrand, createCardToken, getInstallments } from "../../components/PagSeguroDirectPayment"
import validCPF from "../../components/Valid/CPF"
import validaCPF from "../../components/Valid/CPF";
import { useCookies } from "react-cookie";
import card from "../../assets/icons/card.svg"
import visa from "../../assets/img/brand/visa.png"
import americanExpress from "../../assets/img/brand/american-express.png"
import elo from "../../assets/img/brand/elo.png"
import hipercard from "../../assets/img/brand/hipercard.png"
import diners from "../../assets/img/brand/diners.png"
import mastercard from "../../assets/img/brand/mastercard.png"
import pagbank from "../../assets/img/brand/pagbank.png"
import { BrowserView, isMobile, MobileView } from "react-device-detect";
import PagamentoBrowser from "./PagamentoLayout/PagamentoBrowser"
import PagamentoMobile from "./PagamentoLayout/PagamentoMobile"
const Component = () => {
    const [cookies] = useCookies(['id'])
    const {state, dispatch} = useContext(InscricaoContext)
    const [brand, setBrand] = useState()
    const [validCpf, setValidCpf] = useState("")
    const [optionsInstallment, setOptionsInstallment] = useState([])
    const [loadingBtn, setLoadingBtn] = useState(false)
    const {id} = useParams(); 
    const [openSuccess, setOpenSuccess] = useState(false)
    const history = useHistory()

    const optionsPag = [
        {
            value: "credit",
            label: "Cartão de crédito"
        }
    ]

    useEffect(() => {
        if(brand){
            (async () => {
                const result = await getInstallments({amount: state.totalPrice, brand })
                const installments = result.installments;
                const installmentsInternal = installments[brand].map((item) => {
                    return {value: item.quantity, 
                        price: item.installmentAmount,
                        label: item.quantity + "x de R$ " + item.installmentAmount.toFixed(2).replace(".",",")}
                })
                console.log(installmentsInternal)
                setOptionsInstallment(installmentsInternal)
            })()
        }
    },[brand])

    useEffect(() => {
           setOptionsInstallment([{
            value: "1",
            price: state.totalPrice,
            label: "1x de R$ "+ state.totalPrice.toFixed(2).replace(".",",")
        }])
        onChangeField("installmentQuantity", "1")
        onChangeField("installmentValue", state.totalPrice)
    },[state.totalPrice])

    useEffect(() => {
        (async () => {
            try {
                const numberCard = state.numberCard.replace(/\s/g,"").replace(/_/g,"")
                if(numberCard.length == 6){
                    const result = await getBrand(state.numberCard)
                   
                    setBrand(result.brand.name)
                }
               
            } catch(e){
                console.log(e)
            }
        })()
       
    },[state.numberCard])


    const onBuy = async () => {
        let ticketsForApi = []
      
        try {
            setLoadingBtn(true)
            const result = await createCardToken({cardNumber:
                 state.numberCard, 
                brand, cvv: state.cvv,
                expirationMonth: state.valDateCard.getMonth()+1,
                expirationYear: state.valDateCard.getFullYear()
            })
            const card = result.card.token

            state.tickets.map((item) => {

                if(item.athlete.id){ 
                    ticketsForApi.push({
                        "atleta_id": item.athlete.id,
                        "lote_id": item.id,
                        "categoria_id": item.categoria_id,
                        "evento_id": id,
                        "tamanho_roupa": item.clothes,
                        "equipe_id": item.team ? item.team.id : "",
                        "termo": (item.term == undefined || item.term == null) ? false : item.term,
                    })
                } else {
                    ticketsForApi.push({
                        "name": item.athlete.name,
                        "email": item.athlete.email,
                        "CPF": item.athlete.cpf,
                        "data_nascimento": moment(item.athlete.birthdate).format("YYYY-MM-DD"),
                        "atleta_id": item.athlete.id,
                        "lote_id": item.id,
                        "categoria_id": item.categoria_id,
                        "evento_id": id,
                        "tamanho_roupa": item.clothes,
                        "equipe_id": item.team ? item.team.id : "",
                        "termo": (item.term == undefined || item.term == null) ? false : item.term,
                    })
                }
              
            })
            const result2 = await Api().post("instricao/inscrever",
            {
                creditCardToken: card,
                installmentQuantity: state.installmentQuantity,
                installmentValue: state.installmentValue,
                atleta_id_prin: cookies.id,
                ingressos: ticketsForApi
            })
            setLoadingBtn(false)
            if(!result2.data.error){
                if(!isMobile){
                    toast.success("Inscrição realizada com sucesso")
                    history.replace(`/atleta/carteira`)
                } else {
                    history.replace(`/mobile/pagamento/sucesso`)
                }
             
            } else {
                toast.error("Ocorreu um erro ao realizar as inscrições")
            }            
        }
        catch (e){
            if(e.errors){
                const errors = e.errors ? Object.values(e.errors) : []
                toast.error(errors[0])
            } else {
                const response = e.response ? e.response : {};
               
                const data = response.data ? response.data : {};
                console.log(typeof data.msg)
                const msg = data.msg ? (typeof data.msg == "object" ? data.msg[0] : data.msg): "Ocorreu um problema";
                toast.error(msg)
            }
            setLoadingBtn(false)
            return;
        }

        
    }

    const onChangeField = (name,value) => {
       //state.paymentMethod.cardNumber.replace(/\s/g,"").replace(/_/g,"")
        dispatch({type: actions.HANDLE_CHANGE, payload: {
            name,value
        }})
    }

    const onChangeCpf = (value) => {
        setValidCpf(validaCPF(value))
        onChangeField("cpfCard",value)
    }

    return <div>
        <BrowserView>
            <PagamentoBrowser 
                 validCpf={validCpf}
                 optionsInstallment={optionsInstallment}
                 loadingBtn={loadingBtn}
                 onChangeField={onChangeField}
                 onChangeCpf={onChangeCpf}
                 optionsPag={optionsPag} 
                 onBuy={onBuy}
            />
        </BrowserView>
        <MobileView>
            <PagamentoMobile 
              validCpf={validCpf}
              optionsInstallment={optionsInstallment}
              loadingBtn={loadingBtn}
              onChangeField={onChangeField}
              onChangeCpf={onChangeCpf}
              optionsPag={optionsPag} 
              onBuy={onBuy}
            />
        </MobileView>
    </div>
}

const styles = {
    cardTitle: {
        display: "flex"
    },
    btnSalvar: {
        marginTop: 55
    },
    textDados:{
        fontFamily: "Roboto",
        marginLeft: 10,
        fontWeight: "bold",
        lineHeight: "30px"
    },
    textCard: {
        fontSize: 16,
        textAlign: "center"
    },
    divBrands: {
        display: 'flex',
        marginTop: 20,
    },
    divBrand: {
        
        marginRight: 20
    
    },
    brand: {
        height: 30,
    },
    divCard: {
        width: "fit-content"
    },
    main:{
        position: "relative",
        backgroundColor: "#F2F2F2",
        minHeight: "100vh"
    },
    titleSection: {
        color: "#606060",
        fontFamily: "Roboto",
        fontSize: 20
    },
    conteudoEvento: {
        marginTop: 30,
        marginLeft: 10,
        zIndex: 99,
        position: "relative"
    },
    miniCard: {
        width: 30
    },
    card: {
        width: 60,
        marginTop: 30
    }
}

export default Component;