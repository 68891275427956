import React, { useContext, useState, useEffect } from "react"
import { css } from "emotion";
import { ButtonClean } from "../../components/Form";
import MinhasEquipesContext, { actions } from "./MinhasEquipesContext";
import DataTable from 'react-data-table-component';
import ModalAtletas from "./ModalAtletas";
import Api from "../../api/Api";
import config from "../../config.js"
import {BarLoader} from "react-spinners";
import { toast } from "react-toastify";
import ModalDelete from "../../components/ModalDelete";
import { useCookies } from "react-cookie";
import { BrowserView, MobileView } from "react-device-detect";
import MembrosEquipesBrowser from "./MembrosEquipesLayout/MembrosEquipesBrowser";
import MembrosEquipesMobile from "./MembrosEquipesLayout/MembrosEquipesMobile"
 const Component = () => {
     
    const isCancelledTeam = React.useRef(false);
    const isCancelledMembers = React.useRef(false);
    const [cookies] = useCookies(['id']); 
    const {state, dispatch} = useContext(MinhasEquipesContext)
    const [loadingTable,setLoadingTable] = useState(false)
    const [openModalAtletas, setOpenModalAtletas] = useState()
    const [openModalDeleteAtl, setOpenModalDeleteAtl] = useState(false)
    const [openModalDeleteTeam, setOpenModalDeleteTeam] = useState(false)
    const [sameCoach, setSameCoach] = useState(false)
    const [idAtlDelete, setAtleDelete] = useState()
    const [members, setMember] = useState([]);
    const [coaches, setCoaches] = useState([{}])
    
    const onDelete = async () => {
        try {
            setOpenModalDeleteAtl(false)
            const result = await Api().post("membros/deletar",{
                equipe_id: state.team_id,
                user_id: [idAtlDelete]
            })
            const data = result.data ? result.data : {}
            toast.success("Atleta deletado com sucesso")
            dispatch({type: actions.UPDATE_MEMBERS_FORCE})
        }catch(e){
            const response = e.response ? e.response : {};
            const data = response.data ? response.data : {};
            const msg = data.msg ? data.msg[0] : "Ocorreu um problema ao tentar deletar o membro";
            toast.error(msg)
        }
    
    }

    useEffect(() => {
        isCancelledTeam.current = false
        const getMembers = async () => {
            setLoadingTable(true)
            const membersApi = await Api().get(`membros/listar/${state.team_id}`)
            const data = membersApi.data ? membersApi.data : {}
            const members = data.membros ? data.membros : {}
            let membersRows = members.rows ? members.rows : []
            membersRows = membersRows.map((row) => {

                let imagem = row.imagem ? row.imagem : ""
                imagem = imagem.search("https://") == 0 ? imagem :  config.storage_link+"/users/"+row.imagem
            

                return {...row, 
                    image: row.imagem ? imagem : "",
                    pontos: 0
                }
            })
            setLoadingTable(false)
            setMember(membersRows)
        }

        getMembers()
        return () => {isCancelledTeam.current = true;setMember([])}
    },[state.team_id, state.updateMembersForce]);

    useEffect(() => {
        console.log("Teste Advanced")
        isCancelledMembers.current = false
        const coaches = members.filter((item) => item.tecnico)
        const coacheSameUserLog = coaches.filter((item) => item.user_id == cookies.id)
        setSameCoach(coacheSameUserLog.length > 0)
        setCoaches(coaches)
        return () => {isCancelledMembers.current = true; setSameCoach(null); setCoaches([{}])}
    },[members])


    const handleAddAtleta = () => {
        setOpenModalAtletas(!openModalAtletas)
    }

    const handleEditEquipe = () => {
        dispatch({type: actions.SET_CURRENT_ELEMENT, payload: "edit_form"})
    }

    const handleDelEquipe = async () => {

        try {
           
            const result = await Api().delete("/equipe/deletar/"+state.team_id)
            if(!result.data.error){
                setOpenModalDeleteTeam(false)
                dispatch({type: actions.UPDATE_TEAMS_FORCE})
                dispatch({type: actions.SET_CURRENT_ELEMENT, payload: ""})
                toast.success("Equipe Deletada com com sucesso")
            }else {
                toast.error("Ocorreu um problema deletar a equipe")
            }
        }catch (e){
            const response = e.response ? e.response : {};
            const data = response.data ? response.data : {};
            const msg = data.msg ? data.msg[0] : "Ocorreu um problema ao tentar deletar a equipe";
            toast.error(msg) 
        }

      
    }
    if(isCancelledMembers.current && isCancelledTeam.current){
        return null;
    }

    return <div>
        <BrowserView>
            <MembrosEquipesBrowser 
               sameCoach={sameCoach}
               setAtleDelete={setAtleDelete}
               members={members}
               coaches={coaches}
               openModalDeleteAtl={openModalDeleteAtl}
               setOpenModalDeleteAtl={setOpenModalDeleteAtl}
               handleDelEquipe={handleDelEquipe}
               onDelete={onDelete}
               handleEditEquipe={handleEditEquipe}
               loadingTable={loadingTable}
            />
        </BrowserView>
        <MobileView>
            <MembrosEquipesMobile
               sameCoach={sameCoach}
               setAtleDelete={setAtleDelete}
               members={members}
               coaches={coaches}
               openModalDeleteAtl={openModalDeleteAtl}
               setOpenModalDeleteAtl={setOpenModalDeleteAtl}
               handleDelEquipe={handleDelEquipe}
               onDelete={onDelete}
               handleEditEquipe={handleEditEquipe}
               loadingTable={loadingTable}
            />
        </MobileView>
    </div>
 }

 const styles = {
    farol: {
        cursor:'pointer',
        color:'yellow'
    },
    loadingCss: {
        width: "100%"
    },
    imageCoach: {
       marginRight: 30,
       width: 75,
       height: 75,
       borderRadius: "50%",
     //  flex: 1
    },
    containerLine: {
        display: 'flex',
    },
    line: {
        flex: 9,
        width: "100%",
        alignSelf: "center",
        height: 64,
        backgroundColor: "#fff",
        boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.15)",
        borderRadius: 9,
        overflow: "overlay",
        flexWrap: "wrap",
        display: 'flex',
        position: "relative"
    },
    itemLine:{
        flex: 1,
        alignSelf: "center",
        display: "flex",
        marginRight: 20,
        marginLeft: 20
    },
    coachItemLine: {
        justifyContent: "center"
    },
    pointsItemLine: {
        justifyContent: "flex-end"
    },
    iconDelete: {
        cursor:'pointer',
        color:'red'
    },
    title: {
        paddingTop: 30,
        display: "flex"
    },
    subContainer: {
        marginTop: 30
    },
    btns: {
        float: "right"
    },
    titleSpan: {
        fontFamily: "Roboto",
        fontSize: 26,
        flex: 1
    },
    itemBtnTitle: {
        marginLeft: 40
    },
    container:{
        width: "70%",
        "@media(max-width: 1205px)": {
            width: "100%",
        }
    },
    imageMember: {
        width: 35,
        height: 35,
        borderRadius: "50%"
    },
    titleLine: {
        fontSize: 18,
        fontFamily: "Roboto"
    },
    sportLine: {
        fontSize: 14,
        fontFamily: "Roboto"
    }
 }

 export default  React.memo(Component);