import React,{ useState, useEffect, useContext, useReducer } from "react"
import { Row, Col, Container, Table } from "react-materialize"
import config from "../../../../config.js"
import Carousel from "../../../../components/Carousel"
import {  useParams } from "react-router-dom"
import ModalFaleConosco from "../../ModalFaleConosco"
import { css } from "emotion"
import ItemEventoLista from "../../ItemEventoLista"
import Footer from "../../../../layouts/Footer"
import HeaderViewEvento from "../../../../layouts/HeaderViewEvento"
import { Button } from "../../../../components/Form"
import ApresentacaoEventoContext from "../../ApresentacaoEventoContext"
import moment from "moment"

export default () => {
    const apresentacaoContext = useContext(ApresentacaoEventoContext)
    const [openModalFaleConosco, setOpenModalFaleConosco] = useState(false)
    
    const {id} = useParams(); 

    console.log(apresentacaoContext.state)

    return <>
    <div className={css(styles.main)}>
        <HeaderViewEvento 
            buttons={[<button className={css(styles.button)}
                onClick={apresentacaoContext.openResultado}>
                    <span>Resultado</span> 
                </button>]}
            picture={apresentacaoContext.state.picture} 
            name={apresentacaoContext.state.name} 
            dateStart={apresentacaoContext.state.dateStart} 
            city={apresentacaoContext.state.city} 
            state={apresentacaoContext.state.state} />
        <div className={css(styles.conteudoEvento)}>
        
            <Row>
                <Col s={12} l={6}>
                    <div className={css(styles.divDeskView)}>
                        <Row>
                            <Col>
                                <h5 className={css(styles.labelDescricao)}>DESCRIÇÃO DO EVENTO:</h5>
                            </Col>
                        </Row>
                        <Row >
                        <Col s={12}>
                                <div dangerouslySetInnerHTML={{ __html: apresentacaoContext.state.description }}></div>
                        </Col>
                        </Row>
                    </div>
                </Col>
                <Col s={12} l={6}>
                    <div>
                        <Container>
                        {(!apresentacaoContext.state.block && apresentacaoContext.state.lots.length > 0) && <><Row>
                                <span className={css(styles.dataEndSub)}>Data encerramento inscrições: </span>
                                <span className={css(styles.dataEndSubSpan)}>{apresentacaoContext.state.endDateSubscription}</span>
                            </Row>
                            
                            <Row>
                                <Table className={css(styles.tableCategories)} >
                                    <thead className={css(styles.headTableCat)}>
                                        <tr >
                                            <th data-field="categoria" colSpan={2} className={css(styles.headTableCatCat)}>
                                                Inscrições
                                            </th>
                                        
                                            <th data-field="preco" colSpan={2} className={css(styles.headTableCatTotalPrice)}>
                                                R$ {apresentacaoContext.state.total}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {apresentacaoContext.state.lots.map((lot,i) => {
                                                return lot.visible == true && <tr key={"lot"+lot.id}>
                                                    <td data-field="categoria" colSpan={3}>
                                                        <div><label className={css(styles.nome_cat)}>{lot.nome_cat}</label></div>
                                                        <div><label className={css(styles.valor_lote)}>R$ {(lot.valor && lot.valor != "0") ? parseFloat(lot.valor).toFixed(2).replace(".",",")+" ( + 10% de taxa)" : "0,00"} </label></div>
                                                        <div><label className={css(styles.parcela_lote)}>{(lot.valor && lot.valor != "0") ? "em até 12x R$ "+(parseFloat(lot.valor) / 12).toFixed(2).replace(".",",") : ""}</label></div>
                                                        <div><label className={css(styles.valor_lote)}> {lot.descricao_lote} encerra em: {moment(lot.data_fim).format("DD/MM/YYYY")} </label></div>
                                                    </td>
                                                    <td data-field="preco" className={css(styles.acoesTable)}>
                                                        <div> 
                                                            <button className={css(styles.btnChangeQuantLote)} onClick={() => apresentacaoContext.subQuantidade(i)}>-</button> 
                                                            <span className={css(styles.qtdLote)}> {apresentacaoContext.retornoZeroBeforeNumbers(lot.quantidade)} </span>  
                                                            <button className={css(styles.btnChangeQuantLote)} onClick={() => apresentacaoContext.addQuantidade(i)}>+</button> 
                                                        </div> 
                                                    </td>
                                                </tr>
                                            }
                                        )}
                                    
                                    </tbody>
                                    <tfoot>
                                        <tr className={css(styles.btnComprarTR)}>
                                            <td className={css(styles.btnComprarTD)}  colSpan={4} onClick={apresentacaoContext.onBuy}>
                                                COMPRAR
                                            </td>
                                        </tr>
                                    </tfoot>
                                </Table>
                            </Row></>}
                        </Container>
                    </div>
                </Col>
            </Row>
            <ModalFaleConosco event_id={id} open={openModalFaleConosco} toggle={setOpenModalFaleConosco}/>
            <div className={css(styles.percurso)}>
                {apresentacaoContext.state.pictureRoute &&  apresentacaoContext.state.pictureRoute.length > 0 && <Row>
                    <Col>
                        <h5 className={css(styles.percursoLabel)}>PERCURSO:</h5>
                    </Col>
                </Row>}
                <Row>
                    <Col s={12} m={12}>
                        {apresentacaoContext.state.pictureRoute.map((item,i) => {
                            return <>
                            <img key={i} src={config.storage_link+`/evento/${id}/percurso/${item.nome}`} 
                                className={css(styles.imgPercurso)}
                            />
                            </>
                        })}
                    </Col>
                </Row>
            </div>
            <div className={css(styles.organizador)}>
                <Row>
                    <Col>
                        <h5 className={css(styles.organizadorLabel)}>ORGANIZADOR:</h5>
                    </Col>
                </Row>
                <Row>
                    <Col s={12} m={12}>
                        <div className={css(styles.txtOrg)}>Nome: {apresentacaoContext.state.organizer}</div>
                        <span className={css(styles.txtOrg)}>Site: </span><a href={apresentacaoContext.state.site} target="_blank" className={css(styles.txtOrg)}>{apresentacaoContext.state.site}</a>
                        <div className={css(styles.txtOrg)}>Email: {apresentacaoContext.state.email}</div><br/>
                        <Button 
                            className={css(styles.btnFaleConosco)} 
                            onClick={() => setOpenModalFaleConosco(true)}
                            >Fale com o Organizador</Button>
                    </Col>

                </Row>
            </div>
            <div className={css(styles.percurso)}>
                {apresentacaoContext.state.patrocinadores && apresentacaoContext.state.patrocinadores.length > 0 && <Row>
                    <Col>
                        <h5 className={css(styles.percursoLabel)}>PATROCINADORES:</h5>
                    </Col>
                </Row>}
                <Row>
                    <Col s={12} m={12}>
                        {apresentacaoContext.state.patrocinadores.map((item,i) => {
                            return <a target="_blank" href={item.nome}>
                                <img key={i} alt={item.nome} src={config.storage_link+`/patrocinador/${item.imagem}`} 
                                    className={css(styles.imgPatrocinadores)}
                                />
                            </a>
                        })}
                    </Col>
                </Row>
            </div>
            <div className={css(styles.maisEventos)}>
                <Row>
                    <Col>
                        <h5 className={css(styles.meusEventosLabel)}>MEUS EVENTOS:</h5>
                    </Col>
                </Row>
                <Container>
                    <Carousel 
                        loading={apresentacaoContext.state.loadingOtherEvents}
                        template={ItemEventoLista}
                        items={apresentacaoContext.state.otherEventsOrganizer}/>
                </Container>
            </div>
        </div>
    </div>
    <Footer />
</>
}

const styles = {
    main: {
        position: "relative",
        backgroundColor: "#F2F2F2",
        minHeight: "100%"
    },
    acoesTable: {
        marginTop: "20%",
        float: "right"
    },
    imgPercurso: {
        width: 200,
        height: 100,
        paddingLeft: 20,
        objectFit: "cover",
        ":hover": {
            width: 220,
            height: 120
        }
    },
    imgPatrocinadores: {
        width: 200,
        height: 100,
        paddingLeft: 20,
        objectFit: "cover",
        cursor: "pointer",
        ":hover": {
            width: 220,
            height: 120
        }
    },
    divDeskView: {
        marginLeft: '8%',
        "@media(max-width: 992px)": {
            marginLeft: "7%",
            marginRight: "7%",
        },
        "@media(max-width: 700px)": {
            marginLeft: 7,
            marginRight: 7,
        },
    },
    maisEventos: {
        marginLeft: '4%',
        paddingBottom: 20,
        "@media(max-width: 992px)": {
            marginLeft: "7%",
            marginRight: "7%",
        },
        "@media(max-width: 700px)": {
            marginLeft: 15,
            marginRight: 15,
        },
    },
    organizador: {
        marginLeft: '4%',
        "@media(max-width: 992px)": {
            marginLeft: "7%",
            marginRight: "7%",
        },
        "@media(max-width: 700px)": {
            marginLeft: 15,
            marginRight: 15,
        },
    },
    percurso: {
        marginLeft: '4%',
        "@media(max-width: 992px)": {
            marginLeft: "7%",
            marginRight: "7%",
        },
        "@media(max-width: 700px)": {
            marginLeft: 15,
            marginRight: 15,
        },
    },
    nome_cat: {
        color: "#606060",
        fontFamily: "Roboto",
        fontSize: 18
    },
    valor_lote: {
        color: "#606060",
        fontFamily: "Roboto",
        fontSize: 14
    },
    parcela_lote: {
        color: "#19BC0B",
        fontFamily: "Roboto",
        fontSize: 14
    },
    labelDescricao: {
        color: "#606060",
        fontFamily: "Roboto",
        fontSize: 20
    },
    meusEventosLabel: {
        color: "#606060",
        fontFamily: "Roboto",
        fontSize: 20
    },
    organizadorLabel: {
        color: "#606060",
        fontFamily: "Roboto",
        fontSize: 20
    },
    percursoLabel: {
        color: "#606060",
        fontFamily: "Roboto",
        fontSize: 20
    },
    titleEvento: {
        "@media(max-width: 992px)": {
            marginLeft: -10,
            textAlign: 'center',
        },
        fontWeight: 600,
        fontFamily: "Roboto",
        color: "#606060"
    },
    headerCapa: {
        height: 160,
        width: "100%",
        position: "relative",
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundColor: "#2F2E41"
    },
    viewImageEvento: {
        position: "absolute",
        zIndex: 2,
        top: 80,
        left: "5%",
        width: "70%",
        "@media(max-width: 992px)": {
            left: "0%",
            width: "100%",
        },
    },
    conteudoEvento: {
        marginLeft: 10,
    },
    imgEventoDiv: {
        height: "15rem"
    },
    imgEvento: {
        objectFit: 'cover',
        objectPosition: '0%',
        "@media(max-width: 992px)": {
            objectFit: 'contain',
            objectPosition: 'center center'
        },
    },
    dateStart: {
        color: "#606060",
        fontFamily: "Roboto",
        fontSize: 16,
    },
    dateEnd: {
        color: "#606060",
        fontSize: 16,
        fontFamily: "Roboto",
    },
    dataEndSub: {
        color: "#606060",
        fontFamily: "Roboto",
        fontSize: 14,
    },
    dataEndSubSpan: {
        color: "#606060",
        fontFamily: "Roboto",
        fontSize: 14,
    },
    txtOrg: {
        color: "#606060",
        fontFamily: "Roboto",
        fontSize: 18,
    },
    btnFaleConosco: {
        fontSize: 14, 
        width: "35% !important",
        "@media(max-width: 992px)": {
            width: "100% !important",
        },
    },
    btnTodosEsportes: {
        marginTop: 10
    },
    btnEsporte: {
        marginTop: 10,
        overflow: "hidden"
    },
    tableCategories: {
        width: "98%"
    },
    headTableCat: {
        backgroundColor: "#6B69CF"
    },
    headTableCatCat: {
        fontSize: 18,
        fontWeight: 500,

        fontFamily: "Roboto",
        paddingBottom:8,
        paddingLeft: 20,
        paddingTop: 8,
        borderRadius: 0,
        color: "#fff"
    },
    headTableCatTotalPrice: {
        fontSize: 18,
        fontWeight: 500,
        paddingBottom:8,
        fontFamily: "Roboto",
        textAlign: "center",
        paddingTop: 8,
        borderRadius: 0,
        color: "#fff"
    },
    headDataEncerra: {
        fontSize: 14,
        fontWeight: 400,
        paddingBottom:8,
        paddingLeft: 20,
        fontFamily: "Roboto",
        textAlign: "left",
        paddingTop: 8,
        borderRadius: 0,
        color: "#fff"
    },
    btnComprarTR: {
        backgroundColor: "#6B69CF",
        cursor: "pointer"
    },
    btnComprarTD: {
        textAlign: "center",
        fontSize: 18,
        paddingBottom:8,
        paddingTop: 8,
        color: "#fff"
    },
    btnChangeQuantLote: {
        cursor: "pointer",
        fontSize: 24,
        paddingLeft: 10,
        paddingRight: 10,
        backgroundColor: "transparent",
        border: "none",
        ":hover": {
            backgroundColor: "transparent"
        },
        ":focus": {
            backgroundColor: "transparent"
        }
    },
    qtdLote: {
        fontFamily: "Roboto",
        fontSize: 20
    },
    encerrado: {
        color: "#f00",
        paddingLeft: 15
    },
    divResultado: {
        position: "absolute", 
        right: 50, 
        bottom: 50,
        border: "1px solid",
        padding: "5px 15px",
        cursor: "pointer",
        borderRadius: 25,
        color: "#fff",
        "@media(max-width: 700px)": {
            right: 10, 
            top: 10,
            bottom: 'auto',
        },
    },
    button: {
        backgroundColor: "transparent",
        border: "1px solid",
        padding: "5px 15px",
        cursor: "pointer",
        borderRadius: 25,
        color: "#fff",
    }
}