import React from "react"
import { Row, Col } from "react-materialize"
import {Button, InputPassword} from "../../../components/Form"
import {useCookies} from "react-cookie"

export default ({
    onSubmit, 
    loadingPub, 
    oldPassword, 
    setOldPassword,
    newPasssword,
    setNewPassword,
    regexPassword,
    messageErroPassword,
    newPasswordRepeat,
    setNewPasswordRepeat
}) => {
    const [cookies,] = useCookies(['id']);

    return <form  id="evento" type="POST" onSubmit={onSubmit}>
                    <Row>
                        <Col s={12} l={6}>
                            <InputPassword
                                    id="old_password"
                                    label={"Senha Atual*:"}
                                    type={"password"}
                                    required={true}
                                    value={oldPassword}
                                    onChange={(e) => setOldPassword(e.target.value)}
                                />
                        </Col>
                    </Row>
                    <Row>
                        <Col s={12} l={6}>
                                <InputPassword
                                    id="new_password"
                                    label={"Nova Senha*:"}
                                    type={"password"}
                                    required={true}
                                    pattern={`${regexPassword}`}
                                    messageError={(!newPasssword || newPasssword.match(regexPassword) == null) && messageErroPassword}
                                    title={messageErroPassword}
                                    value={newPasssword}
                                    onChange={(e) => setNewPassword(e.target.value)}
                                />
                        </Col>
                        <Col s={12} l={6}>
                                <InputPassword
                                    id="new_password_repeat"
                                    label={"Confirmar Nova Senha*:"}
                                    type={"password"}
                                    required={true}
                                    pattern={`^${newPasswordRepeat}$`}
                                    messageError={!newPasswordRepeat || newPasswordRepeat != newPasssword && "As senhas devem ser iguais"}
                                    title="As senhas devem ser iguais"
                                    value={newPasswordRepeat}
                                    onChange={(e) => setNewPasswordRepeat(e.target.value)}
                                />
                        </Col>
                    </Row>
                    <Row>
                        <Col m={12} s={12} l={6} className="offset-l3">
                            <Button loading={loadingPub}>Atualizar</Button>
                        </Col>
                    </Row>
                </form>

}

const styles={
    newEvento : {
        float:"left"
    },
    main: {
        paddingBottom: 20
    },
    labelImg:{
        color: "#000",
    },
    messageInfo: {
        color: "#000",
        fontWeight: 700,
        fontSize: 16
    },
    photoEvent: {
        width: "100%",
        paddingTop: 20,
        position: "relative"
    }
}