import React, { useState, useContext,  useEffect } from "react"
// import {Modal} from "react-materialize"
import {Button, Input, ImageUploader, Select, ButtonClean, Checkbox} from "../../../../components/Form"
import { Row, Col } from "react-materialize"
import FormTorneioContext, { actions } from "../../FormTorneioContext" 
import { Modal } from 'react-responsive-modal';
import moment from "moment"
import { toast } from "react-toastify";
import api from "../../../../api/Api";
import { css } from "emotion";
const ModalCategoria = (props) => {

  const {state, dispatch} = useContext(FormTorneioContext);

  const [picture, setPicture] = useState([])
  const [pictureUrl, setPictureUrl] = useState([])
  const [name, setName] = useState("")
  const [distance, setDistance] = useState("")
  const [sport,setSport] = useState("") 
  const [loadingPubCat, setLoadingPubCat] = useState(false)
  const [classificationAge, setClassificationAge] = useState([])


  const onDrop = (picture,pictureDataURLs) => {
       setPicture(picture)
       setPictureUrl(pictureDataURLs)
  }

  useEffect(() => {
    if(props.id){
      const oldValues = state.categories[props.id]
      console.log(oldValues)
      if(oldValues){
        
        if(oldValues.picture){
          setPicture([oldValues.picture])
        }else {
          setPicture([])
        }
        setPictureUrl([oldValues.pictureUrl])

        setName(oldValues.nome)
        setClassificationAge(oldValues.faixa_idade ? oldValues.faixa_idade : [])
        setDistance(oldValues.distancia)
        setSport(oldValues.esporte)
        console.log(oldValues.esporte)
      }
  
    }
  },[props.id])


  useEffect(() => {
    dispatch({type: actions.PREVENT_SUBMIT, payload: true})

  },[])

  useEffect(() => {
    dispatch({type: actions.PREVENT_SUBMIT, payload: props.open})

  },[props.open])

  const onPressSave = async  (e) => {
    e.preventDefault();
    if(!pictureUrl[0]){
      toast.error('É necessario inserir a foto da categoria para continua')
      return;
    }else if(!classificationAge[0]){
      toast.error('É necessario inserir a classificação por idade para continuar.')
      return;
    }else if(distance < 0){
      toast.error('A distância não pode ser negativa')
      return;
    }
    let id;
    let result = {};
    if(props.id && state.idTorneio){
      result = await updateCategoryApi();
      id = props.id
      uploadImageCategory(id)
    }else if(state.idTorneio){
      result = await saveCategoryApi()
      if(!result){setLoadingPubCat(false); return}
      id = result
      uploadImageCategory(id)
    }else {
      id = props.id ? props.id : moment().valueOf()
    }

    if(!result){ setLoadingPubCat(false); return}
    const newId = !props.id;

    const payload = { 
      ...state.categories, [id] : { 
        esporte: sport, 
        id, 
        picture: picture[0] ? picture[0] : null,
        pictureUrl: pictureUrl[0] ? pictureUrl[0] : null,
        faixa_idade: classificationAge,
        nome:  name,
        distancia: distance,
        new: newId 
      }}
    dispatch({type: actions.ADD_CATEGORIES, payload})
    onClose()

    if(newId) {
      setPicture([])
      setPictureUrl([])
      setName("")
      setClassificationAge([])
      setDistance(0)
      setSport("")
    }
  }

  const updateCategoryApi = async () => {
    try {
      setLoadingPubCat(true)


      const result = await api().post("categorias/editar/"+props.id,{
        nome: name,
        distancia: distance,
        esporte: sport,
        faixa_idade: classificationAge,
      })
      setLoadingPubCat(false)
      return result;
    } catch (e){
      setLoadingPubCat(false)
      const response = e.response ? e.response : {};
      const data = response.data ? response.data : {};
      const msg = data.msg ? data.msg[0] : "Ocorreu um problema ao tentar atualizar a categoria";
      toast.error(msg)
      return false;
    }
  }

  const saveCategoryApi = async () => {
    try {
      setLoadingPubCat(true)

      const result = await api().post("categorias/salvar",{
        nome: name,
        distancia: distance,
        esporte: sport,
        faixa_idade: classificationAge,
        ativo: true
      })
      const idCat = result.data.msg.categoria_id
      setLoadingPubCat(false)
      return idCat;
    } catch (e){
      setLoadingPubCat(false)
      const response = e.response ? e.response : {};
      const data = response.data ? response.data : {};
      const msg = data.msg ? data.msg[0] : "Ocorreu um problema ao tentar salvar a categoria";
      toast.error(msg)
      return false;
    }
  }

    const uploadImageCategory = async (id) => {
        let formData = new FormData();
        if(picture[0]){
            formData.append("categoria_id",id)
            formData.append("file",picture[0])
            await api().post("categorias/uploadimagens",formData)
        }
    }

  const onClose = () => {
    dispatch({type: actions.PREVENT_SUBMIT, payload: false})

    props.toggle(false)
  }

  const onChangeClassificationAge = (value) =>{
    const isChecked = classificationAge.includes(value)
    let classificationAgeInternal = classificationAge
    if(isChecked){
      classificationAgeInternal = classificationAge.filter((item) => item != value)
    }else {
      classificationAgeInternal.push(value)
    }
    console.log(classificationAgeInternal)
    setClassificationAge(classificationAgeInternal)
  }
  return <div>
{!props.id && !props.edit && 
  <ButtonClean onClick={() =>  props.toggle(true)} icon={"plus"}>Adicionar Categoria</ButtonClean>}
<Modal
  open={props.open}
  center
  type={"button"}
  onClose={onClose}
  styles={{
    modal: styles.modal
  }}
>
  <h5 className="grey-text text-darken-3 center-align">Categoria</h5>
  <form id="formCategoria" type="POST" name="formCategoria" onSubmit={onPressSave}  role="form">
    <Row>
        <Col s={12} l={4}>
                <ImageUploader
                    buttonText='Escolher Imagem'
                    defaultImages={pictureUrl}
                    aspect={16/9}
                    crop
                    required={true}
                    onChange={onDrop}
                />
        </Col>
        <Col  s={12} l={8}>
            <Row>
                <Col s={12}>
                    <Input
                            label={"Nome da Categoria*:"}
                            key="nome_cat"
                            value={name}
                            required={true}
                            onChange={(e) => setName(e.target.value)}
                        />
                </Col>
            </Row>
            <Row>
                <Col s={12} l={6}>
                    <Select
                        label={"Esporte*:"}
                        options={state.listSports}
                        value={state.listSports.filter(option => option.value == sport)}
                        required={true}
                        form="required"
                        onChange={(data) => setSport(data.value)}
                      />
                </Col>
                <Col s={12} l={6}>
                    <Input
                      label={"Distância*:"}
                      type={"number"}
                      placeholder={"Metros"}
                      value={distance}
                      required={true}
                      onChange={(e) => setDistance(e.target.value)}

                    />
                </Col>
              </Row>
        </Col>
    </Row>
    <Row>
        <Col s={12} >
          <div className={css(styles.divClassification)}>
              <label className={css(styles.labelClass)}>Classificações por idade*:</label>
          </div>
              <div className={css(styles.classficationBoxes)}>
                <Checkbox 
                      id={"kids"}
                      label={"Kids"}
                      value={classificationAge.includes("kids")}
                      className={css(styles.classCheckBox)}
                      onChange={() => onChangeClassificationAge('kids')}
                />
                <Checkbox 
                      id={"juvenil"}
                      label={"Juvenil"}
                      value={classificationAge.includes("juvenil")}
                      className={css(styles.classCheckBox)}
                      onChange={() => onChangeClassificationAge('juvenil')}
                />
                <Checkbox 
                      id={"open"}
                      label={"Open"}
                      value={classificationAge.includes("open")}
                      className={css(styles.classCheckBox)}
                      onChange={() => onChangeClassificationAge('open')}
                />
                <Checkbox 
                      id={"master"}
                      label={"Master"}
                      value={classificationAge.includes("master")}
                      className={css(styles.classCheckBox)}
                      onChange={() => onChangeClassificationAge('master')}
                />
                <Checkbox 
                      id={"super_master"}
                      label={"Super Master"}
                      value={classificationAge.includes("super_master")}
                      className={css(styles.classCheckBox)}
                      onChange={() => onChangeClassificationAge('super_master')}
                />
                <Checkbox 
                      id={"legend"}
                      label={"Legend"}
                      value={classificationAge.includes("legend")}
                      className={css(styles.classCheckBox)}
                      onChange={() => onChangeClassificationAge('legend')}
                />
              </div>
        </Col>
      </Row>
    <Row>
      <Col m={12} s={12} l={6} className="offset-l3">
        <Button loading={loadingPubCat} form="formCategoria">Publicar Categoria</Button>
      </Col>
    </Row>
    </form>
</Modal>

</div>
}

const styles = {
  modal: {
    width: "100%"
  },
  classCheckBox: {
    marginRight: 10
  },
  classficationBoxes: {
    display: "flex",
    flexWrap: "wrap"
  },
  labelClass: {
      color: "#000",
      fontSize: 16,
      fontFamily: "Roboto"
  },
  divClassification: {
      marginBottom: 30
  }
}

export default React.memo(ModalCategoria)