import React, { useEffect, useState } from "react"
import circle from "../../assets/img/circle.svg"
import Api from "../../api/Api";
import { useCookies } from "react-cookie";
import config from "../../config.js"
import moment from "moment"
import { BrowserView, MobileView } from "react-device-detect";
import HallFamaBrowser from "./HallFamaLayout/HallFamaBrowser";
import HallFamaMobile from "./HallFamaLayout/HallFamaMobile";
const Component = () => {
    const [cookies] = useCookies(['id']); 
    const [labelAchievements, setLabelAchievements] = useState("")
    const [loadingTable, setLoadingTable] = useState(false)
    const [tournamentId, setTournamentId] = useState(0)
    const [events, setEvents] = useState([])
    const [eventId, setEventId] = useState(0)
    const [genre, setGenre] = useState("M")
    const [sport, setSport] = useState("")
    const [category, setCategory] = useState("")
    const [subCategory, setSubCategory] = useState("")
    const [categories, setCategories] = useState([])
    const [subCategories, setSubCategories] = useState([])
    const [points, setPoints] = useState(0)

    const [tournaments, setTournaments] = useState([
        {
            name: "Hall da Fama",
            image: circle,
            action: () => onClickHallFama()
        },
    ])

    const genres = [
        {
            value: "M",
            label: "Masculino"
        },
        {
            value: "F",
            label: "Feminino"
        }
    ]

    const [achievements, setAchievements] = useState([])

    const onClickHallFama = async () => {
        setLabelAchievements("Minhas Conquistas")
        setLoadingTable(true)
        const rankingResponse = await Api().get("/ranking/atleta", {
            params: {
                atleta_id: cookies.id,
            }
        })
        const data = rankingResponse.data ? rankingResponse.data : []
        setTournamentId(0)
        let achs = []
        data.map((item) => {
            achs.push({
                position: item.posicao,
                nome: item.evento_nome,
                esporte: item.esporte,
                data: moment( item.data_hora_finalizou).format("DD/MM/YYYY"),
                categoria_evento: item.categoria,
                tipo_categoria_evento: item.faixa_idade,
                pontuacao: item.pontuacao + " pts",
                tempo: item.tempo,
            })
        })
        setLoadingTable(false)
        setAchievements(achs)
    }

    const onClickTourn = (id, nome) => {
        setLabelAchievements("Raking do Torneio - "+nome)
        setEventId("")
        setSport("")
        setCategory("")
        setCategories([])
        setSubCategories([])
        setSubCategory("")
        setTournamentId(id)
        
    }

    const getRanking = async (id) => {
        if(!category || !sport || !genre){
            return
        }
       

        setLoadingTable(true)
        const rankingResponse = await Api().get("/ranking/torneio/"+id,{
            params: {
                evento_id: eventId,
                categoria_id: category,
                subcategoria_id: subCategory,
                esporte_id: sport,
                genero: genre
            }
        })
        const data = rankingResponse.data ? rankingResponse.data : []
        let achs = []
        data.map((item) => {
            achs.push({
                position: item.posicao,
                nome: item.nome_atleta,
                esporte: item.esporte,
                data: moment( item.data_hora_finalizou).format("DD/MM/YYYY"),
                categoria_evento: item.categoria,
                tipo_categoria_evento: item.faixa_idade,
                pontuacao: item.pontuacao + " pts",
                tempo: item.tempo,
            })
        })
        setLoadingTable(false)
        setAchievements(achs)
    }

    useEffect(() => {
        setSport("")
        setCategories([])
        setCategory("")
        setSubCategories([])
        setSubCategory("")
    },[eventId])

    useEffect(() => {
        getRanking(tournamentId)
    },[tournamentId, eventId, sport,category,subCategory, genre])

    useEffect(() => {
        const getTorn = (async () => {
            setSport("")
            const tournament = {
                name: "Hall da Fama",
                image: circle,
                action: () => onClickHallFama()
            }
            setTournaments([tournament])
            const tourResponse = await Api().get("/instricao/listar/torneios", {
                params: {
                    atleta_id: cookies.id
                }
            })
            const data = tourResponse.data ? tourResponse.data : []
            const dataTourn = data.map((item) => {

                return {...item, 
                    action: () => onClickTourn(item.id, item.nome),
                    image: config.storage_link + `/torneio/${item.id}/${item.imagem}`}
            })
            setTournaments([tournament,...dataTourn])
        })
        getTorn()
    },[])

    useEffect(() => {
        (async () => {
            
            const allEvents = {value: "", label: "Eventos agrupados"}
            const tourResponse = await Api().get(`torneio/${tournamentId}/eventos`)
            const data = tourResponse.data ? tourResponse.data : []
            const dataEvent = data.map((item) => {
                return {value: item.id, label: item.nome}
            })
            setEvents([allEvents,...dataEvent])
        })()
        
    },[tournamentId])

    useEffect(() => {
        (async () => {
            if(!sport ){
                return
            }
            // if(!eventId){
            //     setCategory("")
            //     setSubCategory("")
            // }

            const response = await Api().get(`categorias/listarfiltro`, {
                params: {
                    evento_id: eventId,
                    torneio_id: tournamentId,
                    esporte: sport
                }
            })
           
            const categoriasData = response.data ? response.data : {}
            const categorias = categoriasData.categorias ? (categoriasData.categorias) : [] 
            const dataCategorias = categorias.map((item) => {
                return {value: item.id, label: item.nome}
            })
            console.log( categorias[0])
            setCategories([...dataCategorias])
            setCategory(categorias[0] ? categorias[0].id : "")

        })()
        
    },[sport, eventId,tournamentId])

    useEffect(() => {
        (async () => {

            if(!category){
                setSubCategory("")
                setSubCategories([])
                return;
            }
            const response = await Api().get(`categorias/listarSubCategoria/${category}`)
         
            const categoriasData = response.data ? response.data : {}
            const subCategorias = categoriasData.subcategorias ? (categoriasData.subcategorias) : [] 
            const dataSubCategorias = subCategorias.map((item) => {
                return {value: item.label, label: item.label}
            })
            setSubCategories([{value: "", label: "Geral"},...dataSubCategorias])
        })()
        
    },[category])

    useEffect(() => {
        (async () => {
            if(cookies.id){
                const resultado = await Api().get("ranking/pontosAleta/"+cookies.id)
                
                const data = resultado.data ? resultado.data : {}
                const pontos = data.pontos ? data.pontos : 0
                setPoints(pontos)
            }
        })()
      
    },[cookies.id])
    
    return <>
        <BrowserView>
            <HallFamaBrowser 
               loadingTable={loadingTable}
               tournaments={tournaments}
               labelAchievements={labelAchievements}
               achievements={achievements}
               tournamentId={tournamentId}
               events={events}
               eventId={eventId} 
               categories={categories}
               subCategories={subCategories}
               setCategory={setCategory}
               category={category}
               setEventId={setEventId}
               subCategory={subCategory}
               setSubCategory={setSubCategory}
               genres={genres}
               genre={genre}
               setGenre={setGenre}
               sport={sport}
               setSport={setSport}
            />
        </BrowserView>
        <MobileView>
            <HallFamaMobile
                points={points}
                loadingTable={loadingTable}
                tournaments={tournaments}
                labelAchievements={labelAchievements}
                achievements={achievements}
                tournamentId={tournamentId}
                events={events}
                eventId={eventId} 
                categories={categories}
                subCategories={subCategories}
                setCategory={setCategory}
                category={category}
                setEventId={setEventId}
                subCategory={subCategory}
                setSubCategory={setSubCategory}
                genres={genres}
                genre={genre}
                setGenre={setGenre}
                sport={sport}
                setSport={setSport}
            />
        </MobileView>
    </>
}


export default Component;