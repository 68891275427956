import actions from "./actions";

export default (state, action) => {
    switch(action.type){
        case actions.SET_ATHLETE_TICKET:
            let ticketsAth = [...state.tickets]
            ticketsAth[action.index].athlete =  action.payload
            return {...state, tickets: ticketsAth}
        case actions.SET_PRE_ATHLETE_TICKET:
            let ticketsAthPre = [...state.tickets]
            ticketsAthPre[action.index].athlete =  action.payload
            return {...state, tickets: ticketsAthPre}
        case actions.DELETE_ATHLETE_TICKET:
            let tickets = [...state.tickets]
            delete tickets[action.index].athlete
            return {...state, tickets}
        case actions.SET_TEAM_TICKET:
            let ticketsTeam = [...state.tickets]
            ticketsTeam[action.index].team =  action.payload
            return {...state, tickets: ticketsTeam}
        case actions.DELETE_TEAM_TICKET:
            let ticketsTeam2 = [...state.tickets]
            ticketsTeam2[action.index].team =  action.payload
            return {...state, tickets: ticketsTeam2}
        case actions.SET_TERM_TICKET: 
            let ticketsTerm = [...state.tickets]
            ticketsTerm[action.index].term = true
            return {...state, tickets: ticketsTerm}
        case actions.SET_CLOTHES: 
            let ticketsClothes = [...state.tickets]
            ticketsClothes[action.index].clothes = action.payload
            return {...state, tickets: ticketsClothes}
        case actions.ADD_TICKET: 
            return {...state, tickets: [...state.tickets, action.payload]}
        case actions.SET_PAGE: 
            return {...state, currentPage: action.payload}
        case actions.SET_HEADER_INFO:
            return {...state, picture: action.payload.picture,
            name: action.payload.name, dateStart: action.payload.dateStart,
            city: action.payload.city, state: action.payload.state}
        case actions.HANDLE_CHANGE:
            return {...state, [action.payload.name] : action.payload.value}
    }
}  