import React, { useState, useEffect, useCallback, useContext } from "react"
import { toast } from "react-toastify";
import Api from "../../api/Api"
import config from "../../config.js"
import {HomeProvider} from "./HomeContext"
import useSports from "../../hooks/useSports";
import { MobileView } from "react-device-detect";
import BuscaMobile from "./LayoutBusca/BuscaMobile";
export default () => {

    const [filterName, setFilterName] = useState("")
    const [filterCity, setFilterCity] = useState("")
    const [filterSport, setFilterSport] = useState("")
    const [filter, setFilter] = useState({})
    const [filtering, setFiltering] = useState(false)
    const [loadingTable, setLoadingTable] = useState(true)
    const [totalCount, setTotalCount] = useState({
        eventos_semana: 0,
        eventos_destaque: 0,
        torneios_destaque: 0,
    })
    const [limit,] = useState(9)
    const [pagination, setPagination] = useState(1)
    const [offset, setOffset] = useState(0)
    const listSports = useSports()

    const isFilted = (() => !(!filter.nome && !filter.cidade  && !filter.esporte_id && !filter.typeFilter ));

    const [eventos, setEventos] = useState([])


    const onClickSearch = () => {
        console.log(filterSport)
        setFilter({
            typeFilter: "geral",
            nome: filterName,
            cidade: filterCity,
            esporte: filterSport,
            esporte_id: filterSport,
            situacao: true,
        })
    }

    useEffect(() => {
        findEventos()
    },[filter, offset])

    const findEventos = async () => {
        try {
            setLoadingTable(true)
            const result = await Api().get("/eventos/top", {
                params:  {...filter, publicado: true, offset, limit}
            })
            const resultData = result.data.eventos ? result.data.eventos : []
            let internalData = [];
            resultData.map((value) => {
                const row = {
                    id: value.id,
                    name: value.nome,
                    photo: value.imagem ? config.storage_link+`/evento/${value.id}/${value.imagem}` : "",
                    dateStart: value.data_inicio,
                    street: value.logradouro,
                    city: value.cidade,
                    state: value.uf,
                    neighborhood: value.bairro,
                    number: value.numero
                }

                internalData.push(row)
                
            })
            setEventos(internalData)
            setLoadingTable(false)
        }catch(e){
            const response = e.response ? e.response : {};
            const data = response.data ? response.data : {};
            const msg = data.msg ? data.msg[0] : "Ocorreu um erro ao buscar os eventos";
            toast.error(msg)
        }
    }

    return <HomeProvider value={{
        filter,
        filterName,
        filterCity,
        filterSport,
        setFilterName,
        setFilterCity,
        setFilterSport,
        setFilter,
        onClickSearch,
        loadingTable,
        eventos,
        setEventos,
        filtering,
        setFiltering
    }}>
        <MobileView>
            <BuscaMobile/>
        </MobileView>
    </HomeProvider>
}

const styles = {
    containerEvents: {
        minHeight: "35vh"
    },
    input: {
        color: "rgba(255, 255, 255, 0.6)"
    },
    main: {
        minHeight: "100%",
        backgroundColor: "#F2F2F2"
    },
    rowTitle: {
        marginTop: 20
    },
    titleGroup: {
        color: "#606060",
        fontSize: 24
    },
    box: {
        borderRadius: 20,
        height: 275,
        paddingTop: 10,
        marginTop: 10
    },
    card_panel: {
        padding: 0,
        height: 275,
        borderRadius: 10,
        display: 'block',
        overflow: "hidden"
    },
    moreItems: {
        float: "right",
        marginTop: -25,
        marginRight: 15,
        fontSize: 12,
        fontFamily: "Roboto"
    },
    container: {
        paddingTop: 30,
        paddingBottom: 10
    },
    divFilter: {
        backgroundColor: "#6B69CF !important",
    },
    imgDestaque: {
        width: "100%",
        height: 300,
        objectFit: "cover",
        objectPosition: "50% 50%",
        borderRadius: 10
    },
    btnBuscar: {
        marginTop: 30,
        backgroundColor: "#5452C9",
        borderRadius: 5
    },
    loadingCss: {
        width: "100%",
        textAlign: "center",
        //marginLeft: "50%",
        marginTop: 100,
    },
    photoEvent: {
        maxWidth: "100%",
        height: 100,
     
        position: "relative",
    },
    photoEventCard: {
        width: "100%",
        height: 125,
        objectFit: "cover",
        display: "block",
        //visibility: 'hidden',
       
    },
    itens: {
        marginTop: 5
    },
    item: {
        height: 275,
        paddingTop: 10,
        marginTop: 10
    },
    infoEvento: {
        fontSize: 16,
        marginLeft: 5,
        marginTop: 10,
        color: "#6A6A6A",
        display: "flex"
    },
    divInfoEvento: {
        whiteSpace: "normal"
    },
    spanNome: {
        fontSize: 18,
        fontWeight: 500
    },
    spanData: {
        fontWeight: 300,
        fontSize: 16,
    },
    spanEnd:{
        fontWeight: 300,
        fontSize: 16,
    },
    spanDia: {
        fontSize: 30,
        fontWeight: 500,
        display: "inline-block"
    },
    spanMes:{
        fontSize: 30,
        color: "rgb(4, 122, 178)"
    },
    labelStyle: {
        color: "rgba(255, 255, 255, 0.6)",
    }
}