import React, { useState, useEffect, useContext, useReducer, useRef } from "react"
import {Card, Row, Col } from "react-materialize"
import {Button, Input, Select, ImageUploader, RichTextEditor} from "../../components/Form"
import {
    useHistory 
  } from "react-router-dom";

import { toast } from 'react-toastify';
import {FormTorneioProvider, reducer, initialState, actions} from "./FormTorneioContext"
import Api from "../../api/Api"
import config from "../../config.js"
import {Cookies, useCookies} from "react-cookie"
import LoadingOverlayContext from "../../components/LoadingOverlayContext/LoadingOverlayContext"
import TabMenuCard from "../../components/TabMenuCard";
import {DadosTorneio, AdicionarCategoria, RegraPontuacao} from "./CadastroTorneioSteps";
import { css } from "emotion";
import useSports from "../../hooks/useSports";
import MainContext, { actionsMain } from "../MainContext";
import useIdOrganizer from "../../hooks/useIdOrganizer";

export default (props) => {

    const pages = ["Dados do Torneio", "Adicionar Categoria", "Regra Pontuação"]
    const loadingOverlay = useContext(LoadingOverlayContext)
    const contextMain = useContext(MainContext)
    const [state, dispatch] = useReducer(reducer, initialState)
    const user_id = useIdOrganizer();

    const ref_form = useRef(null)
    const sportList = useSports()
    const history = useHistory()

    useEffect(() => {
        dispatch({type: actions.SPORT_LIST_UPDATE, payload: sportList})
    },[sportList])

    useEffect(() => {
        (async function getTorn(){
            try {
                let id = props.match.params.id
                if(id){
                    dispatch({type: actions.LOADING_PUB, payload: true})
                    loadingOverlay.activeLoading(true,"Buscando conteúdo")
    
                    const result = await Api().get(`torneios/getinfo/${id}`)
                    if(result.data.info){
                        const data = result.data.info;
                        const categorias = (data.categorias) ? data.categorias : []
                        
                        let categoriesInternal = {}
                        categorias.map((cat) => {
                            categoriesInternal[cat.id] = cat;
                            categoriesInternal[cat.id].pictureUrl = [config.storage_link+`/categoria/${cat.imagem}`]
                            categoriesInternal[cat.id].esporte = cat.esporte
                        })
    
                        const payload = {
                            idTorneio: data.id,
                            name: data.nome,
                            privacy: data.privacidade,
                            edition: data.edicao,
                            policy: data.politica ? data.politica : "",
                            pictureUrl: [config.storage_link+`/torneio/${data.id}/${data.imagem}`],
                            categories: categoriesInternal
                        }
                        const regras_pontuacao = data.regras_pontuacaTorneio?.map((item) => {
                            return {...item, esporte: item.esporte_id, regras: item.regra ? JSON.parse(item.regra) : []}
                        })
                        console.log(regras_pontuacao)
                        dispatch({type: actions.SET_SCORING_RULES, payload: regras_pontuacao})
                        dispatch({type: actions.START_INFO_TOURNAMENT, payload})
                        dispatch({type: actions.LOADING_PUB, payload: false})
                    }
    
                    loadingOverlay.activeLoading(false)
    
                }
            } catch(e) {
                loadingOverlay.activeLoading(false)
    
                console.log(e)
            }
        })()
    },[])

     

    const bestCopyEver = (src) => {
        return JSON.parse( JSON.stringify( src ) )
    }

    const onSubmit = async (e) => {
        e.preventDefault()

        let somethingWithoutRule = false
        let regras = state.scoringRules;
     
        regras.map((item) => {
            if(!item.regras){
                somethingWithoutRule = true
                return
            } else if(!item.regras[0].valor) {
                somethingWithoutRule = true
                return
            }
        })

        if(state.preventSubmit){return}

        if(!state.pictureUrl[0]){
            toast.error('É necessario inserir uma foto para o torneio.')
            return;
        } else if (Object.keys(state.categories).length == 0){
            toast.error("É necessario inserir pelo menos uma categoría")
            return;
        } else if(somethingWithoutRule){
            toast.error("É necessario definir as regras para salvar o torneio")
            return;
        }
        dispatch({type: actions.LOADING_PUB, payload: true})

        var formData = new FormData();
        formData.append("ativo",true)
        formData.append("nome",state.name)
        formData.append("edicao",state.edition)
        formData.append("privacidade",state.privacy)
        formData.append("politica",state.policy)
        formData.append("user_id",user_id)

        let categoriesCopy = bestCopyEver(state.categories)
        let categoriesValues = Object.values(categoriesCopy);
        let categoriesInternal = []
        const onlyNewEvent = !state.idTorneio

        if(state.hasRule === "1" && regras.length > 0){
            regras.map((regra,i) => {
                formData.append(`regras[${i}]`,JSON.stringify(regra))
            })
        }

        if(onlyNewEvent){
            categoriesValues.map((value,i) => {
                categoriesInternal[i] = value;
                categoriesInternal[i].fk_categoria_id = value.id
                categoriesInternal[i].esporte = value.esporte
                categoriesInternal[i].faixa_idade = value.faixa_idade
                categoriesInternal[i].ativo = true
                categoriesInternal[i].lote = []
                delete categoriesInternal[i].picture
                delete categoriesInternal[i].pictureUrl
                formData.append(`categorias[${i}]`,  JSON.stringify(categoriesInternal[i]))
            })    
        }else {
            categoriesValues.map((value,i) => {
                formData.append(`categorias[${i}]`,value.id)
            })  
        }
       
        if(onlyNewEvent){
            salvarApi(formData)
        }else{
            alterarApi(formData)
        }
    }

    const salvarApi = async (formData) => {
        try {
            const result = await Api().post("torneios/salvar",formData)
           console.log(result)
            if(!result.data.error){
                toast.success("Torneio criado com sucesso!")
                toast.success("A imagem do torneio será atualizada posteriormente")
                history.replace("/torneios")
               
                uploadImageTorneio(result.data.msg.torneio_id)
                
                uploadImageCategoryEvent(state.categories,result.data.msg.categorias)
            }else {
                toast.error("Ocorreu um problema ao tentar salvar")
            }
            dispatch({type: actions.LOADING_PUB, payload: false})

        }catch(e){
            console.log(e)

            const response = e.response ? e.response : {};
            const data = response.data ? response.data : {};
            let msg = "";
            if(data.msg?.categorias){
                msg = data.msg.categorias[0]
            } else {
                msg = data.msg ? data.msg[0] : "Ocorreu um problema ao tentar salvar";
            }

            dispatch({type: actions.LOADING_PUB, payload: false})

            toast.error(msg)
        }
       
    }

    const alterarApi = async (formData) => {
        try {
            const result = await Api().post("torneios/editar/"+state.idTorneio,formData)


            if(!result.error){
                toast.success("Torneio alterado com sucesso!")
                history.replace("/torneios")
            
                uploadImageTorneio(state.idTorneio)
            }else {
                toast.error("Ocorreu um problema ao tentar salvar")
            }
            dispatch({type: actions.LOADING_PUB, payload: false})
        }
        catch(e){
            const response = e.response ? e.response : {};
            const data = response.data ? response.data : {};
            const msg = data.msg ? data.msg[0] : "Ocorreu um problema ao tentar atualizar";

            dispatch({type: actions.LOADING_PUB, payload: false})
            toast.error(msg)
        }
    }

    const uploadImageTorneio = async (id) => {
        
        let formData = new FormData();
        if(state.picture){
            formData.append("file",state.picture[0])
        }
      
        formData.append("torneio_id",id)
        if((state.picture && state.picture.length > 0)){
            contextMain.dispatch({type: actionsMain.ADD_UPLOAD_IMAGES,payload: {
                formData, id,
                url: "torneios/uploadimagens",
                type_key: "torneio_id"
            }})
        }
        return await Api().post("torneios/uploadimagens",formData)
    }

    const uploadImageCategoryEvent = async (categoriesFromCadState,categoriesFromResult) => {
        return Object.keys(categoriesFromResult).map(async (key) => {
            let formData = new FormData();
            formData.append("categoria_id",Object.keys(categoriesFromResult[key])[0])
            formData.append("file",categoriesFromCadState[key].picture)
            return await Api().post("categorias/uploadimagens",formData)
        })

        
    }
    
    return <FormTorneioProvider 
    value={{state, dispatch, ref_form}}
    >
    <form  id="torneio" type="POST" ref={ref_form}
    
    onSubmit={onSubmit}  
    role="form">
        <div className={css(styles.main)}>
            <Row>
                <label className={css(styles.title)}>Cadastro novo torneio</label>
            </Row>
            <TabMenuCard 
                pages={pages}
                autoOnLoadPage={true}
                currentPage={state.currentPage}
                onChange={(page) => dispatch({type: actions.CHANGE_PAGE, payload: page})}
            >
                <DadosTorneio/>
                <AdicionarCategoria/>
                <RegraPontuacao />
            </TabMenuCard>
            </div>
        </form>
    </FormTorneioProvider>
}

const styles={
    title: {
        fontFamily: "Roboto",
        fontSize: 16,
        color: "#000",
        fontWeight: "normal"
    },
    newEvento : {
        float:"left"
    },
    main: {
        paddingBottom: 20
    },
    labelImg:{
        color: "#000",
    },
    messageInfo: {
        color: "#000",
        fontWeight: 700,
        fontSize: 16
    },
    photoEvent: {
        width: "100%",
        paddingTop: 20,
        position: "relative"
    }
}