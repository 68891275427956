import { css } from "emotion"
import React, { useContext } from "react"
import { Card, Col, Row } from "react-materialize"
import { ButtonClean, Input, Select } from "../../../../components/Form"
import FormEventoContext, { actions } from "../../FormEventoContext"

const Component = ({index}) => {

    const {state, dispatch} = useContext(FormEventoContext)

    const optionsConditions = [
        {
            value: "=",
            label: "Igual"
        },
        {
            value: ">=",
            label: "Acima"
        }
    ]

    const addRule = () => {
        const internalValue = {...state.scoringRules[index]}

        if(!internalValue.regras) {
            internalValue.regras = []
        }

        internalValue.regras.push({
            posicao: internalValue.regras.length +1,
            condicao: "="
        })

        dispatch({
            type: actions.HANDLE_SCORING_RULES, 
            index,
            payload: internalValue
        })
    }

    const onDelete = () => {
        dispatch({
            type: actions.REMOVE_SCORING_RULES, 
            index,
        })
    }

    return  <Card>
    <Row>
        <Col>
            <label className={css(styles.text)}>A regra avançada você pode adicionar um valor para cada posição desejada.</label>
        </Col>
    </Row>
    <Row>
        <Col>
            <label className={css(styles.text)}>É necessario incluir condições para as pontuações</label>
        </Col>
    </Row>
    <Row>
        <Col>
            <label className={css(styles.text)}>Igual: Pontuação referente a posição exata informada.<br/>
            Acima: Pontuação referente a todas as posições maiores ou iguais a informada.</label>
        </Col>
    </Row>

    {state.scoringRules[index].regras && state.scoringRules[index].regras.map((item,i) => <Row>
        <Col m={2} s={6} >
            <div className={css(styles.divTextPosition)}>
                <label className={css(styles.textPosition)}> {item.posicao}º posição </label>
            </div>
        </Col>
        <Col m={4} s={6}>
            <Select 
                options={optionsConditions.filter(
                    (item) =>  {
                        const index2 = state.scoringRules[index].regras.length-1
                        const ultimaRegra = state.scoringRules[index].regras[index2]
                        if(index2 != i && item.value == ">=" && ultimaRegra.condicao == ">="){
                            return false;
                        }
                        return true
                })}
                isDisabled={state.scoringRules[index].torneio_id}
                value={optionsConditions.filter(
                    (itemOpt) => itemOpt.value == item.condicao)
                }
                onChange={(internal) => {
                    const internalValue = {...state.scoringRules[index]}
                    internalValue.regras[i].condicao = internal.value
                    dispatch({
                        type: actions.HANDLE_SCORING_RULES, 
                        index,
                        payload: internalValue
                    })
                }}
            />
        </Col>
        <Col m={4} s={8} className={css(styles.displayPoints)}>
           
            <Input 
                type={"number"}
                value={item.valor}
                disabled={state.scoringRules[index].torneio_id}
                onChange={(e) => {
                    const internalValue = {...state.scoringRules[index]}
                    internalValue.regras[i].valor =  e.target.value
                    dispatch({
                        type: actions.HANDLE_SCORING_RULES, 
                        index,
                        payload: internalValue
                    })
                }}
            />
            <div className={css(styles.divTextPosition)}>
                <label className={css(styles.textPosition)}> Pts </label>
            </div>
        </Col>
        <Col m={2}>
            {!state.scoringRules[index].torneio_id &&
            <div className={css(styles.buttons)}>
                {item.condicao != ">=" && i == state.scoringRules[index].regras.length-1 && <span className={css(styles.span_add)+ " material-icons modal-trigger"} href="#modal1" id={"ar"+index} onClick={ () => addRule() }>control_point</span>}
                {i > 0 && i == state.scoringRules[index].regras.length-1 && <span className={css(styles.span_remove)+ " material-icons modal-trigger"} href="#modal1" id={"ar"+index} onClick={ () => onDelete() }>delete</span>}
            </div>}
        </Col>
    </Row>)}
</Card>
}

const styles = {
    text: {
        color: "#C4C4C4",
        fontSize: 16
    },
    displayPoints: {
        display: "flex"
    },
    divTextPosition: {
        marginTop: 15
    },
    textPosition: {
        color: "#C4C4C4",
        fontSize: 16
    },
    buttons: { marginTop: 15},
    span_add:{ cursor:'pointer',color:'green'},
    span_remove: { cursor:'pointer',color:'red'}
}

export default Component;