import React from 'react'
import { css } from 'emotion';
import Modal from 'react-responsive-modal';
import { Col, Row } from 'react-materialize';
import { Button, Input, TimePicker } from '../../../components/Form';
import moment from "moment"
import { useLocation } from 'react-router-dom';
const Component = ({
    loadingPub,
    name,
    sport,
    category,
    subcategory,
    subcription,
    position,
    points,
    image,
    time,
    setTime,
    open,
    onClose,
    handleSave
}) => {
    const {search} = useLocation()

    const query = new URLSearchParams(search)

    return <Modal
        open={open}
        center
        styles={styles.modal}
        type={"button"}
        onClose={onClose}
    >
        <Row>
            <div style={{
                "maxWidth": 0,
                "maxHeight": 0,
                "overflow": "hidden"
                }}>
                <input autofocus="true" />
            </div>
            <Col s={4} m={2}>
                <img 
                    src={image}
                    width={75}
                    height={75}
                />
            </Col>
            <Col s={8} m={10}>
                <Input 
                    disabled={true}
                    value={name}
                    label={"Nome do Atleta:"}
                />
            </Col>
        </Row>
        <Row>
            <Col s={12} m={4}>
                <Input 
                    disabled={true}
                    value={sport}
                    label={"Esporte:"}
                />
            </Col>
            <Col s={12} m={4}>
                <Input 
                    disabled={true}
                    value={category}
                    label={"Categoria:"}
                />
            </Col>
            <Col s={12} m={4}>
                <Input 
                    disabled={true}
                    value={subcategory}
                    label={"Sub-Categoria:"}
                />
            </Col>
        </Row>
        <Row>
            <Col s={12} m={3}>
                <Input 
                    disabled={true}
                    value={subcription}
                    label={"Nº Inscrição:"}
                />
            </Col>
            <Col s={12} m={3}>
                <Input 
                    disabled={true}
                    value={position}
                    label={"Posição:"}
                />
            </Col>
            <Col s={12} m={3}>
                <Input 
                    disabled={true}
                    value={points}
                    label={"Pontos:"}
                />
            </Col>
            <Col s={12} m={3}>
                {query.get('pub') === "0" ? <TimePicker 
                    label={"Tempo:"}
                    value={time}
                    showTimeSelect={false}
                    onChange={setTime}
                   // autoFocus={false}
                /> :
                <Input 
                        label={"Tempo:"}
                        disabled={true}
                        value={moment(time).format("HH:mm:ss")}
                    />
                }
            </Col>
        </Row>
        <Row>
           {query.get('pub') === "0" && <Col m={12} s={12} l={6} className={css(styles.btnDivSalvar) + " offset-l3"}>
                <Button type={"button"} loading={loadingPub} onClick={handleSave}>Salvar</Button>
            </Col>}
        </Row>
    </Modal>

}

const styles = {
    modal: {
        modal : {
            width: "100%"
        }
    },
    btnDivSalvar: {
        marginTop: 55
    }
}

export default Component;