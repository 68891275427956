import React, { useState } from "react"
import { Link, useHistory } from "react-router-dom";
import ModalPolitica from "../Politica/ModalPolitica";
import {Cookies, useCookies} from "react-cookie"
export default () => {
    const [openPolicy, setOpenPolicy] = useState(false)
    const cookiesObj = new Cookies()
    const history = useHistory()
    return <div>
        <ul style={styles.subMenu} class="card">
                <li style={styles.li}> 
                    <Link to={"/organizador/editar"} style={styles.linkMenu}> 
                        <span className="material-icons" style={styles.icon}>person</span> 
                        <span>Editar Perfil</span>
                    </Link>
                </li>
                <li style={styles.li} onClick={() => setOpenPolicy(true)}> 
                    <Link  style={styles.linkMenu}> 
                        <span className="material-icons" style={styles.icon}>event_note</span> 
                        <span>Políticas</span> 
                    </Link>  
                </li>
                <li style={styles.li}> 
                    <Link to={"/organizador/trocarsenha"}  style={styles.linkMenu}> 
                        <span className="material-icons" style={styles.icon}>lock</span> 
                        <span>Trocar Senha</span> 
                    </Link>  
                </li>
                <li style={styles.li}> 
                    <div onClick={() => {cookiesObj.remove("access_token"); history.replace("/"); } } style={styles.linkMenu}> 
                        <span className="material-icons" style={styles.icon}>logout</span> 
                        <span>Sair</span> 
                    </div>  
                </li>
            </ul>
            <ModalPolitica open={openPolicy} setOpen={setOpenPolicy}/>
    </div>
}

const styles = {
    subMenu: {
        color: "rgb(4, 122, 200)",
        top: 58,
        zindex: 100,
        position: "absolute",
        width: "90%",
        borderRadius: 10,
        paddingTop: 10,
        paddingBottom: 20,
        paddingLeft: 30,
        borderColor: "rgb(4, 122, 178)",
        backgroundColor: "rgb(255, 255, 255)",
        border: "0.75px solid"
    },
    sideMenu: {
        width: 180,
      
    },
    itemMenu: {
        marginTop: 3,
        paddingLeft: 5
    },
    itemMenuText: {
        fontSize: 20,
        fontWeight: 500
    },
    li: {
        color: "#000",
        width:"100%",
        height: 30,
        marginTop: 10,
        lineHeight: "30px"
    },
    icon: {
        verticalAlign: "bottom"
    },
    linkMenu: {
        color: "#000"
    }
}