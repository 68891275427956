import React from "react"
import { Row, Col, Card} from "react-materialize"
import Api from "../../api/Api"
import { Button } from "../../components/Form"
import moment from "moment"
import { useHistory, useParams } from "react-router-dom"
import { useContext } from "react"
import { css } from "emotion"
import { toast } from "react-toastify"
import { useCookies } from "react-cookie"
import HeaderViewEvento from "../../layouts/HeaderViewEvento"
import InscricaoContext, { actions } from "./InscricaoContext"
import InscricaoIngresso from "./InscricaoIngresso"
import { BrowserView, MobileView } from "react-device-detect"
import InscricaoBrowser from "./InscricaoLayout/InscricaoBrowser"
import InscricaoMobile from "./InscricaoLayout/InscricaoMobile"
const Evento = () => {

    const {state, dispatch} = useContext(InscricaoContext)
    const {id} = useParams(); 

    const [cookies] = useCookies(['id'])
    const history = useHistory()


    const onContinue = async () => {
        let ticketsForApi = []
        let error = false;
        console.log(state.tickets)
        state.tickets.map((item) => {

            if(error){
                return;
            }else if(!item.clothes){
                error = true
                toast.warn("O tamanho da roupa é obrigatorio")
                return
            }
            else if(!item.athlete){
                error = true
                toast.warn("Atleta é obrigatorio")
                return
            } else if (item.athlete.id == cookies.id && !item.term){
                error = true
                toast.warn("O aceite do termo é obrigatorio")
                return;
            } 
            if(item.athlete.id){
                ticketsForApi.push({
                    "atleta_id": item.athlete.id,
                    "lote_id": item.id,
                    "categoria_id": item.categoria_id,
                    "evento_id": id,
                    "tamanho_roupa": item.clothes,
                    "equipe_id": item.team ? item.team.id : "",
                    "termo": (item.term == undefined || item.term == null) ? false : item.term,
                })
            } else {
                ticketsForApi.push({
                    "name": item.athlete.name,
                    "email": item.athlete.email,
                    "CPF": item.athlete.cpf ,
                    "data_nascimento": moment(item.athlete.birthdate).format("YYYY-MM-DD"),
                    "lote_id": item.id,
                    "categoria_id": item.categoria_id,
                    "evento_id": id,
                    "tamanho_roupa": item.clothes,
                    "equipe_id": item.team ? item.team.id : "",
                    "termo": (item.term == undefined || item.term == null) ? false : item.term,
                })
            }
           
        })
       

        if(!error){

            if(state.totalPrice == 0){
                try {
                    const result = await Api().post("instricao/inscrever",
                    {
                        atleta_id_prin: cookies.id,
                        ingressos: ticketsForApi
                    })
                    if(!result.data.error){
                        toast.success("Inscrição realizada com sucesso")
                        history.replace(`/atleta/carteira`)
                    }
                } catch(e){
                    console.log(e)
                    const response = e.response ? e.response : {};
                    const data = response.data ? response.data : {};
                    const msg = data.msg ? data.msg[0] : "Ocorreu um problema";
                    toast.error(msg)
                }
            } else {
                dispatch({type:actions.SET_PAGE, payload: "pagamento"})
            }
        }
    }


    return <div>
        <BrowserView>
           <InscricaoBrowser onContinue={onContinue}/>
        </BrowserView>
        <MobileView>
            <InscricaoMobile onContinue={onContinue} />
        </MobileView>
    </div>

}

const styles = {
    btnSalvar: {
        marginTop: 55
    },
    divCards: {
        display: "flex",
        flexWrap: "wrap"
    },
    lineItem: {
        display: "flex",
        justifyContent: "space-between"
    },
    lineImgDesc: {
        display: "flex",
    },
    labelCard: {
        fontFamily: "Roboto",
        fontSize: 16,
        color: "#606060",
        alignSelf:"center",
        marginLeft: 10
    },
    lineDel: {

    },
    main:{
        position: "relative",
        backgroundColor: "#F2F2F2",
        minHeight: "100vh"
    },
    card: {
        flex: 1,
        "@media(max-width: 700px)": {
            flex: "100%"
        }
    },
    cardRight: {
        "@media(min-width: 700px)": {
            marginLeft: 30
        }
    },
    cardLeft: {
        "@media(min-width: 700px)": {
            marginRight: 30
        }
    },
    titleSection: {
        color: "#606060",
        fontFamily: "Roboto",
        fontSize: 20
    },
    section: {
        marginLeft: '4%',
        marginRight: "4%",
        "@media(max-width: 992px)": {
            marginLeft: "7%",
            marginRight: "7%",
        },
        "@media(max-width: 700px)": {
            marginLeft: 15,
            marginRight: 15,
        },
    },
    
    
    labelDescricao: {
        color: "#606060",
        fontFamily: "Roboto",
        fontSize: 20
    },
    meusEventosLabel: {
        color: "#606060",
        fontFamily: "Roboto",
        fontSize: 20
    },
    organizadorLabel: {
        color: "#606060",
        fontFamily: "Roboto",
        fontSize: 20
    },
    conteudoEvento: {
        marginLeft: 10,
        zIndex: 99,
        position: "relative"
    },
    conteudoEventoTitle: {
        marginLeft: 10,
        position: "absolute",
        left: "40%",
        "@media(max-width: 992px)": {
            position: "relative",
            top: 0,
            left: 0,
        },
        
    },
    imgEventoDiv: {
        height: "25vh"
    },
    imgEvento: {
        objectFit: 'cover',
        objectPosition: '0%',
        "@media(max-width: 992px)": {
            objectFit: 'contain',
            objectPosition: 'center center'
        },
    },
    button: {
        backgroundColor: "transparent",
        border: "1px solid",
        padding: "5px 15px",
        cursor: "pointer",
        borderRadius: 25,
        color: "#fff",
    },
    circleImg: {
        borderRadius: "50%",
        flex: 1,
        width: 40,
        height: 40
    }
}

export default Evento;