import {Button, Input, Checkbox, Select, InputPassword, ImageUploader, DatePicker} from "../../../../components/Form"
import {
    Link, useHistory, useLocation
  } from "react-router-dom";
import { css } from "emotion";
import logo from "../../../../assets/img/logo.png"
import { Col, Row } from "react-materialize";
import Page1 from "./Page1";
import { useEffect, useRef, useState } from "react";
import Page2 from "./Page2";

export default ({
    email,setEmail,
    password,setPassword,
    confpassword,setConfpassword,
    name,setName,
    type,
    pictureUrl,
    cpf_passport, setCpfPassport,
    validCPFText,
    phone,setPhone,
    cellphone,setCellphone,
    zipCode,
    street,setStreet,
    number,setNumber,
    complement,setComplement,
    neighborhood,setNeighborhood,
    city,setCity,
    state,setState,
    term,setTerm,
    validEmail,
    messageErroCPFCNPJ,
    messageErrorEmail,
    loading,
    gender, setGender,
    birthdate, setBirthdate,
    regexPassword, messageErroPassword,
    options, optionsGender, onSubmit, changeType,
    onDrop, handleZipCode,
    handleCPFPassport, validExistsEmail
}) => {

    const [page, setPage] = useState(0)
    const history = useHistory();
    const {search} = useLocation()
    const ref_form = useRef()
    const query = new URLSearchParams(search)

    const theme =  (theme) => ({
        ...theme,
        borderRadius: 0,
        colors: {
            ...theme.colors,
            primary25: '#6B69CF',
            primary: '#6B69CF',
            neutral80: "#6B69CF",
            neutral20: "#6B69CF"
        },
    })

    const onClick = (e) => {
        if(page == 0){
            e.preventDefault()
            if(!ref_form.current.reportValidity()){
                return
            }
            history.push({"search": "?page=1"})
            setPage(1)
        }
    }

    useEffect(() => {
        const page = query.get("page")
        if(page){
            setPage(page)
        }else {
            setPage(0)
        }
    },[search])
    

    return <div className={css(styles.main)}>
        <div className={css(styles.header)}>
            <div className={css(styles.flex1)}>
                <img src={logo}  className={css(styles.logo)}/>
            </div>
            <div className={css(styles.flex1,{display: "flex", justifyContent: "center"})}>
                <label  className={css(styles.title)}>MEU CADASTRO</label>
            </div>
            <div className={css(styles.flex1)}>
            </div>
        </div>
        <div>
            <form onSubmit={onSubmit} ref={ref_form} autocomplete="off">
               {page == 0 && <Page1
                 email={email}
                 setEmail={setEmail}
                 password={password}
                 setPassword={setPassword}
                 confpassword={confpassword}
                 setConfpassword={setConfpassword}
                 name={name}
                 setName={setName}
                 type={type}
                 pictureUrl={pictureUrl}
                 cpf_passport={cpf_passport}
                 setCpfPassport={setCpfPassport}
                 validCPFText={validCPFText}
                 phone={phone}
                 setPhone={setPhone}
                 cellphone={cellphone}
                 setCellphone={setCellphone}
                 validEmail={validEmail}
                 messageErroCPFCNPJ={messageErroCPFCNPJ}
                 messageErrorEmail={messageErrorEmail}
                 gender={gender}
                 setGender={setGender}
                 birthdate={birthdate}
                 setBirthdate={setBirthdate}
                 regexPassword={regexPassword}
                 messageErroPassword={messageErroPassword}
                 options={options}
                 optionsGender={optionsGender}
                 changeType={changeType}
                 onDrop={onDrop}
                 theme={theme}
                 handleCPFPassport={handleCPFPassport}
                 validExistsEmail={validExistsEmail}
               />}
               {page > 0 && <Page2
                    zipCode={zipCode}
                    handleZipCode={handleZipCode}
                    street={street}
                    setStreet={setStreet}
                    number={number}
                    setNumber={setNumber}
                    complement={complement}
                    setComplement={setComplement}
                    neighborhood={neighborhood}
                    setNeighborhood={setNeighborhood}
                    city={city} 
                    setCity={setCity}
                    theme={theme}
                    state={state}
                    setState={setState}
                    term={term}
                    setTerm={setTerm}
               />}
                {page == 0 && 
                    <Button
                        backgroundColor={"#4A0997"}
                        onClick={onClick}
                        type={"button"}
                        className={css(styles.btnNext)}>
                            Avançar
                    </Button>
                }
                 {page > 0 && 
                    <Button
                        backgroundColor={"#4A0997"}
                        loading={loading}
                        loadingColor={"#fff"}
                        className={css(styles.btnNext)}>
                            Cadastrar
                    </Button>
                }
            </form>
        </div>
    </div>
}

const styles = {
    row: {
        paddingRight: 15,
        paddingLeft: 15,
        display: "flex",
    },
    main: {
        backgroundColor: "#6B69CF",
        height: "100%",
        minHeight: "100vh",
        width: "100%",
        zIndex: 0,
        position: "relative"

    },
    header: {
        width: "100%",  
        display: "flex"
    },
    logo: {
        width: 40
    },
    title: {
       fontFamily: "Roboto",
       fontSize: 14,
       color: "#000",
       fontWeight: "bold",
       paddingTop: 10
    },
    flex1: {
        flex: 1,
    },
    labelStyle: {
        color: "#fff",
        width: "100%"
    },
    btnNext: {
        marginTop: 10,
        borderBottomRightRadius: "0px !important",
        borderBottomLeftRadius: "0px !important",
        height: 50,
        position: "fixed",
        bottom: 0
    },
    lastComponent: {
        height: 70
    },
    dataPicker: {
        backgroundColor: "#fff !important",
        borderRadius: "10px !important",
        textIndent: 10
    }
    
}