export default {
    idTorneio: "",
    name: "",
    privacy: {"value" : 1, label: "Público"},
    edition: "",
    policy: "",
    picture: [],
    pictureUrl: [],
    categories: {},
    preventSubmit: false,
    loadingPub: false,
    listSports: [],
    currentPage: 0,
    scoringRules: [],
    hasRule: "1"
}
