import React, { useState, useEffect, useCallback, useContext } from "react"
import { toast } from "react-toastify";
import Api from "../../../api/Api"
import { Row, Col, Container } from "react-materialize"
import {Button, Input, Select} from "../../../components/Form"
import {
    Link 
  } from "react-router-dom";

import config from "../../../config.js"
import {BeatLoader} from "react-spinners";
import { css } from 'emotion'
import ItemEventoLista from "../ItemEventoLista"
import ItemTorneioLista from "../ItemTorneioLista"
import Carousel from "../../../components/Carousel";
import EventsSearched from "../EventsSearched";
import useSports from "../../../hooks/useSports";
import HomeContext from "../HomeContext"
export default () => {

    const contextHome = useContext(HomeContext)

    const listSports = useSports()

    const theme =  (theme) => ({
        ...theme,
        borderRadius: 0,
        colors: {
            ...theme.colors,
            primary25: 'rgba(255, 255, 255, 0.6)',
            neutral80: "rgba(255, 255, 255, 0.6)",
            neutral20: "rgba(255, 255, 255, 0.6)"
        },
    })

    return <div className={css(styles.main)}>
            <div className={css(styles.divFilter)}>
                <Container className={css(styles.container)}>
                    <Row>
                        <Col s={6} l={3}>
                            <Input
                                id="name"
                                label={"Nome do Evento:"}
                                classNameInput={css(styles.input)}
                                color="rgba(255, 255, 255, 0.6)"
                                value={contextHome.filterName}
                                onChange={(e) => contextHome.setFilterName(e.target.value)}
                            />
                        </Col>
                        <Col s={6} l={3}>
                            <Input
                                id="city"
                                label={"Cidade:"}
                                classNameInput={css(styles.input)}
                                color="rgba(255, 255, 255, 0.6)"
                                value={contextHome.filterCity}
                                onChange={(e) => contextHome.setFilterCity(e.target.value)}
                            />
                        </Col>
                        <Col s={6} l={3}>
                            <Select
                                id="esportes"
                                label={"Esportes:"}
                                isSearchable={false}
                                value={contextHome.filterSport}
                                labelStyle={styles.labelStyle}
                                options={[{value: "", label: "Todos os Esportes"},...listSports]}
                                theme={theme}
                                onChange={contextHome.setFilterSport}
                            />
                        </Col>
                        <Col s={6} l={3}>
                            <Button 
                                backgroundColor={"#5452C9"}
                                style={(styles.btnBuscar)}
                                onClick={contextHome.onClickSearch}>
                                Buscar
                            </Button>
                        </Col>
                    </Row>

                    {!contextHome.loadingTable && !contextHome.isFilted() && <Row>
                        {contextHome.eventosDestaque.length > 0 && 
                            <Link to={"evento/apresentacao/"+contextHome.eventosDestaque[0].id}  >
                                <img 
                                    src={contextHome.eventosDestaque[0].photo} 
                                    className={css(styles.imgDestaque)} 
                                    />
                            </Link>
                        }
                    </Row>}

                </Container>
            </div>
            <Container className={css(styles.containerEvents)}>
                {contextHome.loadingTable && <div>
                    <BeatLoader css={styles.loadingCss} color={"#110F62"} size={25}/>
                </div>}
                {!contextHome.loadingTable && !contextHome.isFilted() && <div>
                    
                        {contextHome.eventosSemana.length > 0 && <><Row className={css(styles.rowTitle)}>
                            <Col>
                                <label className={css(styles.titleGroup)}>Eventos dessa semana</label>
                            </Col>
                        </Row>
                        <Row style={styles.itens}>
                            <Carousel 
                                infinite={false}
                                items={contextHome.eventosSemana}
                                controls={false}
                                template={ItemEventoLista}
                            />
                        </Row>
                        <Row>
                            <Link to={"/"}>
                                <span className={css(styles.moreItems)} onClick={() => contextHome.setFilter({typeFilter: "eventos_semana"})}>Todos os eventos da semana</span>
                            </Link>
                        </Row></>}
                        {contextHome.eventosDestaque.length > 0 && <><Row className={css(styles.rowTitle)}>
                            <Col>
                                <label className={css(styles.titleGroup)}>Eventos em destaque</label>
                            </Col>
                        </Row>
                        <Row style={styles.itens}>
                            <Carousel 
                                infinite={false}
                                items={contextHome.eventosDestaque}
                                controls={false}
                                template={ItemEventoLista}
                            />
                        </Row>
                        <Row>
                            <Link to={"/"}>
                                <span className={css(styles.moreItems)}  onClick={() => contextHome.setFilter({typeFilter: "eventos_destaque"})}>Todos os eventos em destaque</span>
                            </Link>
                        </Row></>}
                        {contextHome.torneiosDestaque.length > 0 && <><Row className={css(styles.rowTitle)}>
                            <Col>
                                <label className={css(styles.titleGroup)}>Torneios em destaque</label>
                            </Col>
                        </Row>
                        <Row style={styles.itens}>
                            <Carousel 
                                infinite={false}
                                items={contextHome.torneiosDestaque}
                                controls={false}
                                template={ItemTorneioLista}
                            />
                        </Row>
                        <Row>
                            <Link to={"/"}>
                                <span className={css(styles.moreItems)} onClick={() => contextHome.setFilter({typeFilter: "torneios_destaque"})}>Todos os torneios em destaque</span>
                            </Link>
                        </Row></>}
                    </div>}
                    {!contextHome.loadingTable && contextHome.isFilted() && 
                        <EventsSearched />
                    } 
            </Container>
    </div>
}

const styles = {
    containerEvents: {
        minHeight: "35vh"
    },
    input: {
        color: "rgba(255, 255, 255, 0.6)"
    },
    main: {
        minHeight: "100%",
        backgroundColor: "#F2F2F2"
    },
    rowTitle: {
        marginTop: 20
    },
    titleGroup: {
        color: "#606060",
        fontSize: 24
    },
    box: {
        borderRadius: 20,
        height: 275,
        paddingTop: 10,
        marginTop: 10
    },
    card_panel: {
        padding: 0,
        height: 275,
        borderRadius: 10,
        display: 'block',
        overflow: "hidden"
    },
    moreItems: {
        float: "right",
        marginTop: -25,
        marginRight: 15,
        fontSize: 12,
        fontFamily: "Roboto"
    },
    container: {
        paddingTop: 30,
        paddingBottom: 10
    },
    divFilter: {
        backgroundColor: "#6B69CF !important",
    },
    imgDestaque: {
        width: "100%",
        height: 300,
        objectFit: "cover",
        objectPosition: "50% 50%",
        borderRadius: 10
    },
    btnBuscar: {
        marginTop: 30,
        backgroundColor: "#5452C9",
        borderRadius: 5
    },
    loadingCss: {
        width: "100%",
        textAlign: "center",
        //marginLeft: "50%",
        marginTop: 100,
    },
    photoEvent: {
        maxWidth: "100%",
        height: 100,
     
        position: "relative",
    },
    photoEventCard: {
        width: "100%",
        height: 125,
        objectFit: "cover",
        display: "block",
        //visibility: 'hidden',
       
    },
    itens: {
        marginTop: 5
    },
    item: {
        height: 275,
        paddingTop: 10,
        marginTop: 10
    },
    infoEvento: {
        fontSize: 16,
        marginLeft: 5,
        marginTop: 10,
        color: "#6A6A6A",
        display: "flex"
    },
    divInfoEvento: {
        whiteSpace: "normal"
    },
    spanNome: {
        fontSize: 18,
        fontWeight: 500
    },
    spanData: {
        fontWeight: 300,
        fontSize: 16,
    },
    spanEnd:{
        fontWeight: 300,
        fontSize: 16,
    },
    spanDia: {
        fontSize: 30,
        fontWeight: 500,
        display: "inline-block"
    },
    spanMes:{
        fontSize: 30,
        color: "rgb(4, 122, 178)"
    },
    labelStyle: {
        color: "rgba(255, 255, 255, 0.6)",
    }
}