import React, { useState, useEffect, useContext } from "react"
import { Modal } from 'react-responsive-modal';
import { Row, Col } from "react-materialize";
import { Button, Input, Checkbox, ButtonClean } from "../../components/Form";
import MinhasEquipesContext, { actions } from "./MinhasEquipesContext";

import DataTable from 'react-data-table-component';
import {BarLoader} from "react-spinners";
import { css } from "emotion";
import { toast } from "react-toastify";
import Api from "../../api/Api";
import config from "../../config.js"
import ModalAtletaBrowser from "./ModalAtletaLayout/ModalAtletaBrowser";
import { BrowserView, MobileView } from "react-device-detect";
import ModalAtletaMobile from "./ModalAtletaLayout/ModalAtletaMobile";
const Component = (props) => {

    const [search, setSearch] = useState("")
    const [data, setData] = useState([])
    const [athSelected, setAthSelected] = useState({})
    const [loadingTable, setLoadingTable] = useState(false);
    const [loadingBtn, setLoadingBtn] = useState(false);
    const {state, dispatch, openMembers} = useContext(MinhasEquipesContext)

    const onPressSearch = () => {
        getAthletes()
    }

    const getAthletes = async () => {
        if(search){
            try {
                setLoadingTable(true)
                const result = await Api().get("/users/listar/at", {params: {name: search, limit: 5 }})
                const data = result.data ? result.data : []
                const atletas = data.atletas ? data.atletas : {}
                let rowAtletas = atletas.rows ? atletas.rows : []
                rowAtletas = rowAtletas.map((item) => {
                    return {...item, imagem: item.imagem ? config.storage_link+"/users/"+item.imagem : ""} 
                })
                setData(rowAtletas)
                setLoadingTable(false)
            }catch(e){
                setLoadingTable(false)
                toast.error("Ocorreu um erro ao buscar os atletas")
            }
        } 
    }
            


    const onClose = () => {
        props.toggle(false)
    }

    const bestCopyEver = (src) => {
        return JSON.parse( JSON.stringify( src ) )
    }

    const addAthletes = async () => {
        try {
            setLoadingBtn(true)
            const users = Object.values(athSelected).map((row) => { return { user_id: row.id, tecnico: false}})
            const equipe_id = state.team_id
            const result = await Api().post("membros/salvar", {equipe_id,users})
            const data = result.data ? result.data : {}
            if(!data.error){
                toast.success("Convite enviado com sucesso")
                dispatch({type: actions.UPDATE_MEMBERS_FORCE})
                onClose()
               // setAthSelected({...athSelected,[params.id]: params})
            }else{
                toast.error("Ocorreu um erro ao tentar enviar o convite")
            }
            setLoadingBtn(false)
        }catch(e) {
            const response = e.response ? e.response : {};
            const data = response.data ? response.data : {};
            const msg = data.msg ? data.msg[0] : "Ocorreu um problema ao tentar salvar";
            toast.error(msg)
            setLoadingBtn(false)
        } 
    }

    const onChangeCheckbox = (values) => {
        const athletesSelectedCopy = bestCopyEver(athSelected);
        if(Object.keys(athSelected).includes(`${values.id}`)){
            delete athletesSelectedCopy[values.id]
        }else {
            athletesSelectedCopy[values.id] = values
        }
        
        setAthSelected(athletesSelectedCopy)
    }

    return <div>
        <BrowserView>
            <ModalAtletaBrowser
                search={search}
                setSearch={setSearch}
                data={data}
                athSelected={athSelected}
                loadingTable={loadingTable}
                loadingBtn={loadingBtn}
                onPressSearch={onPressSearch}
                open={props.open}
                toggle={props.toggle}
                addAthletes={addAthletes}
                onChangeCheckbox={onChangeCheckbox}
            />
        </BrowserView>
        <MobileView>
            <ModalAtletaMobile
                search={search}
                setSearch={setSearch}
                data={data}
                athSelected={athSelected}
                loadingTable={loadingTable}
                loadingBtn={loadingBtn}
                onPressSearch={onPressSearch}
                open={props.open}
                toggle={props.toggle}
                addAthletes={addAthletes}
                onChangeCheckbox={onChangeCheckbox}
            />
        </MobileView>
    </div>
}

const styles={
    btnSalvar: {
        marginTop: 55
    },
    newEvento : {
        float:"left"
    },
    main: {
        paddingBottom: 20
    },
    photoEvent: {
        width: "100%",
        paddingTop: 20,
        position: "relative"
    },
    modal: {
        modal : {
            width: "80%"
        }
    },
    loadingCss: {
        width: "100%"
    },
    photo: {
        width: 40,
        height: 40,
        borderRadius: 250,
        marginTop: 5,
        marginBottom: 5
    },
    itemTableSpan: {
        fontSize: 14
    },
    iconBtn: {
        cursor:'pointer',
        color:'red !important'
   }
}

export default React.memo(Component);