import { css } from "emotion"
import React, { useEffect, useState } from "react"
import { useCookies } from "react-cookie"
import DataTable from "react-data-table-component"
import { BrowserView, isMobile, MobileView } from "react-device-detect"
import { Container } from "react-materialize"
import { BarLoader } from "react-spinners"
import Api from "../../api/Api"
import config from "../../config.js"
const Component = (props) => {
    const [cookies] = useCookies(['id'])
    const [loadingTable, setLoadingTable] = useState(false)
    const [data, setData] = useState([])
    let columns = [];
    
    if(!isMobile){
        columns = [
            {
                selector: 'image',
                sortable: true,
                cell: row => <>{row.evento_imagem && <img src={config.storage_link+`/evento/${row.evento_id}/${row.evento_imagem}`} className={css(styles.image)}/>}</>
            },
            {
                selector: 'evento_nome',
                sortable: true,
            },
            {
                selector: 'esporte',
                sortable: true,
            },
            {
                selector: 'created_at',
                sortable: true,
            },
            {
                selector: 'lote',
                sortable: true,
                cell: row => <> <span> Lote {row.lote} </span> </>
            },
            {
                selector: 'valor_lote',
                sortable: true,
                cell: row => <> <span> R$ {parseFloat(row.valor_lote).toFixed(2).replace(".",",")} </span> </>
            },
            {
                selector: 'status',
                sortable: true,
            }
        ]
    } else {
        columns = [
            {
                selector: 'image',
                sortable: true,
                cell: row => <>{row.evento_imagem && <img src={config.storage_link+`/evento/${row.evento_id}/${row.evento_imagem}`} className={css(styles.image)}/>}</>
            },
            {
                selector: 'evento_nome',
                sortable: true,
            },
        ]
    }
   

    useEffect(() => {
        (async () => {
            setLoadingTable(true)
            const result = await Api().get("/carteira/"+cookies.id)
            setLoadingTable(false)
            const data = result?.data 
            const carteira = data?.carteira
            console.log(carteira)
            setData(carteira)
        })()

    },[])

    const customStyles = {
        rows: {
            style: {
                marginTop: 30,
                backgroundColor:"#fff",
                boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.15)",
                borderRadius: 9,
                height: 50
            }
        },
        table: {
            style: {
                backgroundColor:"transparent",
            }
        }
    };

    const ExpandedComponent  = ({data}) => {
        return <div>
            <ul>
                <li><b>Esporte:</b> {data.esporte}</li>
                <li><b>Data da Compra:</b> {data.created_at}</li>
                <li><b>Valor:</b> R$ {parseFloat(data.valor_lote).toFixed(2).replace(".",",")}</li>
                <li><b>Situação:</b>  {data.status}</li>
            </ul>
        </div>
    }
    return <div className={css(styles.main)}>
        <BrowserView>
            <span className={css(styles.titleSpan)}>Minha carteira</span>
            <DataTable
                columns={columns}
                data={data}
                progressComponent={<BarLoader css={styles.loadingCss} color={"#6B69CF"}/>}
                progressPending={loadingTable}
                customStyles={customStyles}
                noDataComponent={""}
                noHeader
                noTableHead
            />
        </BrowserView>
        <MobileView>
            <span className={css(styles.titleSpan2)}>Minha carteira</span>
            <DataTable
                columns={columns}
                data={data}
                progressComponent={<BarLoader css={styles.loadingCss} color={"#6B69CF"}/>}
                progressPending={loadingTable}
                noDataComponent={""}
                expandableRows
                noHeader
                noTableHead
                expandableRowsComponent={<ExpandedComponent/>}
            />
        </MobileView>
        
     
    </div>
}

const styles = {
    main: {
        marginTop: 10,
        marginLeft: 30,
        marginRight: 30
    },
    loadingCss: {
        width: "100%"
    },
    image: {
        width: 40,
        height: 40,
        borderRadius: "50%"
    },
    titleSpan: {
        fontFamily: "Roboto",
        fontSize: 26,
        flex: 1
    },
    titleSpan2: {
        fontFamily: "Roboto",
        fontSize: 22,
        flex: 1,
    }
}

export default Component;