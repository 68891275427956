import { css } from "emotion"
import React, { useContext } from "react"
import { Container } from "react-materialize"
import { Input, Select } from "../../../components/Form"
import ModalMobileFullScreen from "../../../components/ModalMobileFullScreen"

export default ({
    filtering,
    setFiltering,
    events,
    eventId,
    setEventId,
    categories,
    setCategory,
    category,
    setSubCategory,
    subCategories,
    subCategory
}) => {

    return <div>
        <ModalMobileFullScreen 
            open={filtering} 
            toggle={() => setFiltering(false)}
            onClose={() => setFiltering(false)}
            title={"Filtro"}
            btnFooterText={"Aplicar"}
            onClickBtnFooter={() => {
                setFiltering(false)
            }}
        >
            <Container>
                <div>
                    <span className={css(styles.text)}>Te ajudamos a descobrir para onde ir!</span>
                </div>
                <div className={css(styles.components)}>
                    <div className={css(styles.component)}>
                        <Select
                            label={"Eventos:"}
                            options={events}
                            onChange={(data) => setEventId(data.value)}
                            value={events.filter(option => option.value == eventId)}
                        />
                    </div>
                    <div className={css(styles.component)}>
                        <Select
                            label={"Categorias:"}
                            options={categories}
                            onChange={(data) => setCategory(data.value)}
                            value={categories.filter(option => option.value == category)}
                        />
                    </div>
                    <div className={css(styles.component)}>
                        <Select
                            label={"Sub-Categoria:"}
                            options={subCategories}
                            onChange={(data) => setSubCategory(data.value)}
                            value={subCategories.filter(option => option.value == subCategory)}
                        />
                    </div>
                </div>
            </Container>
        </ModalMobileFullScreen>
    </div>
}

const styles = {
    text: {
        fontWeight: "bold",
        fontSize: 16
    },
    components: {
        marginTop: 10
    },
    component: {
        paddingTop: 10
    },
    input: {
        border: "0.5px solid #9e9e9e !important",
        boxShadow: "none"
    }
}