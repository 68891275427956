import React, { useContext,useState } from "react"
import { Card, Row, Col } from "react-materialize"
import { css } from "emotion"
import { ImageUploader, Input, Select, Button, DatePicker } from "../../../components/Form"
import useSports from "../../../hooks/useSports"
import { toast } from "react-toastify"
import listUf from "../../../constants/listUf"
import { useCookies } from "react-cookie"
import moment from "moment"
import ModalMobileFullScreen from "../../../components/ModalMobileFullScreen"
import MinhasEquipesContext, { actions } from "../MinhasEquipesContext"

export default ({
    onSubmit, 
    onDrop, 
    handleZipCode,
    loading, 
    name,
    setName,
    foundation_data,
    setFoundationData,
    sport,
    setSport,
    zipCode,
    neighborhood,
    setNeightborhood,
    city,
    setCity,
    uf,
    setUf,
    number,
    setNumber,
    complement,
    setComplement,
    pictureUrl,
    street,
    setStreet}) => {
        const listSports = useSports(); 
        const { dispatch } = useContext(MinhasEquipesContext)

        return <ModalMobileFullScreen
            open={true} 
            //toggle={onClose}
            contentStyle={styles.contentModal}
            onClose={() =>   dispatch({type: actions.SET_CURRENT_ELEMENT, payload: ""})}
            title={"Equipe"}
            loading={loading}
            btnFooterText={"Salvar"}
            onClickBtnFooter={onSubmit}
        >  
            <div  className={css(styles.ImageUploader)}>
                <ImageUploader
                   
                   // aspect={16/9}
                   // crop
                    buttonText='Escolher Imagem'
                    backgroundColor="#6B69CF"
                    rounded
                    defaultImages={pictureUrl}
                    onDrop={onDrop}
                />
            </div>
            <div className={css(styles.row)}>
                    <Input 
                        label={"Nome da equipe*:"}
                        value={name}
                        classNameInput={css(styles.input)}
                        radius={true}
                        onChange={(e) => setName(e.target.value)}
                    />
            </div>
            <div className={css(styles.row)}>
                <Select
                    label={"Esporte*:"}
                    options={listSports}
                    value={listSports.filter(option => option.value == sport)}
                    required={true}
                    borderRadius={10}
                    onChange={(data) => setSport(data.value)}
                />
            </div>
            <div className={css(styles.row)}>
                <DatePicker 
                    label={"Data de fundação*:"}
                    type="date"
                    radius
                    className={css(styles.dataPicker)}
                    value={foundation_data}
                    onChange={(value) => setFoundationData(value)}
                />
            </div>
            <div className={css(styles.row)}>
                <Input
                    id="cep"
                    label={"Cep*:"}
                    value={zipCode}
                    mask={"99999-999"}
                    classNameInput={css(styles.input)}
                    radius={true}
                    required={true}
                    onChange={(e) => handleZipCode( e.target.value)}
                />
            </div>
            <div className={css(styles.row)}>
                <Input
                    id="street"
                    label={"Logradouro*:"}
                    value={street}
                    required={true}
                    classNameInput={css(styles.input)}
                    radius={true}
                    maxLength={100}
                    onChange={(e) => setStreet(e.target.value)}
                    />
            </div>
            <div className={css(styles.row)}>
                <Input
                    id="number"
                    label={"Número*:"}
                    value={number}
                    type={"number"}
                    classNameInput={css(styles.input)}
                    radius={true}
                    required={true}
                    onChange={(e) => setNumber(e.target.value)}
                />
            </div>
            <div className={css(styles.row)}>
                <Input
                    id="complement"
                    label={"Complemento:"}
                    maxLength={100}
                    value={complement} 
                    classNameInput={css(styles.input)}
                    radius={true}
                    onChange={(e) => setComplement(e.target.value)}
                />
            </div>
            <div className={css(styles.row)}>
                <Input
                    id="neighborhood"
                    label={"Bairro*:"}
                    value={neighborhood}
                    maxLength={100}
                    classNameInput={css(styles.input)}
                    radius={true}
                    required={true}
                    onChange={(e) => setNeightborhood(e.target.value)}
                />
            </div>
            <div className={css(styles.row)}>
                <Input
                    id="city"
                    label={"Cidade*:"}
                    value={city}
                    maxLength={100}
                    required={true}
                    classNameInput={css(styles.input)}
                    radius={true}
                    onChange={(e) => setCity(e.target.value)}
                />
            </div>
            <div className={css(styles.row)}>
                <Select 
                    label={"UF*:"}
                    menuPlacement={"top"}
                    classNameInput={css(styles.input)}
                    borderRadius={10}
                    value={listUf.filter(option => option.value == uf)}
                    onChange={(data) => setUf(data.value)}
                    required={true}
                    options={listUf}
                />
            </div>
    </ModalMobileFullScreen>

}

const styles = {
    ImageUploader: {
        display: "flex",
        marginTop: -60,
        paddingRight: 15,
        paddingLeft: 15,
    },
    row:{
        paddingRight: 15,
        paddingLeft: 15,
        paddingTop: 10
    },
    dataPicker: {
        backgroundColor: "#fff !important",
        borderRadius: "10px !important",
        textIndent: 10,
        backgroundColor: "#fff",
        border: "0.5px solid #9e9e9e !important",
        boxShadow: "none",
    },
    contentModal:{
        minHeight: "1200px !important",
        backgroundColor: "#fff",
        zIndex: 99999
    },
    input: {
        border: "0.5px solid #9e9e9e !important",
        boxShadow: "none",
       // textAlign: "center",
        //margin: "0 0 0 0 !important",
        //height: "2rem !important",
       // borderBottom: "0px !important",
        //lineHeight: 2,
        boxShadow: "none !important",
    }
}