import React from "react"
import { css } from "emotion"
import { Container, Row, Col } from "react-materialize"

export default () => {
    return <div className={css(styles.footer)}>
        <Container>
            <Row>
                <Col s={3}>
                    <div><span className={css(styles.title)}>Olimpoz</span></div>
                    <div className={css(styles.itemDiv)}><span className={css(styles.item)}>Termos e politicas</span></div>
                    <div className={css(styles.otherTitle)}><span className={css(styles.title)}>Siga-nos</span></div>
                </Col>
                <Col s={3}>
                    <div><span className={css(styles.title)}>Atletas</span></div>
                    <div className={css(styles.itemDiv)}><span className={css(styles.item)}>Torneios</span></div>
                    <div className={css(styles.itemDiv)}><span className={css(styles.item)}>Competições</span></div>
                    <div className={css(styles.itemDiv)}><span className={css(styles.item)}>Equipes</span></div>
                    <div className={css(styles.itemDiv)}><span className={css(styles.item)}>Resultados</span></div>
                </Col>
                <Col s={3}>
                    <div><span className={css(styles.title)}>Organizadores</span></div>
                    <div className={css(styles.itemDiv)}><span className={css(styles.item)}>Torneios</span></div>
                    <div className={css(styles.itemDiv)}><span className={css(styles.item)}>Eventos</span></div>
                    <div className={css(styles.itemDiv)}><span className={css(styles.item)}>Serviços</span></div>
                </Col>
                <Col s={3}>
                    <div><span className={css(styles.title)}>Ajuda</span></div>
                    <div className={css(styles.itemDiv)}><span className={css(styles.item)}>Central de atendimento</span></div>
                    <div className={css(styles.itemDiv)}><span className={css(styles.item)}>Para organizadores</span></div>

                </Col>
            </Row>
        </Container>
    </div>
}

const styles = {
    footer: {
        position: "relative",
        width: "100%",
        bottom: 0,
       
        backgroundColor: "#606060",
        fontFamily: "Roboto",
        height: 280
    },
    title: {
       
        color: "#fff",
        fontSize: 26,
        "@media(max-width: 992px)": {
            fontSize: 13.5,
        }
    },
    item: {
        color: "#fff",
        fontSize: 16,
        "@media(max-width: 992px)": {
            fontSize: 11,
        }
    },
    otherTitle: {
        marginTop: 60,
    },
    itemDiv: {
        marginTop: 15,
    },

}