import { css } from "emotion"
import { useState } from "react"
import { useEffect } from "react"
import { useContext } from "react"
import { Col, Row } from "react-materialize"
import { useHistory, useLocation, useParams } from "react-router-dom"
import { toast } from "react-toastify"
import Api from "../../../../api/Api"
import { Button, Input, RichTextEditor } from "../../../../components/Form"

export default () => {    
    const {id} = useParams(); 
    const history = useHistory()
    const [email,setEmail] = useState("")
    const [msg,setMsg] = useState("")
    const [loading,setLoading] = useState(false)


    const onPressSave = async (e) => {
        e.preventDefault()
        try {
            setLoading(true)
            const result = await Api().post("evento/email/faleconosco",{
                evento_id: id,
                email, 
                assunto: "Contato Evento", msg})
            const data = result.data ? result.data : {}
            setLoading(false)
            if(!data.error){
                toast.success("E-mail enviado com sucesso")
                setEmail("")
                setMsg("")
                history.goBack()
            }
        }catch(e){
            const response = e.response ? e.response : {};
            const data = response.data ? response.data : {};
            const msg = data.msg ? data.msg[0] : "Ocorreu um problema";
            toast.error(msg)
            setLoading(false)
        }
    }

    
    return  <div>
        <div className={css(styles.content)}>
            <span className={css(styles.title)}>Contato</span>
            <form id="formCategoria" type="POST" name="formCategoria" onSubmit={onPressSave}  role="form">
                <Row>
                    <Col s={12}>
                        <Input
                                label={"Seu Email*:"}
                                key="email"
                                type={"email"}
                                backgroundColor="#FFFCFC"
                                radius={true}
                                value={email}
                                required={true}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                    </Col>
                </Row>
                <Row>
                    <Col s={12} l={12}>
                        <RichTextEditor
                                label={"Mensagem:"}
                                removeAllButtons
                                value={msg}
                                required={true}
                                onChange={setMsg}
                            />
                    </Col>
                </Row>
                <Row>
                    <Button loading={loading} form="formCategoria">Enviar</Button>
                </Row>
            </form>
        </div>
    </div>
}

const styles = {
    title: {
        fontFamily: "Roboto",
        fontSize: 18,
        fontWeight: "bold",
    },
    content: {
        paddingLeft: 20,
        paddingRight: 20
    },
    
}