import React, { useContext, useState } from "react"
import { css } from "emotion";
import ModalAcesso from "./ModalAcessos"
import JuizCronometroContext, { actions } from "../../JuizCronometroContext";
import TableAcessos from "./TableAcessos";

const Component = () => {

    const {state, dispatch} = useContext(JuizCronometroContext)

    const [openModalAcessos, setOpenModalAcesso] = useState(false)

    const handleSetEventId = (id) => {
        dispatch({type:actions.SET_ID_EVENT_ACCESS, payload: id})
    }

    return <div className={css(styles.container)}>
        <div className={css(styles.menuEventos)}>
            <div className={css(styles.lineEvents,styles.lineSelected)}>
                <span>Eventos</span>
            </div>
            {state.events.map((item,i) => 
                <div 
                    key={i}
                    onClick={() => handleSetEventId(item.id)} 
                    className={css(styles.lineEvents, item.id == state.id_event_access ? styles.lineSelected: {} )}>
                    <span>{item.nome}</span>
                </div>
            )}
        </div>
        <div className={css(styles.mainAcc)}>
            {state.id_event_access !== 0 &&
            <>
                <ModalAcesso open={openModalAcessos} toggle={setOpenModalAcesso}/> 
                <TableAcessos/>
            </>}
        </div>
    </div>
}

const styles = {
    container: {
        display: "flex",
        marginBottom: 40
    },
    mainAcc: {
        marginLeft: 40,
        width: "100%"
    },
    menuEventos: {
        margin: -24,
        width: 140,
        border: "1px solid #C4C4C4",
        fontFamily: "Roboto"
    },
    lineSelected: {
        backgroundColor: "rgba(107, 105, 207, 0.8)",
        color: "#fff",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        paddingLeft: 0,
        textAlign: "center",
        fontSize: 16
    },
    lineEvents: {
        minHeight: 40,
        marginBottom: 10,
        display: "flex",
        paddingLeft: 10,
        alignItems: "center",
        cursor: "pointer",
        fontSize: 14
    }
}

export default Component;