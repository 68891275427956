import actions from "./actions";
import moment from "moment"

export default (state, action) => {
    switch(action.type){
        case actions.HANDLE_CHANGE:
            return {...state, [action.payload.name] : action.payload.value}
        case actions.CHANGE_PAGE:
            return {...state, currentPage : action.payload}
        case actions.ADD_CATEGORIES: {
            return {...state, categories: action.payload}
        }
        case actions.PREVENT_SUBMIT: {
            return {...state, preventSubmit: action.payload}
        }
        case actions.SPORT_LIST_UPDATE: {
            return {...state, listSports: action.payload}
        }
        case actions.START_INFO_TOURNAMENT: {
            return {...state, 
                idTorneio: action.payload.idTorneio,
                name: action.payload.name,
                privacy: action.payload.privacy,
                edition: action.payload.edition,
                policy: action.payload.policy,
                pictureUrl: action.payload.pictureUrl,
                categories: action.payload.categories,
            }
        }
        case actions.LOADING_PUB: {
            return {...state, 
                loadingPub: action.payload
            }
        }
        case actions.SET_SCORING_RULES: {
            return {...state, scoringRules: action.payload}
        }
        case actions.ADD_SCORING_RULES: {
            return {...state, scoringRules: [...state.scoringRules, action.payload]}
        }
        case actions.HANDLE_SCORING_RULES: {
            let scoringRules = [...state.scoringRules]
            scoringRules[action.index] = action.payload
            return {...state, scoringRules: scoringRules}
        }
        case actions.REMOVE_SCORING_RULES: {
            let scoringRules = [...state.scoringRules]
            scoringRules[action.index].regras.pop()
            return {...state, scoringRules: scoringRules}
        }
        case actions.HANDLE_HAS_RULE: {
            return {...state, hasRule: action.payload}   
        }
        default:
            return state;
    }
}
