import React from "react";
import { Button  } from 'react-materialize';
import { css } from "@emotion/core";
import {BeatLoader} from "react-spinners";
const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const Component = (props) => {
    const {backgroundColor, onClick,children,type, className, loading, loadingColor, ...rest} = props

    return <>
    {!loading &&
    <Button 
        {...rest}
        type={type} 
        className={className} 
        style={{...styles.button, ...props.style, backgroundColor: backgroundColor ? backgroundColor : styles.button.backgroundColor}} 
        onClick={onClick}>{children}
    </Button>}
    {loading &&
    <BeatLoader
          css={{textAlign: "center"}}
          color={loadingColor ? loadingColor : "#6B69CF"}
          loading={true}
        /> 
    }   
    
    </>
}



export default React.memo(Component)

const styles = {
    button : {
        fontFamily: "Roboto",
        width:"100%",
        backgroundColor:"#6B69CF",
        borderRadius: 10
    }
}