import React from "react"
import { Modal } from 'react-responsive-modal';
import { Row, Col } from "react-materialize";
import { Button, Input, Checkbox, InputSearch } from "../../../components/Form";
import DataTable from 'react-data-table-component';
import {BarLoader} from "react-spinners";
import { css } from "emotion";
import ModalMobileFullScreen from "../../../components/ModalMobileFullScreen";

const Component = ({open,data,teamSelected,
    loadingTable,onPressSearch,onClose,addAthletes,
    onChangeCheckbox, search, setSearch}) => {

    const columns = [
        {
            name: 'Nome ',
            selector: 'nome',
            sortable: true,
            style:{display: "inline-grid"},
            cell:(row) => {
                return(<div > 
                    <div><span className={css(styles.itemTableSpan)}>{(row.nome)}</span></div>
                </div>);
            }
        },
        {
            name: '',
            maxWidth:"10px",
            ignoreRowClick: true,
            cell:(row) => 
            <div>
                <Checkbox
                    id={"ath"+row.id}
                    value={teamSelected.id == `${row.id}`}
                    onChange={() => onChangeCheckbox(row)}
                />
            </div>,
        }
        
    ]

    return <ModalMobileFullScreen
            open={open} 
            toggle={onClose}
            contentStyle={styles.contentModal}
            // onClose={() => history.goBack()}
            title={"Filtro - Selecione a Equipe"}
            btnFooterText={"Adicionar"}
            onClickBtnFooter={() => {
                addAthletes()
            }}
        >   
                <Row>
                    <Col s={12} >
                        <InputSearch
                            cssStyle={css(styles.search)}
                            radius={true}
                            label={"Nome da Equipe:"}
                            key="nome_equipe"
                            value={search}
                            onSearch={onPressSearch}
                            onChange={(e) => setSearch(e.target.value)}
                        />
                    </Col>
                </Row>
                <Row>
                    <DataTable
                        columns={columns}
                        progressComponent={<BarLoader css={styles.loadingCss} color={"#6B69CF"}/>}
                        progressPending={loadingTable}
                        data={data}
                        highlightOnHover={true}
                        pointerOnHover={true}
                        noHeader={true}
                        noDataComponent={""}
                    />
                </Row>
               
                
      </ModalMobileFullScreen>
}

const styles={
    contentModal: {
        minHeight: "1000px !important",
        backgroundColor: "#fff",
        zIndex: 9999
    },
    btnSalvar: {
        marginTop: 55
    },
    newEvento : {
        float:"left"
    },
    search: {
        backgroundColor:"#FFFCFC !important",
        paddingLeft: "10px !important",
        paddingRight: "10px !important",
    },
    main: {
        paddingBottom: 20
    },
    photoEvent: {
        width: "100%",
        paddingTop: 20,
        position: "relative"
    },
    modal: {
        modal : {
            width: "80%"
        }
    },
    loadingCss: {
        width: "100%"
    },
    photo: {
        width: 40,
        height: 40,
        borderRadius: 250,
        marginTop: 5,
        marginBottom: 5
    },
    itemTableSpan: {
        fontSize: 14
    },
    iconBtn: {
        cursor:'pointer',
        color:'red !important'
   }
}

export default React.memo(Component);