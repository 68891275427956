import React from "react"
import { Row, Col  } from 'react-materialize';
import {Button, Input, InputPassword} from "../../../components/Form"
import {
    Link
} from "react-router-dom";
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import { GoogleLogin } from 'react-google-login';
import AppLogin from "../../../layouts/AppLogin"

export default ({refForm, onSubmit, loading, email, password, setEmail, setPassword, responseGoogle, responseFacebook}) => {

    return <AppLogin>
                <h4 className="text-darken-4 center-align" style={styles.loginText}>Login Atleta</h4>
                    <form ref={refForm} onSubmit={onSubmit}>
                        <div>
                            <Input 
                                id={"email"}
                                type={"email"}
                                data-testid={"email"}
                                required={true}
                                value={email}
                                color="rgba(107, 105, 207, 0.5)"
                                title={"E-mail incorreto"}
                                onChange={(e) => setEmail(e.target.value.toLowerCase())}
                                label={"meu e-mail"}
                            />
                            
                            <InputPassword
                                id={"password"} 
                                required={true}
                                data-testid={"password"}
                                color="rgba(107, 105, 207, 0.5)"
                                coloricon="#6B69CF"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                label={"minha senha"}
                            />
                            
                            <Row>
                                <Col m={12} s={12} l={8} className="offset-l2">
                                    <Button data-testid={"btnLogin"} loading={loading}>Entrar</Button>
                                </Col>
                            </Row>
                            <Row>
                                <div className="center-align">
                                    <Link data-testid={"btnEsqueceusenha"} to={{pathname: "/esqueceusenha", search: "?type=at"}} style={styles.esqLink}>
                                        <span className="center-align" style={styles.spanesqueceusenha}>Esqueceu a senha?</span>
                                    </Link>
                                </div>
                            </Row>
                            <Row>
                                <Col m={12} s={12} l={8} className="offset-l2">
                                    <Link data-testid={"btnCadastrar"} to="/atleta/novo">
                                        <Button type={"button"}>Cadastrar</Button>
                                    </Link>
                                </Col>
                            </Row>
                            <Row>
                                <Col m={6} s={6} l={4}  className="offset-l2">
                                    <FacebookLogin 
                                        appId="1132241200475337"
                                        autoLoad={false}
                                        data-testid={"btnFacebook"}
                                        callback={responseFacebook}
                                        render={renderProps => (
                                            <Button onClick={renderProps.onClick}
                                            backgroundColor="transparent" 
                                            type={"button"} 
                                            style={styles.btnFacebook}>facebook</Button>
                                        )}
                                    />
                                </Col>
                                <Col m={6} s={6} l={4}>
                                        <GoogleLogin
                                            clientId="277718010751-5res725lptn7qo7ina37iktahan93gcv.apps.googleusercontent.com"
                                            render={renderProps => (
                                                <Button onClick={renderProps.onClick} backgroundColor="transparent" type={"button"}  style={styles.btnGoogle}>Google</Button>
                                            )}
                                            buttonText="Login"
                                            data-testid={"btnGoogle"}
                                            onSuccess={responseGoogle}
                                            onFailure={responseGoogle}
                                            cookiePolicy={'single_host_origin'}
                                        />
                                </Col>
                            </Row>
                        </div>
                    </form>
        </AppLogin>
   
}

const styles =  {
    loginText: {
        color: "#6B69CF",
        fontFamily: "Roboto"
    },
    container : {
        height: "100vh", 
    },
    spanesqueceusenha: {
        fontFamily: "Roboto",
        cursor: "pointer",
        color: "#6B69CF",
    },
    logo: {
        width: "30%", 
        textAlign:"center" 
    },
    esqLink: {
        color: "#000"
    },
    btnGoogle: {
        color: "#d34836",
        borderColor: "#d34836",
        border: "2px solid",
        fontSize: 12,
        lineHeight: "12px"

    },
    
    btnFacebook: {
        color: "#3b5998",
        borderColor: "#3b5998",
        border: "2px solid",
       padding: 0,
        fontSize: 12,
       lineHeight: "12px"
    }

}