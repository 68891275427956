import React from "react"
import DataTable from 'react-data-table-component';
import { css } from "emotion";
import ModalMobileFullScreen from "../../../components/ModalMobileFullScreen";
import { useHistory } from "react-router-dom";
import { BarLoader } from "react-spinners";

const Component = ({
    invites, onAccept, onReject, loading
}) => {

    const history = useHistory()
    const columns = [
        {
          selector: 'image',
          sortable: true,
          width: "45px",
          cell: row => <img src={row.image} className={css(styles.image)}/>
        },
        {
            selector: 'nome',
            sortable: true,
            width: "80px",
        },
        {
            selector: 'tecnico',
            sortable: true,
            cell: row => <span>{row.tecnico ? "Técnico" : "Atleta"}</span>
        },
        {
            selector: 'pontos',
            sortable: true,
            width: "50px"
        },
        {
            selector: 'acoes',
            sortable: true,
            cell: row =>  
            <>
             <span href="#modal1" id={row.id} onClick={ () => onAccept(row.id) } 
            className={"material-icons modal-trigger "+css(styles.iconEdit)}>check</span>
            <span href="#modal1" id={row.id} onClick={ () => onReject(row.id) } 
            className={"material-icons modal-trigger "+css(styles.iconDelete)}>delete</span>
            </>
        },
    ];

    return <ModalMobileFullScreen
    open={true}
    onClose={() => history.goBack()} 
    >
        <div className={css(styles.container)}>
            <div >
                <span className={css(styles.titleSpan)}>
                    Solicitação pendente
                </span>
            </div>
            <div className={css(styles.convite)}>
                <DataTable
                    progressComponent={<BarLoader css={styles.loadingCss} color={"#6B69CF"}/>}
                    progressPending={loading}
                    data={invites}
                    columns={columns}
                    noDataComponent=""
                    noHeader
                    noTableHead
                />
            </div>
        </div>
    </ModalMobileFullScreen>
}

const styles = {
    loadingCss: {
        width: "100%"
    },
    container: {
        marginRight: 20,
        marginLeft: 20
    },
    iconDelete: {
        cursor:'pointer',
        color:'red'
    },
    iconEdit: {
        cursor:'pointer',
        color:'green',
        marginRight: 30
    },
    convite: {
      marginTop: 20
    },
 
    titleSpan: {
        fontWeight: "bold",
        fontFamily: "Roboto",
        fontSize: 20
    },
    image: {
        width: 35,
        height: 35,
        borderRadius:"50%"
    },
}

export default  React.memo(Component);