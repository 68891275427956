import React,{ useRef } from "react"
import { Row, Col} from "react-materialize"
import {  Button, Select } from "../../../components/Form"
import {  useHistory, useLocation } from "react-router-dom"
import { css } from "emotion"
import useSports from "../../../hooks/useSports"
import DataTable from "react-data-table-component"
import metalha_ouro from "../../../assets/icons/medalha-ouro.png"
import metalha_prata from "../../../assets/icons/medalha-prata.png"
import metalha_bronze from "../../../assets/icons/medalha-bronze.png"
import medalha_participacao from "../../../assets/icons/medalha-participacao.png"
import ReactToPrint from 'react-to-print';
import ModalInfoAtletaPontos from "../ModalInfoAtletaPontos"
import HeaderViewEvento from "../../../layouts/HeaderViewEvento"
import ModalMobileFullScreen from "../../../components/ModalMobileFullScreen"
import config from "../../../config"
import FiltroMobileResultadoEvento from "../FiltroMobileResultadoEvento"
import { useState } from "react"
const Evento = ({
    picture,
    name,
    city, 
    state,
    dateStart, 
    openModalInfo,
    setOpenModalInfo,
    loadingPub,
    categoriesOptions,
    modalInfoCronometroId,
    modalInfoCronometroAtleta,
    sportFilter,
    setSportFilter,
    categoryFilter,
    setCategoryFilter,
    handlePub,
    handleOpenInfoAth,
    values
}) => {
    const [openFiltroModal, setFiltroModal] = useState(false)
    const history = useHistory()
    const {search} = useLocation()
    const query = new URLSearchParams(search)
    const columns = [
        {
            selector: 'imagem',
            width: "40px",
            cell: row => <>{row.atleta_imagem && <img src={config.storage_link+`/users/${row.atleta_imagem}`} className={css(styles.image)}/>}</>
        },
        {
            selector: 'posicao',
            width: "40px",
        },
        {
            selector: 'atleta',
            width: "105px",
        },
        {
            selector: 'categoria',
        },
        {
            selector: 'tempo',
        },
    ];

    return <ModalMobileFullScreen
        open={true} 
        loading={loadingPub}
        // toggle={onClose}
        // contentStyle={styles.contentModal}
        onClose={() => history.goBack()}
        title={"Ranking "+name}
        btnFooterText={query.get('pub') === "0" ? "Publicar" : ""}
        onClickBtnFooter={() => {
            if(query.get('pub') === "0"){
                handlePub()
            }
        }}
    > 
    <div className={css(styles.content)}>
        <div className={css(styles.btnDivFiltro)}>
            <Button onClick={() => setFiltroModal(true)}>Filtro</Button>
        </div>
        <div>
            <ModalInfoAtletaPontos
                    open={openModalInfo}
                    toggle={setOpenModalInfo}
                    cronometro_id={modalInfoCronometroId}
                    atleta_id={modalInfoCronometroAtleta}
                />
            <FiltroMobileResultadoEvento 
                open={openFiltroModal}
                toggle={() => setFiltroModal(false)}
                setSportFilter={setSportFilter}
            />
            <DataTable 
                columns={columns}
                data={values}
                noDataComponent={""}
                responsive={true}
                noHeader
                noTableHead
                highlightOnHover
                onRowClicked={handleOpenInfoAth}
            />
        </div>
    </div>
       
    </ModalMobileFullScreen>
}

const styles = {
    image: {
        width: 30,
        height: 30,
        borderRadius: "50%"
    },
    content: {
        paddingRight: 20,
        paddingLeft: 20
    },
    btnDivFiltro: {
        paddingRight: 20,
        paddingLeft: 20,
        paddingBottom: 20
    }
}

export default Evento;