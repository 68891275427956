import React from "react"
import { css } from "emotion"
import {  Col, Row } from "react-materialize"
import { Input, Button, Select } from "../../../../components/Form"
import { useContext } from "react"
import FormEventoContext, { actions } from "../../FormEventoContext"
import listBanks from "../../../../constants/listBanks"

const Component = () => {

    const {state, dispatch, ref_form} = useContext(FormEventoContext)


    const handleChange =  (name, value) => {
        return dispatch({type: actions.HANDLE_CHANGE,
            payload: {name, value}})
    }

    const nextPage = (e) => {
        e.preventDefault()
        if(!ref_form.current.reportValidity()){
            return
        }
        dispatch({type: actions.CHANGE_PAGE,payload: 6})
    }

    return <div>
        <Row>
            <Col>
                <span className={css(styles.title)}>Dados para transferencia das vendas</span>
            </Col>
        </Row>
        <Row>
            <Col>
                <span className={css(styles.messageInfo)}>Dados do titular</span>
            </Col>
        </Row>
        <Row>
            <Col>
                <Input
                    id="nome_completo"
                    label={"Nome Completo:"}
                    maxLength={100}
                    required
                    value={state.trans_nome} 
                    onChange={(e) => handleChange('trans_nome',  e.target.value)}
                />
            </Col>
        </Row>
        <Row>
            <Col>
                <Input
                    id="cpf_cnpj"
                    label={"CPF/CNPJ:"}
                    maxLength={100}
                    required
                    value={state.trans_cpf_cnpj} 
                    onChange={(e) => handleChange('trans_cpf_cnpj',  e.target.value)}
                />
            </Col>
        </Row>
        <Row>
            <Col>
                <span className={css(styles.messageInfo)}>Dados da conta</span>
            </Col>
        </Row>
        <Row>
            <Col s={4}>
                <Select
                    label={"Banco*:"}
                
                    options={listBanks}
                    value={listBanks.filter(option => option.value == state.trans_banco)}
                    required
                    form="required"
                    onChange={(data) => handleChange('trans_banco',  data.value)}
                    />
            </Col>
        </Row>
        <Row>
            <Col>
                <Input
                    id="agencia"
                    label={"Agencia:"}
                    maxLength={100}
                    required
                    value={state.trans_agencia} 
                    onChange={(e) => handleChange('trans_agencia',  e.target.value)}
                />
            </Col>
            <Col>
                <Input
                    id="conta"
                    label={"Conta:"}
                    maxLength={100}
                    required
                    value={state.trans_conta} 
                    onChange={(e) => handleChange('trans_conta',  e.target.value)}
                />
            </Col>
        </Row>
        <Row className={css(styles.btnProxDiv)}>
                <Col m={12} s={12} l={6} className="offset-l3">
                    <Button onClick={nextPage}>Próximo</Button>
                </Col>
            </Row>
    </div>
}

const styles = {
    title: {
        fontSize: 20,
        fontWeight: "500",
        textAlign: "justify"
    },
    messageInfo: {
        color: "#000",
        fontWeight: 700,
        fontSize: 18
    },
    btnProxDiv: {
        marginTop: 55
    },
}

export default Component;