import React from "react"
import DataTable from 'react-data-table-component';
import { css } from "emotion";

const Component = ({
    invites, onAccept, onReject
}) => {

    const columns = [
        {
          selector: 'image',
          sortable: true,
          cell: row => <img src={row.image} className={css(styles.image)}/>
        },
        {
            selector: 'nome',
            sortable: true,
        },
        {
            selector: 'esporte_nome',
            sortable: true,
        },
        {
            selector: 'tecnico',
            sortable: true,
        },
        {
            selector: 'pontos',
            sortable: true,
        },
        {
            selector: 'acoes',
            sortable: true,
            cell: row =>  
            <>
             <span href="#modal1" id={row.id} onClick={ () => onAccept(row.id) } 
            className={"material-icons modal-trigger "+css(styles.iconEdit)}>check</span>
            <span href="#modal1" id={row.id} onClick={ () => onReject(row.id) } 
            className={"material-icons modal-trigger "+css(styles.iconDelete)}>delete</span>
            </>
        },
    ];

    const customStyles = {
        rows: {
            style: {
                marginTop: 30,
                backgroundColor:"#fff",
                boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.15)",
                borderRadius: 9
            }
        },
        table: {
            style: {
                backgroundColor:"transparent",
            }
        }
    };
    return <div>
        <div className={css(styles.title)}>
            <span className={css(styles.titleSpan)}>
                Convites para equipes
            </span>
        </div>
        <div className={css(styles.convite)}>
            <DataTable
                data={invites}
                customStyles={customStyles}
                columns={columns}
                noDataComponent=""
                noHeader
                noTableHead
            />
        </div>
    </div>
}

const styles = {
    iconDelete: {
        cursor:'pointer',
        color:'red'
    },
    iconEdit: {
        cursor:'pointer',
        color:'green',
        marginRight: 30
    },
    convite: {
        width: "70%",
        "@media(max-width: 1205px)": {
            width: "100%",
        }
    },
    title: {
        paddingTop: 30,
    },
    titleSpan: {
       
        fontFamily: "Roboto",
        fontSize: 26
    },
    image: {
        width: 35,
        height: 35,
        borderRadius:"50%"
    },
}

export default  React.memo(Component);