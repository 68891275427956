import { css } from "emotion"
import React, { useContext } from "react"
import { Card, Col, Row } from "react-materialize"
import { Input } from "../../../../components/Form"
import FormTorneioContext, { actions } from "../../FormTorneioContext"

const Component = ({index}) => {

    const {state, dispatch} = useContext(FormTorneioContext)
    
    return  <Card>
    <Row>
        <Col>
            <label className={css(styles.text)}>A regra simples você define um valor de diferença entre as posições do ranking</label>
        </Col>
    </Row>
    <Row>
        <Col>
            <label className={css(styles.text)}>Exemplo: O valor definido é de 2 de diferença por posição de um evento  com 3 tletas, então:</label>
        </Col>
    </Row>
    {[...new Array(3)].map((item,i) => <Row>
        <Col s={2}>
            <label className={css(styles.text)}>{i+1}</label>
        </Col>
        <Col s={4}>
            <label className={css(styles.text)}>Danilo Passos</label>
        </Col>
        <Col s={3}>
            <label className={css(styles.text)}>Pts 0{6-2*i}</label>
        </Col>
    </Row>)}
    <Row>
        <Col>
            <label className={css(styles.text)}>Valor de diferença entre as posições:</label>
        </Col>
    </Row>
    <Row>
        <Col s={4}>
            <Input 
                value={state.scoringRules[index].regras ? state.scoringRules[index].regras[0].valor : ""}
                onChange={(e) => {
                    const internalValue = {...state.scoringRules[index]}
                    internalValue.regras = [{valor: e.target.value}]
                    dispatch({
                        type: actions.HANDLE_SCORING_RULES, 
                        index,
                        payload: internalValue
                    })
                }}
            />
        </Col>
    </Row>
</Card>
}

const styles = {
    text: {
        color: "#C4C4C4",
        fontSize: 16
    }
}

export default Component;