export default  {
    START_INFO_EVENT: 'START_INFO_EVENT',
    HANDLE_CHANGE: 'HANDLE_CHANGE',
    CHANGE_PAGE: 'CHANGE_PAGE',
    HANDLE_ZIP_CODE: "HANDLE_ZIP_CODE",
    ADD_CATEGORIES: "ADD_CATEGORIES",
    ADD_CATEGORIES_TOURNAMENT: "ADD_CATEGORIES_TOURNAMENT",
    ADD_TOURNAMENTS: "ADD_TOURNAMENTS",
    REMOVE_TOURNAMENT: "REMOVE_TOURNAMENT",
    PREVENT_SUBMIT: "PREVENT_SUBMIT",
    UPDATE_WARNING_CATEGORIES_END_SUBSCRIPTION: "UPDATE_WARNING_CATEGORIES_END_SUBSCRIPTION",
    SPORT_LIST_UPDATE: "SPORT_LIST_UPDATE",
    LOADING_PUB: "LOADING_PUB",
    CHANGE_END_DATE: "CHANGE_END_DATE",
    ADD_NEW_PATROCINADOR: "ADD_NEW_PATROCINADOR",
    REMOVE_PATROCINADOR: "REMOVE_PATROCINADOR",
    HANDLE_PATROCINADOR: "HANDLE_PATROCINADOR",
    SET_SCORING_RULES: "SET_SCORING_RULES",
    HANDLE_SCORING_RULES: "HANDLE_SCORING_RULES",
    ADD_SCORING_RULES: "ADD_SCORING_RULES",
    ADD_SCORING_RULE: "ADD_SCORING_RULE",
    HANDLE_HAS_RULE: "HANDLE_HAS_RULE",
    REMOVE_SCORING_RULES: "REMOVE_SCORING_RULES",
    ADD_TOURNAMENTS_RULES: "ADD_TOURNAMENTS_RULES"
}