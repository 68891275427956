import React, { useState } from "react";
import { Button  } from 'react-materialize';
import { css } from "@emotion/core";
import {BeatLoader} from "react-spinners";
import PropTypes from "prop-types"
const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const Component = (props) => {
    const {backgroundColor, src, onClick,children,type, className, loading, ...rest} = props

    const [hover,setHover] = useState(false)

    return <div>

    <Button 
        {...rest}
        type={type} 
        className={className} 
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        style={{...styles.button, ...props.style, 
            background: `url(${src}) no-repeat`,
            backgroundSize: !hover ? "75% 75%" : "90% 90%",
            backgroundPosition: "center center"
           
        }} 
        onClick={onClick}>
            <img src={src}  style={{width: "100%", height: "100%",   float: 'left'}} />
    </Button>  
        <div style={styles.divLabelBtn}>
            <span style={styles.labelBtn}>{children}</span>
        </div>
    </div>
}



export default React.memo(Component)

const styles = {
    button : {
        width:"80%",
        display: "inline-block",
        paddingBottom: "70%",
        marginBottom: 20,
        height:0,
        borderRadius: "50%",
        backgroundColor:"transparent",
        border: "2px solid #cfdcec"
    },
    divLabelBtn: {
        textAlign: "center",
        width: "80%"
    },
    labelBtn: {
        textAlign: "center",
        width: "100%"
    }
}