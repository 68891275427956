import React from "react"
import { Modal } from 'react-responsive-modal';
import { Row, Col } from "react-materialize";
import { Button, Input, Checkbox } from "../../../components/Form";
import DataTable from 'react-data-table-component';
import {BarLoader} from "react-spinners";
import { css } from "emotion";
import FormPreCadastro from "../FormPreCadastro";

export default ({
    index,
    open,
    athSelected,
    onChangeCheckbox,
    search,
    setSearch,
    onClose,
    onPressSearch,
    loadingTable,
    addAthletes,
    newAth,
    newAthView,
    setNewAthView,
    data
}) => {

    const columns = [
        {
            name: '',
            selector: 'photo',
            cell:(row) => {
                return( 
                   <> {row.imagem && <img src={row.imagem} className={css(styles.photo)}/>} </>
                );
            }
        },
        {
            name: 'Nome ',
            selector: 'name',
            sortable: true,
            style:{display: "inline-grid"},
            cell:(row) => {
                return(<div > 
                    <div><span className={css(styles.itemTableSpan)}>{(row.name)}</span></div>
                </div>);
            }
        },
        {
            name: 'Local',
            selector: 'local',
            sortable: true,
            style:{display: "inline-grid"},
            cell:(row) => {
                return(<div > 
                    <div><span className={css(styles.itemTableSpan)}>{(row.cidade + " - " + row.uf)}</span></div>
                </div>);
            }
        },
        {
            name: '',
            maxWidth:"10px",
            ignoreRowClick: true,
            cell:(row) => 
            <div>
                <Checkbox
                    id={"ath"+row.id}
                    value={athSelected.id == `${row.id}`}
                    onChange={() => onChangeCheckbox(row)}
                />
            </div>,
        }
        
    ]

    return <Modal
    open={open}
    center
    styles={styles.modal}
    type={"button"}
    onClose={onClose}>
        <h5 className="grey-text text-darken-3 center-align">Seleção de atleta</h5>
        <form>
            {!newAthView && <><Row>
                <Col s={12} m={9}>
                    <Input
                        label={"CPF do atleta:"}
                        key="cpf_atleta"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                    />
                </Col>
                <Col s={12} m={3}>
                    <Button type={"button"} onClick={onPressSearch} style={{marginTop: 30,}}>Buscar</Button>
                </Col>
            </Row>
            <Row>
                <DataTable
                    columns={columns}
                    progressComponent={<BarLoader css={styles.loadingCss} color={"#6B69CF"}/>}
                    progressPending={loadingTable}
                    data={data}
                    highlightOnHover={true}
                    pointerOnHover={true}
                    noHeader={true}
                    noDataComponent={
                        newAth &&  <div>
                        <div className={css(styles.withoutAth)}> Atleta não encontrado </div>
                        <div>
                            <Button type={"button"} onClick={() => setNewAthView(true)}>Fazer Pré-cadastro</Button>
                        </div>
                        
                    </div>
                    }
                />
            </Row>
            <Row className={css(styles.btnSalvar)}>
                <Col m={12} s={12} l={6} className="offset-l3">
                    <Button type="button"  onClick={addAthletes}>Adicionar</Button>
                </Col>
            </Row></>}
            {newAthView && <FormPreCadastro 
                index={index} 
                onClose={onClose}
                cpf={search}/>}
            
        </form>
  </Modal>
}

const styles = {
    withoutAth: {
        textAlign: "center"
    },
    btnSalvar: {
        marginTop: 55
    },
    newEvento : {
        float:"left"
    },
    main: {
        paddingBottom: 20
    },
    photoEvent: {
        width: "100%",
        paddingTop: 20,
        position: "relative"
    },
    modal: {
        modal : {
            width: "80%"
        }
    },
    loadingCss: {
        width: "100%"
    },
    photo: {
        width: 40,
        height: 40,
        borderRadius: 250,
        marginTop: 5,
        marginBottom: 5
    },
    itemTableSpan: {
        fontSize: 14
    },
    iconBtn: {
        cursor:'pointer',
        color:'red !important'
   }
}