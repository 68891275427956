import React, { useState, useContext, useCallback, useEffect } from "react"
// import {Modal} from "react-materialize"
import ImageUploader from 'react-images-upload';
import {Card, Row, Col } from "react-materialize"
import {Button, Input, DateTimePicker, DatePicker, RichTextEditor, Select} from "./Form"

import { Modal } from 'react-responsive-modal';

const Component = (props) => {

    const [loading, setLoading] = useState(false)

    const onDelete = async () => {
        setLoading(true)
        if(props.onDelete) {
            await props.onDelete();
        }
        setLoading(false)
    }

    return <Modal
    open={props.open}
    center
    type={"button"}
    onClose={props.onClose}
  >
        <h5 className="grey-text text-darken-3 center-align">{props.title ? props.title : "Excluir"}</h5>
        <Row>
            <Col s={12}>
                <span>{props.children}</span>
            </Col>
        </Row>
        <Row>
            <Col s={6}>
                <Button loading={loading} onClick={onDelete}>Sim</Button>
            </Col>
            <Col s={6}>
                <Button onClick={props.onClose}>Não</Button>
            </Col>
        </Row>
    </Modal>
}

export default React.memo(Component)