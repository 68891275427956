import React from "react"
import { useContext } from "react";
import { Col, Container, Row } from "react-materialize";
import { Button, DatePicker, Input, Select } from "../../../components/Form";
import InscricaoContext from "../InscricaoContext";
import {css} from "emotion"
import moment from "moment"
import card from "../../../assets/icons/card.svg"
import ModalMobileFullScreen from "../../../components/ModalMobileFullScreen";
import { useHistory } from "react-router-dom";

const Component = ({
    validCpf, 
    optionsInstallment, 
    loadingBtn,
    onChangeField,
    onChangeCpf,
    optionsPag, 
    onBuy}) => {

    const history = useHistory()
    const {state} = useContext(InscricaoContext)
    const theme =  (theme) => ({
        ...theme,
        borderRadius: 0,
        colors: {
            ...theme.colors,
            primary25: '#FFFCFC',
            primary: '#FFFCFC',
            neutral80: "#FFFCFC",
            neutral20: "#FFFCFC"
        },
    })
    return <ModalMobileFullScreen
            open={true} 
            contentStyle={styles.contentModal}
            onClose={() => history.goBack()}
            title={"Pagamento Ingresso"}
            btnFooterText={"Continuar"}
            loading={loadingBtn}
            onClickBtnFooter={() => {
                onBuy()
            }}
        >
        <div className={css(styles.main)}>

            <div className={css(styles.conteudoEvento)}>
                <Container>
                    <div className={css(styles.cardTitle)}>
                        <img src={card} className={css(styles.miniCard)}/>
                        <span className={css(styles.textDados)}>Dados de pagamento</span>
                    </div>
                    {/* <div className={css(styles.divCard)}>
                        <img src={card} className={css(styles.card)}/>
                        <div className={css(styles.textCard)}>Crédito</div>
                    </div> */}
                   
                    {/* <h5 className={css(styles.titleSection)}>Dados do titular do cartão:</h5> */}
                    <Select
                            id="type"
                            label={"Tipo de pagamento*:"}
                            value={optionsPag.filter(option => option.value == state.typePag)}
                            isSearchable={false}
                            borderRadius={10}
                            color={"#fff"}
                            //theme={theme}
                            labelStyle={styles.labelStyle}
                            required={true}
                            onChange={(item) => onChangeField("typePag", item.value)}
                            options={optionsPag}
                            
                        />
                    <Input 
                        label={"CPF:"} 
                        validate={false}
                        classNameInput={validCpf ? "valid" : ( state.cpfCard.length > 0 ? "invalid" : "") }      
                        value={state.cpfCard}
                        backgroundColor={"#FFFCFC"}
                        inputmode={"numeric"}
                        radius={true}
                        onChange={(e) => onChangeCpf(e.target.value)}
                    />
                    
                    <Input label={"Número do cartão:"} 
                        value={state.numberCard} 
                        mask={"9999 9999 9999 9999"}
                        inputmode={"numeric"}
                        backgroundColor={"#FFFCFC"}
                        radius={true}
                        maskChar={"_"}
                        onChange={(e) => onChangeField("numberCard", e.target.value)}    
                    />
                    <Input 
                        label={"Nome Impresso no cartão:"}
                        backgroundColor={"#FFFCFC"}
                        radius={true} 
                        value={state.nameCard} 
                        onChange={(e) => onChangeField("nameCard", e.target.value)}    
                    />
                    <DatePicker 
                        label={"Data de validade*:"} 
                        dateFormat={"MM/yyyy"}
                        mask={"99/9999"}
                        style={styles.dataPicker}
                        value={state.valDateCard}
                        minDate={moment().startOf("month").toDate()}
                        showMonthYearPicker
                        onChange={(value) => onChangeField("valDateCard", value)}
                        placeholder="Data de validade*:"/>

                    <Input 
                        label={"Codigo de segurança*"} 
                        value={state.cvv} 
                        inputmode={"numeric"}
                        backgroundColor={"#FFFCFC"}
                        radius={true} 
                        onChange={(e) => onChangeField("cvv", e.target.value)} 
                    />
                    
                    <Select
                            id="installment"
                            menuPlacement={"top"}
                            label={"Opções de pagamento*:"}
                            value={optionsInstallment.filter(option => option.value == state.installmentQuantity)}
                            isSearchable={false}
                            borderRadius={10}
                            required={true}
                            onChange={(item) => {
                                onChangeField("installmentQuantity", item.value)
                                onChangeField("installmentValue", item.price)
                            }}
                            options={optionsInstallment}
                        /> 
                </Container>
            </div>
            
        </div>
    </ModalMobileFullScreen>
}

const styles = {
    dataPicker: {
        backgroundColor: "#fff !important",
        borderRadius: "10px !important",
        textIndent: 10
    },
    labelStyle: {
        color: "#fff",
        width: "100%"
    },
    cardTitle: {
        display: "flex"
    },
    btnSalvar: {
        marginTop: 55
    },
    textDados:{
        fontFamily: "Roboto",
        marginLeft: 10,
        fontWeight: "bold",
        lineHeight: "30px"
    },
    textCard: {
        fontSize: 16,
        textAlign: "center"
    },
    divBrands: {
        display: 'flex',
        marginTop: 20,
    },
    divBrand: {
        
        marginRight: 20
    
    },
    brand: {
        height: 30,
    },
    divCard: {
        width: "fit-content"
    },

    titleSection: {
        color: "#606060",
        fontFamily: "Roboto",
        fontSize: 20
    },
    conteudoEvento: {
        marginTop: 30,
        marginLeft: 10,
        zIndex: 99,
        position: "relative"
    },
    miniCard: {
        width: 30
    },
    card: {
        width: 60,
        marginTop: 30
    }
}

export default Component;