import Api from "../../api/Api";
import actions from "./actionsMain";

export default (state, action) => {
    switch(action.type){
        case actions.ADD_UPLOAD_IMAGES:
            const images_upload = [...state.images_upload]
          
            images_upload.push({ ...action.payload,
                status: "TO_UPLOAD"})
            return {...state, images_upload}
        case actions.UPDATE_STATUS_UPLOAD_IMAGES:
            const images_upload_update = [...state.images_upload];
            images_upload_update[action.payload.index].status = action.payload.status
            if(action.payload.status === "SUCCESS"){
                images_upload_update[action.payload.index].image = action.payload.image
                images_upload_update[action.payload.index].formData = null
            } else {
                images_upload_update[action.payload.index].formData = null
            }
            return {...state, images_upload: images_upload_update}
        case actions.SET_ID_ORGANIZER:
            return {...state, id_organizer: action.payload}
        default:
            return state;
    }
}