import React, { useContext,useState } from "react"
import { Card, Row, Col } from "react-materialize"
import { css } from "emotion"
import { ImageUploader, Input, Select, Button, DatePicker } from "../../../components/Form"
import useSports from "../../../hooks/useSports"
import { toast } from "react-toastify"
import listUf from "../../../constants/listUf"
import { useCookies } from "react-cookie"
import moment from "moment"

export default ({
    onSubmit, 
    onDrop, 
    handleZipCode,
    loading, 
    name,
    setName,
    foundation_data,
    setFoundationData,
    sport,
    setSport,
    zipCode,
    neighborhood,
    setNeightborhood,
    city,
    setCity,
    uf,
    setUf,
    number,
    setNumber,
    complement,
    setComplement,
    pictureUrl,
    street,
    setStreet}) => {

    const listSports = useSports(); 

    return <div>
        <div className={css(styles.title)}>
            <span className={css(styles.titleSpan)}>
                Criar nova equipe
            </span>
        </div>
        <Card className={css(styles.card)}>
            <form onSubmit={onSubmit}>
                <Row>
                    <Col s={12} l={3}>
                        <ImageUploader
                            aspect={16/9}
                            crop
                            buttonText='Escolher Imagem'
                            backgroundColor="#6B69CF"
                            rounded
                            defaultImages={pictureUrl}
                            onDrop={onDrop}
                        />
                    </Col>
                    <Col s={12} l={9}>
                        <Row>
                            <Col s={12}>
                                <Input 
                                    label={"Nome da equipe*:"}
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col s={6}>
                                <Select
                                    label={"Esporte*:"}
                                    options={listSports}
                                    value={listSports.filter(option => option.value == sport)}
                                    required={true}
                                    onChange={(data) => setSport(data.value)}
                                />
                            </Col>
                            <Col s={6}>
                                
                                <DatePicker 
                                    label={"Data de fundação*:"}
                                    type="date"
                                    value={foundation_data}
                                    onChange={(value) => setFoundationData(value)}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col s={12} l={4}>
                            <Input
                                id="cep"
                                label={"Cep*:"}
                                value={zipCode}
                                mask={"99999-999"}
                                required={true}
                                onChange={(e) => handleZipCode( e.target.value)}
                            />
                    </Col>
                </Row> 
                <Row>
                    <Col s={12} l={9}>
                            <Input
                                id="street"
                                label={"Logradouro*:"}
                                value={street}
                                required={true}
                                maxLength={100}
                                onChange={(e) => setStreet(e.target.value)}
                                />
                    </Col>
                    <Col s={12} l={3}>
                            <Input
                                id="number"
                                label={"Número*:"}
                                value={number}
                                type={"number"}
                                required={true}
                                onChange={(e) => setNumber(e.target.value)}
                            />
                            
                    </Col>
                </Row> 
                <Row>
                    <Col s={12} l={6}>
                            <Input
                                id="complement"
                                label={"Complemento:"}
                                maxLength={100}
                                value={complement} 
                                onChange={(e) => setComplement(e.target.value)}
                            />
                    </Col>
                    <Col s={12} l={6}>
                            <Input
                                id="neighborhood"
                                label={"Bairro*:"}
                                value={neighborhood}
                                maxLength={100}
                                required={true}
                                onChange={(e) => setNeightborhood(e.target.value)}
                            />
                    </Col>
                </Row> 
                <Row>
                    <Col s={12} l={9}>
                            <Input
                                id="city"
                                label={"Cidade*:"}
                                value={city}
                                maxLength={100}
                                required={true}
                                onChange={(e) => setCity(e.target.value)}
                            />
                    </Col>
                    <Col s={12} l={3}>
                            <Select 
                                label={"UF*:"}
                                menuPlacement={"top"}
                                value={listUf.filter(option => option.value == uf)}
                                onChange={(data) => setUf(data.value)}
                                required={true}
                                options={listUf}
                            />
                    </Col>
                </Row>
                <Row className={css(styles.btnProxDiv)}>
                    <Col m={12} s={12} l={6} className="offset-l3">
                        <Button loading={loading} onClick={onSubmit}>Salvar</Button>
                    </Col>
                </Row>
            </form>
        </Card>
    </div>
}

const styles = {
    title: {
        paddingTop: 30,
    },
    titleSpan: {
       
        fontFamily: "Roboto",
        fontSize: 26
    },
    card: {
        width: "70%",
        "@media(max-width: 1205px)": {
            width: "100%",
        }
    }
}

