import React from "react"
import circlePlus from "../../assets/icons/circle-plus.svg"
import circleEdit from "../../assets/icons/circle-edit.svg"
import circleDel from "../../assets/icons/circle-del.svg"
import { css } from "emotion"

const Component = (props) => {

    const icon = {
        'plus' : circlePlus,
        'edit': circleEdit,
        'del': circleDel
    }

    return <div className={css(styles.divNovoEvento,props.className)} onClick={props.onClick}>
        <img src={icon[props.icon]} className={css(props.cssImg)} />
        <span className={css(styles.novoEventoSpan, props.cssSpan)}>{props.children}</span>
    </div>
}

const styles = {
    divNovoEvento: {
        display: "inline-flex",
        alignItems: "center",
        cursor: "pointer"
    },
    novoEventoSpan: {
        fontFamily: "Roboto",
        fontSize: 14,
        marginLeft: 10,
    },
}

export default React.memo(Component)