import React, { useContext } from "react"
import { css } from "emotion"
import ItemEventoLista from "./ItemEventoLista"
import { Row, Col } from "react-materialize"
import HomeContext from "./HomeContext"

export default (props) => {

    const contextHome = useContext(HomeContext)
    
    let filterDesc = "";
    if(contextHome.typeFilter == "eventos_destaque"){
        filterDesc = "Eventos em destaque"
    }else if(contextHome.typeFilter == "eventos_semana"){
        filterDesc = "Eventos dessa semana"
    }else if(contextHome.typeFilter == "torneios_destaque"){
        filterDesc = "Torneios em destaque"
    }else {
        filterDesc = 
        (contextHome.filter.nome ? contextHome.filter.nome + " " : "")
        + (contextHome.filter.cidade ? contextHome.filter.cidade + " " : "")
        + (contextHome.filter.esporte_id ? contextHome.filter.esporte_label : ""  )
    }

    return <div className={css(styles.container)}>
        <div className={css(styles.seachDiv)}>
            <label className={css(styles.titleGroup)}>Você está buscando por: "{filterDesc}"</label>
        </div>
        {contextHome.data && 
            <Row>
                {contextHome.data.map((item) => <Col  key={item.id} s={12} m={4}><ItemEventoLista item={item} /> </Col>)}
            </Row>
        }
        <div className={css(styles.pages)}>
        {[...Array(contextHome.limitPaginas())].map((x,i) => 
            <div key={i} className={css(styles.dotsPage)} onClick={() => {contextHome.alteraPagina (contextHome.pagina(i)); return false} }>
                <span className={css(styles.dotsPageSpan)}>{contextHome.pagina(i)}</span>
            </div>
        )}
        </div>
      
    </div>
}

const styles = {
    container: {
        marginTop: 20,
    },
    seachDiv: {
        marginBottom: 20,
    },
    dotsPage: {
        marginLeft: 5,
        marginRight: 5,
        backgroundColor: "#C4C4C4",
        width: 35,
        height: 35,
        display: "inline-block",
        textAlign: "center",
        borderRadius: 40,
        cursor: "pointer"
    },
    dotsPageSpan:{
        fontFamily: "Roboto",
        fontSize: 20,
        lineHeight: 1.5,
        color: "#000",
        "-moz-user-select": 'none',
        "-khtml-user-select": 'none',
        "-webkit-user-select": 'none',
        '-ms-user-select': 'none',
        userSelect: 'none'
    },
    titleGroup: {
        color: "#606060",
        fontSize: 30
    },
    pages: {
        textAlign: "center",
        width: "100%"
    }
}