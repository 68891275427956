import React, {useContext, useState, useEffect} from "react"
import DataTable from 'react-data-table-component';
import JuizCronometroContext,{ actions } from "../../JuizCronometroContext";
import { css } from "emotion";
import ModalDelete from "../../../../components/ModalDelete";
import Api from "../../../../api/Api";
import { toast } from "react-toastify";
import { BarLoader } from "react-spinners";
import config from "../../../../config.js"

const Categoria = (props) => {

    const {state, dispatch} = useContext(JuizCronometroContext)
    const [openDelete, setOpenDelete] = useState(false)
    const [idJuizDelete, setIdJuizDelete] = useState()

    const onDelete = async () => {
        

        try {
            const result = await Api().delete("eventosjuiz/desvinculajuiz",{
                data: {
                    evento_id: state.id_event_access,
                    juizes: [idJuizDelete]
                }
            })
            if(!result.data.error){
                toast.success("Juiz desvinculado do evento")
                dispatch({type: actions.REMOVE_JUDGE_ACCESS, payload: idJuizDelete})
            }
        } catch(e){
            const response = e.response ? e.response : {};
            const data = response.data ? response.data : {};
            const msg = data.msg ? data.msg[0] : "Ocorreu um erro ao tentar desvinular o juiz";
            toast.error(msg)
        }

        setOpenDelete(false)

    }

    const columns = [
        {
            name: '',
            selector: 'photo',
            cell:(row) => {
                return( 
                    row.imagem && <img src={config.storage_link+`/users/${row.imagem}`} className={css(styles.photo)}/>
                );
            }
        },
        {
            name: 'Nome ',
            selector: 'name',
            sortable: true,
            style:{display: "inline-grid"},
            cell:(row) => {
                return(<div > 
                    <div><span className={css(styles.itemTableSpan)}>{(row.name)}</span></div>
                </div>);
            }
        },
        {
            name: 'Local',
            selector: 'local',
            sortable: true,
            style:{display: "inline-grid"},
            cell:(row) => {
                return(<div > 
                    <div><span className={css(styles.itemTableSpan)}>{(row.cidade) +  " - " +(row.uf)}</span></div>
                </div>);
            }
        },
 
        {
            name: 'Excluir',
            ignoreRowClick: true,
            cell:(row) => 
            <div>
                <span className={"material-icons modal-trigger  "+css(styles.iconBtn)} href="#modal1" id={row.id} 
                onClick={() => { setOpenDelete(true); setIdJuizDelete(row.user_id) } } >delete</span>
            </div>,
        }
        
    ]
    return  <div> 
        <DataTable
            columns={columns}
            data={state.judges_event_access}
            highlightOnHover={true}
            pointerOnHover={true}
            progressComponent={<BarLoader css={styles.loadingCss} color={"#110F62"}/>}
            progressPending={state.loadingTableAccess}
            noHeader={true}
            noDataComponent={""}
        />
         <ModalDelete 
            open={openDelete}
            onDelete={onDelete}
            onClose={() => setOpenDelete(false)}>
                Você realmente deseja desvincular esse juiz do evento?
        </ModalDelete>
</div>
}

const styles={
    loadingCss: {
        width: "100%"
    },
    newEvento : {
        float:"left"
    },
    main: {
        paddingBottom: 20
    },
    photo: {
        width: 40,
        height: 40,
        borderRadius: 250,
        marginTop: 5,
        marginBottom: 5
    },
    itemTableSpan: {
        fontSize: 14
    },
    iconBtn: {
        cursor:'pointer',
        color:'red !important'
   },
}

export default React.memo(Categoria);