import { css } from "emotion"
import React from "react"
const Component = (props) => {
    const idPrefex = typeof props.id === 'undefined' ? `` : `RadioGroup_${props.id}_`;

    return  <div className={props.className}> 
          <React.Fragment>
            {props.options.map((radioItem, idx) => (
                <label
                className={css(styles.radio)}
                htmlFor={`${idPrefex}radio${idx}`}
                key={`${idPrefex}radio${idx}`}
                >
                <input
                    id={`${idPrefex}radio${idx}`}
                    value={radioItem.value}
                    type="radio"
                    checked={radioItem.value === props.value}
                    name={props.id}
                    onChange={props.onChange}
                    className={'with-gap'}
                />
                <span>{radioItem.label}</span>
                </label>
            ))}
    </React.Fragment>
    </div>
}

const styles = {
    radio: {
        '[type="radio"]+span:after, [type="radio"].with-gap+span:before, [type="radio"].with-gap+span:after': {
            width: "30px",
            height: "30px",
            border: "2px solid #6B69CF",
            marginTop: "25px !important",
        },

        '[type="radio"]:checked+span:after, [type="radio"].with-gap:checked+span:after': {
            width: "30px",
            height: "30px",
            backgroundColor: "#6B69CF",
            marginTop: "25px !important",
        },
        '[type="radio"]:checked+span:after, [type="radio"].with-gap:checked+span:before, [type="radio"].with-gap:checked+span:after': {
            width: "30px",
            height: "30px",
            border: "10px solid #6B69CF",
            marginTop: "25px !important",
        },    
        "span": {
            paddingLeft: "10px !important",
            //paddingRight: 20,
            marginRight: 18,
            paddingTop:"0px !important",
            marginTop: "0px !important"
        },
    }
}

export default React.memo(Component)
