import React from "react"
import { Row, Col  } from 'react-materialize';
import Card from "../components/Card"
import background from "../assets/img/background-login.jpg"
import logo from "../assets/img/logo.png"
import loginLeft from '../assets/img/login-left.png'
import { css } from 'emotion'

const AppLogin = (props) => {
    return <div className={css(styles.main)}>
        <img src={loginLeft} className={css(styles.imgLeft)} />
        <img src={background} className={css(styles.imgRight)} />
        <div className={css(styles.rect)}></div>
        <Row className={css(styles.container)+" "}>
            <Col className={css(styles.leftContent)} style={(styles.leftContentStyle)} m={6} s={12}>
                <div className={css(styles.divLogo)+" center-align"}>
                    <img src={logo} style={styles.logo} className="center-align"/>
                </div>
                <Card className={css(styles.card)}>
                  {props.children}
                </Card>
            </Col>
            <Col m={6} className={css(styles.rightContent)}>
                <div className={css(styles.textContent)}>
                    <div className={css(styles.welcomeOlimpoz)}>Bem vindo à Olimpoz!</div>
                    <div className={css(styles.fraseTop)}>Uma nova forma de superar seus desafios</div>
                </div>
            </Col>
        </Row>
    </div> 
   
}

export default AppLogin

 

const styles =  {
    main : {
        zIndex: 1,
        "@media(max-width: 600px)": {
           backgroundColor: "#6B69CF"
        },
    },
    rect: {
        position: "absolute",
        height: "100vh",
        width: "60vw",
        right: 0,
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        "@media(max-width: 600px)": {
            display: "none"
        },
    },
    imgRight: {
        position: "absolute",
        height: "100vh",
        width: "60vw",
        objectFit: "cover",
        right: 0,
        "@media(max-width: 600px)": {
            display: "none"
        },
    },
    loginText: {
        color: "#6B69CF",
        fontFamily: "Roboto"
    },
    imgLeft: {
        zIndex: 2,
        position: "absolute",
        width: "50%", 
        height: "100vh",
        "@media(max-width: 600px)": {
            display: "none"
        }
    },
    container : {
        position: "relative",
        height: "100vh", 
        width: "100%",
        paddingLeft: "6%",
        "@media(max-width: 600px)": {
            width: "100%",
            paddingLeft: "0rem",
        },
        margin: 0,
        zIndex: 3,
        
    },
    leftContentStyle: {
        marginLeft: 0,
    },
    leftContent: {
        "& .card-custom": {
            width: "75% !important", 
            maxHeight: "75vh !important",
            "@media(max-width: 600px)": {
                width: "100% !important",
            },
        }
    },
    rightContent: {
        "@media(max-width: 600px)": {
            display: "none"
        }
    },
    card: {
        position: "relative",
        zIndex: 3,
     
    },
    textContent: {
        position: "absolute",
        top: "10vh",
    },
    welcomeOlimpoz: {
        color: "#fff",
        fontSize: "5rem",
        lineHeight: "5rem",
        fontFamily: "Roboto"
    },
    fraseTop: {
        marginTop: "25px",
        color: "#fff",
        fontSize: "2rem",
        fontFamily: "Roboto"
    },
    spanesqueceusenha: {
        cursor: "pointer",
        color: "#6B69CF",
    },
    divLogo: {
        width: "75%",
        "@media(max-width: 600px)": {
            width: "100%",
        },
    },
    logo: {
        width: 120, 
        textAlign:"center" 
    },
    esqLink: {
        color: "#6B69CF",
    }

}