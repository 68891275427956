import React,{ useState, useEffect, useContext, useReducer } from "react"
import Api from "../../api/Api"
import config from "../../config.js"
import moment from "moment"
import {  useParams, useHistory } from "react-router-dom"
import LoadingOverlayContext from "../../components/LoadingOverlayContext/LoadingOverlayContext"
import { useCookies } from "react-cookie"
import { toast } from "react-toastify"
import { actions, ApresentacaoEventoProvider, initialState, reducer } from "./ApresentacaoEventoContext"
import { BrowserView, MobileView } from "react-device-detect"
import ApresentacaoEventoBrowser from "./ApresentacaoEventoLayout/ApresentacaoEventoBrowser"
import ApresentacaoEventoMobile from "./ApresentacaoEventoLayout/ApresentacaoEventoMobile"
const Evento = () => {

    const [cookies] = useCookies(['id','imagem','access_token', 'nome'])
    
    const [openModalFaleConosco, setOpenModalFaleConosco] = useState(false)
    
    const {id} = useParams(); 
    const history = useHistory()

    const loadingOverlay = useContext(LoadingOverlayContext)
    const [ state, dispatch ] = useReducer(reducer,initialState)

    useEffect(() => {
        moment.locale("pt-BR")
        if(state.id_organizer){
            getOtherEventsOrganizer()
        }
        getInfo()
        
    },[id])

    useEffect(() => {
        getOtherEventsOrganizer()
    },[state.id_organizer])

    useEffect(() => {
        let internalLots = [];
        state.lots.map((lot) => {
            if(state.sportChecked == ""){
                lot.visible = true
            }
            else if(lot.esporte == state.sportChecked){
                lot.visible = true
            }else {
                lot.visible = false
            }
            internalLots.push(lot)
        })
        dispatch({type: actions.SET_LOTS, payload: internalLots})
    },[state.sportChecked])

    useEffect(() => {
        let total = 0;
        state.lots.map((lot) => {
            total += (lot.quantidade ? lot.quantidade : 0) * (lot.valor ? lot.valor : 0)
        })
        total = total*1.1
        dispatch({type: actions.SET_TOTAL, payload: total ? total.toFixed(2).replace(".",",") : "0,00"})
        dispatch({type: actions.SET_TOTAL_NUMBER, payload: total})
    },[state.lots])

    useEffect(() => {
        const sports = []
        state.categories.map((category) => {
            const sport = category.esporte ? category.esporte : {}
            if(!sports.includes(sport)){
                sports[sport.id] = ({id: sport.id, name: sport.nome})
            }
        })
    },[state.categories])

    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    const getInfo = async () => {
        if(id){
            dispatch({type: actions.SET_LOADING, payload: true})
            loadingOverlay.activeLoading(true,"Buscando conteúdo")
           // loadingOverlay.activeLoading(true,"Buscando conteúdo")
            const result = await Api().get(`eventos/getinfo/${id}`)
            if(result.data.info){
                const data = result.data.info;
                const cats = data.categorias ? data.categorias : []
                const data_inicio = moment(data.data_inicio,'YYYY-MM-DD HH:mm:ss').format("ddd, DD [de] MMMM [de] YYYY, HH[h]mm")
                const data_inicio2 = moment(data.data_inicio,'YYYY-MM-DD HH:mm:ss').format("DD MMM - HH[:]mm")

                let site = data.site_organizador ? data.site_organizador : ""
                
                if(!site.match(/http/) && site){
                    site = "https:\\\\"+site
                }
               
                let lotsCat = {}
                cats.map((cat) => {
                    const lote = cat.lote ? cat.lote : []
                    const sport = cat.esporte ? cat.esporte : []

                    lote.map((item,i) => {
                        
                        if((item.disponivel === true)){
                                lotsCat[item.id] = {
                                    id: item.id, 
                                    nome_cat: cat.nome,
                                    index: i,
                                    imagem: cat.imagem ? config.storage_link+`/categoria/${cat.imagem}` : null ,
                                    descricao_lote: "Lote "+(i+1),
                                    data_inicio: item.data_inicio_lote,
                                    data_fim: item.data_termino_lote,
                                    disponivel: item.disponivel,
                                    valor: item.valor_lote,
                                    id_cat: cat.id,
                                    tamanho_roupa: cat.tamanho_roupa,
                                    esporte: sport.id,
                                    visible: true,
                                    quantidade: 0
                                }
                        }
                    })
                })
                
                const arrayLotes = Object.values(lotsCat);
                let endSubscription = await Math.max.apply(null,arrayLotes.map((e) => {
                    
                    return !(e.data_fim) ?  -Infinity : moment(e.data_fim).toDate();
                }))
                let endDateSubscription = endSubscription ? moment(endSubscription).format("DD/MM/YYYY") : ""
                
                arrayLotes.sort((a,b) => {
                    if (a.index < b.index)
                        return -1;
                    if (a.index > b.index )
                        return 1;
                    return 0
                })
                console.log(arrayLotes)
                dispatch({type: actions.START_INFO_EVENT, payload: {
                    description: data.descricao,
                    cotegories: data.categorias,
                    dateStart: capitalizeFirstLetter(data_inicio),
                    dateStart2: data_inicio2,
                    name: data.nome,
                    block: data.block,
                    city: data.cidade,
                    street: data.logradouro,
                    neighborhood: data.bairro,
                    zipCode: data.cep,
                    organizer: data.organizador,
                    number: data.numero,
                    site,
                    pictureRoute: data.percurso,
                    id_organizer: data.user_id,
                    email: data.email,
                    state: data.uf,
                    patrocinadores: data.patrocinadores,
                    endDateSubscription,
                    lots: arrayLotes,
                    picture: config.storage_link+`/evento/${data.id}/${data.imagem}`
                }})
                dispatch({type: actions.SET_LOADING, payload: false})
                loadingOverlay.activeLoading(false)
            }
        }
    }

    const getOtherEventsOrganizer = async () => {
        dispatch({type: actions.SET_LOADING_OTHER_EVENTS, payload: true})
        if(state.id_organizer){
            const result = await Api().post("/eventos/listar",{ publicado: true,situacao: true, user_id: state.id_organizer })
            let otherEventosInternal = []
    
            if(result.data.eventos){
                const data = result.data.eventos;
             //   console.log(data)
                data.map((item) => {
                    console.log(item)
                    if(item.id != id){
                        const row = {
                            id: item.id,
                            name: item.nome,
                            photo: item.imagem ? config.storage_link+`/evento/${item.id}/${item.imagem}` : "",
                            dateStart: item.data_inicio,
                            street: item.logradouro,
                            city: item.cidade,
                            state: item.uf,
                            neighborhood: item.bairro,
                            number: item.numero
                        }
                        otherEventosInternal.push(row)
                    }
              
                })
                dispatch({type: actions.SET_OTHER_EVENTS_ORGANIZER, payload: otherEventosInternal})
                dispatch({type: actions.SET_LOADING_OTHER_EVENTS, payload: false})
            }
        }
    }

    const addQuantidade = (index) => {
        const lotsCat = state.lots;
        lotsCat[index].quantidade = lotsCat[index].quantidade + 1
        dispatch({type: actions.SET_LOTS, payload: lotsCat})
    }

    const subQuantidade = (index) => {
        const lotsCat = state.lots;
        if(lotsCat[index].quantidade > 0){
           
            lotsCat[index].quantidade = lotsCat[index].quantidade - 1
            dispatch({type: actions.SET_LOTS, payload: lotsCat})
        }
    }

    function retornoZeroBeforeNumbers(n){
        return n > 9 ? "" + n: "0" + n;
    }

    function openResultado(){
        history.push(`/evento/apresentacao/${id}/resultado_review`)
    }

    const onBuy = () => {
        let lotsSelected = Object.values(state.lots).filter((item) => item.quantidade > 0)
        if(lotsSelected.length == 0){
            toast.warn("Adicione pelo menos uma categoria")
            return;
        }


        let url = `/evento/inscricao/${id}`
        if(!cookies.access_token){
            url = `/atleta/login`
        }
        
        history.push(url,{
            inscricao: {
                id,
                picture: state.picture,
                name: state.name,
                dateStart: state.dateStart,
                city: state.city,
                state: state.state,
                totalPrice: state.totalNumber,
                lotsSelected: lotsSelected,
                athlete: {
                    id: cookies.id,
                    name: cookies.nome,
                    imagem: cookies.imagem ? config.storage_link+"/users/"+cookies.imagem : ""
                }
            }
        })
    }

    return <ApresentacaoEventoProvider value={{state, dispatch, onBuy, openResultado, retornoZeroBeforeNumbers, subQuantidade, addQuantidade}}>
        <BrowserView>
            <ApresentacaoEventoBrowser/>
        </BrowserView>
        <MobileView>
            <ApresentacaoEventoMobile/>
        </MobileView>
    </ApresentacaoEventoProvider>
    
}

const styles = {
    main: {
        position: "relative",
        backgroundColor: "#F2F2F2",
        minHeight: "100%"
    },
    acoesTable: {
        marginTop: "6%",
        float: "right"
    },
    imgPercurso: {
        width: 200,
        height: 100,
        paddingLeft: 20,
        objectFit: "cover",
        ":hover": {
            width: 220,
            height: 120
        }
    },
    imgPatrocinadores: {
        width: 200,
        height: 100,
        paddingLeft: 20,
        objectFit: "cover",
        cursor: "pointer",
        ":hover": {
            width: 220,
            height: 120
        }
    },
    divDeskView: {
        marginLeft: '8%',
        "@media(max-width: 992px)": {
            marginLeft: "7%",
            marginRight: "7%",
        },
        "@media(max-width: 700px)": {
            marginLeft: 7,
            marginRight: 7,
        },
    },
    maisEventos: {
        marginLeft: '4%',
        paddingBottom: 20,
        "@media(max-width: 992px)": {
            marginLeft: "7%",
            marginRight: "7%",
        },
        "@media(max-width: 700px)": {
            marginLeft: 15,
            marginRight: 15,
        },
    },
    organizador: {
        marginLeft: '4%',
        "@media(max-width: 992px)": {
            marginLeft: "7%",
            marginRight: "7%",
        },
        "@media(max-width: 700px)": {
            marginLeft: 15,
            marginRight: 15,
        },
    },
    percurso: {
        marginLeft: '4%',
        "@media(max-width: 992px)": {
            marginLeft: "7%",
            marginRight: "7%",
        },
        "@media(max-width: 700px)": {
            marginLeft: 15,
            marginRight: 15,
        },
    },
    nome_cat: {
        color: "#606060",
        fontFamily: "Roboto",
        fontSize: 18
    },
    valor_lote: {
        color: "#606060",
        fontFamily: "Roboto",
        fontSize: 14
    },
    parcela_lote: {
        color: "#19BC0B",
        fontFamily: "Roboto",
        fontSize: 14
    },
    labelDescricao: {
        color: "#606060",
        fontFamily: "Roboto",
        fontSize: 20
    },
    meusEventosLabel: {
        color: "#606060",
        fontFamily: "Roboto",
        fontSize: 20
    },
    organizadorLabel: {
        color: "#606060",
        fontFamily: "Roboto",
        fontSize: 20
    },
    percursoLabel: {
        color: "#606060",
        fontFamily: "Roboto",
        fontSize: 20
    },
    titleEvento: {
        "@media(max-width: 992px)": {
            marginLeft: -10,
            textAlign: 'center',
        },
        fontWeight: 600,
        fontFamily: "Roboto",
        color: "#606060"
    },
    headerCapa: {
        height: 160,
        width: "100%",
        position: "relative",
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundColor: "#2F2E41"
    },
    viewImageEvento: {
        position: "absolute",
        zIndex: 2,
        top: 80,
        left: "5%",
        width: "70%",
        "@media(max-width: 992px)": {
            left: "0%",
            width: "100%",
        },
    },
    conteudoEvento: {
        marginLeft: 10,
    },
    imgEventoDiv: {
        height: "15rem"
    },
    imgEvento: {
        objectFit: 'cover',
        objectPosition: '0%',
        "@media(max-width: 992px)": {
            objectFit: 'contain',
            objectPosition: 'center center'
        },
    },
    dateStart: {
        color: "#606060",
        fontFamily: "Roboto",
        fontSize: 16,
    },
    dateEnd: {
        color: "#606060",
        fontSize: 16,
        fontFamily: "Roboto",
    },
    dataEndSub: {
        color: "#606060",
        fontFamily: "Roboto",
        fontSize: 14,
    },
    dataEndSubSpan: {
        color: "#606060",
        fontFamily: "Roboto",
        fontSize: 14,
    },
    txtOrg: {
        color: "#606060",
        fontFamily: "Roboto",
        fontSize: 18,
    },
    btnFaleConosco: {
        fontSize: 14, 
        width: "35% !important",
        "@media(max-width: 992px)": {
            width: "100% !important",
        },
    },
    btnTodosEsportes: {
        marginTop: 10
    },
    btnEsporte: {
        marginTop: 10,
        overflow: "hidden"
    },
    tableCategories: {
        width: "98%"
    },
    headTableCat: {
        backgroundColor: "#6B69CF"
    },
    headTableCatCat: {
        fontSize: 18,
        fontWeight: 500,

        fontFamily: "Roboto",
        paddingBottom:8,
        paddingLeft: 20,
        paddingTop: 8,
        borderRadius: 0,
        color: "#fff"
    },
    headTableCatTotalPrice: {
        fontSize: 18,
        fontWeight: 500,
        paddingBottom:8,
        fontFamily: "Roboto",
        textAlign: "center",
        paddingTop: 8,
        borderRadius: 0,
        color: "#fff"
    },
    headDataEncerra: {
        fontSize: 14,
        fontWeight: 400,
        paddingBottom:8,
        paddingLeft: 20,
        fontFamily: "Roboto",
        textAlign: "left",
        paddingTop: 8,
        borderRadius: 0,
        color: "#fff"
    },
    btnComprarTR: {
        backgroundColor: "#6B69CF",
        cursor: "pointer"
    },
    btnComprarTD: {
        textAlign: "center",
        fontSize: 18,
        paddingBottom:8,
        paddingTop: 8,
        color: "#fff"
    },
    btnChangeQuantLote: {
        cursor: "pointer",
        fontSize: 24,
        paddingLeft: 10,
        paddingRight: 10,
        backgroundColor: "transparent",
        border: "none",
        ":hover": {
            backgroundColor: "transparent"
        },
        ":focus": {
            backgroundColor: "transparent"
        }
    },
    qtdLote: {
        fontFamily: "Roboto",
        fontSize: 20
    },
    encerrado: {
        color: "#f00",
        paddingLeft: 15
    },
    divResultado: {
        position: "absolute", 
        right: 50, 
        bottom: 50,
        border: "1px solid",
        padding: "5px 15px",
        cursor: "pointer",
        borderRadius: 25,
        color: "#fff",
        "@media(max-width: 700px)": {
            right: 10, 
            top: 10,
            bottom: 'auto',
        },
    },
    button: {
        backgroundColor: "transparent",
        border: "1px solid",
        padding: "5px 15px",
        cursor: "pointer",
        borderRadius: 25,
        color: "#fff",
    }
}

export default Evento;