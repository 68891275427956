import React from 'react';
import AppContainer from './navigation/Routes'
import { ToastContainer } from 'react-toastify';
import { registerLocale }  from "react-datepicker";
import "materialize-css"
import 'materialize-css/dist/css/materialize.min.css'
import 'react-toastify/dist/ReactToastify.css';
import "react-datepicker/dist/react-datepicker.css";
import 'react-responsive-modal/styles.css';
import 'react-alice-carousel/lib/alice-carousel.css'
import "moment/locale/pt-br"
import 'react-nice-dates/build/style.css'
import ptBR from "date-fns/locale/pt-BR"
import "fontsource-roboto"
import "./App.css";
import {CookiesProvider} from "react-cookie"
import LoadingOverlayProvider from './components/LoadingOverlayContext/LoadingOverlayProvider';
import { MainProvider } from './containers/MainContext';

function App() {
  registerLocale("pt-BR", ptBR)
  return (
    <>
      <CookiesProvider>
          <LoadingOverlayProvider>
            <ToastContainer data-testid={"toast"}/>
            <MainProvider>
              <AppContainer/>
            </MainProvider>
          </LoadingOverlayProvider>
      </CookiesProvider>
    </>
  );
}

export default App;
