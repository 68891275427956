import React, { useEffect } from "react" 
import logo from "../../assets/img/logo.png"
import { css } from "emotion"
import evento_icon from "../../assets/icons/evento.svg"
import perfil_icon from "../../assets/icons/perfil.svg"
import buscar_icon from "../../assets/icons/buscar.svg"
import sair_icon from "../../assets/icons/sair.svg"
import carteira_icon from "../../assets/icons/carteira.svg"
import equipe_icon from "../../assets/icons/equipe.svg"
import hall_fama_icon from "../../assets/icons/hall-fama.svg"
import cad_icon from "../../assets/icons/cad.svg"
import entrar_icon from "../../assets/icons/entrar.svg"
import juiz_icon from "../../assets/icons/juiz.svg"
import {  Link, useHistory } from "react-router-dom"
import { useCookies} from "react-cookie"
import M from "materialize-css/dist/js/materialize.min.js";
const Component = () => {
    const [cookies, ,removeCookie] = useCookies(['id','access_token','nome',"imagem", "tipo"]);
    const history = useHistory()

    useEffect(() => {
        var elems = document.querySelectorAll('.sidenav');
        M.Sidenav.init(elems, {edge: 'right',});
    },[])

    return  <div >
        <div className={css(styles.fakeHeader)}></div>
        <div className={css(styles.header)}>
            <div className={css(styles.flex1)}>
                <img src={logo}  className={css(styles.logo)}/>
                <span  className={css(styles.title)}>DPAZZ</span>
            </div>
            <div className={css(styles.flex1)}>
            </div>
            <span data-target="sidenav-1"  className={css(styles.flex1, {justifyContent: "flex-end", paddingRight: 10})+" left sidenav-trigger hide-on-medium-and-up"}>
                <i className="material-icons">menu</i>
            </span>
        </div>
        {<div id="sidenav-1" className="sidenav sidenav-fixed" style={styles.sideMenu}>
            <div className={css(styles.menuLabel)}>Menu</div>
            <ul>
                {cookies.access_token && cookies.tipo == "at"  && <>
                    <li className={css(styles.itemMenu)}> <Link to={"/atleta/convites"}> <span style={styles.itemMenuText}>Solicitação Pendente</span> </Link> </li>
                    <li className={css(styles.itemMenu)}> <Link to={"/atleta/carteira"}> <span style={styles.itemMenuText}>Meus Pedidos</span> </Link> </li>
                    <li className={css(styles.itemMenu)}> <Link to={"/atleta/carteira"}> <span style={styles.itemMenuText}>Politica</span> </Link> </li>
                    <li className={css(styles.itemMenu)}> <Link to={"/atleta/trocarsenha"}> <span style={styles.itemMenuText}>Trocar Senha</span> </Link> </li>
                    <li className={css(styles.itemMenu)}> <Link onClick={() => { 
                            removeCookie("access_token", { path: '/' });
                            history.replace("/") }}> <span style={styles.itemMenuText}>Sair</span> </Link> </li>
                </>}
                {(!cookies.access_token || cookies.tipo != "at") && <>
                    <li className={css(styles.itemMenu)}> <Link to={"/atleta/login"}> <span style={styles.itemMenuText}>Entrar</span> </Link> </li>
                </>}
            </ul>
        </div>}
        {cookies.access_token && cookies.tipo == "at"  && 
        <div className={css(styles.footerMenu)}>
            <Link to={"/"} className={css(styles.itemFooterMenu)}>
                <img src={evento_icon} style={styles.icon_menu}/>
                <label style={styles.text_menu}>Eventos</label>
            </Link>
            <Link to={"/busca"} className={css(styles.itemFooterMenu)}>
                <img src={buscar_icon} style={styles.icon_menu}/>
                <label style={styles.text_menu}>Buscar</label>
            </Link>
            <Link to={"/atleta/minhas-equipes"} className={css(styles.itemFooterMenu)}>
                <img src={equipe_icon} style={styles.icon_menu}/>
                <label style={styles.text_menu}>Equipe</label>
            </Link>
            <Link to={"/atleta/cronometro"} className={css(styles.itemFooterMenu)}>
                <img src={juiz_icon} style={styles.icon_menu}/>
                <label style={styles.text_menu}>Cronômetro</label>
            </Link>
            <Link to={"atleta/hall-fama"} className={css(styles.itemFooterMenu)}>
                <img src={perfil_icon} style={styles.icon_menu}/>
                <label style={styles.text_menu}>Perfil</label>
            </Link>
        </div>
        } 
    </div>
    
}

const styles = {
    fakeHeader: {
        height: 55
    },
    sideMenu: {
        width: 180,
    },
    menuLabel: {
        fontFamily: "Roboto",
        fontWeight: "bold",
        fontSize: 16,
        marginLeft: 15,
        marginTop: 15
    },
    itemFooterMenu: {
        flex: 1,
        backgroundColor: "#6B69CF",
        marginRight: 5,
        marginLeft: 5,
        borderRadius: 10,
        position: "relative"
    },
    icon_menu: {
        width: 20,
        marginTop: 10,
        marginLeft: 10
    },
    text_menu: {
        color: "#fff",
        fontFamily: "Roboto",
        position: "absolute",
        bottom: 5,
        fontSize: 10,
        left: 5
    },
    itemMenu: {
        marginTop: 0,
        '> a': {
            height: "30px !important",
            lineHeight: "30px !important",
            paddingLeft: "15px !important",
        }
    },
    itemMenuText: {
        fontSize: 12,
        fontWeight: 500
    },
    title: {
        fontFamily: "Roboto",
        fontSize: 14,
        fontWeight: "bold",

    },
    itemMenuRight: {
        marginRight: 30,
        marginTop: 10,
        lineHeight: "normal !important",
        textAlign: "center"
    },
    navWrapper: {
        backgroundColor: "#6B69CF !important",
        boxShadow: "none"
    },
    line: {
        marginTop: 15,
        marginBottom: 15
    },
    divLogo: {
        marginTop: 10,
        width: "100%",
    },
    logo: {
        width: 50, 
        textAlign:"center" 
    },
    loginText: {
        color: "#6B69CF",
        fontFamily: "Roboto"
    },
    container : {
        height: "100vh", 
    },
    header: {
        position: 'fixed',
        backgroundColor: "#fff",
        width: "100%",  
        top: 0,
        zIndex: 30,
        display: "flex"
    },
    flex1: {
        flex: 1,
        display: "flex",
        alignItems: 'center',
    },
    footerMenu: {
        position: "fixed",
        display: "flex",
        height: "18vw",
        bottom: 0,
        paddingBottom: 5,
        width: "100%",
        zIndex: 10,
        backgroundColor: "#fff",
    },
    itemsMenuFooter: {
        display: "flex",
        justifyContent: "space-evenly"
    },
    itemMenuIconFooter: {
        marginTop: 5,
        width: 40, 
        textAlign:"center",
    },
    itemMenuIconFooter: {
        marginTop: 5,
        width: 40, 
        textAlign:"center",
    },
}

export default (Component)