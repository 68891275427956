export default  {
    START_INFO_TOURNAMENT: 'START_INFO_TOURNAMENT',
    HANDLE_CHANGE: 'HANDLE_CHANGE',
    CHANGE_PAGE: 'CHANGE_PAGE',
    ADD_CATEGORIES: "ADD_CATEGORIES",
    PREVENT_SUBMIT: "PREVENT_SUBMIT",
    SPORT_LIST_UPDATE: "SPORT_LIST_UPDATE",
    LOADING_PUB: "LOADING_PUB",
    SET_SCORING_RULES: "SET_SCORING_RULES",
    HANDLE_SCORING_RULES: "HANDLE_SCORING_RULES",
    ADD_SCORING_RULES: "ADD_SCORING_RULES",
    HANDLE_HAS_RULE: "HANDLE_HAS_RULE",
    REMOVE_SCORING_RULES: "REMOVE_SCORING_RULES"
}