import React, { useContext, useEffect } from "react"
import TabMenuCard from "../../components/TabMenuCard";
import { Acessos, Cronometro } from "./JuizCronometroSteps";
import JuizCronometroContext, { actions } from "./JuizCronometroContext"
import Api from "../../api/Api";
import useIdOrganizer from "../../hooks/useIdOrganizer";

const Component = () => {
    const pages = ["Cronômetro", "Acessos"]

    const {state,dispatch} = useContext(JuizCronometroContext)
    const user_id = useIdOrganizer()
    useEffect(() => {
        (async () => {
            const result = await Api().get("/cronometro/listar/comprado/"+user_id)
            const data = result.data ? result.data : {}
            const events = data.eventos ? data.eventos : []
            dispatch({type: actions.SET_EVENTS,payload: events})
        })()
    },[])

    useEffect(() => {
        (async () => {
            const result = await Api().post("/eventos/listar",{ativo: true,  user_id})
 
            const data = result.data ? result.data : {}
            const events = data.eventos ? data.eventos : []
            dispatch({type: actions.SET_ALL_EVENTS,payload: events})
            dispatch({type: actions.SET_LOADING_ALL_EVENTS})
        })()
    },[])

    

    return <TabMenuCard
            pages={pages}
            currentPage={state.currentPage}
            onChange={(page) => dispatch({type: actions.CHANGE_PAGE, payload: page})}
        >
            <Cronometro/>
            <Acessos/>
        </TabMenuCard>
}

export default Component;