import React, {  useContext } from "react"
import JuizCronometroContext, { actions } from "../../JuizCronometroContext";
import { useHistory } from "react-router-dom";
import { BrowserView, MobileView } from "react-device-detect";
import ListEventsUploadBrowser from "./ListEventsUploadLayout/ListEventsUploadBrowser";
import ListEventsUploadMobile from "./ListEventsUploadLayout/ListEventsUploadMobile";

const Component = () => {

    const {dispatch} = useContext(JuizCronometroContext)

    const history = useHistory()

    const handleSetEventId = (id, name="") => {
        history.push({
            search: "?id_event="+id
        })
        dispatch({type: actions.SET_ID_EVENT_TIMER, payload: id})
        dispatch({type: actions.SET_NAME_EVENT_TIMER, payload: name})
    }

    return <div>
       <BrowserView>
            <ListEventsUploadBrowser
                handleSetEventId={handleSetEventId}
            />
       </BrowserView>
       <MobileView>
            <ListEventsUploadMobile
                handleSetEventId={handleSetEventId}
            />
       </MobileView>
    </div>
}

const styles = {
    divSelect: {
        width: "100%"
    },
    containers: {
        display: 'flex',
        flexWrap: "wrap"
    },
    itemContainer: {
        marginBottom: 50,
        flex: "50%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        "@media(max-width: 992px)": {
            flex: "100%",
        },
    },
    titleContainer: {
        fontFamily: "Roboto",
        color: "#606060",
        fontSize: 22,
        fontWeight: 500,
        textAlign: "center"
    },
    box: {
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        border: "1px solid #6B69CF",
        marginTop: 20,
        height: "100%",
        width: "80%",
        borderRadius: 9,
        display: "flex",
        alignItems: "center",
        flexDirection: "column"
    },
    tittleBox: {
        fontFamily: "Roboto",
        fontSize: 17,
        textAlign: "center",
        color: "#C4C4C4",
        border: "0.3px solid #FFFFFF"
    },
    contentBox: {
        width: "100%",
        marginTop: 20,
        minHeight: 70,
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        paddingRight: 20,
        paddingLeft: 20,

    },
    btnBox: {
        marginBottom: 10,
        lineHeight: 1.7,
        height: "auto",
        minHeight: 35,
        fontSize: 12,
        justifyContent: "center",
        alignItems: "center",
        display: "flex"
      
    },
    subTextResults: {
        fontFamily: "Roboto",
        alignSelf: "start",
        fontSize: 14,
        marginTop: 5,
        color: "#C4C4C4"
    }
}

export default React.memo(Component);