import { css } from "emotion";
import React, { useState } from "react"
import { useEffect } from "react";
import { Modal } from 'react-responsive-modal';
import Api from "../../api/Api";
import ModalMobileFullScreen from "../../components/ModalMobileFullScreen";

const ModalPoliticaAtletaBrowser = (props) => {

    const [policyOrg, setPolicy] = useState("")

    useEffect(() => {
        window.scrollTo(0, 0);
    },[props.open])

    useEffect(() => {
        (async () => {
            const result = await Api().get("politicas/getinfo")
            const data = result.data?.politicas
            const politicas = data[0] ? data[0] : {};
            const organizador = politicas['atleta']
            setPolicy(organizador)
        })()
    },[])

    return <div>
    <ModalMobileFullScreen
        open={props.open} 
       // contentStyle={styles.contentModal}
        onClose={() =>  props.setOpen(false) }
        title={"Politicas"}
       // headerCss={styles.headerCss}
       // iconCloseCss={styles.iconCloseCss}
    > 
        <div className={css(styles.policy)}>
            <div dangerouslySetInnerHTML={{ __html: policyOrg }}>
                
            </div>
        </div>
            {/* <h5 className="grey-text text-darken-3 center-align">Políticas</h5> */}

        </ModalMobileFullScreen>
    </div>
}

const styles = {
    modal: {
        closeButton: {
            backgroundColor: "transparent"
        },
        modal: {
            width: "100%"
        }
    },
    policy: {
        marginRight: 20,
        marginLeft: 20
    },
    textPolicy: {
        textJustify: "inter-word", 
        textAlign: "justify"
    }
}

export default React.memo(ModalPoliticaAtletaBrowser)
