import { css } from "emotion"
import React, { useContext, useState } from "react"
import { Container } from "react-materialize"
import { Input, Select } from "../../components/Form"
import ModalMobileFullScreen from "../../components/ModalMobileFullScreen"
import useSports from "../../hooks/useSports"

export default ({open,toggle, setSportFilter}) => {

    const [sport, setSport] = useState("")
    const sports = useSports()

    const theme =  (theme) => ({
        ...theme,
        borderRadius: 0,
        colors: {
            ...theme.colors,
            primary25: '#6B69CF',
            primary: '#6B69CF',
            neutral80: "#6B69CF",
            neutral20: "#6B69CF"
        },
    })
    return <div>
        <ModalMobileFullScreen 
            open={open} 
            title={"Filtro"}
            btnFooterText={"Aplicar"}
            toggle={toggle}
            onClickBtnFooter={() => {
                setSportFilter(sport)
                toggle()
            }}
        >
            <Container>
                <div>
                    <span className={css(styles.text)}>Para revisar os resultados use os filtros</span>
                </div>
                <div className={css(styles.components)}>
                    <div className={css(styles.component)}>
                        <Select
                            id="esporte"
                            label={"Esporte:"}
                            // labelStyle={styles.labelStyle}
                            theme={theme}
                            // value={type}
                            value={sports.filter(option => option.value == sport)}
                            isSearchable={false}
                            color="#fff"
                            borderRadius={10}
                            backgroundColor={"#fff"}
                            required={true}
                            onChange={(item) => setSport(item.value)}
                            options={sports}
                        /> 
                    </div>
                   
                </div>
            </Container>
        </ModalMobileFullScreen>
    </div>
}

const styles = {
    text: {
        fontWeight: "bold",
        fontSize: 16
    },
    components: {
        marginTop: 10
    },
    component: {
        paddingTop: 10
    },
    input: {
        border: "0.5px solid #9e9e9e !important",
        boxShadow: "none"
    }
}