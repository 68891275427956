import { Input, Select, InputPassword, ImageUploader, DatePicker} from "../../../../components/Form"
import { css } from "emotion";

export default ({
    email,setEmail,
    password,setPassword,
    confpassword,setConfpassword,
    name,setName,
    type,
    pictureUrl,
    cpf_passport, setCpfPassport,
    validCPFText,
    phone,setPhone,
    cellphone,setCellphone,
    validEmail,
    messageErroCPFCNPJ,
    messageErrorEmail,
    gender, setGender,
    birthdate, setBirthdate,
    regexPassword, messageErroPassword,
    options, optionsGender, changeType,
    onDrop, theme,
    handleCPFPassport, validExistsEmail
}) => {


    return <>
            <div className={css(styles.row)}>
                <ImageUploader
                    buttonText='Minha Imagem'
                    defaultImages={pictureUrl}
                    onDrop={onDrop}
                    backgroundColor={"#fff"}
                    color="#6B69CF"
                    borderRadius={10}
                    // aspect={4/3}
                    // crop={true}
                />
            </div>
            <div className={css(styles.row)}>
                <Select
                        id="type"
                        label={"Minha Nascionalidade*:"}
                        labelStyle={styles.labelStyle}
                        theme={theme}
                        value={type}
                        isSearchable={false}
                        color="#fff"
                        borderRadius={10}
                        backgroundColor={"#fff"}
                        required={true}
                        onChange={changeType}
                        options={options}
                    /> 
            </div>
            <div className={css(styles.row)}>
                <Input
                    inputmode="numeric"
                    id="cpf_passport"
                    radius={true}
                    backgroundColor="#fff"
                    label={type.value == "nativo" ? "CPF*:" : "Passaporte*:"}
                    placeholder={type.value == "nativo" ? "CPF*:" : "Passaporte*:"}
                    mask={type.value == "nativo" ? "999.999.999-99" : ""}
                    pattern={validCPFText}
                    validate={false}
                    classNameInput={validCPFText ? "valid" : ( cpf_passport.length > 0 ? "invalid" : "") }
                    title="CPF está incorreto"
                    color="#fff"
                    value={cpf_passport}
                    required={true}
                    messageError={messageErroCPFCNPJ}
                    onBlur={(e) => handleCPFPassport(e.target.value)}
                    onChange={(e) => setCpfPassport(e.target.value)}
                />
            </div>
            <div className={css(styles.row)}>
                <Input
                    id="email"
                    label={"E-mail*:"}
                    placeholder={"E-mail*:"}
                    value={email}
                    radius={true}
                    backgroundColor="#fff"
                    validate={false}
                    classNameInput={validEmail ? "valid" : ( email.length > 0 ? "invalid" : "") }
                    title={"E-mail incorreto"}
                    color="#fff"
                    required={true}
                    pattern={validEmail}
                    messageError={messageErrorEmail}
                    onBlur={(e) => validExistsEmail(e.target.value)}
                    onChange={(e) => setEmail(e.target.value.toLowerCase())}
                    type="email"
                />
            </div>
            <div className={css(styles.row)}>
                <Input
                    id="name"
                    label={"Nome*:"}
                    placeholder={"Nome*:"}
                    radius={true}
                    backgroundColor="#fff"
                    color="#fff"
                    value={name}
                    maxLength={100}
                    required={true}
                    onChange={(e) => setName(e.target.value)}
                />
            </div>
            <div className={css(styles.row)}>
                <Select
                    id="genero"
                    label={"Gênero*:"}
                    color="#fff"
                    labelStyle={styles.labelStyle}
                    theme={theme}
                    value={type}
                    backgroundColor={"#fff"}
                    borderRadius={10}
                    isSearchable={false}
                    required={true}
                    value={gender}
                    options={optionsGender}
                    onChange={(value) => setGender(value)}
                />
            </div>
            <div className={css(styles.row)}>
                <Input
                    id="cellphone"
                    label={"Celular*:"}
                    placeholder={"Celular*:"}
                    pattern=".{0}|.{15,}"
                    title="O celular está incorreto"
                    color="#fff"
                    value={cellphone}
                    backgroundColor={"#fff"}
                    radius={true}
                    required={true}
                    mask={"(99) 99999-9999"}
                    inputmode="numeric"
                    onChange={(e) => setCellphone(e.target.value)}
                />
            </div>
            <div className={css(styles.row)}>
                <Input
                    id="phone"
                    label={"Telefone:"}
                    placeholder={"Telefone:"}
                    pattern=".{0}|.{14,}"
                    title="O telefone está incorreto"
                    color="#fff"
                    backgroundColor={"#fff"}
                    inputmode="numeric"
                    radius={true}
                    value={phone}
                    mask={phone.length >= 15 ? "(99) 99999-9999" : "(99) 9999-99999"}
                    onChange={(e) => setPhone(e.target.value)}
                />
            </div>
            <div className={css(styles.row)}>
                <DatePicker 
                    color="#fff"
                    label={"Data de Nascimento*:"}
                    maxDate={new Date()}
                    style={styles.dataPicker}
                    backgroundColor={"#fff"}
                    value={birthdate}
                    onChange={setBirthdate}
                />
            </div>
            <div className={css(styles.row)}>
                <InputPassword
                    id="password"
                    placeholder={"Senha*:"}
                    label={"Senha*:"}
                    type={"password"}
                    value={password}
                    backgroundColor={"#fff"}
                    radius={true}
                    color="#fff"
                    coloricon="#6B69CF"
                    pattern={`${regexPassword}`}
                    messageError={!password || password.match(regexPassword) == null && messageErroPassword}
                    title={messageErroPassword}
                    maxLength={30}
                    required={true}
                    onChange={(e) => setPassword(e.target.value)}
                />
            </div>
            <div className={css(styles.row)}>
                <InputPassword
                    id="conf_password"
                    placeholder={"Confirmar Senha*:"}
                    label={"Senha*:"}
                    type={"password"}
                    color="#fff"
                    coloricon="#6B69CF"
                    backgroundColor={"#fff"}
                    radius={true}
                    pattern={`^${password}$`}
                    messageError={!confpassword || confpassword != password && "As senhas devem ser iguais"}
                    title="As senhas devem ser iguais"
                    required={true}
                    value={confpassword}
                    maxLength={30}
                    onChange={(e) => setConfpassword(e.target.value)}
                />
            </div>
            <div className={css(styles.lastComponent)}></div>
        </>
}

const styles = {
    row: {
        paddingRight: 15,
        paddingLeft: 15,
        display: "flex",
        marginTop: 10
    },
    main: {
        backgroundColor: "#6B69CF",
        height: "100%",
        minHeight: "100vh",
        width: "100%",
        zIndex: 0,
        position: "relative"

    },
    header: {
        width: "100%",  
        display: "flex"
    },
    logo: {
        width: 40
    },
    title: {
       fontFamily: "Roboto",
       fontSize: 14,
       color: "#000",
       fontWeight: "bold",
       paddingTop: 10
    },
    flex1: {
        flex: 1,
    },
    labelStyle: {
        color: "#fff",
        width: "100%"
    },
    btnNext: {
        marginTop: 10,
        borderBottomRightRadius: "0px !important",
        borderBottomLeftRadius: "0px !important",
        height: 50,
        position: "fixed",
        bottom: 0
    },
    lastComponent: {
        height: 70
    },
    dataPicker: {
        backgroundColor: "#fff !important",
        borderRadius: "10px !important",
        textIndent: 10
    }
    
}