import React, { useEffect, useReducer } from "react"
import { toast } from "react-toastify"
import MainContext, { actionsMain, initialState, reducer } from "."
import Api from "../../api/Api"
import LoadingImage from "../../components/LoadingImage"

const Component = ({children}) => {

    const [state, dispatch] = useReducer(reducer, initialState)
    
    useEffect(() => {
        const images_upload = [...state.images_upload]
        console.log(images_upload)
        images_upload.forEach((item,i) => {
            if(item.status === "TO_UPLOAD"){
                dispatch({type: actionsMain.UPDATE_STATUS_UPLOAD_IMAGES, payload: {
                    index: i, status: "UPLOADING"
                }})
                Api().post(item.url, item.formData)
                .then((response) => {
                    state.images_upload.forEach((item,i) => {
                        if(response?.config?.data.get(item.type_key) == item.id){
                            dispatch({type: actionsMain.UPDATE_STATUS_UPLOAD_IMAGES, payload: {
                                index: i, status: "SUCCESS", image: response.data.imagem
                            }})
                        }
                    })
                })
                .catch((e) => {
                    const response = e.response ? e.response : {};
                    console.log(e)
                    state.images_upload.forEach((item,i) => {
                        if(response?.config?.data.get(item.type_key) == item.id){
                            dispatch({type: actionsMain.UPDATE_STATUS_UPLOAD_IMAGES, payload: {
                                index: i, status: "ERROR"
                            }})
                        }
                    })
                    toast.error("Ocorreu um problema ao tentar fazer upload das imagens")
                })
            }
        })
    },[state.images_upload])

    return <MainContext.Provider value={{state, dispatch}}>
        {/* <LoadingImage/> */}
        {children}
    </MainContext.Provider>
}

export default Component;