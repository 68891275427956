import React, { useContext } from "react"
import HeaderViewEventoMobile from "../../../../layouts/HeaderViewEventoMobile"
import ApresentacaoEventoContext from "../../ApresentacaoEventoContext"
import { Route, Switch } from "react-router-dom"
import InfoEvento from "./InfoEvento"
import FaleConoscoMobile from "./FaleConoscoMobile"
import CategoriasMobile from "./CategoriasMobile"
export default () => {
    const apresentacaoContext = useContext(ApresentacaoEventoContext)


    return <div>
        <HeaderViewEventoMobile
            picture={apresentacaoContext.state.picture}
        />
            <Switch>
                <Route exact path="/evento/apresentacao/:id" component={props => { return  ( <InfoEvento {...props}/>) } }/>
                <Route exact path="/evento/apresentacao/:id/faleconosco" component={props => { return  ( <FaleConoscoMobile {...props} /> )} }/>
                <Route exact path="/evento/apresentacao/:id/categorias" component={props => { return  ( <CategoriasMobile {...props} /> )} }/>
            </Switch>
    </div>
}

