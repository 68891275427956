export default  {
    CHANGE_PAGE: 'CHANGE_PAGE',
    SET_EVENTS: 'SET_EVENTS',
    SET_JUDGES_ACCESS: 'SET_JUDGES_ACCESS',
    SET_ID_EVENT_TIMER: 'SET_ID_EVENT_TIMER',
    SET_ID_EVENT_ACCESS: 'SET_ID_EVENT_ACCESS',
    HANDLE_NUMBER_KEYBOARD_TIMER: 'HANDLE_NUMBER_KEYBOARD_TIMER',
    START_TIMER: 'START_TIMER',
    ADD_ATH_TIMER: 'ADD_ATH_TIMER',
    SET_ATH_TIMER: 'SET_ATH_TIMER',
    RESET_TIMER: 'RESET_TIMER',
    REMOVE_JUDGE_ACCESS: "REMOVE_JUDGE_ACCESS",
    FORCE_UPDATE_JUDGES_ACCESS: "FORCE_UPDATE_JUDGES_ACCESS",
    SET_LOADING_TABLE_ACCESS: "SET_LOADING_TABLE_ACCESS",
    SET_EVENT: 'SET_EVENT',
    SET_TIMERS: 'SET_TIMERS',
    FORCE_TIMERS: 'FORCE_TIMERS',
    FORCE_STARTS: 'FORCE_STARTS',
    SET_ALL_EVENTS: 'SET_ALL_EVENTS',
    SET_LOADING_ALL_EVENTS: 'SET_LOADING_ALL_EVENTS',
    SET_NAME_EVENT_TIMER: 'SET_NAME_EVENT_TIMER'
}