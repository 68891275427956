import circlePlus from "../../../assets/icons/circle-plus.svg"

export default {
    teams: [{name: "Nova equipe", image: circlePlus}],
    invites: [],
    members: [],
    picture: [],
    pictureUrl: [],
    currentElement: "",
    loadingTeams: false,
    updateTeamsForce: false,
    updateMembersForce: false,  
    updateInvitesForce: false
}