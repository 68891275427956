import React from "react"
import {  useHistory } from "react-router-dom"
import {  CardPanel } from "react-materialize"
import { css } from "emotion"
import Api from "../../api/Api"

const Component =  ({item}) => {

    const history = useHistory()
    const onClick = () => {
        Api().post("torneios/somavisualizacao/"+item.id)
        history.push("torneio/apresentacao/"+item.id)
    }

    return <CardPanel style={styles.card_panel} onClick={onClick}>
            <img src={item.photo} className={css(styles.photoEventCard)}/>
            <div className={css(styles.divInfoEvento)}>
                <span className={css(styles.infoEvento)}>
                    <span className={css(styles.spanNome)}>{item.name}</span>
                </span>
            </div>
            <div className={css(styles.divInfoEvento)}>
                <span className={css(styles.infoEvento)}>
                    <span  className={css(styles.spanEnd)}></span>
                </span>
            </div>
            <div className={css(styles.divInfoEvento)}>
                <span className={css(styles.infoEvento)}>
                    <span  className={css(styles.spanTipo)}></span>
                </span>
            </div>
    </CardPanel>
}

const styles = {
    box: {
        borderRadius: 20,
        height: 275,
        paddingTop: 10,
        marginTop: 10
    },
    card_panel: {
        cursor: "pointer",
        padding: 0,
        height: 275,
        borderRadius: 10,
        display: 'block',
        overflow: "hidden"
    },
    photoEventCard: {
        width: "100%",
        borderRadius: 10,
        height: 125,
        objectFit: "cover",
        display: "block",
    },
    divInfoEvento: {
        whiteSpace: "normal"
    },
    infoEvento: {
        fontSize: 16,
        marginLeft: 5,
        marginTop: 10,
        color: "#6A6A6A",
        display: "flex"
    },
    spanNome: {
        fontSize: 18,
        fontWeight: 500
    },
    spanData: {
        fontWeight: 300,
        fontSize: 16,
    },
    spanEnd:{
        fontWeight: 300,
        fontSize: 16,
    },
    spanTipo: {
        marginTop: 20,
        fontWeight: 300,
        fontSize: 16,
    }
}

export default React.memo(Component)