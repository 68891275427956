import { css } from "emotion"
import React, { forwardRef,useImperativeHandle, useContext, useEffect, useState } from "react"
import { BrowserView, MobileView } from "react-device-detect"
import { Row } from "react-materialize"
import { toast } from "react-toastify"
import Api from "../../api/Api"
import { Button, DatePicker, Input } from "../../components/Form"
import InscricaoContext, { actions } from "./InscricaoContext"

export default forwardRef(({index,cpf,onClose, refSaveAth},ref) => {
    const {dispatch} = useContext(InscricaoContext)

    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [messageErrorEmail, setMessageErrorEmail] = useState("")
    const [validEmail,setValidEmail] = useState("")
    const [oldEmail, setOldEmail] = useState("")
    const [birthdate, setBirthdate] = useState(null)
    // const refFromUseRef = createRef();

    // useEffect(() => {
    //     if(refSaveAth){
    //         refSaveAth.current.onSave = onClickSave
    //     }
    // },[])

    useImperativeHandle(ref, () => ({

        onSaveAth() {
            onClickSave()
        }
    
      }));

    const onClickSave = () => {
        if(!validEmail){
            toast.error("Email inválido")
            return
        } else if(!name || !email || !birthdate){
            toast.error("Todos os campos são obrigatórios")
            return;
        }
        const payload = {
            cpf,name,email,birthdate
        }
        dispatch({type: 
            actions.SET_PRE_ATHLETE_TICKET, 
            index,
            payload
        })
        onClose()

    }

    const validExistsEmail = async (value) => {
        try {  
            if(value != oldEmail){
               setOldEmail(value);      
               const result = await Api().get(`/users/validaemail?email=${value}&tipo=at`)
                if(result.data.msg['Atleta']){
                    toast.error(`O E-MAIL já existe`)
                    setMessageErrorEmail(`O E-MAIL JÁ EXISTE`);
                    setValidEmail("")
                    return false;
                }
                setMessageErrorEmail("")
                setValidEmail(value)
                return true;
            }
         
        } catch (e){
            console.log(e)
            const response = e.response ? e.response : {};
            const data = response.data ? response.data : {};
            const msg = data.msg ? data.msg[0] : "Ocorreu um erro ao tentar validar o E-mail";
            toast.error(msg)
            return false;
        }
    }

    return <div>
        <BrowserView>
            <Row>
                <Input label={"CPF*:"} value={cpf} disabled/>
            </Row>
            <Row>
                <Input label={"Nome*:"} 
                    value={name} 
                    onChange={(e) => setName(e.target.value)}
                />
            </Row>
            <Row>
                <Input 
                    label={"Email*:"} 
                    value={email} 
                    onBlur={(e) => validExistsEmail(e.target.value)}
                    validate={false}
                    classNameInput={validEmail ? "valid" : ( email.length > 0 ? "invalid" : "") }
                    messageError={messageErrorEmail}
                    pattern={validEmail}
                    onChange={(e) => setEmail(e.target.value.toLowerCase())}
                    type={"email"}
                />
            </Row>
            <Row>
                <DatePicker 
                    label={"Data de nascimento*:"} 
                    dateFormat={"dd/MM/yyyy"}
                    mask={"99/99/9999"}
                    value={birthdate}
                    maxDate={new Date()}
                    onChange={(value) => setBirthdate(value)}
                    placeholder="Data de nascimento*:"/>
            </Row>
            <Button className={css(styles.button)} onClick={onClickSave} type={"button"}> Salvar </Button>
        </BrowserView>
        <MobileView>
            <div className={css(styles.container)}>
                <Row>
                    <Input 
                        label={"CPF*:"}

                         value={cpf} 
                         disabled/>
                </Row>
                <Row>
                    <Input 
                        label={"Nome*:"} 
                        value={name} 
                        onChange={(e) => setName(e.target.value)}
                    />
                </Row>
                <Row>
                    <Input 
                        label={"Email*:"} 
                        value={email} 

                        onBlur={(e) => validExistsEmail(e.target.value)}
                        validate={false}
                        classNameInput={validEmail ? "valid" : ( email.length > 0 ? "invalid" : "") }
                        messageError={messageErrorEmail}
                        pattern={validEmail}
                        onChange={(e) => setEmail(e.target.value.toLowerCase())}
                        type={"email"}
                    />
                </Row>
                <Row>
                    <DatePicker 
                        label={"Data de nascimento*:"} 
                        dateFormat={"dd/MM/yyyy"}
                        mask={"99/99/9999"}
                        radius
                        backgroundColor={"#FFFCFC"}
                        className={css(styles.dataPicker)}
                        value={birthdate}
                        maxDate={new Date()}
                        onChange={(value) => setBirthdate(value)}
                        placeholder="Data de nascimento*:"/>
                </Row>
            </div>
        </MobileView>
    
    </div>
})
const styles = {
    container: {
        paddingLeft: 10,
        paddingRight: 10
    },
    button: {
        marginTop: 55
    },
    dataPicker: {
        backgroundColor: "#FFFCFC"
    }
}