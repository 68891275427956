import React, { useContext, useState } from "react"
import { useCallback } from "react";
import { useMemo } from "react";
import { useEffect } from "react";
import { useCookies } from "react-cookie";
import { useHistory, useLocation } from "react-router-dom";
import MainContext, { actionsMain } from "../containers/MainContext";

export default () => {
    const {state, dispatch} = useContext(MainContext)
    const [cookies] = useCookies(['id']); 
    const history = useHistory();


    useEffect(() => {
        if(history.location.state?.id_organizer){
            dispatch({type: actionsMain.SET_ID_ORGANIZER, payload: history.location.state?.id_organizer})
        } else if (state.id_organizer){
            history.replace({
                ...history.location,
                state: {
                  ...history.location.state,
                  id_organizer: state.id_organizer
                }
            });
        } 
    },[])

    const id = useMemo(() => {
        let id_org = cookies.id
        console.log(history.location.state)
        if(history.location.state?.id_organizer){
            id_org = (history.location.state?.id_organizer)
        } else if (state.id_organizer){
            id_org = (state.id_organizer)
        } 

        return id_org
    },[])
    console.log(id)

    return id;
}