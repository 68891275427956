import React,{ useRef } from "react"
import { Row, Col} from "react-materialize"
import {  Select } from "../../../components/Form"
import {  useLocation } from "react-router-dom"
import { css } from "emotion"
import useSports from "../../../hooks/useSports"
import DataTable from "react-data-table-component"
import metalha_ouro from "../../../assets/icons/medalha-ouro.png"
import metalha_prata from "../../../assets/icons/medalha-prata.png"
import metalha_bronze from "../../../assets/icons/medalha-bronze.png"
import medalha_participacao from "../../../assets/icons/medalha-participacao.png"
import ReactToPrint from 'react-to-print';
import ModalInfoAtletaPontos from "../ModalInfoAtletaPontos"
import HeaderViewEvento from "../../../layouts/HeaderViewEvento"
const Evento = ({
    picture,
    name,
    city, 
    state,
    dateStart, 
    openModalInfo,
    setOpenModalInfo,
    loadingPub,
    categoriesOptions,
    modalInfoCronometroId,
    modalInfoCronometroAtleta,
    sportFilter,
    setSportFilter,
    categoryFilter,
    setCategoryFilter,
    handlePub,
    handleOpenInfoAth,
    values
}) => {
   
    const sportsOptions = useSports();

    const iconsPositions = [metalha_ouro,metalha_prata,metalha_bronze,medalha_participacao] 

    const ref_print = useRef(null)

    const {search} = useLocation()
    const query = new URLSearchParams(search)



    const columns = [
        {
            selector: 'icon_posicao',
            cell: ({posicao}) =>  posicao && <img className={css(styles.imgAchievement)} src={iconsPositions[posicao > 4 ? 3 : posicao-1]} />
        },
        {
            selector: 'posicao',
            cell: ({posicao}) =>  (posicao ? posicao : 0) + "º"
        },
        {
            selector: 'pontos',
            cell: ({pontuacoes}) =>  (pontuacoes?.categoria ? pontuacoes?.categoria : 0)  + " pontos"
        },
        {
            selector: 'atleta',
        },
        {
            selector: 'categoria',
        },
        // {
        //     selector: 'sub_categoria',
        // },
        {
            selector: 'tempo',
        },
        {
            selector: 'pontos_totais',
            cell: ({pontuacoes}) =>  (pontuacoes?.total ? pontuacoes?.total : 0) + " pontos"
        },
    ];

    const customStyles = {
        rows: {
            style: {
                marginTop: 30,
                backgroundColor:"#fff",
                boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.15)",
                borderRadius: 9,
                cursor: "pointer"
            }
        },
        table: {
            style: {
                backgroundColor:"transparent",
            }
        }
    };

    let buttons = [ <ReactToPrint
        trigger={() => <button className={css(styles.button)}>Imprimir</button> }
        content={() => ref_print.current}
    />]

    if(query.get('pub') === "0"){
        buttons.push(<button className={css(styles.button, {marginLeft: 5})} loading={loadingPub} onClick={handlePub}>Publicar</button>)
    }

    return <div>
        <div className={css(styles.main)}>
            <HeaderViewEvento 
                buttons={buttons}
                picture={picture} 
                name={name} 
                dateStart={dateStart} 
                city={city} 
                state={state} />
            <div className={css(styles.conteudoEvento)}>
                <div className={css(styles.divResultados)}>
                    <Row>
                        <span>Resultado do evento</span>
                    </Row>
                    <Row className={css(styles.divfiltros)}> 
                        <Col s={6} l={2}>
                            <Select
                                label={"Esporte:"}
                                options={sportsOptions}
                                onChange={(data) => setSportFilter(data.value)}
                                value={sportsOptions.filter(option => option.value == sportFilter)}
                            />
                        </Col>
                        <Col s={6} l={2}>
                            <Select
                                label={"Categorias:"}
                                options={categoriesOptions}
                                onChange={(data) => setCategoryFilter(data.value)}
                                value={categoriesOptions.filter(option => option.value == categoryFilter)}
                            />
                        </Col>
                        <Col s={6} l={2}>
                            {/* <Select
                                label={"Sub-Categorias:"}
                                options={subCategoriesOptions}
                                onChange={(data) => setSubCategoryFilter(data.value)}
                                value={subCategoriesOptions.filter(option => option.value == subCategoryFilter)}
                            /> */}
                        </Col>
                    </Row>
                    <Row>
                        <div ref={ref_print}>
                            <ModalInfoAtletaPontos
                                open={openModalInfo}
                                toggle={setOpenModalInfo}
                                cronometro_id={modalInfoCronometroId}
                                atleta_id={modalInfoCronometroAtleta}
                            />
                            <Col s={12}>
                                <DataTable 
                                    columns={columns}
                                    data={values}
                                    customStyles={customStyles}
                                    noDataComponent={""}
                                    responsive={false}
                                    noHeader
                                    noTableHead
                                    highlightOnHover
                                    onRowClicked={handleOpenInfoAth}
                                />
                            </Col>
                        </div>
                    </Row>
                </div>
               
            </div>
          
        </div>
       
    </div>
    
}

const styles = {
    main: {
        position: "relative",
        backgroundColor: "#F2F2F2",
        minHeight: "100vh"
    },
    imgAchievement: {
        width: 35
    },
    divResultados: {
        marginTop: 10,
        marginLeft: '5%',
        fontFamily: "Roboto",
        "@media(max-width: 992px)": {
            marginLeft: "7%",
            marginRight: "7%",
        },
        "@media(max-width: 700px)": {
            marginLeft: 7,
            marginRight: 7,
        },
    },
    divfiltros: {
        marginLeft: '-1%',
        marginTop: 10
    },
    acoesTable: {
        marginTop: "6%",
        float: "right"
    },
    imgPercurso: {
        width: 200,
        height: 100,
        paddingLeft: 20,
        objectFit: "cover",
        ":hover": {
            width: 220,
            height: 120
        }
    },
    imgPatrocinadores: {
        width: 200,
        height: 100,
        paddingLeft: 20,
        objectFit: "cover",
        cursor: "pointer",
        ":hover": {
            width: 220,
            height: 120
        }
    },
    divDeskView: {
        marginLeft: '8%',
        "@media(max-width: 992px)": {
            marginLeft: "7%",
            marginRight: "7%",
        },
        "@media(max-width: 700px)": {
            marginLeft: 7,
            marginRight: 7,
        },
    },
    maisEventos: {
        marginLeft: '4%',
        paddingBottom: 20,
        "@media(max-width: 992px)": {
            marginLeft: "7%",
            marginRight: "7%",
        },
        "@media(max-width: 700px)": {
            marginLeft: 15,
            marginRight: 15,
        },
    },
    organizador: {
        marginLeft: '4%',
        "@media(max-width: 992px)": {
            marginLeft: "7%",
            marginRight: "7%",
        },
        "@media(max-width: 700px)": {
            marginLeft: 15,
            marginRight: 15,
        },
    },
    percurso: {
        marginLeft: '4%',
        "@media(max-width: 992px)": {
            marginLeft: "7%",
            marginRight: "7%",
        },
        "@media(max-width: 700px)": {
            marginLeft: 15,
            marginRight: 15,
        },
    },
    nome_cat: {
        color: "#606060",
        fontFamily: "Roboto",
        fontSize: 18
    },
    valor_lote: {
        color: "#606060",
        fontFamily: "Roboto",
        fontSize: 14
    },
    parcela_lote: {
        color: "#19BC0B",
        fontFamily: "Roboto",
        fontSize: 14
    },
    labelDescricao: {
        color: "#606060",
        fontFamily: "Roboto",
        fontSize: 20
    },
    meusEventosLabel: {
        color: "#606060",
        fontFamily: "Roboto",
        fontSize: 20
    },
    organizadorLabel: {
        color: "#606060",
        fontFamily: "Roboto",
        fontSize: 20
    },
    percursoLabel: {
        color: "#606060",
        fontFamily: "Roboto",
        fontSize: 20
    },
    titleEvento: {
        "@media(max-width: 992px)": {
            marginLeft: -10,
            textAlign: 'center',
        },
        lineHeight: 1,
        fontWeight: 600,
        fontFamily: "Roboto",
        color: "#606060"
    },
    headerCapa: {
        height: 200,
        width: "100%",
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundColor: "#2F2E41"
    },
    viewImageEvento: {
        position: "absolute",
      //  zIndex: 2,
        top: 80,
        left: "5%",
        width: "70%",
        "@media(max-width: 992px)": {
            left: "0%",
            width: "100%",
        },
    },
    conteudoEvento: {
        marginLeft: 10,
        zIndex: 99,
        position: "relative"
    },
    conteudoEventoTitle: {
        marginLeft: 10,
        position: "absolute",
      //  top: 65,
        left: "40%",
        "@media(max-width: 992px)": {
          //  marginTop: '18vh',
            position: "relative",
            top: 0,
            left: 0,
        },
        
    },
    imgEventoDiv: {
        height: "25vh"
    },
    imgEvento: {
        objectFit: 'cover',
        objectPosition: '0%',
        "@media(max-width: 992px)": {
            objectFit: 'contain',
            objectPosition: 'center center'
        },
    },
    dateStart: {
        color: "#606060",
        fontFamily: "Roboto",
        fontSize: 16,
    },
    dateEnd: {
        color: "#606060",
        fontSize: 16,
        fontFamily: "Roboto",
    },
    dataEndSub: {
        color: "#606060",
        fontFamily: "Roboto",
        fontSize: 14,
    },
    dataEndSubSpan: {
        color: "#606060",
        fontFamily: "Roboto",
        fontSize: 14,
    },
    txtOrg: {
        color: "#606060",
        fontFamily: "Roboto",
        fontSize: 18,
    },
    btnFaleConosco: {
        fontSize: 14, 
        width: "100%"
    },
    btnTodosEsportes: {
        marginTop: 10
    },
    btnEsporte: {
        marginTop: 10,
        overflow: "hidden"
    },
    button: {
        backgroundColor: "transparent",
        border: "1px solid",
        padding: "5px 15px",
        cursor: "pointer",
        borderRadius: 25,
        color: "#fff",
    }
}

export default Evento;