import React, { useState, useEffect, useContext } from "react"
import { Modal } from 'react-responsive-modal';
import { Row, Col } from "react-materialize";
import { Button, Input, Checkbox } from "../../components/Form";
import DataTable from 'react-data-table-component';
import {BarLoader} from "react-spinners";
import { css } from "emotion";
import { toast } from "react-toastify";
import Api from "../../api/Api";
import config from "../../config.js"
import InscricaoContext, { actions } from "./InscricaoContext";
import validaCPF from "../../components/Valid/CPF";
import FormPreCadastro from "./FormPreCadastro";
import { BrowserView, MobileView } from "react-device-detect";
import ModalAtletaBrowser from "./ModalAtletaLayout/ModalAtletaBrowser";
import ModalAtletaMobile from "./ModalAtletaLayout/ModalAtletaMobile";
const Component = (props) => {

    const [search, setSearch] = useState("")
    const [data, setData] = useState([])
    const [athSelected, setAthSelected] = useState({})
    const [loadingTable, setLoadingTable] = useState(false);
    const [loadingBtn, setLoadingBtn] = useState(false);
    const [newAth, setNewAth] = useState(false)
    const [newAthView, setNewAthView] = useState(false)
    const {dispatch} = useContext(InscricaoContext)
    
    useEffect(() => {
        setAthSelected({})
        setData([])
        setSearch("")
    },[])

    const onPressSearch = (e) => {
        getAthletes()
    }

    const getAthletes = async () => {
        if(search){
            if(!validaCPF(search)){
                toast.warn("CPF inválido")
                return;
            }
            try {
                setLoadingTable(true)
                const result = await Api().get("/users/listar/at", {params: {cpf_cnpj: search, limit: 1 }})
                const data = result.data ? result.data : []
                const atletas = data.atletas ? data.atletas : {}
                let rowAtletas = atletas.rows ? atletas.rows : []
              
                rowAtletas = rowAtletas.map((item) => {
                    return {...item, imagem: item.imagem ? config.storage_link+"/users/"+item.imagem : ""} 
                })
                setData(rowAtletas)

                if(rowAtletas.length == 0){
                    setNewAth(true)
                } else {
                    setAthSelected(rowAtletas[0])
                    setNewAth(false)
                }

                setLoadingTable(false)
                console.log(rowAtletas)
            }catch(e){
                setLoadingTable(false)
                toast.error("Ocorreu um erro ao buscar os atletas")
            }
        } 
    }
            


    const onClose = () => {
        props.toggle(false)
    }

    const addAthletes = async () => {
        
        if(!athSelected.id){
            toast.warn("Necessário selecionar um atleta")
            return;
        }

        dispatch({type: 
            actions.SET_ATHLETE_TICKET, 
            index: props.index,
            payload: athSelected
        })
        onClose()
    }

    const onChangeCheckbox = (values) => {
        setAthSelected(values)
    }

    return <>
        <BrowserView>
            <ModalAtletaBrowser
              index={props.index}
              open={props.open}
              athSelected={athSelected}
              onChangeCheckbox={onChangeCheckbox}
              search={search}
              setSearch={setSearch}
              onClose={onClose}
              onPressSearch={onPressSearch}
              loadingTable={loadingTable}
              addAthletes={addAthletes}
              newAth={newAth}
              newAthView={newAthView}
              setNewAthView={setNewAthView}
              data={data}
            />
        </BrowserView>
        <MobileView>
            <ModalAtletaMobile 
                index={props.index}
                open={props.open}
                athSelected={athSelected}
                onChangeCheckbox={onChangeCheckbox}
                search={search}
                setSearch={setSearch}
                onClose={onClose}
                onPressSearch={onPressSearch}
                loadingTable={loadingTable}
                addAthletes={addAthletes}
                newAth={newAth}
                newAthView={newAthView}
                setNewAthView={setNewAthView}
                data={data}
            />
        </MobileView>
    </>
}

const styles={
    withoutAth: {
        textAlign: "center"
    },
    btnSalvar: {
        marginTop: 55
    },
    newEvento : {
        float:"left"
    },
    main: {
        paddingBottom: 20
    },
    photoEvent: {
        width: "100%",
        paddingTop: 20,
        position: "relative"
    },
    modal: {
        modal : {
            width: "80%"
        }
    },
    loadingCss: {
        width: "100%"
    },
    photo: {
        width: 40,
        height: 40,
        borderRadius: 250,
        marginTop: 5,
        marginBottom: 5
    },
    itemTableSpan: {
        fontSize: 14
    },
    iconBtn: {
        cursor:'pointer',
        color:'red !important'
   }
}

export default React.memo(Component);