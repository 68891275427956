import React, { useEffect, useState, useContext, useRef } from "react"
import { css } from "emotion";
import { Button, Select } from "../../../../../components/Form";
import JuizCronometroContext, { actions } from "../../../JuizCronometroContext";
import Api from "../../../../../api/Api";
import config from "../../../../../config.js" 
import { useCookies } from "react-cookie";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { BeatLoader } from "react-spinners";

const Component = ({handleSetEventId}) => {

    const {state} = useContext(JuizCronometroContext)
    const [eventUploadSelected, setEventUploadSelected] = useState("")
    const [listEvents, setListEvents] = useState([])
    const [loadingUpload, setLoadingUpload] = useState(false)
    const inputFile = useRef(null) 
    const [cookies] = useCookies(['id'])

    const history = useHistory()


    useEffect(() => {
        if(state.events){
            const events = state.allEvents.map((item) => {
                return {value: item.id, label: item.nome}
            })
            setListEvents(events)
        }
    }, [state.events])

    const upload = () => {
        if(!eventUploadSelected){
            toast.warn("Selecione o evento antes de fazer o upload")
            return;
        }
        inputFile.current.click();
    }

    const handleReview = () => {
        if(!eventUploadSelected){
            toast.warn("Selecione o evento antes de abrir a review")
            return;
        }

        history.push(`/evento/apresentacao/${eventUploadSelected}/resultado_review?pub=0`)
    }

    const fileHandle = async (event) => {
        if(event.target.files[0]){
            const file = (event.target.files[0])
            setLoadingUpload(true)
            try {
                let formData = new FormData();
                formData.append("evento_id",eventUploadSelected)
                formData.append("user_id_finalizou",cookies.id)
                formData.append("file",file)
                const result = await Api().post(config.api+"/importador/ranking",formData)
                if(!result.data.error){
                    toast.success("Planilha carregada com sucesso")
                }
            } catch(e){
                console.log(e)
                const response = e.response ? e.response : {};
                const data = response.data ? response.data : {};
                const msg = data.msg ? data.msg[0] : "Ocorreu um problema";
                toast.error(msg)
            }
            setLoadingUpload(false)
            inputFile.current.value = ""
        }
    }
    
    const download = () => {
        if(!eventUploadSelected){
            toast.warn("Selecione o evento antes de fazer o download")
            return;
        }
        window.open(config.url+"/exportar/ranking/"+eventUploadSelected,"_blank")
    }

    return <div>
        <div className={css(styles.containers)}>
            <div className={css(styles.itemContainer)}>
                <span className={css(styles.titleContainer)}>Ferramenta de Cronômetro</span>
                <div className={css(styles.box)}>
                    <div className={css(styles.tittleBox)}>
                        <span>
                            Provas disponiveis para cronometragem
                        </span>
                    </div>
                    <div className={css(styles.contentBox)}>
                        {state.loadingAllEvents && <BeatLoader  color={"#110F62"} size={25}/>}
                        {state.events.map((item,i) => 
                            <Button key={i} className={css(styles.btnBox)} onClick={() => handleSetEventId(item.id)}>{item.nome}</Button>
                        )}
                    </div>
                </div>
            </div>
            <div className={css(styles.itemContainer)}>
                <span className={css(styles.titleContainer)}>Carregar resultado</span>
                <div className={css(styles.box)}>
                    <div className={css(styles.tittleBox)}>
                        <span>
                            Carregar resultado
                        </span>
                    </div>
                    <div className={css(styles.contentBox)}>
                        <div className={css(styles.divSelect)}>
                            <Select
                                label={"Evento*:"}
                                options={listEvents}
                                value={listEvents.filter(option => option.value == eventUploadSelected)}
                               
                                onChange={(data) => setEventUploadSelected(data.value)}
                            />
                        </div>
                        <div className={css(styles.subTextResults)}>
                            <span>Download</span>
                        </div>
                        <Button disabled={!eventUploadSelected} className={css(styles.btnBox)} onClick={download}>Planilha modelo</Button>
                        <div className={css(styles.subTextResults)}>
                            <span>Upload</span>
                            <input type='file' id='file' ref={inputFile} onChange={fileHandle} style={{display: 'none'}}/>
                        </div>
                        <Button disabled={!eventUploadSelected} loading={loadingUpload} className={css(styles.btnBox)} onClick={upload}>Planilha Preenchida</Button>
                        <Button disabled={!eventUploadSelected} className={css(styles.btnBox)} onClick={handleReview}>Revisão do evento</Button>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

const styles = {
    divSelect: {
        width: "100%"
    },
    containers: {
        display: 'flex',
        flexWrap: "wrap"
    },
    itemContainer: {
        marginBottom: 50,
        flex: "50%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        "@media(max-width: 992px)": {
            flex: "100%",
        },
    },
    titleContainer: {
        fontFamily: "Roboto",
        color: "#606060",
        fontSize: 22,
        fontWeight: 500,
        textAlign: "center"
    },
    box: {
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        border: "1px solid #6B69CF",
        marginTop: 20,
        height: "100%",
        width: "80%",
        borderRadius: 9,
        display: "flex",
        alignItems: "center",
        flexDirection: "column"
    },
    tittleBox: {
        fontFamily: "Roboto",
        fontSize: 17,
        textAlign: "center",
        color: "#C4C4C4",
        border: "0.3px solid #FFFFFF"
    },
    contentBox: {
        width: "100%",
        marginTop: 20,
        minHeight: 70,
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        paddingRight: 20,
        paddingLeft: 20,

    },
    btnBox: {
        marginBottom: 10,
        lineHeight: 1.7,
        height: "auto",
        minHeight: 35,
        fontSize: 12,
        justifyContent: "center",
        alignItems: "center",
        display: "flex"
      
    },
    subTextResults: {
        fontFamily: "Roboto",
        alignSelf: "start",
        fontSize: 14,
        marginTop: 5,
        color: "#C4C4C4"
    }
}

export default React.memo(Component);