import React, {  useEffect, useReducer, useRef } from "react"
import { Row } from "react-materialize"
import {
     useHistory 
} from "react-router-dom";
import { toast } from 'react-toastify';
import {FormEventoProvider, reducer, initialState, actions} from "./FormEventoContext"
import moment from "moment"
import Api from "../../api/Api"
import config from "../../config.js"
import { useContext } from "react";
import LoadingOverlayContext from "../../components/LoadingOverlayContext/LoadingOverlayContext"
import {DadosEvento, AdicionarTorneio, AdicionarCategoria, Patrocinadores, RegraPontuacao,DadosPagamento,  Cronometragem} from "./CadastroEventoSteps";
import { css } from "emotion";
import TabMenuCard from "../../components/TabMenuCard";
import useSports from "../../hooks/useSports";
import MainContext, {actionsMain} from "../MainContext";
import useIdOrganizer from "../../hooks/useIdOrganizer"

export default (props) => {

    const pages = ["Dados do Evento", "Adicionar Torneio","Adicionar Categoria","Regra Pontuação","Patrocinadores", "Dados Pagamento", "Cronometragem"]
    const loadingOverlay = useContext(LoadingOverlayContext)
    const contextMain = useContext(MainContext)
    const [state, dispatch] = useReducer(reducer, initialState)
    const user_id = useIdOrganizer();
    const ref_pub = useRef(false) 
    const ref_loading_btn = useRef("pub")
    const ref_form = useRef(null)
    const sportList = useSports()
    
    const history = useHistory()

    useEffect(() => {
        dispatch({type: actions.SPORT_LIST_UPDATE, payload: sportList})
    },[sportList])

    useEffect(() => {
       (async function getDataEvento(){
            try {
                let id = props.match.params.id
                if(id){
                    loadingOverlay.activeLoading(true,"Buscando conteúdo")
                    dispatch({type: actions.LOADING_PUB, payload: true})
                    const result = await Api().get(`eventos/getinfo/${id}`)
                    
                    if(result.data.info){
                        const data = result.data.info;
                        const categorias = (data.categorias) ? data.categorias : []
                        const torneios = (data.torneios) ? data.torneios : []
                        const patrocinadores = (data.patrocinadores) ? data.patrocinadores : []

                        let categoriesInternal = {}
    
                        categorias.map(async (cat) => {  
                            categoriesInternal[cat.id] = cat;
                            
                            categoriesInternal[cat.id].description = cat.descricao ? cat.descricao : "";
                            categoriesInternal[cat.id].torneio_id = cat.origin_torneio;
                            categoriesInternal[cat.id].pictureUrl = cat.imagem ?  [config.storage_link+`/categoria/${cat.imagem}`] : null
                            categoriesInternal[cat.id].esporte = cat.esporte.id
                            let lotes = {}
                           
                            cat.lote.map((lot,index) => {
                               const dataInicio = moment(lot.data_inicio_lote).toDate()
                               const dataFim = moment(lot.data_termino_lote).toDate()
                                lotes[lot.id] = { 
                                    id: lot.id, 
                                    index, 
                                    preco: lot.valor_lote, 
                                    dataInicio,
                                    dataFim
                                }
                            })
                            categoriesInternal[cat.id].lote = lotes
                        })
                       
                        let torneiosInternal = {}

                        torneios.map(async (tor) => {
                            torneiosInternal[tor.id] = tor;
                            torneiosInternal[tor.id].name = tor.nome;
                            torneiosInternal[tor.id].edition = tor.edicao;
                            torneiosInternal[tor.id].photo = tor.imagem ? config.storage_link+`/torneio/${tor.id}/${tor.imagem}` : ""
                        })
    
                        let percurso = [];
                        data.percurso.map((per) => {
                            percurso.push(config.storage_link+`/evento/${data.id}/percurso/${per.nome}`)
                        })

                        let patrocinadoresInternal = []
                        patrocinadores.map((pat) => {
                            console.log(pat)
                            const patrocinador = {
                                id : pat.id,
                                link : pat.nome,
                                pictureUrl : pat.imagem ? [config.storage_link+`/patrocinador/${pat.imagem}`] : []

                            }
                            patrocinadoresInternal.push(patrocinador)
                        })

                        const payload = {
                            idEvent: data.id,
                            name: data.nome,
                            block: data.block,
                            city: data.cidade,
                            startEvent: moment(data.data_inicio).toDate(),
                            endEvent: moment(data.data_termino).toDate(),
                            pictureUrl: [config.storage_link+`/evento/${data.id}/${data.imagem}`],
                            description: data.descricao,
                            street: data.logradouro,
                            number: data.numero,
                            neighborhood: data.bairro,
                            complement: data.complement,
                            site: data.site_organizador ? data.site_organizador : "",
                            phone: data.telefone ? data.telefone : "",
                            state: data.uf,
                            zipCode: data.cep,
                            ativo: data.ativo,
                            tournaments: torneiosInternal,
                            categories: categoriesInternal,
                            patrocinadores: patrocinadoresInternal,
                            pictureRouteUrl: percurso,
                            cronometro: data.cronometro,
                            trans_nome: data.trans_nome,
                            trans_cpf_cnpj: data.trans_cpf_cnpj,
                            trans_banco: data.trans_banco,
                            trans_agencia: data.trans_agencia,
                            trans_conta: data.trans_conta,
                            hasRule:  data.regras_pontuacao.length > 0 ? "1" : "0"
                        }

                        ref_pub.current = data.publicado
    
                        const regras_pontuacao = data.regras_pontuacao.map((item) => {
                            return {...item, regras: item.regras ? JSON.parse(item.regras) : []}
                        })
                        dispatch({type: actions.SET_SCORING_RULES, payload: regras_pontuacao})
                        dispatch({type: actions.START_INFO_EVENT, payload})
                        dispatch({type: actions.LOADING_PUB, payload: false})
                    }
                    loadingOverlay.activeLoading(false)

                }
            }
            catch(e) {
                const response = e.response ? e.response : {};
                const data = response.data ? response.data : {};
                const msg = data.msg ? data.msg[0] : "Ocorreu um problema";
                toast.error(msg)
                loadingOverlay.activeLoading(false)
            }
           
        })()

    },[])
 
    const bestCopyEver = (src) => {
        return JSON.parse( JSON.stringify( src ) )
    }


    const onSubmit = async (e) => {
        e.preventDefault()
        if(state.preventSubmit){return}

        if(!state.pictureUrl[0]){
            toast.error('É necessario inserir uma foto para o evento.')
            return;
        }
        dispatch({type: actions.LOADING_PUB, payload: true})

        var formData = new FormData();
        formData.append("ativo",state.ativo)
        formData.append("nome",state.name)
        formData.append("data_inicio",moment(state.startEvent).format("DD/MM/YYYY HH:mm"))
        formData.append("data_termino",moment(state.endEvent).format("DD/MM/YYYY HH:mm"))
        formData.append("descricao",state.description)
        formData.append("site_organizador",state.site)
        formData.append("telefone",state.phone ? state.phone.replace(/\(|\)|\s|-/g,"") : "")
        formData.append("cep",state.zipCode)
        formData.append("logradouro",state.street)
        formData.append("bairro",state.neighborhood)
        formData.append("cidade",state.city)
        formData.append("numero",state.number)
        formData.append("uf",state.state)
        formData.append("complemento",state.complement)
        formData.append("trans_nome",state.trans_nome ? state.trans_nome : "")
        formData.append("trans_cpf_cnpj",state.trans_cpf_cnpj ? state.trans_cpf_cnpj : "")
        formData.append("trans_banco",state.trans_banco ? state.trans_banco : "")
        formData.append("trans_agencia",state.trans_agencia ? state.trans_agencia : "")
        formData.append("trans_conta",state.trans_conta ? state.trans_conta : "")
        formData.append("user_id",user_id)

        if(ref_pub.current !== null){
            formData.append("publicado",ref_pub.current)
        }
        
        // formData.append("faixa_idade", "")

        let categoriesCopy = bestCopyEver(state.categories)
        let categoriesInternal = Object.values(categoriesCopy)
        let torneios = (state.tournaments)  ? Object.keys(state.tournaments) : []
        let regras = state.scoringRules;

        const onlyNewEvent = !state.idEvent
        if(onlyNewEvent){
            formData.append("cronometro",state.cronometro)
            categoriesInternal.map((value,i) => {
                categoriesInternal[i] = value;
                categoriesInternal[i].fk_categoria_id = value.id
                categoriesInternal[i].esporte = value.esporte
                categoriesInternal[i].ativo = true
                categoriesInternal[i].gratuito = value.gratuito
                categoriesInternal[i].descricao = value.description
                categoriesInternal[i].faixa_idade = value.faixa_idade
                categoriesInternal[i].origin_torneio = value.torneio_id ? parseInt(value.torneio_id) : null 
                
                if(value.lote){
                    const lotes =  Object.values(value.lote).filter((lot) => {
                        return (lot.preco > 0 && !isNaN(lot.preco)) || value.gratuito
                    })
                   
                    categoriesInternal[i].lote = lotes.map((lot) => {
                        return {...lot, dataInicio: moment(lot.dataInicio).format("DD/MM/YYYY"), dataFim: moment(lot.dataFim).format("DD/MM/YYYY")}
                    })
                }else {
                    categoriesInternal[i].lote = []
                }
               
                delete categoriesInternal[i].picture
                delete categoriesInternal[i].pictureUrl
                formData.append(`categorias[${i}]`,  JSON.stringify(categoriesInternal[i]))
            })    
        }else {
            categoriesInternal.map((value,i) => {
                formData.append(`categorias[${i}]`,value.id)
            })  
        }

        if(torneios.length > 0){
            torneios.map((torneio,i) => {
                formData.append(`torneios[${i}]`,torneio)
        })
        }else {
            formData.append(`torneios[]`,"")
        }

       // if(onlyNewEvent){
        if(state.hasRule === "1" && regras.length > 0){
            regras.map((regra,i) => {
                formData.append(`regras[${i}]`,JSON.stringify(regra))
            })
        }
        //}
       


        if(state.patrocinadores.length > 0){
            state.patrocinadores.map((item,i) => {
                let patrocinador = []
                if(item.newId){
                    patrocinador = {fk_patrocinador_id: item.fk_patrocinador_id, nome: item.link}    
                }else {
                    patrocinador = {id: item.id, nome: item.link}    
                }

                formData.append(`patrocinadores[${i}]`, JSON.stringify(patrocinador))

            })
        }
        if(state.patrocinadores_del.length > 0){
            state.patrocinadores_del.map((item,i) => {
                formData.append(`patrocinadore_del[${i}]`, item)
            })
        }        

        if(onlyNewEvent){
            salvarApi(formData)
            ref_pub.current = false
        }else{
            alterarApi(formData)
        }
           
           
     
    }

    const salvarApi = async (formData) => {
        try {
            const result = await Api().post("eventos/salvar",formData)

            if(result.data.msg && result.data.msg.evento_id){
                toast.success("Evento criado com sucesso!")
                toast.success("A imagem do evento será atualizada posteriormente")
                history.replace("/eventos")
               
                uploadImageEvento(result.data.msg.evento_id)
                if(result.data.msg && result.data.msg.patrocinadores){
                    uploadImagePatrocinadoresEvent(result.data.msg.patrocinadores)
                }
                if(result.data.msg.categoria_id){
                    uploadImageCategoryEvent(state.categories,result.data.msg.categoria_id)
                }
            }else {
                toast.error("Ocorreu um problema ao tentar salvar")
            }
            dispatch({type: actions.LOADING_PUB, payload: false})
        }catch(e){
            console.log(e)
            dispatch({type: actions.LOADING_PUB, payload: false})
            const response = e.response ? e.response : {};
            const data = response.data ? response.data : {};
            const msg = data.msg ? data.msg[0] : "Ocorreu um problema ao tentar salvar";
            toast.error(msg)
        }
    }

    const alterarApi = async (formData) => {
        try {
            console.log("Chama duas vezes")
            const result= await Api().post("eventos/editar/"+state.idEvent,formData)


            if(!result.error){
              
                toast.success("Evento alterado com sucesso!")
                history.replace("/eventos")
                uploadImageEvento(state.idEvent)
                if(result.data.msg && result.data.msg.patrocinadores){
                    uploadImagePatrocinadoresEvent(result.data.msg.patrocinadores)
                }

            }else {
                toast.error("Ocorreu um problema ao tentar salvar")
            }
            dispatch({type: actions.LOADING_PUB, payload: false})
        }
        catch(e){
            console.log(e)
            dispatch({type: actions.LOADING_PUB, payload: false})
            const response = e.response ? e.response : {};
            const data = response.data ? response.data : {};
            const msg = data.msg ? data.msg[0] : "Ocorreu um problema ao tentar salvar";
            toast.error(msg)
        }
    }

    const uploadImageEvento = async (id) => {
        
        let formData = new FormData();
        if(state.picture  && state.picture.length > 0){
            formData.append("imagem_evento",state.picture[0])
        }
        if(state.pictureRoute){
            state.pictureRoute.map((pic,i) => {
                formData.append(`imagem_percurso[${i}]`,pic)
            })
        }
        
        formData.append("evento_id",id)
        
        if((state.picture && state.picture.length > 0) || (state.pictureRoute && state.pictureRoute.length > 0)){
            contextMain.dispatch({
                type: actionsMain.ADD_UPLOAD_IMAGES, payload: {
                formData, 
                id,
                url: "eventos/uploadimagens",
                type_key: "evento_id"
            }})
        }

    }
    const uploadImageCategoryEvent = (categoriesFromCadState,categoriesFromResult) => {
       
    
        return Object.keys(categoriesFromResult).map((key) => {
            if(categoriesFromCadState[key].picture){
                let formData = new FormData();
                formData.append("categoria_id",Object.keys(categoriesFromResult[key])[0])
                formData.append("file",categoriesFromCadState[key].picture)
                contextMain.dispatch({type: actionsMain.ADD_UPLOAD_IMAGES,payload: {
                    formData, id: Object.keys(categoriesFromResult[key])[0],
                    url: "categorias/uploadimagens",
                    type_key: "categoria_id"
                }})
            }
        })

        
    }

    const uploadImagePatrocinadoresEvent = async (patrocinadoresFromResult) => {
        
        return Object.keys(patrocinadoresFromResult).map(async (key) => {
            const patrocinador = state.patrocinadores.find(element => element.id == key)
            if(patrocinador.picture && patrocinador.picture[0]){
                let formData = new FormData();
                formData.append("patrocinador_id",(patrocinadoresFromResult[key]))
                formData.append("file",patrocinador.picture[0])
                contextMain.dispatch({type: actionsMain.ADD_UPLOAD_IMAGES,payload: {
                    formData, id: patrocinadoresFromResult[key],
                    url: "patrocinadores/uploadimagens",
                    type_key: "patrocinador_id"
                }})
            }
        })

        
    }

    useEffect(() => {
        if(state.endEvent && state.startEvent && state.startEvent > state.endEvent){
            
            dispatch({type: actions.CHANGE_END_DATE, payload: null})

         }
    },[state.endEvent,state.startEvent])

    
    
    return <FormEventoProvider 
    value={{state, dispatch,ref_pub, ref_form, ref_loading_btn}}
    >
    <form  id="evento" type="POST" 
        ref={ref_form}
        onSubmit={onSubmit}  
        role="form">
        <div style={styles.main}>
            <Row>
                <label className={css(styles.title)}>Cadastro novo Evento</label>
            </Row>
            <TabMenuCard 
                autoOnLoadPage={true}
                numberStepsPerRowMobile={2}
                numberStepsPerRow={4}
                pages={pages}
                currentPage={state.currentPage}
                onChange={(page) => dispatch({type: actions.CHANGE_PAGE, payload: page})}
            >
                <DadosEvento/>
                <AdicionarTorneio/>
                <AdicionarCategoria/>
                <RegraPontuacao />
                <Patrocinadores />
                <DadosPagamento/>
                <Cronometragem />
            </TabMenuCard>
        </div>
    </form> 
</FormEventoProvider>
}

const styles={
    title: {
        fontFamily: "Roboto",
        fontSize: 16,
        color: "#000",
        fontWeight: "normal"
    },
    newEvento : {
        float:"left"
    },
    main: {
        paddingBottom: 20
    },
    labelImg:{
        color: "#000",
    },
    messageInfo: {
        color: "#000",
        fontWeight: 700,
        fontSize: 16
    },
    photoEvent: {
        width: "100%",
        paddingTop: 20,
        position: "relative"
    }
}