import React, { useState, useEffect } from "react"
import M from "materialize-css/dist/js/materialize.min.js";
import { css } from 'emotion'
import Footer from "./Footer";
import MenuHomeAtleta from "./MenuHomeAtleta";
import { BrowserView } from "react-device-detect";

export default (props) => {
    const [subMenu, setSubMenu] = useState(false)
    
    useEffect(() => {
         var elems = document.querySelectorAll('.sidenav');

        M.Sidenav.init(elems, {edge: 'right',});
        var elems = document.querySelectorAll('.tooltipped');
        M.Tooltip.init(elems, {});
    },[])

    const hideMenu = () => {
        if(subMenu){
            setSubMenu(false)
        }
    }

    return   <div className={css(styles.main)}>
    <MenuHomeAtleta/>
  
    <div style={styles.background}>
        <div className={css(styles.container)}  onClick={hideMenu}>
            {props.children}
        </div>
    </div>
    <BrowserView>
        <Footer/>
    </BrowserView>
  </div>
}

const styles = {
    main: {
        position: "relative",
        minHeight: "100%",
        backgroundColor: "#F2F2F2",
        "@media(max-width: 992px)": {
            backgroundColor: "#fff",
        },
    },
    container: {
        paddingBottom: 290
    },
    title: {
        fontFamily: "Roboto",
        marginLeft: 80,
        fontSize: 22,
        fontWeight: "normal"
    },
    itemMenuRight: {
        marginRight: 30,
        marginTop: 10,
        lineHeight: "normal !important",
        textAlign: "center"
    },
    navWrapper: {
        backgroundColor: "#6B69CF !important",
        boxShadow: "none"
    },
    logo : {
        marginTop: -10,
        borderRadius: 25,
    },
    perfil : {
        borderRadius: 20,
        width: 50,
        height: 50,
        marginRight: 5,
        marginTop: 5
    },
    background: {
        backgroundSize: "cover"
    },
    menu :{
        display: "inline",
        position: "absolute",
        right: 60,
        bottom: 5,
        top: 5,
        border: "1.5px solid"
    },
    spanMenu: {
        paddingLeft: 10,
        paddingRight: 10,
        position: "relative",
        top: -6,
    },
    name :{
        paddingRight: 5,
        display: "inline",
        position: "absolute",
        right: 60
    },
    divPerfil: {
        display: "inline"
    },

    subMenu: {
        color: "rgb(4, 122, 200)",
        top: 58,
        zindex: 100,
        position: "absolute",
        right: 1,
        width: 200,
        borderRadius: 10,
        paddingTop: 10,
        paddingBottom: 20,
        paddingLeft: 30,
        borderColor: "rgb(4, 122, 178)",
        backgroundColor: "rgb(255, 255, 255)",
        border: "0.75px solid"
    },
    sideMenu: {
        width: 180,
      
    },
    itemMenu: {
        marginTop: 3,
        paddingLeft: 5
    },
    itemMenuText: {
        fontSize: 20,
        fontWeight: 500
    },
    li: {
        color: "#000",
        width:"100%",
        height: 30,
        marginTop: 10,
        lineHeight: "30px"
    },
    icon: {
        verticalAlign: "bottom"
    },
    linkMenu: {
        color: "#000"
    }
}