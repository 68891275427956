import React, {  useState } from "react"
import { css } from "emotion";
import { ButtonClean } from "../../../components/Form";
import DataTable from 'react-data-table-component';
import ModalAtletas from "../ModalAtletas";
import {BarLoader} from "react-spinners";
import ModalDelete from "../../../components/ModalDelete";
import { useCookies } from "react-cookie";

 const Component = ({
    sameCoach,
    setAtleDelete,
    members,
    coaches,
    openModalDeleteAtl,
    setOpenModalDeleteAtl,
    handleDelEquipe,
    onDelete,
    handleEditEquipe,
    loadingTable
 }) => {
     
    const [cookies] = useCookies(['id'])
    const [openModalAtletas, setOpenModalAtletas] = useState()
    const [openModalDeleteTeam, setOpenModalDeleteTeam] = useState(false)

    console.log(coaches)
    const handleAddAtleta = () => {
        setOpenModalAtletas(!openModalAtletas)
    }

    const customStyles = {
        rows: {
            style: {
                marginTop: 30,
                backgroundColor:"#fff",
                boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.15)",
                borderRadius: 9
            }
        },
        table: {
            style: {
                backgroundColor:"transparent",
            }
        }
    };

    const columns = [
        {
            selector: 'image',
            sortable: true,
            cell: row => <>{row.image && <img src={row.image} className={css(styles.imageMember)}/>}</>
        },
        {
            selector: 'name',
            sortable: true,
        },
        {
            selector: 'categoria_tipo',
            sortable: true,
        },
        {
            selector: 'categoria',
            sortable: true,
        },
        {
            selector: 'pontos',
            sortable: true,
            cell: row =>  row.pontos + " pontos"
        },
        {
            selector: 'acoes',
            sortable: true,
            cell: row =>  
            <>
           {!row.aceite && <span  className={"material-icons modal-trigger "+css(styles.farol)}>error_outline</span>}
           {sameCoach && <span href="#modal1" id={row.id} onClick={ () => { setAtleDelete(row.user_id); setOpenModalDeleteAtl(true) }} 
            className={"material-icons modal-trigger "+css(styles.iconDelete)}>delete</span>}
            </>
        },
    ];

    return <div className={css(styles.container)}>
        <div className={css(styles.title)}>
            <span className={css(styles.titleSpan)}>
                Membros da equipe
            </span>
            <div className={css(styles.btns)}>
                {sameCoach && <><ButtonClean icon={"plus"} onClick={handleAddAtleta} className={css(styles.itemBtnTitle)}>
                    Adicionar atleta
                </ButtonClean>
                <ButtonClean icon={"edit"} onClick={handleEditEquipe} className={css(styles.itemBtnTitle)}>
                    Editar
                </ButtonClean>
                <ButtonClean icon={"del"} onClick={() => setOpenModalDeleteTeam(true)} className={css(styles.itemBtnTitle)}>
                    Excluir
                </ButtonClean></>}
            </div>
        </div>
        <div className={css(styles.subContainer)}>
            {coaches.map((coach) => <div className={css(styles.containerLine)}>
                {coach.image && <img src={coach.image} className={css(styles.imageCoach)}/>}
                <div className={css(styles.line)}>
                    <div className={css(styles.itemLine)}>
                        <div>
                            <div>
                                <span className={css(styles.titleLine)}>Sampa Canoe</span>
                            </div>
                            <div>
                                <span  className={css(styles.sportLine)}>Remo</span>
                            </div>
                        </div>
                    </div>
                    <div className={css(styles.itemLine,styles.coachItemLine)}>
                        <div>
                            <span className={css(styles.titleLine)}>Tecnico: {coach.name}</span>
                        </div>
                    </div>
                    <div className={css(styles.itemLine,styles.pointsItemLine)}>
                        <div>
                            <span className={css(styles.titleLine)}>Pontos {coach.pontos}</span>
                        </div>
                    </div>
                </div>
            </div>)}
           
            <DataTable
                progressComponent={<BarLoader css={styles.loadingCss} color={"#6B69CF"}/>}
                progressPending={loadingTable}
                data={Object.values(members).filter((item) => !item.tecnico && (item.aceite || (!item.aceite && coaches[0]?.user_id == cookies.id)))}
                customStyles={customStyles}
                columns={columns}
                noDataComponent={""}
                noHeader
                noTableHead
            />

            <ModalAtletas open={openModalAtletas} toggle={setOpenModalAtletas}/>
            <ModalDelete 
                open={openModalDeleteAtl}
                onDelete={onDelete}
                onClose={() => setOpenModalDeleteAtl(false)}>
                    Você realmente deseja excluir esse membro?
            </ModalDelete>

            <ModalDelete 
                open={openModalDeleteTeam}
                onDelete={handleDelEquipe}
                onClose={() => setOpenModalDeleteTeam(false)}>
                    Você realmente deseja excluir essa equipe?
            </ModalDelete>
        </div>
           
    </div>
 }

 const styles = {
    farol: {
        cursor:'pointer',
        color:'yellow'
    },
    loadingCss: {
        width: "100%"
    },
    imageCoach: {
       marginRight: 30,
       width: 75,
       height: 75,
       borderRadius: "50%",
     //  flex: 1
    },
    containerLine: {
        display: 'flex',
    },
    line: {
        flex: 9,
        width: "100%",
        alignSelf: "center",
        height: 64,
        backgroundColor: "#fff",
        boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.15)",
        borderRadius: 9,
        overflow: "overlay",
        flexWrap: "wrap",
        display: 'flex',
        position: "relative"
    },
    itemLine:{
        flex: 1,
        alignSelf: "center",
        display: "flex",
        marginRight: 20,
        marginLeft: 20
    },
    coachItemLine: {
        justifyContent: "center"
    },
    pointsItemLine: {
        justifyContent: "flex-end"
    },
    iconDelete: {
        cursor:'pointer',
        color:'red'
    },
    title: {
        paddingTop: 30,
        display: "flex"
    },
    subContainer: {
        marginTop: 30
    },
    btns: {
        float: "right"
    },
    titleSpan: {
        fontFamily: "Roboto",
        fontSize: 26,
        flex: 1
    },
    itemBtnTitle: {
        marginLeft: 40
    },
    container:{
        width: "70%",
        "@media(max-width: 1205px)": {
            width: "100%",
        }
    },
    imageMember: {
        width: 35,
        height: 35,
        borderRadius: "50%"
    },
    titleLine: {
        fontSize: 18,
        fontFamily: "Roboto"
    },
    sportLine: {
        fontSize: 14,
        fontFamily: "Roboto"
    }
 }

 export default  React.memo(Component);