import React, { useContext } from "react"

import { Cronometro, ListEventsUpload } from "./CronometroScreens";
import JuizCronometroContext from "../JuizCronometroContext";

const Component = () => {

    const {state} = useContext(JuizCronometroContext)

    return <div>
        {state.id_event_timer === 0 && <ListEventsUpload/>}
        {state.id_event_timer !== 0 && <Cronometro/>}
    </div>
}
export default Component;