import React from "react"
import { Row, Col } from 'react-materialize';
import {Button, Input} from "../../../components/Form"
import { css } from 'emotion'
import background from "../../../assets/img/background-login-mobile.jpg"
import logo from "../../../assets/img/logo.png"

const EsqueceuSenha = ({
    email, setEmail, loading, refForm, onSubmit
}) => {

    return <div>
        <img src={background} alt="" className={css(styles.background)}/>
        <div className={css(styles.divLogo)+" center-align"}>
            <div>
                <img src={logo} className={css(styles.logo)} width={30} />
            </div>
            <span className={css(styles.labelTitle)}>DPAZZ</span>
        </div>
        <p className={css(styles.text)+ " text-darken-4 center-align"}>Recuperação de Senha</p>
        <form ref={refForm} className={css(styles.formDiv)} onSubmit={onSubmit}>
            <div>
                <Input 
                    id={"email"}
                    type={"email"}
                    data-testid={"email"}
                    backgroundColor="#fff"
                    required={true}
                    value={email}
                    color="#fff"
                    label={"E-mail"}
                    title={"E-mail incorreto"}
                    onChange={(e) => setEmail(e.target.value.toLowerCase())}
                    placeholder={"e-mail"}
                    radius={true}
                />
            </div>
            <div className={css(styles.line)}>
                <Button loadingColor={"#fff"} data-testid={"btnLogin"} loading={loading} backgroundColor={"#4A0997"}>Enviar</Button>
            </div>
        </form>
    </div>
}

const styles =  {
    main: {
        width: "100%",
    },
    text:{
        fontFamily: "Roboto",
        color: "#fff",
        paddingRight: 20,
        paddingLeft: 20,
        fontSize: 18,
        paddingTop: 10
    },
    background: {
        zIndex: -1,
        position: "fixed",
        maxWidth: "100%",
        height: "100vh",
        objectFit: "cover",
        top: 0,
        left: 0,
    },
    line: {
        marginTop: 15,
        marginBottom: 15
    },
    divLogo: {
        marginTop: 10,
        width: "100%",
    },
    logo: {
        width: 50, 
        textAlign:"center" 
    },
    loginText: {
        color: "#6B69CF",
        fontFamily: "Roboto"
    },
    container : {
        height: "100vh", 
    },
    spanesqueceusenha: {
        fontFamily: "Roboto",
        cursor: "pointer",
        color: "#fff",
    },
    esqLink: {
        color: "#000"
    },
    btnGoogle: {
        color: "#d34836",
        borderColor: "#d34836",
        border: "2px solid",
        fontSize: 12,
        lineHeight: "12px"

    },
    
    btnFacebook: {
        color: "#3b5998",
        borderColor: "#3b5998",
        border: "2px solid",
       padding: 0,
        fontSize: 12,
       lineHeight: "12px"
    },
    labelTitle: {
        fontSize: 14,
        position: "relative",
        top: -10,
        color: "#040404",
        fontFamily: "Roboto",
        fontWeight: "bold"
    },
    formDiv: {
        marginTop: 50,
        marginRight: "20%",
        marginLeft: "20%"
    }

}



export default EsqueceuSenha;