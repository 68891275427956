import { css } from "emotion"
import { createRef, useRef } from "react"
import DataTable from "react-data-table-component"
import { Button, Col, Row } from "react-materialize"
import { BarLoader } from "react-spinners"
import { Checkbox, Input, InputSearch } from "../../../components/Form"
import ModalMobileFullScreen from "../../../components/ModalMobileFullScreen"
import FormPreCadastro from "../FormPreCadastro"

export default ({
    index,
    open,
    athSelected,
    onChangeCheckbox,
    search,
    setSearch,
    onClose,
    onPressSearch,
    loadingTable,
    addAthletes,
    newAth,
    newAthView,
    setNewAthView,
    data
}) => {
    const refSaveAth = useRef();

    const columns = [
        {
            name: 'Nome ',
            selector: 'name',
            sortable: true,
            style:{display: "inline-grid"},
            cell:(row) => {
                return(<div > 
                    <div><span className={css(styles.itemTableSpan)}>{(row.name)}</span></div>
                </div>);
            }
        },
        {
            name: '',
            maxWidth:"10px",
            ignoreRowClick: true,
            cell:(row) => 
            <div>
                <Checkbox
                    id={"ath"+row.id}
                    value={athSelected.id == `${row.id}`}
                    onChange={() => onChangeCheckbox(row)}
                />
            </div>,
        }
        
    ]

    return <ModalMobileFullScreen
        open={open} 
        toggle={onClose}
        contentStyle={styles.contentModal}
        // onClose={() => history.goBack()}
        title={"Filtro - Selecione o Atleta"}
        btnFooterText={"Adicionar"}
        onClickBtnFooter={() => {
            if(newAthView){
                refSaveAth.current.onSaveAth()
            } else {
                addAthletes()               
            }
        }}
    >   

        {!newAthView && <>
            <Row>
                <Col s={12}>
                    <InputSearch
                        cssStyle={css(styles.search)}
                        radius={true}
                        label={"CPF do atleta:"}
                        key="cpf_atleta"
                        value={search}
                        onSearch={onPressSearch}
                        onChange={(e) => setSearch(e.target.value)}
                    />
                </Col>
            </Row>
            <Row>
                <DataTable
                    columns={columns}
                    progressComponent={<BarLoader css={styles.loadingCss} color={"#6B69CF"}/>}
                    progressPending={loadingTable}
                    data={data}
                    highlightOnHover={true}
                    pointerOnHover={true}
                    noHeader={true}
                    noDataComponent={
                        newAth &&  <div>
                        <div className={css(styles.withoutAth)}> Atleta não encontrado </div>
                        <div>
                            <Button type={"button"} onClick={() => setNewAthView(true)}>Fazer Pré-cadastro</Button>
                        </div>
                        
                    </div>
                    }
                />
            </Row>
        </>}
        {newAthView && <FormPreCadastro 
                index={index} 
                onClose={onClose}
                ref={refSaveAth}
                cpf={search}/>}

    </ModalMobileFullScreen>
}

const styles = {
    contentModal: {
        minHeight: "1000px !important",
        backgroundColor: "#fff",
        zIndex: 9999
    },
    search: {
        backgroundColor:"#FFFCFC !important",
        paddingLeft: "10px !important",
        paddingRight: "10px !important",
    },
    withoutAth: {
        textAlign: "center"
    },
    btnSalvar: {
        marginTop: 55
    },
    newEvento : {
        float:"left"
    },
    main: {
        paddingBottom: 20
    },
    photoEvent: {
        width: "100%",
        paddingTop: 20,
        position: "relative"
    },
    modal: {
        modal : {
            width: "80%"
        }
    },
    loadingCss: {
        width: "100%"
    },
    photo: {
        width: 40,
        height: 40,
        borderRadius: 250,
        marginTop: 5,
        marginBottom: 5
    },
    itemTableSpan: {
        fontSize: 14
    },
    iconBtn: {
        cursor:'pointer',
        color:'red !important'
   }
}