import React from "react" 
import logo from "../../assets/img/logo.png"
import { css } from "emotion"
import evento_icon from "../../assets/icons/evento.svg"
import sair_icon from "../../assets/icons/sair.svg"
import carteira_icon from "../../assets/icons/carteira.svg"
import equipe_icon from "../../assets/icons/equipe.svg"
import hall_fama_icon from "../../assets/icons/hall-fama.svg"
import cad_icon from "../../assets/icons/cad.svg"
import entrar_icon from "../../assets/icons/entrar.svg"
import juiz_icon from "../../assets/icons/juiz.svg"
import trocar_senha_icon from "../../assets/icons/padlock-white.svg"

import { Link, useHistory } from "react-router-dom"
import { useCookies} from "react-cookie"

const Component = () => {
    const [cookies, ,removeCookie] = useCookies(['id','access_token','nome',"imagem", "tipo"]);
    const history = useHistory()

    return  <>
    <div className="navbar-fixed">
        <nav className={css(styles.navWrapper)}>
            <div >
            {(!cookies.access_token || cookies.tipo != "at" ) && <span href="/" data-target="sidenav-1" 
                    className="left sidenav-trigger hide-on-medium-and-up onlySideMenuDevices">
                    <i className="material-icons">menu</i>
                </span>}
                <a href="/" className="brand-logo">
                    <img  width="70px" src={logo} className={css(styles.logo)} />
                </a>
                <div className="left hide-on-med-and-down pointer">
                    <div>
                        <span className={css(styles.title)}>Olimpoz</span>
                    </div>
                </div>
                <div className="right hide-on-med-and-down pointer">
                    {(!cookies.access_token || cookies.tipo != "at" ) && <ul>
                        <li className={css(styles.itemMenuRight)}>
                            <Link to={"/atleta/login"}>
                                <img src={entrar_icon}/>
                                <div><span>Atleta</span></div>
                            </Link>
                        </li>
                        <li className={css(styles.itemMenuRight)}>
                            <Link to={"/login"}>
                                <img src={cad_icon}></img><br/>
                                <span>Organizador</span>
                            </Link>
                        </li>
                    </ul>}
                    {cookies.access_token && cookies.tipo == "at"  && <ul>
                        <li className={css(styles.itemMenuRight)}>
                            <Link to={"/"}>
                                <img src={evento_icon}/>
                                <div><span>Eventos</span></div>
                            </Link>
                        </li>
                        <li className={css(styles.itemMenuRight)}>
                            <Link to={"/atleta/hall-fama"}>
                                <img src={hall_fama_icon}></img><br/>
                                <span>Hall da Fama</span>
                            </Link>
                        </li>
                        <li className={css(styles.itemMenuRight)}>
                            <Link to={"/atleta/minhas-equipes"}>
                                <img src={equipe_icon}></img><br/>
                                <span>Equipe</span>
                            </Link>
                        </li>
                        <li className={css(styles.itemMenuRight)}>
                            <Link to={"/atleta/carteira"}>
                                <img src={carteira_icon}></img><br/>
                                <span>Carteira</span>
                            </Link>
                        </li>
                        <li className={css(styles.itemMenuRight)}>
                            <Link to={"/atleta/cronometro"}>
                                <img src={juiz_icon} ></img><br/>
                                <span>Cronômetro</span>
                            </Link>
                        </li>
                        <li className={css(styles.itemMenuRight)}>
                            <Link to={"/atleta/trocarsenha"}>
                                <img src={trocar_senha_icon} className={css(styles.padlock)}></img><br/>
                                <span>Trocar Senha</span>
                            </Link>
                        </li>
                        <li className={css(styles.itemMenuRight)}>
                            <div onClick={() => { 
                                removeCookie("access_token", { path: '/' });
                                history.replace("/") }}>
                                <img src={sair_icon}></img><br/>
                                <span>Sair</span>
                            </div>
                        </li>
                    </ul>}
                </div> 
            </div>
        </nav>
    </div>
    {(!cookies.access_token || cookies.tipo != "at" ) && <ul id="sidenav-1" className="sidenav sidenav-fixed onlySideMenuDevices" style={styles.sideMenu}>
        <li style={styles.itemMenu}> <Link to={"/login"}> <span style={styles.itemMenuText}>Organizador</span> </Link> </li>
        <li style={styles.itemMenu}> <Link to={"/atleta/login"}> <span style={styles.itemMenuText}>Atleta</span> </Link> </li>
    </ul>}
    {cookies.access_token && cookies.tipo == "at"  && 
        <div className={css(styles.footerMenu)}>
            <div className={css(styles.itemsMenuFooter)+" center-align"}>
                <Link to={"/"}>
                    <div>
                        <img src={evento_icon} style={styles.itemMenuIconFooter} className="center-align"/>
                    </div>
                </Link>
                <Link to={"/atleta/hall-fama"}>
                    <div>
                        <img src={hall_fama_icon} style={styles.itemMenuIconFooter} className="center-align"/>
                    </div>
                </Link>
                <Link to={"/atleta/minhas-equipes"}>
                    <div>
                        <img src={equipe_icon} style={styles.itemMenuIconFooter} className="center-align"/>
                    </div>
                </Link>
                <Link to={"/atleta/cronometro"}>
                    <div>
                        <img src={juiz_icon} style={styles.itemMenuIconFooter} className="center-align"/>
                    </div>
                </Link>
                <Link to={"/configuracoes/menuatleta"}>
                    <div>
                        <img src={carteira_icon} style={styles.itemMenuIconFooter} className="center-align"/>
                    </div>
                </Link>
            </div>
            
        </div>}
  
    </>
    
}

const styles = {
    padlock: {
        height: 30
    },
    sideMenu: {
        width: 180,
    },
    itemMenu: {
        marginTop: 3,
        paddingLeft: 5
    },
    itemMenuText: {
        fontSize: 20,
        fontWeight: 500
    },
    title: {
        fontFamily: "Roboto",
        marginLeft: 80,
        fontSize: 22,
        fontWeight: "normal"
    },
    itemMenuRight: {
        marginRight: 30,
        marginTop: 10,
        lineHeight: "normal !important",
        textAlign: "center"
    },
    navWrapper: {
        backgroundColor: "#6B69CF !important",
        boxShadow: "none"
    },
    logo : {
        marginTop: -10,
        borderRadius: 25,
    },
    footerMenu: {
        position: "fixed",
        height: 50,
        bottom: 0,
        width: "100%",
        zIndex: 10,
        backgroundColor: "#6B69CF",
        "@media(min-width: 850px)": {
            display: "none"
        }
    },
    itemsMenuFooter: {
        display: "flex",
        justifyContent: "space-evenly"
    },
    itemMenuIconFooter: {
        marginTop: 5,
        width: 40, 
        textAlign:"center",
    },
    itemMenuIconFooter: {
        marginTop: 5,
        width: 40, 
        textAlign:"center",
    },
}

export default (Component)