import React, { useContext } from "react"
import { css } from "emotion";
import MiniCards from "../../components/MiniCards";
import MinhasEquipesContext from "./MinhasEquipesContext";
import MainContext from "../MainContext"
import { ClipLoader } from "react-spinners";
import config from "../../config.js"
import { BrowserView, MobileView } from "react-device-detect";
const Component = () => {

    const {state} = useContext(MinhasEquipesContext)

    return <div><BrowserView>
            <div className={css(styles.title)}>
                <span className={css(styles.titleSpan)}>
                    Minhas equipes
                </span>
            </div>
            </BrowserView>
            <MobileView>
                <div className={css(styles.title2)}>
                    <span className={css(styles.titleSpan2)}>
                        Minhas equipes
                    </span>
                </div>
            </MobileView>
        <MiniCards
            loading={state.loadingTeams}
            data={state.teams}
            ImgTemplate={ImageTeam}
        />
 
    </div>
}

const ImageTeam = ({img,id}) => {
    const contextMain = useContext(MainContext)
    let imageLoading = null;

    contextMain.state.images_upload.forEach((item) => {
        if(item.id == id && item.type_key == "equipe_id"){           
            if(item.status == "UPLOADING" || item.status == "TO_UPLOAD"){
                imageLoading = <ClipLoader color={"#110F62"} css={css(styles.loadingImg)}/> 
            }else if(item.status == "SUCCESS"){
                img = item.image ? config.storage_link+`/equipe/${id}/${item.image}` : ""
            }
        }
    })
    return( img ? 
        <img src={img} className={css(styles.imgCard)}/> : imageLoading
    );
}

const styles = {
    loadingImg: {
        backgroundColor: "#fff",
        width: 250,
        marginTop: 10
    },
    title: {
        paddingTop: 20,
    },
    title2: {
        marginTop: -20,
    },
    titleSpan: {
        fontFamily: "Roboto",
        fontSize: 26
    },
    titleSpan2: {
        fontFamily: "Roboto",
        fontSize: "1.5rem",
        fontWeight: "bold"
    },
    imgCard: {
        borderRadius: "50%",
        flex: 1,
        width: 80,
        height: 80
    }
}

export default  (Component);