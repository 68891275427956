import React, {useRef} from "react"
//import JoditEditor from "jodit-react";
import JoditEditor from "../JoditEditor";

import { css } from "emotion";

const RichTextEditor = (props) => {
	const editor = useRef(null)
  
    const removeAllButtons = props.removeAllButtons ? 
    {buttons:  [ ],
    buttonsMD: [],
    buttonsSM: [],
    buttonsXS: []} : {}
    return <div className={css(style.divComponent)}>
        <label 
        htmlFor={props.id} 
        className={css(style.label, style.joditMain)+" left-align"}>{props.label}</label>
        <div className={css(style.joditMain)}>
            <JoditEditor
              
                ref={editor}
                value={props.value}
                config={{
                    beautifyHTML: false,
                    readonly: false,
                    ...removeAllButtons,
                    removeButtons: ['image','video','file','about','undo','redo'],
                    placeholder: "Digite alguma coisa",
                    language: "pt_br"

                }}
                required={props.required}
                tabIndex={1}
                onBlur={newContent => props.onChange(newContent?.target?.innerHTML)} 
                onChange={newContent => {}}
            />
            {props.required && (
                <input tabIndex={-1} 
                autoComplete={"off"}
                style={style.input}
                required={true}
                onFocus={() => editor.current.focus()} 
                value={props.value}/>
            )} 
        </div>
      
  </div>
}

function areEqual(prevProps, nextProps) {
    return (prevProps.value === nextProps.value)
}

export default React.memo(RichTextEditor,areEqual)

const style = {
    divComponent: {
        width: "100%"
    },
    label:{
        color: "#000"
    },
    joditMain: {
        position: "relative",
        "& ul": {
            paddingLeft: "revert"
        },
        "& li": {
            listStyleType: "revert !important"
        },
        "& ul:not(.browser-default)": {
            listStyleType: "revert "
        }
    },
    input: {
        opacity: 0,
        width: "100%",
        height: 0,
        position: "absolute"
    }
}