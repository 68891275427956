import React, { useReducer, useRef, useEffect, useState } from "react"
import { reducer, initialState, MinhasEquipesProvider, actions } from "./MinhasEquipesContext";
import { css } from "emotion";
import ListaEquipes from "./ListaEquipes"
import FormEquipe from "./FormEquipe"
import circlePlus from "../../assets/icons/circle-plus.svg"
import ConviteEquipe from "./ConviteEquipe"
import MembrosEquipe from "./MembrosEquipe"
import Api from "../../api/Api";
import config from "../../config.js"
import { useCookies } from "react-cookie";
import { BrowserView, MobileView } from "react-device-detect";
import ModalMobileFullScreen from "../../components/ModalMobileFullScreen";
import { useHistory } from "react-router-dom";

const Component = (props) => {
    const [state, dispatch] = useReducer(reducer, initialState)
    const [cookies] = useCookies(['id']); 
    const  history = useHistory()
    const ref_form = useRef(null)

    useEffect(() => {
        dispatch({type: actions.UPDATE_LOADING_TEAMS, payload: true})
        const teams = [{name: "Nova equipe", image: circlePlus, action: openForm}]
        dispatch({type: actions.SET_LIST_TEAMS, payload: teams})
        const getTeams = async () => {
            const teamsResponse = await Api().post("equipe/listarfazparte",{"user_id": cookies.id, aceite: true})
            const data = teamsResponse.data
            let teams = data ? data.lista : []
            teams = teams.map((item) => {
                if(!item.imagem){
                    return {...item, action: () => openMembers(item.id)}
                }
              
                return {...item, 
                    image: config.storage_link+`/equipe/${item.id}/${item.imagem}`,
                    action: () => openMembers(item.id)
                }
            })
            dispatch({type: actions.ADD_LIST_TEAMS, payload: teams})
            dispatch({type: actions.UPDATE_LOADING_TEAMS, payload: false})
        }
        getTeams()
    },[state.updateTeamsForce])

    const openForm = () => {
      
        dispatch({type: actions.SET_CURRENT_ELEMENT, payload: "new_form"})
    }

    const openMembers = (id) => {
        dispatch({type: actions.SET_TEAM_ID, payload: id})
        dispatch({type: actions.SET_CURRENT_ELEMENT, payload: "edit_team"})
    }

    return <MinhasEquipesProvider value={{state, dispatch,ref_form, openMembers}}>
       
            <BrowserView>
                <div className={css(styles.container)}>
                    <ListaEquipes/>
                    {(state.currentElement === "new_form" || state.currentElement === "edit_form")  && <FormEquipe/>}
                    {(state.currentElement === "edit_team" || state.currentElement === "") &&
                        <ConviteEquipe/>
                    }
                    {(state.currentElement === "edit_team" ) &&  <MembrosEquipe/>}
                </div>
            </BrowserView>
            <MobileView>
                <ModalMobileFullScreen
                     open={true} 
                     title={""}
                     onClose={() => history.goBack()}
                >
                    <div className={css(styles.container2)}>
                        <ListaEquipes/>
                        {(state.currentElement === "new_form" || state.currentElement === "edit_form")  && <FormEquipe/>}
                        {(state.currentElement === "edit_team" ) &&  <MembrosEquipe/>}
                    </div>
                </ModalMobileFullScreen>
            </MobileView>
    </MinhasEquipesProvider>
}

const styles = {
    container: {
        marginLeft: 30,
        marginRight: 30,
        paddingTop: 10
    },
    container2: {
        marginLeft: 30,
        marginRight: 30,
    },
}

export default (Component);