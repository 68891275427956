import React, { useEffect, useState, useRef } from "react"
import { Row, Col  } from 'react-materialize';
import {Button, Input, InputPassword} from "../../components/Form"
import {
    Link, useHistory
  } from "react-router-dom";

import Api from "../../api/Api"
import { toast } from "react-toastify";
import {useCookies} from "react-cookie"
import { css } from 'emotion'
import AppLogin from "../../layouts/AppLogin"

const Login = (props) => {
    const [cookies, setCookie] = useCookies(['access_token','nome','imagem','id']);
    const [email,setEmail] = useState("")
    const [password,setPassword] = useState("");
    const [loading,setLoading] = useState(false)

    const refForm = useRef(null);

    const history = useHistory()

    const onSubmit = async (e) => {
        e.preventDefault()

        if(!refForm.current.reportValidity()){
            toast.error("Campos obrigatórios não foram preenchidos");
            return
        }
        setLoading(true)
        try {
           
            const result = await Api().post("/login",{email,password,tipo:"or"},{"Content-Type" : "application/json"})
            console.log(result)
            const access_token = result.data.access_token;
            const nome = result.data.nome
            const imagem = result.data.imagem
            const tipo = result.data.tipo
            const id = result.data.id
            const admin = result.data.admin
            const acesso = result.data.acesso

            if(access_token){
                setCookie('access_token', access_token, { path: '/' });
                setCookie('nome', nome, { path: '/' });
                setCookie('nome_admin', "", { path: '/' });
                setCookie('imagem', imagem, { path: '/' });
                setCookie('acesso', acesso, { path: '/' });
                setCookie('id', id, { path: '/' });
                setCookie("tipo",tipo, { path: '/' })
                setCookie("admin",admin, { path: '/' })
                history.replace("eventos")
                setLoading(false)

            }else {
                toast.error("Não autorizado")
            }
          
        } catch(e){
            toast.error("Não autorizado")
            setLoading(false)
        }
    }

    useEffect(() => {
        
        if(localStorage.getItem("access_token")){
            history.replace("eventos")
        }
    },[])

    return <AppLogin>
                <h4 className="text-darken-4 center-align" style={styles.loginText}>Login</h4>
                <form ref={refForm} data-testid={"form"} onSubmit={onSubmit}>
                    <div>
                        <Input 
                            id={"email"}
                            data-testid={"email"}
                            type={"email"}
                            required={true}
                            value={email}
                            color="rgba(107, 105, 207, 0.5)"
                            title={"E-mail incorreto"}
                            onChange={(e) => setEmail(e.target.value.toLowerCase())}
                            label={"meu e-mail"}
                        />
                        
                        <InputPassword
                            id={"password"} 
                            data-testid={"password"}
                            required={true}
                            value={password}
                            color="rgba(107, 105, 207, 0.5)"
                            coloricon="#6B69CF"
                            onChange={(e) => setPassword(e.target.value)}
                            label={"minha senha"}
                        />
                        
                        <Row>
                            <Col m={12} s={12} l={8} className="offset-l2">
                                <Button 
                                data-testid={"btnLogin"}
                                loading={loading}>Entrar</Button>
                            </Col>
                        </Row>
                        <Row>
                            <div className="center-align">
                                <Link data-testid={"btnEsqueceusenha"} to="/esqueceusenha" className={css(styles.esqLink)}>
                                    <span className={css(styles.spanesqueceusenha) + "center-align"}>Esqueceu a senha?</span>
                                </Link>
                            </div>
                        </Row>
                        <Row>
                            <Col m={12} s={12} l={8} className="offset-l2">
                                <Link data-testid={"btnCadastrar"} to="/usuario/novo">
                                    <Button type={"button"}>Cadastrar</Button>
                                </Link>
                            </Col>
                        </Row>
                    </div>
                </form>
        </AppLogin>
   
}

export default Login

 

const styles =  {
    loginText: {
        color: "#6B69CF",
        fontFamily: "Roboto"
    },
   
    spanesqueceusenha: {
        fontFamily: "Roboto",
        cursor: "pointer",
        color: "#6B69CF",
    },
    esqLink: {
        fontFamily: "Roboto",
        color: "#6B69CF",
    }

}