import { css } from "emotion"
import React from "react"
import { Card } from "react-materialize"
import ResetSenha from "./ResetSenha"

export default () => {
    return <div>
        <div className={css(styles.main)}>
            <h4 className="grey-text text-darken-4 center-align">Trocar Senha</h4>
            <Card>
                <ResetSenha/>
            </Card>
        </div>
    </div>
}

const styles={
    main: {
        paddingBottom: 20
    },
}