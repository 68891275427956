import { css } from "emotion"
import DataTable from "react-data-table-component"
import { Col, Row } from "react-materialize"
import { BarLoader } from "react-spinners"
import { Checkbox, InputSearch } from "../../../components/Form"
import ModalMobileFullScreen from "../../../components/ModalMobileFullScreen"

export default ({
    search, setSearch,
    data,
    athSelected,
    loadingTable, loadingBtn,
    onPressSearch, open,
    toggle, addAthletes, 
    onChangeCheckbox
}) => {

    const onClose = () => {
        toggle(false)
    }

    const columns = [
        {
            name: 'Nome ',
            selector: 'name',
            sortable: true,
            style:{display: "inline-grid"},
            cell:(row) => {
                return(<div > 
                    <div><span className={css(styles.itemTableSpan)}>{(row.name)}</span></div>
                </div>);
            }
        },
        {
            name: '',
            maxWidth:"10px",
            ignoreRowClick: true,
            cell:(row) => 
            <div>
                <Checkbox
                    id={"ath"+row.id}
                    value={Object.keys(athSelected).includes(`${row.id}`)}
                    onChange={() => onChangeCheckbox(row)}
                />
            </div>,
        }
        
    ]

    return <ModalMobileFullScreen
        open={open} 
        toggle={onClose}
        contentStyle={styles.contentModal}
        // onClose={() => history.goBack()}
        title={"Filtro - Selecione o Atleta"}
        btnFooterText={"Adicionar"}
        onClickBtnFooter={() => {
            addAthletes()
        }}
    >  
    <Row>
            <Col s={12}>
                <InputSearch
                    cssStyle={css(styles.search)}
                    radius={true}
                    label={"Nome do atleta:"}
                    key="nome_atleta"
                    value={search}
                    onSearch={onPressSearch}
                    onChange={(e) => setSearch(e.target.value)}
                />
            </Col>
        </Row>
        <Row>
            <DataTable
                columns={columns}
                progressComponent={<BarLoader css={styles.loadingCss} color={"#6B69CF"}/>}
                progressPending={loadingTable}
                data={data}
                highlightOnHover={true}
                pointerOnHover={true}
                noHeader={true}
                noDataComponent={""}
            />
        </Row>
</ModalMobileFullScreen> 
}

const styles = {
    itemTableSpan: {
        fontSize: 14
    },
    contentModal: {
        minHeight: "1000px !important",
        backgroundColor: "#fff",
        zIndex: 9999
    },
    search: {
        backgroundColor:"#FFFCFC !important",
        paddingLeft: "10px !important",
        paddingRight: "10px !important",
    },
}