import actions from "./actions";

export default (state, action) => {
    switch(action.type){
        case actions.SET_CURRENT_ELEMENT:
            return {...state, currentElement : action.payload}
        case actions.HANDLE_CHANGE:
            return {...state, [action.payload.name] : action.payload.value}
        case actions.ADD_LIST_TEAM:
            return {...state, teams : [...state.teams, action.payload]}
        case actions.ADD_LIST_TEAMS:
            return {...state, teams : [...state.teams, ...action.payload]}
        case actions.SET_LIST_TEAMS:
            return {...state, teams : action.payload}
        case actions.UPDATE_LOADING_TEAMS:
            return {...state, loadingTeams : action.payload}
        case actions.SET_TEAM_ID:
            return {...state, team_id: action.payload}
        case actions.UPDATE_TEAMS_FORCE:
            return {...state, updateTeamsForce: !state.updateTeamsForce}
        case actions.UPDATE_INVITES_FORCE:
            return {...state, updateInvitesForce: !state.updateInvitesForce}
        case actions.UPDATE_MEMBERS_FORCE:
            return {...state, updateMembersForce: !state.updateMembersForce}
        case actions.HANDLE_ZIP_CODE:
            return {...state, 
                zipCode : action.payload.zipCode,
                street : action.payload.street,
                neighborhood : action.payload.neighborhood,
                city : action.payload.city,
                state : action.payload.state,
            }
    }
}