import { css } from "emotion";
import React, { useContext, useEffect } from "react"
import { useCookies } from "react-cookie";
import TabMenuCard from "../../components/TabMenuCard";
import { Acessos, Cronometro } from "./JuizCronometroSteps";
import JuizCronometroContext, { actions } from "./JuizCronometroContext"
import Api from "../../api/Api";

const Component = () => {

    const {state,dispatch} = useContext(JuizCronometroContext)

    const [cookies] = useCookies(["id"])

    useEffect(() => {
        (async () => {
            const result = await Api().get("/eventosjuiz/listarEvento/"+cookies.id)
            const data = result.data ? result.data : {}
            const events = data.eventos ? data.eventos : []
            console.log(events)
            dispatch({type: actions.SET_EVENTS,payload: events})
            dispatch({type: actions.SET_LOADING_ALL_EVENTS})

        })()
    },[])

    return <div className={css(styles.container)}>
        <Cronometro/>
    </div>
}

const styles = {
    container: {
        marginLeft: 20,
        marginRight: 20,
        paddingTop: 10
    }
}

export default Component;