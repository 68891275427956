import React, { useState } from "react"

import Input from "./Input"
import { css } from 'emotion'

const Component =(props) => {

    const [visibility,setVisibility] = useState(false)

    const eye = {
        ...style.eye, 
        color: props.coloricon ? props.coloricon : "#000" 
        
    };

    const onChangeVisibility = () => {
        setVisibility(!visibility)
    }

    return  <div className={css(style.container)}>
        <Input
            {...props}
            messageError={""}
            type={visibility ? "text" :"password"}
            onChange={props.onChange}
        />
        <i className={css(eye, {top: props.label ? 30 : 10})+" material-icons"} onClick={onChangeVisibility}>
            {visibility ? "visibility_off" : "visibility"}
        </i>
        {props.messageError && <label className={css(style.messageError)}>{props.messageError}</label>}
    </div>
}

const style={
    eye: {
        position: "relative",
        color: "#6B69CF",
        float: "right",
        cursor: "pointer",
        position: "absolute",
        top: 10,
        right: 5
    },
    container: {
        width: "100%",
        position: "relative"
    },
    messageError: {
        color: "#f00"
    }
}

export default React.memo(Component)

