import React, { useContext } from "react"
import { Row, Col } from "react-materialize"
import { Checkbox, ImageUploader, DateTimePicker, RichTextEditor, Input, Select, Button } from "../../../../components/Form"
import FormEventoContext, {actions} from "../../FormEventoContext"
import listUf from "../../../../constants/listUf"
import { css } from "emotion"
import apicep from "../../../../api/ApiCep"
import { toast } from "react-toastify"

export default (props) => {

    const {state, dispatch, ref_form} = useContext(FormEventoContext)
    
    const handleChange =  (name, value) => {
        return dispatch({type: actions.HANDLE_CHANGE,
            payload: {name, value}})
    }
    
    const onDrop = (picture,pictureDataURLs) => {
        dispatch({type: actions.HANDLE_CHANGE,
            payload: {name: 'picture', value: picture}})
        dispatch({type: actions.HANDLE_CHANGE,
            payload: {name: 'pictureUrl', value: pictureDataURLs}})
    }

    const onDropRoute = (picture,pictureDataURLs) => {
        console.log(picture,pictureDataURLs);
        dispatch({type: actions.HANDLE_CHANGE,
            payload: {name: 'pictureRoute', value: picture}})
        dispatch({type: actions.HANDLE_CHANGE,
            payload: {name:  "pictureRouteUrl", value: pictureDataURLs}})
    }

    const nextPage = (e) => {
        e.preventDefault()
        if(!ref_form.current.reportValidity()){
            return
        }
        dispatch({type: actions.CHANGE_PAGE,payload: 1})
    }

    const handleZipCode = async (value) => {
        dispatch({type: actions.HANDLE_CHANGE,
            payload: {name: 'zipCode', value: value}})
        if(value.length == 9){
            try {
                const address = await apicep.get(`/${value}/json`);
                const data = address.data
                dispatch({type: actions.HANDLE_ZIP_CODE,
                    payload: {zipCode: value,street: data.logradouro, neighborhood: data.bairro,
                        city: data.localidade, state: data.uf}})

            } catch {
                toast.error("Ocorreu um erro ao tentar buscar o endereço")
            }
        }else {
            dispatch({type: actions.HANDLE_ZIP_CODE,
                payload: {street: "", neighborhood: "",
                    city: "", state: ""}})
        }
    }
    
    return <div>
            <Row>
                <Col s={3} className="offset-s9 right-align">
                    <Checkbox 
                        label={"Evento Ativo?"}
                        value={state.ativo}
                        onChange={() => handleChange('ativo',!state.ativo)}
                    />
                </Col>
            </Row>
            <Row>
                <Col s={12} l={4}>
                        <ImageUploader
                            aspect={16/9}
                            crop
                            buttonText='Escolher Imagem'
                            defaultImages={state.pictureUrl}
                            onDrop={onDrop}
                        />
                </Col>
                <Col  s={12} l={8}>
                    <Row>
                        <Col s={12}>
                            <Input
                                    id="name"
                                    label={"Nome do Evento*:"}
                                    value={state.name}
                                    required={true}
                                    onChange={(e) => handleChange('name',  e.target.value)}
                                />
                        </Col>
                    </Row>
                    <Row>
                        <Col s={12} l={6} >
                            <DateTimePicker
                                id="date_start"
                                label={"Data/Hora Inicio*:"}
                                value={state.startEvent}
                                required={true}
                                minDate={new Date()}
                                onChange={(value) => handleChange('startEvent',  value)}
                            /> 
                        </Col>
                        <Col s={12} l={6}>
                            <DateTimePicker
                                id="date_end"
                                label={"Data/Hora Fim*:"}
                                showOn={"both"}
                                openToDate={state.startEvent ? state.startEvent : new Date()}
                                value={state.endEvent}
                                startDate={state.startEvent ? state.startEvent : new Date()}
                                startOpen={false}
                                minDate={state.startEvent ? state.startEvent : new Date()}
                                required={true}
                                onChange={(value) => handleChange('endEvent',  value)}
                            /> 
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row>
                <Col s={12} l={12}>
                    <label 
                    className="left-align" 
                    className={css(styles.labelImg)}>{"Imagens do Percurso:"}</label>
                        <ImageUploader
                            buttonText='Adicionar Imagens'
                            onChange={onDropRoute}
                            aspect={16/9}
                            singleImage={false}
                            defaultImages={state.pictureRouteUrl}
                            buttonClassName={"btn-file-adjust"}
                            labelClass={"label-file-adjust"}
                            fileContainerStyle = {styles.containerStyle}
                        />
                </Col>
            </Row>
            <Row>
                <Col s={12} l={12}>
                    <RichTextEditor
                        label={"Descrição*:"}
                        value={state.description}
                        required={true}
                        onChange={(value) => { handleChange('description', value)}}
                    />
                </Col>
            </Row>
            <Row>
            
                <Col s={12} l={6}>
                        <Input
                            id="site"
                            label={"Site do Organizador:"}
                            value={state.site}
                            onChange={(e) => handleChange('site',  e.target.value)}
                        />
                </Col>
                <Col s={12} l={6}>
                        <Input
                            id="phone"
                            label={"Telefone:"}
                            pattern=".{0}|.{14,}"
                            title="O telefone está incorreto"
                            value={state.phone}
                            mask={state.phone.length >= 15 ? "(99) 99999-9999" : "(99) 9999-99999"}
                            onChange={(e) => handleChange('phone',  e.target.value)}
                        />
                </Col>
            </Row>
            <Row>
                <Col s={12}>
                    <label className={css(styles.messageInfo)}>Valide as informações preenchidas automaticamente do seu endereço antes de publicar o seu evento</label>
                </Col>
            </Row>
            <Row>
                <Col s={12} l={4}>
                        <Input
                            id="cep"
                            label={"Cep*:"}
                            value={state.zipCode}
                            mask={"99999-999"}
                            required={true}
                            onChange={(e) => handleZipCode( e.target.value)}
                        />
                </Col>
            </Row> 
            <Row>
                <Col s={12} l={9}>
                        <Input
                            id="street"
                            label={"Logradouro*:"}
                            value={state.street}
                            required={true}
                            maxLength={100}
                            onChange={(e) => handleChange('street',  e.target.value)}
                            />
                </Col>
                <Col s={12} l={3}>
                        <Input
                            id="number"
                            label={"Número*:"}
                            value={state.number}
                            type={"number"}
                            required={true}
                            onChange={(e) => handleChange('number',  e.target.value)}
                            />
                        
                </Col>
            </Row> 
            <Row>
                <Col s={12} l={6}>
                        <Input
                            id="complement"
                            label={"Complemento:"}
                            maxLength={100}
                            value={state.complement} 
                            onChange={(e) => handleChange('complement',  e.target.value)}
                        />
                </Col>
                <Col s={12} l={6}>
                        <Input
                            id="neighborhood"
                            label={"Bairro*:"}
                            value={state.neighborhood}
                            maxLength={100}
                            required={true}
                            onChange={(e) => handleChange('neighborhood',  e.target.value)}
                        />
                </Col>
            </Row> 
            <Row>
                <Col s={12} l={9}>
                        <Input
                            id="city"
                            label={"Cidade*:"}
                            value={state.city}
                            maxLength={100}
                            required={true}
                            onChange={(e) => handleChange('city',  e.target.value)}
                        />
                </Col>
                <Col s={12} l={3}>
                        <Select 
                            label={"UF*:"}
                            menuPlacement={"top"}
                            value={listUf.filter(option => option.value == state.state)}
                            onChange={(data) => handleChange('state',  data.value)}
                            required={true}
                            options={listUf}
                        />
                </Col>
            </Row>
            <Row className={css(styles.btnProxDiv)}>
                <Col m={12} s={12} l={6} className="offset-l3">
                    <Button onClick={nextPage}>Próximo</Button>
                </Col>
            </Row>
    </div>

}

const styles={
    btnProxDiv: {
        marginTop: 55
    },
    newEvento : {
        float:"left"
    },
    main: {
        paddingBottom: 20
    },
    labelImg:{
        color: "#000",
    },
    messageInfo: {
        color: "#000",
        fontWeight: 700,
        fontSize: 16
    },
    photoEvent: {
        width: "100%",
        paddingTop: 20,
        position: "relative"
    },
    containerStyle:{
        alignItems: "start",
        boxShadow: "0px 0px 0px ",
        backgroundColor: "transparent"
    }
}

