import actions from "./actions";
import moment from "moment"

export default (state, action) => {
    switch(action.type){
        case actions.CHANGE_PAGE:
            return {...state, currentPage : action.payload}
        case actions.RESET_TIMER:
            return {...state, 
                numberAthTimer : "", 
                isStartedTimer: false, 
                athAddedTimer: [],
                timeStart: ""
            }
        case actions.SET_EVENTS:
            return {...state, events : action.payload }
        case actions.SET_TIMERS:
            return {...state, timers : action.payload }
        case actions.SET_EVENT:
            return {...state, event: action.payload }
        case actions.SET_LOADING_TABLE_ACCESS:
            return {...state, loadingTableAccess : action.payload}
        case actions.SET_JUDGES_ACCESS:
            return {...state, judges_event_access : action.payload}
        case actions.FORCE_UPDATE_JUDGES_ACCESS:
            return {...state, forceJudgeAccess : !state.forceJudgeAccess}
        case actions.FORCE_TIMERS:
            return {...state, forceTimers : !state.forceTimers}
        case actions.FORCE_STARTS:
            return {...state, forceStarts : !state.forceStarts}
        case actions.REMOVE_JUDGE_ACCESS:
            return {...state, 
                judges_event_access: state.judges_event_access.filter((item) => item.user_id !== action.payload)}
        case actions.SET_ID_EVENT_TIMER:
            return {...state, id_event_timer: action.payload}
        case actions.SET_NAME_EVENT_TIMER:
            return {...state, name_event_timer: action.payload}
        case actions.SET_ID_EVENT_ACCESS:
            return {...state, id_event_access: action.payload}
        case actions.HANDLE_NUMBER_KEYBOARD_TIMER:
            if(action.payload.length <= 4){
                return {...state, numberAthTimer: action.payload}
            }
            return state;
        case actions.START_TIMER:
            return {...state, timeStart: moment(), isStartedTimer: true }
        case actions.ADD_ATH_TIMER: 
            const now = moment()
            const athAddedTimer = {
                numero: state.numberAthTimer,
                atleta: action.payload.nome,
                categoria: action.payload.categoria,
                sub_categoria: action.payload.sub_categoria,
                tempo: moment.utc(now.diff(state.timeStart,'H:m:i')).format("HH:mm:ss")
            }
            return {...state, 
                athAddedTimer: [...state.athAddedTimer, athAddedTimer],
                numberAthTimer: ""}
        case actions.SET_ATH_TIMER: 
            return {...state, 
                athAddedTimer: action.payload,
            }
        case actions.SET_ALL_EVENTS:
                return {...state, allEvents : action.payload }
        case actions.SET_LOADING_ALL_EVENTS:
            return {...state, loadingAllEvents : !state.loadingAllEvents }
        default:
            return state;
    }
}
