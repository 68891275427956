import React,{ useState, useEffect, useRef } from "react"
import { Row, Col} from "react-materialize"
import Api from "../../api/Api"
import config from "../../config.js"
import { Button, Select } from "../../components/Form"
import moment from "moment"
import { useHistory, useParams, useLocation } from "react-router-dom"
import { useContext } from "react"
import LoadingOverlayContext from "../../components/LoadingOverlayContext/LoadingOverlayContext"
import ModalFaleConosco from "./ModalFaleConosco"
import { css } from "emotion"
import useSports from "../../hooks/useSports"
import DataTable from "react-data-table-component"
import metalha_ouro from "../../assets/icons/medalha-ouro.png"
import metalha_prata from "../../assets/icons/medalha-prata.png"
import metalha_bronze from "../../assets/icons/medalha-bronze.png"
import medalha_participacao from "../../assets/icons/medalha-participacao.png"
import { toast } from "react-toastify"
import { useCookies } from "react-cookie"
import ReactToPrint from 'react-to-print'
import { BrowserView, MobileView } from "react-device-detect"
import ResultadoEventoBrowser from "./ResultadoEventoLayout/ResultadoEventoBrowser"
import ResultadoEventoMobile from "./ResultadoEventoLayout/ResultadoEventoMobile"

const Evento = () => {

    const {id} = useParams(); 
    const [picture,setPicture] = useState("")
    const [name,setName] = useState("")
    const [city, setCity] = useState("")
    const [state,setState] = useState("")
    const [dateStart, setDateStart] = useState("")
    const [openModalInfo, setOpenModalInfo] = useState(false)
    const [loadingPub, setLoadingPub] = useState(false)
    const [categoriesOptions,setCategoriesOptions] = useState([])

    const [modalInfoCronometroId, setModalInfoCronometroId] = useState("")
    const [modalInfoCronometroAtleta, setModalInfoCronometroAtleta] = useState("")

    const [cookies] = useCookies(['id'])
    const [sportFilter, setSportFilter] = useState("");
    const [categoryFilter, setCategoryFilter] = useState("");


    const ref_print = useRef(null)

    const params = useParams()
    const {search} = useLocation()
    const query = new URLSearchParams(search)

    const history = useHistory()
    
    
    const loadingOverlay = useContext(LoadingOverlayContext)

    const [values, setValues] = useState([])

    useEffect(() => {
        moment.locale("pt-BR")
        getInfoEvento()
    },[])

    useEffect(() => {
        getInfoCronometro()
    },[categoryFilter, sportFilter])

    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    const getInfoEvento = async () => {
        if(id){
            loadingOverlay.activeLoading(true,"Buscando conteúdo")
            const result = await Api().get(`eventos/getinfo/${id}`)
            if(result.data.info){
                const data = result.data.info;
                const cats = data.categorias ? data.categorias : []
                const data_inicio = moment(data.data_inicio,'YYYY-MM-DD HH:mm:ss').format("ddd, DD [de] MMMM [de] YYYY, HH[h]mm")
                setDateStart(capitalizeFirstLetter(data_inicio))
                setName(data.nome)
                setState(data.uf)
                setCity(data.cidade)
                setPicture(config.storage_link+`/evento/${data.id}/${data.imagem}`)
                let catsInternal = [{value: "", label: "Todas as categorias"}]
                let catIds = []
                cats.map((cat) => {
                    if(!catIds.includes(cat.id)){
                        catsInternal.push({value: cat.id, label: cat.nome})
                        catIds.push(cat.id)
                    }
                })

                setCategoriesOptions(catsInternal)
                loadingOverlay.activeLoading(false)
            }
        }
    }

    const getInfoCronometro = async () => {
        if(id){
            loadingOverlay.activeLoading(true,"Buscando conteúdo")
            const pub = query.get('pub') === "0" ? false : true
            const result = await Api().get(`cronometro/listar/${id}?categoria_id=${categoryFilter}&publicado=${pub}&esporte_id=${sportFilter}&subcategoria_id`)
            if(result.data.cronometros){
                let rows = result.data.cronometros.rows ? result.data.cronometros.rows : []
                rows.forEach((item,i) => item.posicao = i+1)
                setValues(rows)
            }
            loadingOverlay.activeLoading(false,"Buscando conteúdo")
        }
    }

    const handlePub = async () => {
        try {
            setLoadingPub(true)
            const result = await Api().post("cronometro/publicar", {
                user_id_publicou: cookies.id,
                evento_id: params.id
            })
            setLoadingPub(false)
            if(!result.data.error){
                toast.success("Cronômetro publicado com sucesso")
                history.goBack()
            }
        } catch(e){
            console.log(e)
            const response = e.response ? e.response : {};
            const data = response.data ? response.data : {};
            const msg = data.msg ? data.msg[0] : "Ocorreu um problema ao publicar";
            toast.error(msg)
        }  
    }


    const handleOpenInfoAth = (item) => {
        console.log(item)
        setModalInfoCronometroId(item.cronometro_id)
        setModalInfoCronometroAtleta(item.user_id)
        setOpenModalInfo(true)
        console.log(item)
    }

    let buttons = [ <ReactToPrint
        trigger={() => <button className={css(styles.button)}>Imprimir</button> }
        content={() => ref_print.current}
    />]

    if(query.get('pub') === "0"){
        buttons.push(<button className={css(styles.button, {marginLeft: 5})} loading={loadingPub} onClick={handlePub}>Publicar</button>)
    }

    return <div>
        <BrowserView>
            <ResultadoEventoBrowser 
                 picture={picture}
                 name={name}
                 city={city}
                 state={state}
                 dateStart={dateStart}
                 openModalInfo={openModalInfo}
                 setOpenModalInfo={setOpenModalInfo}
                 loadingPub={loadingPub}
                 categoriesOptions={categoriesOptions}
                 modalInfoCronometroId={modalInfoCronometroId}
                 modalInfoCronometroAtleta={modalInfoCronometroAtleta}
                 sportFilter={sportFilter}
                 setSportFilter={setSportFilter}
                 categoryFilter={categoryFilter}
                 setCategoryFilter={setCategoryFilter}
                 handlePub={handlePub}
                 handleOpenInfoAth={handleOpenInfoAth}
                 values={values}
            />
        </BrowserView>
        <MobileView>
            <ResultadoEventoMobile 
                picture={picture}
                name={name}
                city={city}
                state={state}
                dateStart={dateStart}
                openModalInfo={openModalInfo}
                setOpenModalInfo={setOpenModalInfo}
                loadingPub={loadingPub}
                categoriesOptions={categoriesOptions}
                modalInfoCronometroId={modalInfoCronometroId}
                modalInfoCronometroAtleta={modalInfoCronometroAtleta}
                sportFilter={sportFilter}
                setSportFilter={setSportFilter}
                categoryFilter={categoryFilter}
                setCategoryFilter={setCategoryFilter}
                handlePub={handlePub}
                handleOpenInfoAth={handleOpenInfoAth}
                values={values}
            />
        </MobileView>
    </div>
    
}

const styles = {
    main: {
        position: "relative",
        backgroundColor: "#F2F2F2",
        minHeight: "100vh"
    },
    imgAchievement: {
        width: 35
    },
    divResultados: {
        marginTop: 10,
        marginLeft: '5%',
        fontFamily: "Roboto",
        "@media(max-width: 992px)": {
            marginLeft: "7%",
            marginRight: "7%",
        },
        "@media(max-width: 700px)": {
            marginLeft: 7,
            marginRight: 7,
        },
    },
    divfiltros: {
        marginLeft: '-1%',
        marginTop: 10
    },
    acoesTable: {
        marginTop: "6%",
        float: "right"
    },
    imgPercurso: {
        width: 200,
        height: 100,
        paddingLeft: 20,
        objectFit: "cover",
        ":hover": {
            width: 220,
            height: 120
        }
    },
    imgPatrocinadores: {
        width: 200,
        height: 100,
        paddingLeft: 20,
        objectFit: "cover",
        cursor: "pointer",
        ":hover": {
            width: 220,
            height: 120
        }
    },
    divDeskView: {
        marginLeft: '8%',
        "@media(max-width: 992px)": {
            marginLeft: "7%",
            marginRight: "7%",
        },
        "@media(max-width: 700px)": {
            marginLeft: 7,
            marginRight: 7,
        },
    },
    maisEventos: {
        marginLeft: '4%',
        paddingBottom: 20,
        "@media(max-width: 992px)": {
            marginLeft: "7%",
            marginRight: "7%",
        },
        "@media(max-width: 700px)": {
            marginLeft: 15,
            marginRight: 15,
        },
    },
    organizador: {
        marginLeft: '4%',
        "@media(max-width: 992px)": {
            marginLeft: "7%",
            marginRight: "7%",
        },
        "@media(max-width: 700px)": {
            marginLeft: 15,
            marginRight: 15,
        },
    },
    percurso: {
        marginLeft: '4%',
        "@media(max-width: 992px)": {
            marginLeft: "7%",
            marginRight: "7%",
        },
        "@media(max-width: 700px)": {
            marginLeft: 15,
            marginRight: 15,
        },
    },
    nome_cat: {
        color: "#606060",
        fontFamily: "Roboto",
        fontSize: 18
    },
    valor_lote: {
        color: "#606060",
        fontFamily: "Roboto",
        fontSize: 14
    },
    parcela_lote: {
        color: "#19BC0B",
        fontFamily: "Roboto",
        fontSize: 14
    },
    labelDescricao: {
        color: "#606060",
        fontFamily: "Roboto",
        fontSize: 20
    },
    meusEventosLabel: {
        color: "#606060",
        fontFamily: "Roboto",
        fontSize: 20
    },
    organizadorLabel: {
        color: "#606060",
        fontFamily: "Roboto",
        fontSize: 20
    },
    percursoLabel: {
        color: "#606060",
        fontFamily: "Roboto",
        fontSize: 20
    },
    titleEvento: {
        "@media(max-width: 992px)": {
            marginLeft: -10,
            textAlign: 'center',
        },
        lineHeight: 1,
        fontWeight: 600,
        fontFamily: "Roboto",
        color: "#606060"
    },
    headerCapa: {
        height: 200,
        width: "100%",
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundColor: "#2F2E41"
    },
    viewImageEvento: {
        position: "absolute",
      //  zIndex: 2,
        top: 80,
        left: "5%",
        width: "70%",
        "@media(max-width: 992px)": {
            left: "0%",
            width: "100%",
        },
    },
    conteudoEvento: {
        marginLeft: 10,
        zIndex: 99,
        position: "relative"
    },
    conteudoEventoTitle: {
        marginLeft: 10,
        position: "absolute",
      //  top: 65,
        left: "40%",
        "@media(max-width: 992px)": {
          //  marginTop: '18vh',
            position: "relative",
            top: 0,
            left: 0,
        },
        
    },
    imgEventoDiv: {
        height: "25vh"
    },
    imgEvento: {
        objectFit: 'cover',
        objectPosition: '0%',
        "@media(max-width: 992px)": {
            objectFit: 'contain',
            objectPosition: 'center center'
        },
    },
    dateStart: {
        color: "#606060",
        fontFamily: "Roboto",
        fontSize: 16,
    },
    dateEnd: {
        color: "#606060",
        fontSize: 16,
        fontFamily: "Roboto",
    },
    dataEndSub: {
        color: "#606060",
        fontFamily: "Roboto",
        fontSize: 14,
    },
    dataEndSubSpan: {
        color: "#606060",
        fontFamily: "Roboto",
        fontSize: 14,
    },
    txtOrg: {
        color: "#606060",
        fontFamily: "Roboto",
        fontSize: 18,
    },
    btnFaleConosco: {
        fontSize: 14, 
        width: "100%"
    },
    btnTodosEsportes: {
        marginTop: 10
    },
    btnEsporte: {
        marginTop: 10,
        overflow: "hidden"
    },
    button: {
        backgroundColor: "transparent",
        border: "1px solid",
        padding: "5px 15px",
        cursor: "pointer",
        borderRadius: 25,
        color: "#fff",
    }
}

export default Evento;