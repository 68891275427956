import DataTable from "react-data-table-component";
import { BarLoader } from "react-spinners"
import metalha_ouro from "../../../assets/icons/medalha-ouro.png"
import metalha_prata from "../../../assets/icons/medalha-prata.png"
import metalha_bronze from "../../../assets/icons/medalha-bronze.png"
import medalha_participacao from "../../../assets/icons/medalha-participacao.png"
import { css } from "emotion";
import MiniCards from "../../../components/MiniCards";
import ModalMobileFullScreen from "../../../components/ModalMobileFullScreen";
import { useHistory } from "react-router-dom";
import { useCookies } from "react-cookie";
import config from "../../../config";
import { useEffect } from "react";
import { Col, Row } from "react-materialize";
import { Select } from "../../../components/Form";
import useSports from "../../../hooks/useSports";
import ModalFilterHallFama from "./ModalFilterHallFama";
import { useState } from "react";

export default ({
    loadingTable,
    tournaments,
    labelAchievements,
    achievements,
    tournamentId,
    events,
    eventId,
    setEventId,
    genres,
    genre,
    setGenre,
    sport,
    categories,
    setSport,
    category,
    setCategory,
    subCategories,
    setSubCategory,
    subCategory,
    points
}) => {
    const iconsPositions = [metalha_ouro,metalha_prata,metalha_bronze,medalha_participacao] 
    const history = useHistory()

    const [cookies] = useCookies(['id','access_token','nome',"imagem","nome_admin"]);

    const [filtering, setFiltering] = useState(false)

    const sports = useSports({evento_id: eventId, torneio_id: tournamentId}, (sports) => {
        sports = sports[0] ? sports[0] : {}
        setSport(sports.value)
    })

    let imagem = cookies.imagem ? cookies.imagem : ""
    imagem = imagem.search("https://") == 0 ? imagem :  config.storage_link+"/users/"+cookies.imagem

    const columns = [
        {
            selector: 'img_medalha',
            width: "45px",
           // omit: true,
            cell: ({position}) =>  position && <img className={css(styles.imgAchievement)} src={iconsPositions[position > 4 ? 3 : position-1]} />
        },
        {
            selector: 'position',
            width: "40px",
            style: styles.position,
            cell: ({position}) =>  <span>{(position ? position : 0) + "º"}</span> 
        },
        {
            selector: 'nome',
            style: styles.eventName,
            sortable: true,
        },
        {
            selector: 'tipo_categoria_evento',
            sortable: true,
            omit: true
        },
        {
            selector: 'pontuacao',
            sortable: true,
        },
    ];


    const ExpandedComponent  = ({data}) => {
        return <div>
            <table>
                <tbody>
                    <tr>
                        <td><b>Esporte:</b> {data.esporte}</td>
                        <td><b>Categoria:</b> {data.categoria_evento}</td>
                    </tr>
                    <tr>
                        <td><b>SubCategoria:</b> {data.tipo_categoria_evento}</td>
                        <td><b>Pontos:</b> {data.pontuacao}</td>
                    </tr>
                    <tr>
                        <td colSpan={2}><b>Tempo:</b> {data.tempo}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    }

    return <ModalMobileFullScreen
        open={true} 
        contentStyle={styles.contentModal}
        onClose={() =>  history.goBack() }
        title={""}
        headerCss={styles.headerCss}
        iconCloseCss={styles.iconCloseCss}
    > 
    <div className={css(styles.header)}>
       <div className={css(styles.subHeader)}>
            {cookies.imagem && <img src={imagem}
                className={css(styles.img)}
            />}
            <label className={css(styles.textTitle)}> { cookies.nome } </label>
            <div className={css(styles.textPoints)}> 
                <span>{points} pts</span>
            </div>
       </div>
      
    </div>
    <div className={css(styles.container)}>
        <div className={css(styles.title)}>
            <span className={css(styles.titleSpan)}>Torneios</span>
        </div>
        <MiniCards
            data={tournaments}
        />
        {tournamentId != 0 && <Row > 
            <Col s={5}>
                <Select
                    label={"Sexo:"}
                    options={genres}
                    onChange={(data) => setGenre(data.value)}
                    value={genres.filter(option => option.value == genre)}
                />
            </Col>
            <Col s={5} >
                <Select
                    label={"Esporte:"}
                    options={sports}
                    onChange={(data) => setSport(data.value)}
                    value={sports.filter(option => option.value == sport)}
                />
            </Col>
            <Col s={2}>
                <div className={css(styles.filterDiv)} onClick={() => setFiltering(true)}>
                    <span className={css(styles.iconFilter) + " material-icons"}>filter_alt</span>
                </div>
            </Col>
        </Row>}
        <div className={css(styles.title)}>
            <span className={css(styles.titleSpan)}>{labelAchievements}</span>
        </div>
        <div className={css(styles.achievements)}>
            <DataTable
                progressComponent={<BarLoader css={styles.loadingCss} color={"#110F62"}/>}
                progressPending={loadingTable}
                columns={columns}
                data={achievements}
                noHeader
                noTableHead
                expandableRows
                noDataComponent={""}
                expandableRowsComponent={<ExpandedComponent/>}
            />
        </div>
        <ModalFilterHallFama
             filtering={filtering}
             setFiltering={setFiltering}
             events={events}
             eventId={eventId}
             setEventId={setEventId}
             categories={categories}
             setCategory={setCategory}
             category={category}
             setSubCategory={setSubCategory}
             subCategories={subCategories}
             subCategory={subCategory}
        />
    </div>
   
</ModalMobileFullScreen>
}

const styles = {
    filterDiv: {
        marginTop: 25,
        marginLeft: 5
    },
    iconFilter: {
        fontSize: 30
    },
    position: {
        paddingRight: 0,
        paddingLeft: 10
    },
    eventName: {
        paddingLeft: 0,
        paddingRight: 0,
    },
    textTitle: {
        color: "#fff",
        fontSize: 16,
        flex: "1",
        marginLeft: 30,
        alignSelf: "center"
    },
    textPoints: {
        display: "flex",
        justifyContent: "flex-end",
        marginRight: 20,
        color: "#fff",
        fontSize: 14,
        flex: "1",
        marginLeft: 10,
        alignSelf: "center"
    },
    headerCss: {
        backgroundColor: "#6B69CF",

    },
    subHeader: {
        width: 60,
        height: 60,
        display: "flex",
        width: "100%"
    },
    img: {
        width: 60,
        height: 60,
        marginLeft: 30,
        borderRadius: 50
    },
    iconCloseCss: {
        zIndex: 99999,
        color: "#fff"
    },
    header: {
        // position: "absolute",
        backgroundColor: "#6B69CF",
        // left: 0,
        // top: 0,
        width: "100%",
        height: 80,
        borderBottomLeftRadius: 50,
        borderBottomRightRadius: 50
    },
    contentModal:{
        minHeight: "1000px !important",
        backgroundColor: "#fff",
        marginTop: 50,
        zIndex: 99999
    },
    loadingCss: {
        width: "100%"
    },
    achievements: {
        marginTop: 20
    },
    lineAchievement: {
        width: "100%",
        marginTop: 30,
        height: 64,
        backgroundColor: "#fff",
        boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.15)",
        borderRadius: 9,
        display: 'flex',
        position: "relative"
    },
    container: {
        marginLeft: 30,
        marginRight: 30,
        paddingTop: 10
    },
    title: {
        paddingTop: 20,
    },
    titleSpan: {
        fontWeigth: "bold",
        fontFamily: "Roboto",
        fontSize: 20
    },
    imgAchievement: {
        width: 35
    },
    itemLineAch: {
        flex: 1,
        marginTop: 10,
        marginLeft: 10
    },
}