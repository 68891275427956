import React, { useContext } from "react"
import { Row, Col } from "react-materialize"
import { Button, ButtonClean } from "../../../../components/Form"
import { css } from "emotion"
import FormEventoContext, { actions } from "../../FormEventoContext"
import ListPatrocinadores from "./ListPatrocinadores"
export default () => {
    const { ref_form, dispatch } = useContext(FormEventoContext)

    const add = () => {
        dispatch({type: actions.ADD_NEW_PATROCINADOR})
    }

    const nextPage = (e) => {
        e.preventDefault()
        if(!ref_form.current.reportValidity()){
            return
        }
        dispatch({type: actions.CHANGE_PAGE,payload: 5})
    }

    return <div>
        <Row>
            <Col s={12} l={12}>
                <ButtonClean onClick={add} icon={"plus"}>Adicionar Patrocinador</ButtonClean>
            </Col>
        </Row>
        <Row>
            <ListPatrocinadores />
        </Row>
        <Row className={css(styles.btns)}>
            <Col m={12} s={12} l={6} className="offset-l3">
                <Button onClick={nextPage}>Próximo</Button>
            </Col>
        </Row>
    </div>
}

const styles={
    btns: {
        marginTop: 55
    },
}