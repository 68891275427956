import React, { useEffect, useState, useContext, useRef } from "react"
import { css } from "emotion";
import { Button, Select } from "../../../../../components/Form";
import JuizCronometroContext, { actions } from "../../../JuizCronometroContext";
import Api from "../../../../../api/Api";
import config from "../../../../../config.js" 
import { useCookies } from "react-cookie";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { BeatLoader } from "react-spinners";

const Component = ({handleSetEventId}) => {

    const {state} = useContext(JuizCronometroContext)


    return <div>
        <div className={css(styles.containers)}>
            <div className={css(styles.itemContainer)}>
                <span className={css(styles.titleContainer)}>Minhas provas</span>
            </div>
            
            <div className={css(styles.events)}>
               
                    {state.loadingAllEvents && <BeatLoader  color={"#110F62"} size={25}/>}
                    {state.events.map((item,i) => 
                        <><div className={css(styles.event)}>
                            <div className={css(styles.eventDiv)} onClick={() => handleSetEventId(item.id, item.nome)}>
                                <img src={config.storage_link+`/evento/${item.id}/${item.imagem}`} className={css(styles.imgEvent)}/>
                                <div className={css(styles.titleDiv)}>
                                    <label className={css(styles.titleEvent)}>{item.nome}</label>
                                </div>
                            </div>
                        </div>
                        </>
                    )}
            </div>
        </div>
    </div>
}

const styles = {
    titleDiv: {
        textAlign: "center"
    },
    eventDiv: {
        width: "fit-content"
    },
    titleEvent: {
        marginTop: 10,
        fontSize: 12,
        color: "#040404",
        fontFamily: "Roboto"
    },
    imgEvent: {
        width: 100,
        height: 100,
        borderRadius: 15,
        objectFit: "cover"
    },
    event: {
        marginRight: 10,
        marginBottom: 10,
        //textAlign: "center",
        flex: 1
    },
    events: {
        display: "flex",
        flexWrap: "wrap"
    },
    divSelect: {
        width: "100%"
    },
    containers: {
        width: "100%"
    },
    itemContainer: {
        marginBottom: 50,
        
    },
    titleContainer: {
        fontFamily: "Roboto",
        color: "#000",
        fontSize: 20,
        fontWeight: "bold",
    },
    box: {
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        border: "1px solid #6B69CF",
        marginTop: 20,
        height: "100%",
        width: "80%",
        borderRadius: 9,
        display: "flex",
        alignItems: "center",
        flexDirection: "column"
    },
    tittleBox: {
        fontFamily: "Roboto",
        fontSize: 17,
        textAlign: "center",
        color: "#C4C4C4",
        border: "0.3px solid #FFFFFF"
    },
    contentBox: {
        width: "100%",
        marginTop: 20,
        minHeight: 70,
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        paddingRight: 20,
        paddingLeft: 20,

    },
    btnBox: {
        marginBottom: 10,
        lineHeight: 1.7,
        height: "auto",
        minHeight: 35,
        fontSize: 12,
        justifyContent: "center",
        alignItems: "center",
        display: "flex"
      
    },
    subTextResults: {
        fontFamily: "Roboto",
        alignSelf: "start",
        fontSize: 14,
        marginTop: 5,
        color: "#C4C4C4"
    }
}

export default React.memo(Component);