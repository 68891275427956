import React from "react"
import { useContext } from "react";
import { Col, Container, Row } from "react-materialize";
import { Button, DatePicker, Input, Select } from "../../../components/Form";
import HeaderViewEvento from "../../../layouts/HeaderViewEvento";
import InscricaoContext from "../InscricaoContext";
import {css} from "emotion"
import moment from "moment"
import card from "../../../assets/icons/card.svg"
import visa from "../../../assets/img/brand/visa.png"
import americanExpress from "../../../assets/img/brand/american-express.png"
import elo from "../../../assets/img/brand/elo.png"
import hipercard from "../../../assets/img/brand/hipercard.png"
import diners from "../../../assets/img/brand/diners.png"
import mastercard from "../../../assets/img/brand/mastercard.png"
import pagbank from "../../../assets/img/brand/pagbank.png"

const Component = ({
    validCpf, 
    optionsInstallment, 
    loadingBtn,
    onChangeField,
    onChangeCpf,
    optionsPag, 
    onBuy}) => {

    const {state} = useContext(InscricaoContext)

    return <div>
        <div className={css(styles.main)}>
            
            <HeaderViewEvento 
                picture={state.picture} 
                name={state.name} 
                dateStart={state.dateStart} 
                city={state.city} 
                state={state.uf} />

            <div className={css(styles.conteudoEvento)}>
                <Container>
                    <div className={css(styles.cardTitle)}>
                        <img src={card} className={css(styles.miniCard)}/>
                        <span className={css(styles.textDados)}>Dados de pagamento</span>
                    </div>
                    <div className={css(styles.divCard)}>
                        <img src={card} className={css(styles.card)}/>
                        <div className={css(styles.textCard)}>Crédito</div>
                    </div>
                    <div className={css(styles.divBrands)}>
                        <div className={css(styles.divBrand)}>
                            <img src={visa} className={css(styles.brand)}/>
                        </div>
                        <div className={css(styles.divBrand)}>
                            <img src={mastercard} className={css(styles.brand)}/>
                        </div>
                        <div className={css(styles.divBrand)}>
                            <img src={hipercard} className={css(styles.brand)}/>
                        </div>
                        <div className={css(styles.divBrand)}>
                            <img src={diners} className={css(styles.brand)}/>
                        </div>
                        <div className={css(styles.divBrand)}>
                            <img src={americanExpress} className={css(styles.brand)}/>
                        </div>
                        <div className={css(styles.divBrand)}>
                            <img src={elo} className={css(styles.brand)}/>
                        </div>
                        <div className={css(styles.divBrand)}>
                            <img src={pagbank} className={css(styles.brand)}/>
                        </div>
                    </div>
                    <h5 className={css(styles.titleSection)}>Dados do titular do cartão:</h5>
                    <Select
                            id="type"
                            label={"Tipo de pagamento*:"}
                            value={optionsPag.filter(option => option.value == state.typePag)}
                            isSearchable={false}
                            required={true}
                            onChange={(item) => onChangeField("typePag", item.value)}
                            options={optionsPag}
                        />
                    <Input 
                        label={"CPF:"} 
                        validate={false}
                        classNameInput={validCpf ? "valid" : ( state.cpfCard.length > 0 ? "invalid" : "") }      
                        value={state.cpfCard}
                        onChange={(e) => onChangeCpf(e.target.value)}
                    />
                    
                    <Input label={"Número do cartão:"} 
                        value={state.numberCard} 
                        mask={"9999 9999 9999 9999"}
                        maskChar={"_"}
                        onChange={(e) => onChangeField("numberCard", e.target.value)}    
                    />
                    <Input label={"Nome Impresso no cartão:"} />
                    <DatePicker 
                        label={"Data de validade*:"} 
                        dateFormat={"MM/yyyy"}
                        mask={"99/9999"}
                        value={state.valDateCard}
                        minDate={moment().startOf("month").toDate()}
                        showMonthYearPicker
                        onChange={(value) => onChangeField("valDateCard", value)}
                        placeholder="Data de validade*:"/>

                    <Input label={"Codigo de segurança*"} value={state.cvv} 
                        onChange={(e) => onChangeField("cvv", e.target.value)} />
                    
                    <Select
                            id="installment"
                            menuPlacement={"top"}
                            label={"Opções de pagamento*:"}
                            value={optionsInstallment.filter(option => option.value == state.installmentQuantity)}
                            isSearchable={false}
                            required={true}
                            onChange={(item) => {
                                onChangeField("installmentQuantity", item.value)
                                onChangeField("installmentValue", item.price)
                            }}
                            options={optionsInstallment}
                        /> 
                </Container>
            </div>
            <Row className={css(styles.btnSalvar)}>
                <Col m={12} s={12} l={6} className="offset-l3">
                    <Button type="button" onClick={onBuy} loading={loadingBtn}>Continuar</Button>
                </Col>
            </Row>
        </div>
    </div>
}

const styles = {
    cardTitle: {
        display: "flex"
    },
    btnSalvar: {
        marginTop: 55
    },
    textDados:{
        fontFamily: "Roboto",
        marginLeft: 10,
        fontWeight: "bold",
        lineHeight: "30px"
    },
    textCard: {
        fontSize: 16,
        textAlign: "center"
    },
    divBrands: {
        display: 'flex',
        marginTop: 20,
    },
    divBrand: {
        
        marginRight: 20
    
    },
    brand: {
        height: 30,
    },
    divCard: {
        width: "fit-content"
    },
    main:{
        position: "relative",
        backgroundColor: "#F2F2F2",
        minHeight: "100vh"
    },
    titleSection: {
        color: "#606060",
        fontFamily: "Roboto",
        fontSize: 20
    },
    conteudoEvento: {
        marginTop: 30,
        marginLeft: 10,
        zIndex: 99,
        position: "relative"
    },
    miniCard: {
        width: 30
    },
    card: {
        width: 60,
        marginTop: 30
    }
}

export default Component;