import React, { useEffect, useReducer } from "react"
import { Route, useHistory, useLocation } from "react-router-dom"
import Pagamento from "./Pagamento"
import Inscricao from "./Inscricao"
import { actions, initialState, InscricaoProvider, reducer } from "./InscricaoContext"
import moment from "moment"
import { setSessionId } from "../../components/PagSeguroDirectPayment"
import Api from "../../api/Api"

export default (props) => {
    const [state, dispatch] = useReducer(reducer, initialState)
    const history = useHistory()
    const location = useLocation()
    const locationState = location.state
    

    useEffect(() => {
        moment.locale("pt-BR")
        if(!locationState || !locationState.inscricao)
        {
            history.replace("/")
            return;
        }
        getInfoEvento()
    },[])


    const getInfoEvento = async () => {
        dispatch({type: actions.SET_HEADER_INFO, payload: {
            dateStart: locationState.inscricao.dateStart,
            name: locationState.inscricao.name,
            state: locationState.inscricao.state,
            city: locationState.inscricao.city,
            picture: locationState.inscricao.picture
        }})
        
        locationState.inscricao.lotsSelected.forEach((item) => {
            for(let i = 0; i < item.quantidade; i++ ){
                dispatch({type: actions.ADD_TICKET, payload: { 
                    id: item.id,
                    categoria_id: item.id_cat,
                    categoria_nome: item.nome_cat, 
                    athlete: i == 0 ? locationState.inscricao.athlete : null,
                    tamanho_roupa: item.tamanho_roupa }})
            }
        })
        dispatch({type: actions.HANDLE_CHANGE, payload: {
            name: 'totalPrice', value: locationState.inscricao.totalPrice
        }})
    }

    useEffect(() => {
        (async () => {
            const result = await Api().get("/idSession");
            setSessionId(result.data)
        })()     
    },[]);


    return <InscricaoProvider value={{state, dispatch}}>
        {state.currentPage == "inscricao" && <Inscricao {...props}/>}
        {state.currentPage == "pagamento" && <Pagamento {...props}/>}
    </InscricaoProvider>
}