import React from "react"
import { Row, Col  } from 'react-materialize';
import {Button, Input, InputPassword} from "../../../components/Form"
import {
    Link
} from "react-router-dom";
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import { GoogleLogin } from 'react-google-login';
import background from "../../../assets/img/background-login-mobile.jpg"
import { css } from "emotion";
import logo from "../../../assets/img/logo.png"
import google_icon from "../../../assets/icons/google.svg"
import facebook_icon from "../../../assets/icons/facebook.svg"

export default ({refForm, onSubmit, loading, email, password, setEmail, setPassword, responseGoogle, responseFacebook}) => {

    return <div className={css(styles.main)}>
                <img src={background} alt="" className={css(styles.background)}/>
                <div className={css(styles.divLogo)+" center-align"}>
                    <div>
                        <img src={logo} className={css(styles.logo)} width={30} />
                    </div>
                    <span className={css(styles.labelTitle)}>DPAZZ</span>
                </div>

                <form ref={refForm} className={css(styles.formDiv)} onSubmit={onSubmit}>
                    <div>
                        <Input 
                            id={"email"}
                            type={"email"}
                            data-testid={"email"}
                            backgroundColor="#fff"
                            radius={true}
                            required={true}
                            value={email}
                            color="#fff"
                            label={"E-mail"}
                            title={"E-mail incorreto"}
                            onChange={(e) => setEmail(e.target.value.toLowerCase())}
                            placeholder={"e-mail"}
                        />
                        
                        <InputPassword
                            id={"password"} 
                            required={true}
                            data-testid={"password"}
                            color="#fff"
                            backgroundColor="#fff"
                            coloricon="#6B69CF"
                            radius={true}
                            value={password}
                            label={"Senha"}
                            onChange={(e) => setPassword(e.target.value)}
                            placeholder={"senha"}
                        />
                        
                        <div className={css(styles.line)}>
                            <Button data-testid={"btnLogin"} loading={loading} backgroundColor={"#4A0997"}>Entrar</Button>
                        </div>
                        <div className={css(styles.line)}>
                            <div>
                                <Link data-testid={"btnEsqueceusenha"} to={{pathname: "/esqueceusenha", search: "?type=at"}} style={styles.esqLink}>
                                    <span className="center-align" style={styles.spanesqueceusenha}>Esqueceu a senha?</span>
                                </Link>
                            </div>
                        </div>
                        <Row>
                            <Link data-testid={"btnCadastrar"} to="/atleta/novo">
                                <Button type={"button"} backgroundColor={"#4A0997"}>Cadastrar</Button>
                            </Link>
                        </Row>
                        <Row>
                            <div className={css(styles.btnsSocialMedia)}>
                                <FacebookLogin 
                                        appId="1132241200475337"
                                        autoLoad={false}
                                        data-testid={"btnFacebook"}
                                        callback={responseFacebook}
                                        render={renderProps => (
                                            <Button onClick={renderProps.onClick}
                                            backgroundColor="#fff" 
                                            type={"button"} 
                                            style={styles.btnFacebook}>
                                                <img src={facebook_icon} style={styles.imgIconSocialMedia}/>
                                            </Button>
                                        )}
                                    />

                                        <GoogleLogin
                                            clientId="277718010751-5res725lptn7qo7ina37iktahan93gcv.apps.googleusercontent.com"
                                            render={renderProps => (
                                                <Button 
                                                    onClick={renderProps.onClick} 
                                                    backgroundColor="#fff" 
                                                    type={"button"}  
                                                    style={styles.btnGoogle}>
                                                        <img src={google_icon} style={styles.imgIconSocialMedia}/>
                                                    </Button>
                                            )}
                                            buttonText="Login"
                                            data-testid={"btnGoogle"}
                                            onSuccess={responseGoogle}
                                            onFailure={responseGoogle}
                                            cookiePolicy={'single_host_origin'}
                                        />
                            </div>
                               
                        </Row>
                    </div>
                </form>
        </div>
   
}

const styles =  {
    main: {
        width: "100%",
    },
    btnsSocialMedia: {
        display: "flex",
        justifyContent: "center"
    },
    background: {
        zIndex: -1,
        position: "fixed",
        maxWidth: "100%",
        height: "100vh",
        objectFit: "cover",
        top: 0,
        left: 0,
    },
    line: {
        marginTop: 15,
        marginBottom: 15
    },
    divLogo: {
        marginTop: 10,
        width: "100%",
    },
    logo: {
        width: 50, 
        textAlign:"center" 
    },
    imgIconSocialMedia: {
        height: 20
    },
    loginText: {
        color: "#6B69CF",
        fontFamily: "Roboto"
    },
    container : {
        height: "100vh", 
    },
    spanesqueceusenha: {
        fontFamily: "Roboto",
        cursor: "pointer",
        color: "#fff",
    },
    esqLink: {
        color: "#000"
    },
    btnGoogle: {
        color: "#d34836",
        borderColor: "#d34836",
        border: "2px solid",
        fontSize: 12,
        padding: 0,
        width: 40,
        height: 40,
        borderRadius: 50,
        marginLeft: 20,
        lineHeight: "12px"

    },
    btnFacebook: {
        color: "#3b5998",
        borderColor: "#fff",
        border: "2px solid",
        padding: 0,
        width: 40,
        height: 40,
        borderRadius: 50,
        fontSize: 12,
       lineHeight: "12px"
    },
    labelTitle: {
        fontSize: 14,
        position: "relative",
        top: -10,
        color: "#040404",
        fontFamily: "Roboto",
        fontWeight: "bold"
    },
    formDiv: {
        marginTop: 50,
        marginRight: "20%",
        marginLeft: "20%"
    }

}