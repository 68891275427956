import React, { useEffect, useState } from "react"
import { Row, Col, Container } from 'react-materialize';
import {Button, Input, Checkbox, Select, InputPassword, ImageUploader} from "../../components/Form"
import Card from "../../components/Card"
import {
    Link, useHistory 
  } from "react-router-dom";
import ApiCep from "../../api/ApiCep"
import Api from "../../api/Api"
import { toast } from 'react-toastify';
import validCPF from "../../components/Valid/CPF"
import validCNPJ from "../../components/Valid/CNPJ"
import listUf from "../../constants/listUf"
import AppLogin from "../../layouts/AppLogin"
import { css } from 'emotion'
import listCountries from "../../constants/listCountries";
import ModalPolitica from "../Politica/ModalPolitica";

export default () => {

    const [email,setEmail] = useState("")
    const [password,setPassword] = useState("");
    const [confpassword,setConfpassword] = useState("");
    const [name,setName] = useState("")
    const [country,setCountry] = useState({ value: "BR", label: 'Brasil' });
    const [picture,setPicture] = useState();
    const [cpf_cnpj, setCpfCnpj] = useState("")
    const [validCPFCNPJText, setValidCPFCNPJText] = useState("")
    const [typeDoc, setTypeDoc] = useState("cpf")
    const [phone,setPhone] = useState("")
    const [cellphone,setCellphone] = useState("")
    const [zipCode,setZipCode] = useState("")
    const [street,setStreet] = useState("")
    const [number,setNumber] = useState("")
    const [complement,setComplement] = useState("")
    const [neighborhood,setNeighborhood] = useState("")
    const [city,setCity] = useState("")
    const [state,setState] = useState("")
    const [term,setTerm] = useState(false)
    const [validEmail,setValidEmail] = useState("")
    const [messageErroCPFCNPJ,setMessageErrorCPFCNPJ] = useState("")
    const [messageErrorEmail,setMessageErrorEmail] = useState("")
    const [loading,setLoading] = useState(false)
    const [oldEmail,setOldEmail] = useState("")
    const [oldCpfcnpj,setOldCpfCnpj] = useState("")
    const [openPolicy, setOpenPolicy] = useState(false)
    const history = useHistory()
    const regexPassword = '^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&])[A-Za-z\\d@$!%*?&]{8,}$'
    const messageErroPassword = "A senha deve conter caracteres especiais, números, letras maiúsculas e minúsculas e ter no mínimo 8 caracteres."

  


    const theme =  (theme) => ({
        ...theme,
        borderRadius: 0,
        colors: {
            ...theme.colors,
            primary25: '#6B69CF',
            primary: '#6B69CF',
            neutral80: "#6B69CF",
            neutral20: "#6B69CF"
        },
    })
    

    const onSubmit = async (e) => {
        e.preventDefault()
        if(!term){
            toast.error("O termo deve ser selecionado")
        }else {
            setLoading(true)
            try {
              const data = {
                    name,
                    email,
                    password,
                    nome_empresa: typeDoc != "cpf" ? name : null,
                    telefone: phone ? phone.replace(/\(|\)|\s|-/g,"") : "",
                    celular: cellphone ? cellphone.replace(/\(|\)|\s|-/g,"") : "",
                    cpf_cnpj: cpf_cnpj ? cpf_cnpj.replace(/\.|-|\//g,"") : "",
                    endereco : street,
                    cep: zipCode ? zipCode.replace(/-/g,"") : "",
                    bairro: neighborhood,
                    cidade: city,
                    numero: number,
                    pais: country.value,
                    complemento: complement,
                    assinatura: term,
                    uf: state,
                    ativo: true
                }
                const result = await Api().post("organizador/salvar",data)
                if(result.data['error']){
                    toast.error(result.data.msg[0] ? result.data.msg[0] : "Erro")
                    setLoading(false)
                }else {
                    toast.success("Usuário cadastrado com sucesso")
                    if(picture){
                        uploadimagem(result.data.msg['user_id'])
                    }
                    setLoading(false)
                    history.replace("/")
                    
                }

            } catch (e){
                console.log(e)
                setLoading(false)
                const response = e.response ? e.response : {};
                const data = response.data ? response.data : {};
                const msg = data.msg ? data.msg[0] : "Ocorreu um erro";
                toast.error(msg)
                return false;
            }
        }
    }

    const uploadimagem = async (id) => {
        var formData = new FormData();
        formData.append("id_usuario",id)
        formData.append("file", picture);
        try {
            const resultImage = await Api().post('/usuario/uploadimagem', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            if(resultImage.data['error']){
                toast.error(resultImage.data.msg[0])
            }
        } catch(e) {
            console.log(e)
            const response = e.response ? e.response : {};
            const data = response.data ? response.data : {};
            const msg = data.msg ? data.msg[0] : "Ocorreu um erro ao enviar a imagem do organizador";
            toast.error(msg)
        }
        
    }

    const onDrop = (picture) => {
        setPicture(picture[0])
    }
    
    const  handleZipCode = async (value) => {
        setZipCode(value)
        if(value.length == 9){
            try {
                const address = await ApiCep.get(`/${value}/json`);
                const data = address.data
                setStreet(data.logradouro)
                setNeighborhood(data.bairro)
                setCity(data.localidade)
                setState(data.uf)

            } catch {
                
            }
        }else {
            setStreet("")
            setNeighborhood("")
            setCity("")
            setState("")
        }
    }

    const handleCPFCNPJ = async (value) => {
        setCpfCnpj(value)
       
        if(oldCpfcnpj != value){
            setOldCpfCnpj(value);
            if(typeDoc == "cpf" && validCPF(value)){
                if(await validExistsCPFCNPJ(value)){
                    setValidCPFCNPJText(value)
                    setMessageErrorCPFCNPJ("")
                }
            }else if (typeDoc == "cnpj" && validCNPJ(value)){
                if(await validExistsCPFCNPJ(value)){
                    setValidCPFCNPJText(value)
                    setMessageErrorCPFCNPJ("")
                }
            } else {
                setMessageErrorCPFCNPJ(`${typeDoc.toUpperCase()} INVÁLIDO`)
                setValidCPFCNPJText("")
            }
        }
    }

    const validExistsEmail = async (value) => {
        try {  
            if(value != oldEmail){
               setOldEmail(value);      
                const result = await Api().get(`/users/validaemail?email=${value}&tipo=or`)
                console.log(result)
                if(result.data.msg['Organizador']){
                    toast.error(`O E-MAIL já existe`)
                    setMessageErrorEmail(`O E-MAIL JÁ EXISTE`);
                    setValidEmail("")
                    return false;
                }
                setMessageErrorEmail("")
                setValidEmail(value)
                return true;
            }
         
        } catch (e){
            console.log(e)
            const response = e.response ? e.response : {};
            const data = response.data ? response.data : {};
            const msg = data.msg ? data.msg[0] : "Ocorreu um erro ao tentar validar o E-mail";
            toast.error(msg)
            return false;
        }
    }

    const validExistsCPFCNPJ = async (value) => {
        try {
            const cpfcnpj = value.replace(/\.|-|\//g,"")

            const result = await Api().get(`/users/validacpfcnpj/${cpfcnpj}?tipo=or`)
            if(result.data['error']){
                toast.error(`${typeDoc.toUpperCase()} já existe`)
                setMessageErrorCPFCNPJ(`${typeDoc.toUpperCase()} JÁ EXISTE`);
                return false;
            }
            return true;
            
         
        } catch (e){
            console.log(e)
            toast.error("Ocorreu um erro ao tentar validar o CPF")
            return false;
        }
    }


    useEffect(() => {
        setTypeDoc(cpf_cnpj.length > 14 ? "cnpj" : "cpf")
    },[cpf_cnpj])

    return <AppLogin>
            <h5 className={css(styles.title)+" text-darken-3 center-align"}>Cadastro de Organizador</h5>
            <form onSubmit={onSubmit}>
                <div>
                    <Row>
                        <Col s={12} l={4}>
                        <ImageUploader
                            buttonText='Escolher Imagem'
                            onChange={onDrop}
                        />
                        </Col>
                        <Col s={12} l={8}>
                            <Row>
                                <Col s={12}>
                                    <Select
                                        id="pais"
                                        label={"País de Origem*:"}
                                        theme={theme}
                                        value={country}
                                        labelStyle={styles.labelStyle}
                                        isSearchable={true}
                                        required={true}
                                        onChange={setCountry}
                                        options={listCountries}
                                    /> 
                                </Col>
                            </Row>
                            <Row>
                                <Col s={12}>
                                
                                    <Input
                                        id="cpf_cnpj"
                                        label={"CPF/CNPJ*:"}
                                        mask={typeDoc == "cpf" ? "999.999.999-999" : "99.999.999/9999-99"}
                                        pattern={validCPFCNPJText}
                                        validate={false}
                                        classNameInput={validCPFCNPJText ? "valid" : ( cpf_cnpj.length > 0 ? "invalid" : "") }
                                        title="CPF ou CNPJ está incorreto"
                                        value={cpf_cnpj}
                                        color="rgba(107, 105, 207, 0.5)"
                                        required={true}
                                        messageError={messageErroCPFCNPJ}
                                        onBlur={(e) => handleCPFCNPJ(e.target.value)}
                                        onChange={(e) => setCpfCnpj(e.target.value)}
                                    />
                                
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col s={12}>
                                <Input
                                    id="email"
                                    label={"E-mail*:"}
                                    value={email}
                                    title={"E-mail incorreto"}
                                    validate={false}
                                    required={true}
                                    color="rgba(107, 105, 207, 0.5)"
                                    pattern={validEmail}
                                    classNameInput={validEmail ? "valid" : ( email.length > 0 ? "invalid" : "") }
                                    messageError={messageErrorEmail}
                                    onBlur={(e) => validExistsEmail(e.target.value)}
                                    onChange={(e) => setEmail(e.target.value.toLowerCase())}
                                    type="email"
                                />
                        </Col>
                    </Row>  
                    <Row>
                        <Col s={12}>
                                <Input
                                        id="name"
                                        label={typeDoc == "cpf" ? "Nome*:" : "Razão Social*:"}
                                        value={name}
                                        color="rgba(107, 105, 207, 0.5)"
                                        maxLength={100}
                                        required={true}
                                        onChange={(e) => setName(e.target.value)}
                                    />
                        </Col>
                    </Row> 
                    <Row>
                        <Col s={12} l={6}>
                                <Input
                                        id="cellphone"
                                        label={"Celular*:"}
                                        pattern=".{0}|.{15,}"
                                        color="rgba(107, 105, 207, 0.5)"
                                        title="O celular está incorreto"
                                        value={cellphone}
                                        required={true}
                                        mask={"(99) 99999-9999"}
                                        onChange={(e) => setCellphone(e.target.value)}
                                    />
                        </Col>
                        <Col s={12} l={6}>
                                <Input
                                        id="phone"
                                        label={"Telefone:"}
                                        pattern=".{0}|.{14,}"
                                        color="rgba(107, 105, 207, 0.5)"
                                        title="O telefone está incorreto"
                                        value={phone}
                                        mask={phone.length >= 15 ? "(99) 99999-9999" : "(99) 9999-99999"}
                                        onChange={(e) => setPhone(e.target.value)}
                                    />
                        </Col>
                    </Row> 
                    <Row>
                        <Col s={12} l={6}>
                                <InputPassword
                                        id="password"
                                        label={"Senha*:"}
                                        color="rgba(107, 105, 207, 0.5)"
                                        coloricon="#6B69CF"
                                        type={"password"}
                                        value={password}
                                        pattern={`${regexPassword}`}
                                        messageError={!password || password.match(regexPassword) == null && messageErroPassword}
                                        title={messageErroPassword}
                                        maxLength={30}
                                        required={true}
                                        onChange={(e) => setPassword(e.target.value)}
                                    />
                        </Col>
                        <Col s={12} l={6}>
                                <InputPassword
                                        id="conf_password"
                                        label={"Confirmar Senha*:"}
                                        type={"password"}
                                        color="rgba(107, 105, 207, 0.5)"
                                        coloricon="#6B69CF"
                                        pattern={`^${password}$`}
                                        messageError={!confpassword || confpassword != password && "As senhas devem ser iguais"}
                                        title="As senhas devem ser iguais"
                                        required={true}
                                        value={confpassword}
                                        maxLength={30}
                                        onChange={(e) => setConfpassword(e.target.value)}
                                    />
                        </Col>
                    </Row> 
                    <Row>
                        <Col s={12} l={6}>
                                <Input
                                        id="cep"
                                        label={"Cep*:"}
                                        value={zipCode}
                                        color="rgba(107, 105, 207, 0.5)"
                                        mask={"99999-999"}
                                        required={true}
                                        onChange={(e) => handleZipCode(e.target.value)}
                                    />
                        </Col>
                    </Row> 
                    <Row>
                        <Col s={12} l={9}>
                                <Input
                                        id="street"
                                        label={"Logradouro*:"}
                                        color="rgba(107, 105, 207, 0.5)"
                                        value={street}
                                        required={true}
                                        maxLength={100}
                                        onChange={(e) => setStreet(e.target.value)}
                                    />
                        </Col>
                        <Col s={12} l={3}>
                                <Input
                                        id="number"
                                        label={"Número*:"}
                                        color="rgba(107, 105, 207, 0.5)"
                                        value={number}
                                        type={"number"}
                                        required={true}
                                        onChange={(e) => setNumber(e.target.value)}
                                    />
                        </Col>
                    </Row> 
                    <Row>
                        <Col s={12} l={6}>
                                <Input
                                        id="complement"
                                        label={"Complemento:"}
                                        color="rgba(107, 105, 207, 0.5)"
                                        maxLength={100}
                                        value={complement}                                                
                                        onChange={(e) => setComplement(e.target.value)}
                                    />
                        </Col>
                        <Col s={12} l={6}>
                                <Input
                                        id="neighborhood"
                                        label={"Bairro*:"}
                                        value={neighborhood}
                                        color="rgba(107, 105, 207, 0.5)"
                                        maxLength={100}
                                        required={true}
                                        onChange={(e) => setNeighborhood(e.target.value)}
                                    />
                        </Col>
                    </Row> 
                    <Row>
                        <Col s={12} l={9}>
                            <Input
                                    id="city"
                                    label={"Cidade*:"}
                                    color="rgba(107, 105, 207, 0.5)"
                                    value={city}
                                    maxLength={100}
                                    required={true}
                                    onChange={(e) => setCity(e.target.value)}
                                />
                        </Col>
                        <Col s={12} l={3}>
                            <Select 
                                    label={"UF*:"}
                                    menuPlacement={"top"}
                                    labelStyle={styles.labelStyle}
                                    theme={theme}
                                    value={listUf.filter(option => option.value == state)}
                                    onChange={(data) => setState(data.value)}
                                    required={true}
                                    options={listUf}
                                />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Checkbox 
                                label={"Li e concordo com os "}
                                value={term}
                                required={true}
                                onChange={() => setTerm(!term)}
                                extraLabel={<Link to={"#"} onClick={() => setOpenPolicy(true)}> Termos de Uso de Politica de Privacidade</Link>}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col m={12} s={12} l={6} className="offset-l3">
                            <Button loading={loading}>Cadastrar</Button>
                        </Col>
                    </Row>
                </div>
                <ModalPolitica open={openPolicy} setOpen={setOpenPolicy}/>
            </form>
        </AppLogin>
   
}

const styles =  {
    title: {
        color: "#6B69CF",
        fontFamily: "Roboto",
        fontWeight: "600"
    },
    labelStyle: {
        color: "#6B69CF",
    }
}