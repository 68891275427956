import { css } from "emotion"
import React, { useContext, useState } from "react"
import { useHistory } from "react-router-dom"
import ModalMobileFullScreen from "../../../components/ModalMobileFullScreen"
import InscricaoContext from "../InscricaoContext"
import InscricaoIngresso from "../InscricaoIngresso"
import PagamentoSucessoMobile from "../PagamentoSucessoMobile"

export default ({onContinue}) => {
    const {state} = useContext(InscricaoContext)

    const history = useHistory()

    const [openModal, setOpenModal] = useState(true)
    const [currentTicket, setCurrentTicket] = useState(0)
    
    return <ModalMobileFullScreen
            open={openModal} 
            toggle={setOpenModal}
            onClose={() => history.goBack()}
            title={"Ingresso - "+state.tickets[currentTicket]?.categoria_nome}
            btnFooterText={"Continuar"}
            onClickBtnFooter={() => {
                if(state.tickets.length > currentTicket+1){
                    setCurrentTicket(currentTicket+1)
                } else {
                    onContinue()
                }            
            }}
        >
            <div className={css(styles.ingresso)}> Ingresso {currentTicket+1}</div>
        
        <InscricaoIngresso index={currentTicket}/>
    </ModalMobileFullScreen>
}

const styles = {
    ingresso: {
        marginLeft: 20,
        fontSize: 18,
        fontWeight: "bold"
    }
}