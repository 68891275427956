import { css } from "emotion"
import React from "react"
import { useHistory } from "react-router-dom"
import ModalMobileFullScreen from "../../components/ModalMobileFullScreen"
import check_icon from "../../assets/icons/check.svg"
export default () => {

    const history = useHistory()

    return <ModalMobileFullScreen
        open={true} 
        contentStyle={styles.contentModal}
        onClose={() =>  history.replace("/")}
        title={"Pagamento Ingresso"}
        btnFooterText={"Home"}
        onClickBtnFooter={() => {
            history.replace("/")
        }}
    >
        <div className={css(styles.text)}>
            <span>
                Parabéns seu pagamento foi concluído com sucesso!
            </span>
            <br/>
            <img src={check_icon} width={50} className={css(styles.img)}/>
        </div>
    </ModalMobileFullScreen>
}

const styles = {
    contentModal: {
        minHeight: 700,
        zIndex: 999999,
        backgroundColor: "#fff"
    },
    img: {
        marginTop: 30
    },
    text: {
        position: "absolute", /* or  */
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        textAlign: "center",
        fontSize: 22,
        width: "80%",
        color: "#19BC0B"
    }
}