import React from 'react'
import { css } from 'emotion';
import Modal from 'react-responsive-modal';
import { Col, Row } from 'react-materialize';
import { Button, Input, TimePicker } from '../../../components/Form';
import moment from "moment"
import { useLocation } from 'react-router-dom';
import ModalMobileFullScreen from '../../../components/ModalMobileFullScreen';
const Component = ({
    loadingPub,
    name,
    sport,
    category,
    subcategory,
    subcription,
    position,
    points,
    image,
    time,
    setTime,
    open,
    onClose,
    handleSave
}) => {
    const {search} = useLocation()

    const query = new URLSearchParams(search)

    return <ModalMobileFullScreen 
        open={open} 
        title={"Alterar Dados"}
        btnFooterText={query.get('pub') === "0" ? "Salvar" : "Fechar"}
        toggle={() => {}}
        loading={loadingPub}
        onClickBtnFooter={() => {
            if(query.get('pub') === "0"){
                handleSave()
            }else {
                onClose()
            }
        }}
    >
        <div className={css(styles.content)}>
            <div>
                <span className={css(styles.text)}>Para revisar os resultados use os filtros</span>
            </div>
            <Row>
                <div style={{
                        "maxWidth": 0,
                        "maxHeight": 0,
                        "overflow": "hidden"
                    }}>
                    <input autofocus="true" />
                </div>
                <Input 
                    disabled={true}
                    radius={true}
                    value={name}
                    classNameInput={css(styles.input)}
                    label={"Nome:"}
                />
            </Row>
            <Row>
                <Input 
                    disabled={true}
                    value={sport}
                    label={"Esporte:"}
                />
            </Row>
            <Row>
                <Input 
                    disabled={true}
                    value={category}
                    label={"Categoria:"}
                />
            </Row>
            <Row>
                <Input 
                    disabled={true}
                    value={subcategory}
                    label={"Sub-Categoria:"}
                />
            </Row>
            <Row>
                <Input 
                    disabled={true}
                    value={subcription}
                    label={"Nº Inscrição:"}
                />
            </Row>
            <Row>
                <Input 
                    disabled={true}
                    value={position}
                    label={"Posição:"}
                />
            </Row>
            <Row>
                    <Input 
                        disabled={true}
                        value={points}
                        label={"Pontos:"}
                    />
            </Row>
            <Row>
                {query.get('pub') === "0" ? <TimePicker 
                    label={"Tempo:"}
                    value={time}
                    showTimeSelect={false}
                    onChange={setTime}
                // autoFocus={false}
                /> :
                <Input 
                        label={"Tempo:"}
                        disabled={true}
                        value={moment(time).format("HH:mm:ss")}
                    />
                }
            </Row>
     
        </div>
    </ModalMobileFullScreen>

}

const styles = {
    text: {
        fontWeight: "bold",
        fontSize: 16,
    },
    content: {
        marginRight: 20,
        marginLeft: 20
    },
    modal: {
        modal : {
            width: "100%"
        }
    },
    btnDivSalvar: {
        marginTop: 55
    },
    input: {
        border: "1px solid #606060",
    }
}

export default Component;