
import { css } from "emotion"
import moment from "moment"
import React, { useContext } from "react"
import { useEffect } from "react"
import { Container, Row, Table } from "react-materialize"
import { useHistory, useLocation } from "react-router-dom"
import { toast } from "react-toastify"
import { Button } from "../../../../components/Form"
import ApresentacaoEventoContext from "../../ApresentacaoEventoContext"

export default () => {
    const apresentacaoContext = useContext(ApresentacaoEventoContext)

    const history = useHistory()

    useEffect(() => {
        if(!apresentacaoContext.state.id_organizer){
            return;
        }
        else if(apresentacaoContext.state.block || apresentacaoContext.state.lots.length == 0){
            toast.warning("Evento não disponível para compra")
            history.goBack()
        }
    },[apresentacaoContext.state.id_organizer])

    return  <div>
         <div className={css(styles.content)}>
                {(!apresentacaoContext.state.block && apresentacaoContext.state.lots.length > 0) && <><Row>
                        <span className={css(styles.dataEndSub)}>Data encerramento inscrições: </span>
                        <span className={css(styles.dataEndSubSpan)}>{apresentacaoContext.state.endDateSubscription}</span>
                    </Row>
                    
                    <Row>
                        <Table className={css(styles.tableCategories)} >
                            <thead className={css(styles.headTableCat)}>
                                <tr >
                                    <th data-field="categoria" colSpan={2} className={css(styles.headTableCatCat)}>
                                        Inscrições
                                    </th>
                                
                                    <th data-field="preco" colSpan={3} className={css(styles.headTableCatTotalPrice)}>
                                        R$ {apresentacaoContext.state.total}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {apresentacaoContext.state.lots.map((lot,i) => {
                                        return lot.visible == true && <tr key={"lot"+lot.id}>
                                            <td className={css(styles.divImgCat)}>
                                                <img src={lot.imagem} className={css(styles.imgCat)}/>
                                            </td>
                                            <td data-field="categoria" colSpan={3}>
                                                <div><label className={css(styles.nome_cat)}>{lot.nome_cat}</label></div>
                                                <div><label className={css(styles.valor_lote)}>R$ {(lot.valor && lot.valor != "0") ? parseFloat(lot.valor).toFixed(2).replace(".",",")+" ( + 10% de taxa)" : "0,00"} </label></div>
                                                <div><label className={css(styles.parcela_lote)}>{(lot.valor && lot.valor != "0") ? "em até 12x R$ "+(parseFloat(lot.valor) / 12).toFixed(2).replace(".",",") : ""}</label></div>
                                                <div><label className={css(styles.valor_lote)}> {lot.descricao_lote} encerra em: {moment(lot.data_fim).format("DD/MM/YYYY")} </label></div>
                                            </td>
                                            <td data-field="preco" className={css(styles.acoesTable)} colSpan={2}>
                                                <div> 
                                                    <button className={css(styles.btnChangeQuantLote)} onClick={() => apresentacaoContext.subQuantidade(i)}>-</button> 
                                                    <span className={css(styles.qtdLote)}> {apresentacaoContext.retornoZeroBeforeNumbers(lot.quantidade)} </span>  
                                                    <button className={css(styles.btnChangeQuantLote)} onClick={() => apresentacaoContext.addQuantidade(i)}>+</button> 
                                                </div> 
                                            </td>
                                        </tr>
                                    }
                                )}
                            
                            </tbody>
                            
                        </Table>
                    </Row></>}
        </div>
        <Button
            onClick={apresentacaoContext.onBuy}
            backgroundColor={"#6B69CF"}
            className={css(styles.btnBuy)}>
                Continuar
        </Button>
    </div>
}

const styles = {
    divImgCat: {
        width: 50
    },
    imgCat: {
        width: 50
    },
    title: {
        fontFamily: "Roboto",
        fontSize: 18,
        fontWeight: "bold",
    },
    content: {
        paddingLeft: 20,
        paddingRight: 20
    },
    dataEndSub: {
        color: "#606060",
        fontFamily: "Roboto",
        fontSize: 14,
    },
    dataEndSubSpan: {
        color: "#606060",
        fontFamily: "Roboto",
        fontSize: 14,
    },
    tableCategories: {
        width: "98%"
    },
    headTableCat: {
        backgroundColor: "#6B69CF"
    },
    headTableCatCat: {
        fontSize: 18,
        fontWeight: 500,

        fontFamily: "Roboto",
        paddingBottom:8,
        paddingLeft: 20,
        paddingTop: 8,
        borderRadius: 0,
        color: "#fff"
    },
    headTableCatTotalPrice: {
        fontSize: 18,
        fontWeight: 500,
        paddingBottom:8,
        fontFamily: "Roboto",
        textAlign: "center",
        paddingTop: 8,
        borderRadius: 0,
        color: "#fff"
    },
    headDataEncerra: {
        fontSize: 14,
        fontWeight: 400,
        paddingBottom:8,
        paddingLeft: 20,
        fontFamily: "Roboto",
        textAlign: "left",
        paddingTop: 8,
        borderRadius: 0,
        color: "#fff"
    },
    btnComprarTR: {
        backgroundColor: "#6B69CF",
        cursor: "pointer"
    },
    btnComprarTD: {
        textAlign: "center",
        fontSize: 18,
        paddingBottom:8,
        paddingTop: 8,
        color: "#fff"
    },
    btnChangeQuantLote: {
        cursor: "pointer",
        fontSize: 20,
        paddingLeft: 5,
        paddingRight: 5,
        backgroundColor: "transparent",
        border: "none",
        ":hover": {
            backgroundColor: "transparent"
        },
        ":focus": {
            backgroundColor: "transparent"
        }
    },
    qtdLote: {
        fontFamily: "Roboto",
        fontSize: 20
    },
    encerrado: {
        color: "#f00",
        paddingLeft: 15
    },
    btnBuy: {
        marginTop: 10,
        borderBottomRightRadius: "0px !important",
        borderBottomLeftRadius: "0px !important",
        height: 50,
        position: "fixed",
        left: 0,
        bottom: 0
    }
}