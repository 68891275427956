import React, { useContext } from "react"
import { Row, Col } from "react-materialize";
import { ImageUploader, Input } from "../../../../components/Form";
import FormEventoContext, { actions } from "../../FormEventoContext"
import { css } from "emotion";

const Component = (props) => {

    const {state, dispatch} = useContext(FormEventoContext);

     
    const handleChange =  (name, value, index) => {
        return dispatch({type: actions.HANDLE_PATROCINADOR,
            payload: {index, name, value}})
    }

    const onDrop = (picture,pictureDataURLs, index) => {
        dispatch({type: actions.HANDLE_PATROCINADOR,
            payload: {name: 'picture', value: picture, index}})
        dispatch({type: actions.HANDLE_PATROCINADOR,
            payload: {name: 'pictureUrl', value: pictureDataURLs, index}})
    }

    const onDelete = (index) => {
        dispatch({type: actions.REMOVE_PATROCINADOR, payload: index})
    }

    return <div>
        {state.patrocinadores.map((item,index) => 
        <Row>
            <Col s={2}>
                <ImageUploader
                        buttonText='Escolher Imagem'
                        defaultImages={item.pictureUrl}
                        onChange={(picture,pictureDataURLs) => onDrop(picture,pictureDataURLs,index)}
                    />
            </Col>
            <Col s={8}>
                <Input
                    value={item.link}
                    onChange={(e) => handleChange('link',e.target.value,index)}
                    label="Link do patrocinador"
                />
            </Col>
            <Col s={2}>
                <span  onClick={ () => onDelete(index) } 
                    className={"material-icons modal-trigger " + css(styles.delIcon)}>
                    delete
                </span>
            </Col>
        </Row>)}
    </div>
}

const styles={
    delIcon:{ 
        cursor:'pointer',
        color:'red',
        marginTop: 30
    }
}

export default Component;