import React, { useState } from "react"
import { useEffect } from "react";
import { Modal } from 'react-responsive-modal';
import Api from "../../api/Api";

const ModalPoliticas = (props) => {

    const [policyOrg, setPolicy] = useState("")

    useEffect(() => {
        (async () => {
            const result = await Api().get("politicas/getinfo")
            const data = result.data?.politicas
            const politicas = data[0] ? data[0] : {};
            const organizador = politicas['organizador']
            setPolicy(organizador)
        })()
    },[])

    return <div>
    <Modal
        open={props.open}
        center
        styles={styles.modal}
        type={"button"}
        onClose={() => props.setOpen(false)}
        >
            <h5 className="grey-text text-darken-3 center-align">Políticas</h5>
            <div dangerouslySetInnerHTML={{ __html: policyOrg }}>
            
            </div>
        </Modal>
    </div>
}

const styles = {
    modal: {
        closeButton: {
            backgroundColor: "transparent"
        },
        modal: {
            width: "100%"
        }
    },
    textPolicy: {
        textJustify: "inter-word", 
        textAlign: "justify"
    }
}

export default React.memo(ModalPoliticas)
