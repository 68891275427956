import React, {useContext, useState, useEffect} from "react"
import ModalCategoria from "./ModalCategoria"
import DataTable from 'react-data-table-component';
import FormTorneioContext, { actions } from "../../FormTorneioContext" 
import { css } from "emotion";

const Categoria = (props) => {

    const {state, dispatch} = useContext(FormTorneioContext);
    const [values,setValues] = useState([])
    const [idModal,setIdModal] = useState(0)
    const [openModal,setOpenModal] = useState(false)

    const onDelete = (id) => {
        const categories = state.categories;
        delete categories[id]
        dispatch({type: actions.ADD_CATEGORIES, payload: {...categories}})
    }

    const openModalFunc = (state) => {
        setIdModal(state.id)
        setOpenModal(true)
    }

    useEffect(() => {
        setValues(Object.values(state.categories))
    },[state.categories])
    

    const columns = [
        {
            name: '',
            selector: 'photo',
            cell:(row) => {
                return( 
                    <img src={row.pictureUrl} className={css(styles.photo)}/>
                );
            }
        },
        {
            name: 'Nome da categoria',
            selector: 'name',
            sortable: true,
            style:{display: "inline-grid"},
            cell:(row) => {
                return(<div > 
                    <div><span className={css(styles.itemTableSpan)}>{(row.nome)}</span></div>
                </div>);
            }
        },
        {
            name: 'Distancia',
            selector: 'distancia',
            sortable: true,
            style:{display: "inline-grid"},
            cell:(row) => {
                return(<div > 
                    <div><span className={css(styles.itemTableSpan)}>{(row.distancia)}</span></div>
                </div>);
            }
        },
        {
            name: 'Esporte',
            selector: 'esporte',
            sortable: true,
            style:{display: "inline-grid"},
            cell:(row) => {
                let esporte = (state.listSports.filter(option => option.value == row.esporte))
                esporte = esporte ? (esporte[0] ? esporte[0].label : "") : ""
                return(<div > 
                    <div><span className={css(styles.itemTableSpan)}>{(esporte)}</span></div>
                </div>);
            }
        },
 
        {
            name: 'Editar',
            ignoreRowClick: true,
            cell:(row) => 
            <div>
                <span 
                href="#modal1" 
                id={row.id} 
                onClick={ () => openModalFunc({id: row.id}) } 
                className={"material-icons modal-trigger "+css(styles.iconEdit)}>edit</span>
            </div>,
        },
        {
            name: 'Excluir',
            ignoreRowClick: true,
            cell:(row) => 
            <div>
                <span href="#modal1" id={row.id} onClick={ () => onDelete(row.id) } 
                className={"material-icons modal-trigger "+css(styles.iconDelete)}>delete</span>
            </div>,
        }
        
    ]

    return  <div> 
    <DataTable
            columns={columns}
            data={values}
            highlightOnHover={true}
            pointerOnHover={true}
            noHeader={true}
            noDataComponent={""}
        />
    
    <ModalCategoria id={idModal} open={openModal} toggle={setOpenModal} edit={true}/>
</div>
}

const styles={
    farol: {
        cursor:'pointer',
        color:'yellow'
    },
    newEvento : {
        float:"left"
    },
    iconDelete: {
        cursor:'pointer',
        color:'red'
    },
    iconEdit: { 
        cursor:'pointer',
        color:'"#000'
    },
    main: {
        paddingBottom: 20
    },
    photoEvent: {
        width: "100%",
        paddingTop: 20,
        position: "relative"
    },
    photo: {
        width: 40,
        height: 40,
        borderRadius: 250,
        marginTop: 5,
        marginBottom: 5
    },
    itemTableSpan: {
        fontSize: 14
    },
    iconBtn: {
        cursor:'pointer',
        color:'red !important'
   },
}

export default React.memo(Categoria);