import React, { useContext, useState } from "react"
import { Row, Col } from "react-materialize"
import {  Button } from "../../../../components/Form"
import FormTorneioContext, {actions} from "../../FormTorneioContext"
import { css } from "emotion"
import ModalCategoria from "./ModalCategoria"
import TableCategorias from "./TableCategorias"

export default (props) => {

    const {dispatch, ref_form} = useContext(FormTorneioContext)
    const [openModal, setOpenModal] = useState(false)

    const nextPage = (e) => {
        e.preventDefault()
        if(!ref_form.current.reportValidity()){
            return
        }
        dispatch({type: actions.CHANGE_PAGE,payload: 2})
    }

    return <div>
        <Row>
            <Col s={6} l={6}>
                <ModalCategoria open={openModal} toggle={setOpenModal}/>
            </Col>
        </Row>
        <Row>
            <Col s={12} l={12}>
                <TableCategorias />
            </Col>
        </Row>
        <Row className={css(styles.btnProxDiv)}>
            <Col m={12} s={12} l={6} className="offset-l3">
                <Button onClick={nextPage}>Próximo</Button>
            </Col>
        </Row>
    </div>
}

const styles = {
    endSub: {
        fontFamily: "Roboto"
    },
    btnProxDiv: {
        marginTop: 55
    },
}