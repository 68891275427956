import actions from "./actions";
import moment from "moment"

export default (state, action) => {
    switch(action.type){
        case actions.HANDLE_CHANGE:
            return {...state, [action.payload.name] : action.payload.value}
        case actions.CHANGE_END_DATE:
            return {...state, endDate : action.payload}
        case actions.CHANGE_PAGE:
            return {...state, currentPage : action.payload}
        case actions.HANDLE_ZIP_CODE:
            return {...state, 
                zipCode : action.payload.zipCode,
                street : action.payload.street,
                neighborhood : action.payload.neighborhood,
                city : action.payload.city,
                state : action.payload.state,
            }
        case actions.ADD_TOURNAMENTS: {
            return {...state, tournaments: action.payload}
        }
        case actions.ADD_CATEGORIES: {
            return {...state, categories: action.payload}
            
        }
        case actions.ADD_CATEGORIES_TOURNAMENT: {
            let categories = state.categories
            categories = {...categories, ...action.payload}
            return {...state, categories}
        }
        case actions.REMOVE_TOURNAMENT: {
            const tournaments = state.tournaments;
            delete tournaments[action.payload]
            return {...state, tournaments: {...tournaments}}
        }
        case actions.PREVENT_SUBMIT: {
            return {...state, preventSubmit: action.payload}
        }
        case actions.UPDATE_WARNING_CATEGORIES_END_SUBSCRIPTION: {
            return {...state, 
                    categoriesWarning: action.payload.categoriesWarning,
                    endDateSubscription: action.payload.dateLotsGreater && action.payload.dateLotsGreater != -Infinity ? moment(action.payload.dateLotsGreater).format("DD/MM/yyyy") : ""
                }
        }
        case actions.SPORT_LIST_UPDATE: {
            return {...state, listSports: action.payload}
        }
        case actions.START_INFO_EVENT: {
            return {...state, 
                idEvent: action.payload.idEvent,
                name: action.payload.name,
                city: action.payload.city,
                startEvent: action.payload.startEvent,
                endEvent: action.payload.endEvent,
                pictureUrl: action.payload.pictureUrl,
                description: action.payload.description,
                street: action.payload.street,
                number: action.payload.number,
                neighborhood: action.payload.neighborhood,
                complement: action.payload.complement,
                site: action.payload.site,
                phone: action.payload.phone,
                state: action.payload.state,
                zipCode: action.payload.zipCode,
                ativo: action.payload.ativo,
                tournaments: action.payload.tournaments,
                categories: action.payload.categories,
                patrocinadores: action.payload.patrocinadores,
                pictureRouteUrl: action.payload.pictureRouteUrl,
                hasRule: action.payload.hasRule,
                cronometro: action.payload.cronometro,
                trans_nome: action.payload.trans_nome,
                trans_cpf_cnpj: action.payload.trans_cpf_cnpj,
                trans_banco: action.payload.trans_banco,
                trans_agencia: action.payload.trans_agencia,
                trans_conta: action.payload.trans_conta,
                block: action.payload.block
            }
        }
        case actions.LOADING_PUB: {
            return {...state, 
                loadingPub: action.payload
            }
        }
        case actions.ADD_NEW_PATROCINADOR: {
            const fake_id = moment().valueOf()
            return {...state, 
                patrocinadores: [...state.patrocinadores, {
                    id: fake_id,
                    fk_patrocinador_id: fake_id,
                    newId: true
                }]
            }
        }
        case actions.REMOVE_PATROCINADOR: {
            let patrocinadores = [...state.patrocinadores]
            const newState = {...state}
            if(!patrocinadores[action.payload].newId){
                newState.patrocinadores_del = [...newState.patrocinadores_del, patrocinadores[action.payload].id]
            }
            patrocinadores.splice(action.payload,1)
            newState.patrocinadores = patrocinadores

            return newState
        }
        case actions.HANDLE_PATROCINADOR: {
            const patrocinadores = [...state.patrocinadores]
            patrocinadores[action.payload.index][action.payload.name] = action.payload.value
            return {...state, 
                patrocinadores
            }
        }
        case actions.SET_SCORING_RULES: {
            return {...state, scoringRules: action.payload}
        }
        case actions.ADD_SCORING_RULES: {
            return {...state, scoringRules: [...state.scoringRules, ...action.payload]}
        }
        case actions.ADD_SCORING_RULE: {
            return {...state, scoringRules: [...state.scoringRules, action.payload]}
        }
        case actions.HANDLE_SCORING_RULES: {
            let scoringRules = [...state.scoringRules]
            scoringRules[action.index] = action.payload
            return {...state, scoringRules: scoringRules}
        }
        case actions.REMOVE_SCORING_RULES: {
            let scoringRules = [...state.scoringRules]
            scoringRules[action.index].regras.pop()
            return {...state, scoringRules: scoringRules}
        }
        case actions.HANDLE_HAS_RULE: {
            return {...state, hasRule: action.payload}   
        }
        case actions.ADD_TOURNAMENTS_RULES: {
            return {...state, scoringRulesTourn: action.payload}   
        }
        default:
            return state;
    }
}
