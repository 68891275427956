export default {
    idEvent: null,
    name: "",
    startEvent: null,
    endEvent: null,
    zipCode: "",
    street: "",
    number: "",
    complement: "",
    neighborhood: "",
    city: "",
    state: "",
    description: "",
    picture: [],
    pictureRoute: [],
    pictureUrl: [],
    pictureRouteUrl: [],
    site: "",
    phone: "",
    endDateSubscription: null,
    categories: "",
    tournaments: {},
    preventSubmit: false,
    loadingPub: false,
    ativo: true,
    listSports: [],
    categoriesWarning: [],
    currentPage: 0,
    patrocinadores: [],
    patrocinadores_del: [],
    scoringRules: [],
    scoringRulesTourn: [],
    hasRule: "1",
    cronometro: false,
    trans_nome: "",
    trans_cpf_cnpj: "",
    trans_banco: "",
    trans_agencia: "",
    trans_conta: ""
}