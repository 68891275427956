import React, {useContext, useState, useEffect} from "react"
import DataTable from 'react-data-table-component';
import FormEventoContext,{ actions } from "../../FormEventoContext";
import { css } from "emotion";

const Categoria = () => {

    const {state, dispatch} = useContext(FormEventoContext)
    const [values,setValues] = useState([])

    const onDelete = (id) => {
        dispatch({type: actions.REMOVE_TOURNAMENT, payload: id})
    }
    
    useEffect(() => {
        setValues(Object.values(state.tournaments))
    },[state.tournaments])
    

    const columns = [
        {
            name: '',
            selector: 'photo',
            cell:(row) => {
                return( 
                    <img src={row.photo} className={css(styles.photo)}/>
                );
            }
        },
        {
            name: 'Nome ',
            selector: 'name',
            sortable: true,
            style:{display: "inline-grid"},
            cell:(row) => {
                return(<div > 
                    <div><span className={css(styles.itemTableSpan)}>{(row.name)}</span></div>
                </div>);
            }
        },
        {
            name: 'Edição do Torneio',
            selector: 'edition',
            sortable: true,
            style:{display: "inline-grid"},
            cell:(row) => {
                return(<div > 
                    <div><span className={css(styles.itemTableSpan)}>{(row.edition)}</span></div>
                </div>);
            }
        },
 
        {
            name: 'Excluir',
            ignoreRowClick: true,
            cell:(row) => 
            <div>
                <span className={"material-icons modal-trigger  "+css(styles.iconBtn)} href="#modal1" id={row.id} onClick={() => onDelete(row.id) } >delete</span>
            </div>,
        }
        
    ]
    return  <div> 
        <DataTable
            columns={columns}
            data={values}
            highlightOnHover={true}
            pointerOnHover={true}
            noHeader={true}
            noDataComponent={""}
        />
    </div>
}

const styles={
    newEvento : {
        float:"left"
    },
    main: {
        paddingBottom: 20
    },
    photo: {
        width: 40,
        height: 40,
        borderRadius: 250,
        marginTop: 5,
        marginBottom: 5
    },
    itemTableSpan: {
        fontSize: 14
    },
    iconBtn: {
        cursor:'pointer',
        color:'red !important'
   },
}

export default React.memo(Categoria);