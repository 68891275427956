import { css } from "emotion"
import React from "react"
import { BeatLoader } from "react-spinners"

export default ({open, mainStyle, contentStyle, toggle,btnFooterText, title, children, onClickBtnFooter, onClose, loading, loadingColor, iconCloseCss, headerCss}) => {
    return <>
        {open && <div className={css(styles.main, mainStyle)}>
                <div className={css(styles.header, headerCss)}>
                    <span onClick={() => {onClose ? onClose() : toggle(false)}} className={css(styles.iconClose, iconCloseCss)+ " material-icons"}>close</span>
                    <span className={css(styles.title)}> {title} </span>
                </div>
                <div className={css(styles.content, contentStyle)}>
                    {children}
                </div>
                {loading && <BeatLoader
                    css={{textAlign: "center"}}
                    color={loadingColor ? loadingColor : "#6B69CF"}
                    loading={true}
                /> }
                {btnFooterText && !loading && <div onClick={onClickBtnFooter} className={css(styles.btnFooter)}>
                    {btnFooterText}
                </div>}
            </div>
        }
    </>
}

const styles = {
    iconClose: {
        position: "relative",
        float: "left",
        userSelect: 'none'
    },
    title: {
        position: "relative",
        float: "left",
        marginLeft: 20,
        fontSize: 18
    },
    main: {
        top: 0,
        left: 0,
        position: "absolute",
        minHeight: "100vh",
        height: "100%",
        width: "100vw",
        zIndex: 1000,
        backgroundColor: "#fff"
    },
    header: {
        top: 0,
        left: 0,
        position: "absolute",
        width: "100vw",
        height: 50,
        paddingTop: 10,
        paddingLeft: 10
    },
    content: {
        marginTop: 70,
        paddingBottom: 50
    },
    btnFooter: {
        borderTopRightRadius: 10,
        borderTopLeftRadius: 10,
        bottom: 0,
        left: 0,
        width: "100vw",
        position: "fixed",
        height: 40,
        backgroundColor: "#6B69CF",
        color: "#fff",
        fontSize: 16,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        userSelect: 'none',
        zIndex: 999
    },
    loading: {
        bottom: 0,
        left: 0,
        width: "100vw",
        position: "fixed",
        height: 40,
        backgroundColor: "#6B69CF",
        color: "#fff",
        fontSize: 16,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        userSelect: 'none'
    }
  
}