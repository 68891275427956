import DataTable from "react-data-table-component";
import { BarLoader } from "react-spinners"
import metalha_ouro from "../../../assets/icons/medalha-ouro.png"
import metalha_prata from "../../../assets/icons/medalha-prata.png"
import metalha_bronze from "../../../assets/icons/medalha-bronze.png"
import medalha_participacao from "../../../assets/icons/medalha-participacao.png"
import MiniCards from "../../../components/MiniCards";
import { css } from "emotion";
import { Col, Row } from "react-materialize";
import { Select } from "../../../components/Form";
import useSports from "../../../hooks/useSports";
import { useEffect } from "react";
export default ({
    loadingTable,
    tournaments,
    labelAchievements,
    achievements,
    tournamentId,
    events,
    eventId,
    setEventId,
    genres,
    genre,
    setGenre,
    sport,
    categories,
    setSport,
    category,
    setCategory,
    subCategories,
    setSubCategory,
    subCategory
}) => {
    const iconsPositions = [metalha_ouro,metalha_prata,metalha_bronze,medalha_participacao] 

    const sports = useSports({evento_id: eventId, torneio_id: tournamentId}, (sports) => {
        sports = sports[0] ? sports[0] : {}
        setSport(sports.value)
    })

    const columns = [
        {
            selector: 'img_medalha',
            cell: ({position}) =>  position && <img className={css(styles.imgAchievement)} src={iconsPositions[position > 4 ? 3 : position-1]} />
        },
        {
            selector: 'position',
            cell: ({position}) =>  (position ? position : 0) + "º"
        },
        {
            selector: 'nome',
            sortable: true,
        },
        {
            selector: 'data',
            sortable: true,
        },
        {
            selector: 'categoria_evento',
            sortable: true,
        },
        {
            selector: 'tipo_categoria_evento',
            sortable: true,
        },
        {
            selector: 'pontuacao',
            sortable: true,
        },
        {
            selector: 'tempo',
            sortable: true,
            omit: (!eventId && tournamentId)
        },
      ];

    const customStyles = {
        rows: {
            style: {
                marginTop: 30,
                backgroundColor:"#fff",
                boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.15)",
                borderRadius: 9
            }
        },
        table: {
            style: {
                backgroundColor:"transparent",
               // maxWidth: "70%"
            }
        }
    };

   


    return <div className={css(styles.container)}>
        <div className={css(styles.title)}>
            <span className={css(styles.titleSpan)}>Torneios em andamento</span>
        </div>
        <MiniCards
            data={tournaments}
        />
        <div className={css(styles.title)}>
            <span className={css(styles.titleSpan)}>{labelAchievements}</span>
        </div>
        {tournamentId != 0 && <Row > 
            <Col s={6} l={2}>
                <Select
                    label={"Eventos:"}
                    options={events}
                    onChange={(data) => setEventId(data.value)}
                    value={events.filter(option => option.value == eventId)}
                />
            </Col>
            <Col s={6} l={2}>
                <Select
                    label={"Sexo:"}
                    options={genres}
                    onChange={(data) => setGenre(data.value)}
                    value={genres.filter(option => option.value == genre)}
                />
            </Col>
            <Col s={6} l={2}>
                <Select
                    label={"Esporte:"}
                    options={sports}
                    onChange={(data) => setSport(data.value)}
                    value={sports.filter(option => option.value == sport)}
                />
            </Col>
             <Col s={6} l={2}>
                <Select
                    label={"Categorias:"}
                    options={categories}
                    onChange={(data) => setCategory(data.value)}
                    value={categories.filter(option => option.value == category)}
                />
            </Col>
            <Col s={6} l={2}>
                <Select
                    label={"Sub-Categoria:"}
                    options={subCategories}
                    onChange={(data) => setSubCategory(data.value)}
                    value={subCategories.filter(option => option.value == subCategory)}
                />
            </Col> 
        </Row>}
        <div className={css(styles.achievements)}>
            <DataTable
                progressComponent={<BarLoader css={styles.loadingCss} color={"#110F62"}/>}
                progressPending={loadingTable}
                columns={columns}
                data={achievements}
                customStyles={customStyles}
                noHeader
                noTableHead
                noDataComponent={""}
            />
        </div>
    </div>
}

const styles = {

    loadingCss: {
        width: "100%"
    },
    achievements: {
        width: "80%",
    },
    lineAchievement: {
        width: "100%",
        marginTop: 30,
        height: 64,
        backgroundColor: "#fff",
        boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.15)",
        borderRadius: 9,
        display: 'flex',
        position: "relative"
    },
    container: {
        marginLeft: 30,
        marginRight: 30,
        paddingTop: 10
    },
    title: {
        paddingTop: 20,
    },
    titleSpan: {
       
        fontFamily: "Roboto",
        fontSize: 26
    },
    imgAchievement: {
        width: 35
    },
    itemLineAch: {
        flex: 1,
        marginTop: 10,
        marginLeft: 10
    },


}