import React, { useState, useEffect, useContext } from "react"
import { Row, Col } from "react-materialize"
import {InputSearch, ButtonClean} from "../../components/Form"
import {
    Link, useHistory 
  } from "react-router-dom";

import DataTable from 'react-data-table-component';
import Api from "../../api/Api"
import config from "../../config.js"
import ModalDelete from "../../components/ModalDelete"
import { toast } from "react-toastify";
import { useCookies} from "react-cookie"
import {BarLoader, ClipLoader} from "react-spinners";
import { css } from 'emotion'
import moment from "moment"
import eventBanner from "../../assets/img/event-banner.svg"
import MainContext from "../MainContext";
import useIdOrganizer from "../../hooks/useIdOrganizer";

export default () => {
    const [cookies] = useCookies(['id','nome']); 
    const [filter,setFilter] = useState("")
    const [data, setData] = useState([])
    const [dataFiltered, setDataFiltered] = useState([])
    const [openDelete, setOpenDelete] = useState(false)
    const [idDelete, setIdDelete] = useState(0)
    const [loadingTable, setLoadingTable] = useState(false);
    const user_id = useIdOrganizer();

    const history = useHistory();

    useEffect(() => {
        findData()
    },[])

    useEffect(() => {
        if(data){
            const newDate = data.filter((item) => item.name.match(`${filter}.*`))
            setDataFiltered(newDate)
        }

    },[filter])

    const findData = async () => {
        try {
            setLoadingTable(true)
            const result = await Api().post("/eventos/listar",{...filter,  user_id})
            const resultData = result.data.eventos ? result.data.eventos : []
            let internalData = [];
            resultData.map((value) => {
                internalData.push({
                    id: value.id,
                    name: value.nome,
                    photo: value.imagem ? config.storage_link+`/evento/${value.id}/${value.imagem}` : "",
                    dateStart: value.data_inicio,
                    street: value.logradouro,
                    city: value.cidade,
                    state: value.uf,
                    neighborhood: value.bairro,
                    pub: value.publicado ? "Publicado" : "Não Publicado",
                    number: value.numero
                })
            })
            setData(internalData)
            setDataFiltered(internalData)
            setLoadingTable(false)
        }catch(e){
            const response = e.response ? e.response : {};
            const data = response.data ? response.data : {};
            const msg = data.msg ? data.msg[0] : "Ocorreu um erro ao buscar os eventos";
            toast.error(msg)
        }

    }

    const deleteEvent = async () => {
       
        const eventos = data.filter((elem) => {
            return elem.id != idDelete
        })
        const eventosFiltered = dataFiltered.filter((elem) => {
            return elem.id != idDelete
        })

        try {
            const result = await Api().delete("eventos/deletar/"+idDelete)
            if(!result.data.error){
                toast.success("Evento deletado com sucesso")
                setData(eventos)
                setDataFiltered(eventosFiltered)
                setOpenDelete(false)
            }
        } catch(e){
            const response = e.response ? e.response : {};
            const data = response.data ? response.data : {};
            const msg = data.msg ? data.msg[0] : "Ocorreu um erro ao tentar deletar o evento";
            toast.error(msg)
            setOpenDelete(false)
        }

    }

    const download = (url) => {
        window.open(config.url+url,"_blank")
    }

    const columns = [
        {
            name: '',
            selector: 'photo',
            cell:(row) => {

                return <ImageEventTable row={row}/>
            }
        },
        {
            name: 'Data',
            selector: 'date',
            sortable: true,
            cell:(row) => {
                return(<div > 
                    <div><span className={css(styles.itemTableSpan)}>{moment(row.dateStart).format("DD/MM/YYYY")}</span></div>
                </div>);
            }
        },
        {
            name: 'Nome',
            selector: 'name',
            sortable: true,
            cell:(row) => {
                return(<div > 
                    <div><span className={css(styles.itemTableSpan)}>{(row.name)}</span></div>
                </div>);
            }
        },
        {
            name: 'Local',
            selector: 'local',
            sortable: true,
            cell:(row) => {
                return(<div > 
                    <div><span className={css(styles.itemTableSpan)}>{row.city} - {row.state}</span></div>
                </div>);
            }
        },
        {
            name: 'Publicado',
            selector: 'pub',
            sortable: true,
            cell:(row) => {
                return(<div > 
                    <div><span className={css(styles.itemTableSpan)}>{row.pub}</span></div>
                </div>);
            }
        },
        {
            name: 'Editar',
            cell:(row) => 
            <div>
                <span className={"material-icons modal-trigger  "+ css(styles.iconBtn2)} id={row.id} onClick={ () => { onClickLineTable(row.id)} } >edit</span>
            </div>,
        },
        {
            name: 'Inscritos',
            cell: (row) => <span 
            onClick={() => download("exportar/inscritos/"+row.id)}
            className={css(styles.iconDownload) + " material-icons"}>download</span>
        },
        {
            name: 'Excluir',
            cell:(row) => 
            <div>
                <span className={"material-icons modal-trigger  "+ css(styles.iconBtn)} href="#modal1" id={row.id} onClick={ () => { setIdDelete(row.id); setOpenDelete(true);} } >delete</span>
            </div>,
        }
        
    ]

    const onClickLineTable = (id) => {
        history.push(`/eventos/${id}/editar`)
    }

    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    return <div className={css(styles.main)}>
        <Row>
            <Col s={8} m={9}>
                <h6 className={css(styles.titleEvent)}>Meus Eventos</h6>
                <div>
                    <span>{capitalizeFirstLetter(moment().format("dddd, DD [de] MMMM [de] YYYY"))}</span>
                </div>
            </Col>
            <Col s={4} m={3}>
                <InputSearch 
                    id="name"
                    value={filter}
                    onChange={(e) => setFilter(e.target.value)}
                    label={"Buscar"} 
                />
            </Col>
        </Row>
        <Row>
            <div className={css(styles.boxEventos)}>
                <div className={css(styles.titleBox)}>
                    <span className={css(styles.titleBoxSpan)}>Olá, {cookies.nome}</span>
                </div>
                <div className={css(styles.textBox)}>
                    <span className={css(styles.textBoxSpan)}>
                        Construimos uma plataforma pensando em você, vamos juntos transformar o Brasil no maior medalhista do mundo produzindo competições de alto nível para lapidar nossos atletas. 
                    </span>
                </div>
                <img src={eventBanner} className={css(styles.imgBanner)}/>
            </div>
        </Row>
        <Row className={css(styles.rowNovoEvento)}>
            <Col s={12} m={4}>
                <Link to={"eventos/novo"} className={css(styles.linkNovoEvento)}>
                    <ButtonClean icon={"plus"}>
                        Novo Evento
                    </ButtonClean>
                </Link>
            </Col>
        </Row>
        <DataTable
            progressComponent={<BarLoader css={styles.loadingCss} color={"#110F62"}/>}
            progressPending={loadingTable}
            persistTableHead
            columns={columns}
            data={dataFiltered}
            noHeader={true}
            overflowY={true}        
            noDataComponent={"Não existem dados para mostrar"}
        />

        <ModalDelete 
        open={openDelete}
        onDelete={deleteEvent}
        onClose={() => setOpenDelete(false)}>
            Você realmente deseja excluir esse evento?
        </ModalDelete>
    </div>
}

const ImageEventTable = ({row}) => {
    const contextMain = useContext(MainContext)
    let imageLoading = null;
    contextMain.state.images_upload.forEach((item) => {
        console.log(item)
        if(item.id == row.id && item.type_key == "evento_id"){
            if(item.status == "UPLOADING" || item.status == "TO_UPLOAD"){
                imageLoading = <ClipLoader color={"#110F62"} css={css(styles.loadingImg)}/> 
            } else if(item.status == "SUCCESS"){
                row.photo = item.image ? config.storage_link+`/evento/${row.id}/${item.image}` : ""
            } else if(item.status == "ERROR"){

            }
        }
    })

    return( row.photo ? 
        <img src={row.photo} className={css(styles.photoEvent)}/> : imageLoading
    );
}

const styles={
    iconDownload: {
        cursor: "pointer"
    },
    loadingImg: {
        backgroundColor: "#fff"
    },
    titleEvent: {
        fontWeight: "bold"
    },
    loadingCss: {
        width: "100%"
    },
    newEvento : {
        float:"left"
    },
    rowNovoEvento: {
        paddingTop: "20px"
    },
    linkNovoEvento: {
        color: "#000",
    },
    itemTableSpan: {
        fontSize: 14
    },
    novoEventoSpan: {
        fontFamily: "Roboto",
        fontSize: 14,
        marginLeft: 10,
    },
    iconBtn: {
         cursor:'pointer',
         color:'red !important'
    },
    iconBtn2: {
        cursor:'pointer',
   },
    textBox: {
        paddingLeft: 20,
        width: "65%"
    },
    textBoxSpan: {
        fontSize: 12,
        color: "#110F62"
    },
    titleBox: {
        paddingTop: 20,
        paddingLeft: 20
    },
    titleBoxSpan: {
        color: "#110F62",
        fontFamily: "Roboto",
        fontSize: 20,
        marginTop: 20
    },
    main: {
        paddingBottom: 20
    },
    photoEvent: {
        width: 40,
        height: 40,
        borderRadius: 250,
        marginTop: 5,
        marginBottom: 5
    },
    imgBanner: {
        position: "absolute",
        top: -10,
        right: 0
    },
    divNovoEvento: {
        display: "inline-flex",
        alignItems: "center"
    },
    boxEventos: {
        position: "relative",
        width: "95%",
        fontFamily: "Roboto",
        height: 150,
        borderRadius: 20,
        backgroundColor: "#CFCEF2",
        boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.25)",
        "@media(max-width: 850px)": {
            display: "none"
        },
    }
}