import { useReducer } from "react"
import ConviteEquipe from "./ConviteEquipe"
import { initialState, MinhasEquipesProvider, reducer } from "./MinhasEquipesContext"

const Component = (props) => {
    const [state, dispatch] = useReducer(reducer, initialState)
    
    return <MinhasEquipesProvider value={{state, dispatch}}>
        <ConviteEquipe />
    </MinhasEquipesProvider>
}

export default Component;