import { css } from "emotion"
import React from "react"
import { Col, Container, Row } from "react-materialize"

const Component =  ({picture, name, dateStart, city, state, buttons}) => {
    return <>
        <div className={css(styles.headerCapa)}>
            <div className={css(styles.divButtons)}>
                {buttons && buttons.map((ButtonItem) => {
                    return ButtonItem
                })}
            </div>
        </div>
        <div className={css(styles.viewImageEvento)}>
            <Row >
                <Col className={css(styles.imgEventoDiv)} s={12} l={6}>
                    {picture != "" && <img src={picture} width={"100%"} height={"100%"} className={css(styles.imgEvento)}/>}
                </Col>
            </Row>
        </div>
        <div className={css(styles.conteudoEvento)}>
            <Row>
                <Col s={12} l={8} offset={"l4"} >
                    <Container>
                        <Row>
                            <h4 className={css(styles.titleEvento)}>{name}</h4>
                        </Row>
                        <Row>
                            <span className={css(styles.dateStart)}>{dateStart}</span>
                        </Row>
                        <Row>
                            <span className={css(styles.dateEnd)}>{city} - {state}</span><br/>
                        </Row>
                        
                    </Container>
                </Col>
            </Row>
        </div>
    </>
}

const styles = {
    imgEventoDiv: {
        height: "15rem",
       
    },
    imgEvento: {
        objectFit: 'cover',
        objectPosition: '0%',
        "@media(max-width: 992px)": {
            objectFit: 'contain',
            objectPosition: 'center center'
        },
    },
    headerCapa: {
        height: 160,
        width: "100%",
        position: "relative",
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundColor: "#2F2E41"
    },
    viewImageEvento: {
        position: "absolute",
        zIndex: 2,
        top: 80,
        left: "5%",
        width: "70%",
        "@media(max-width: 992px)": {
            left: "0%",
            width: "100%",
        },
    },
    divResultado: {
        position: "absolute", 
        right: 50, 
        bottom: 50,
        border: "1px solid",
        padding: "5px 15px",
        cursor: "pointer",
        borderRadius: 25,
        color: "#fff",
        "@media(max-width: 700px)": {
            right: 10, 
            top: 10,
            bottom: 'auto',
        },
    },
    titleEvento: {
        "@media(max-width: 992px)": {
            marginLeft: -10,
            textAlign: 'center',
        },
        fontWeight: 600,
        fontFamily: "Roboto",
        color: "#606060"
    },
    dateStart: {
        color: "#606060",
        fontFamily: "Roboto",
        fontSize: 16,
    },
    dateEnd: {
        color: "#606060",
        fontSize: 16,
        fontFamily: "Roboto",
    },
    conteudoEvento: {
        marginLeft: 10,
      
        "@media(max-width: 992px)": {
            marginTop: '18vh'

        },
    },
    divButtons: {
        zIndex: 999,
        position: "absolute", 
        right: 50, 
        bottom: 50,
        "@media(max-width: 700px)": {
            right: 10, 
            top: 10,
            bottom: 'auto',
        },
    },
}

export default React.memo(Component)