import React, { useState, useEffect, useContext} from "react"
import {CurrencyInput, DatePicker} from "../../../../components/Form"
import moment from "moment"
import { Row, Col } from "react-materialize"
import { css } from "emotion"
import FormEventoContext from "../../FormEventoContext/FormEventoContext" 

const Categoria = (props) => {

    const {state} = useContext(FormEventoContext);
 
    const [data,setData] = useState([])

    
    const onDelete = (id) => {
         const lots = props.lots;
         delete lots[id]
         props.setLots({...lots})
    }

    const addLot = () => {
        const id = moment().valueOf()
        const newLot = { [id] : {id, index: Object.values(props.lots).length}}
        props.setLots({...props.lots,...newLot})
    }

    const onChangeField = (field,id,value) => {
        let lot = props.lots[id]
        lot[field] = value
        props.setLots({...props.lots, [id] : lot })
    } 


    useEffect(() => {
        setData(Object.values(props.lots)) 
    },[props.lots])


    return <div > 
    {data.map((row,i) => {
        let startDate = props.lots[row.id] ? props.lots[row.id].dataInicio : null
        let endDate = props.lots[row.id] ?  props.lots[row.id].dataFim : null;


        let minDateStart = new Date()
        let maxDateStart = null
        let maxDateEnd = null

        if(data[i-1]){
            if(data[i-1].dataFim){
                minDateStart = addDays(1,data[i-1].dataFim)
            } else if(data[i-1].dataInicio) {
                minDateStart = addDays(1,data[i-1].dataInicio)
            }
        } 

        if(state.endEvent){
            maxDateStart = state.endEvent
            maxDateEnd = state.endEvent
        }

        minDateStart.setHours(0,0,0,0)

        const validStartChange = (startDate && endDate && endDate < startDate) && 
        startDate >= new Date()

        if(validStartChange){
            onChangeField("dataFim",row.id,null)
        }

        
        const validMinStartChange = (startDate && minDateStart > startDate) && 
        startDate >= new Date()

        if(validMinStartChange){
            onChangeField("dataInicio",row.id,null)
            onChangeField("dataFim",row.id,null)
        }

        return <Row key={"lot"+i}>
            {!props.gratuito && <Col s={3} m={4}>
                <InputLot 
                    index={(row.index)} 
                    mask={"R$ "}
                    value={props.lots[row.id] ? props.lots[row.id].preco : null}
                    onBlur={(value) => onChangeField("preco",row.id,value)}
                    placeholder={"Valor:"}/>
            </Col>}
            <Col s={4} m={3}>
                <DatePicker 
                        label={"Data Inicial:"} 
                        value={startDate}
                        minDate={minDateStart}
                        maxDate={maxDateStart}
                        onChange={(value) => onChangeField("dataInicio",row.id,value)}
                        placeholder="Data Inicial"/>
            </Col>
            <Col s={4} m={3}>
                <DatePicker label={"Data Final:"} 
                        placeholder="Data Inicial"
                        minDate={startDate ? startDate : minDateStart}
                        maxDate={maxDateEnd}
                        value={endDate}
                        onChange={(value) => onChangeField("dataFim",row.id,value)}
                        />
            </Col>
            {!props.gratuito && <Col s={1} m={2}>
                <div className={css(styles.buttons)}>
                    {row.index == data.length-1 && <span className={css(styles.span_add)+ " material-icons modal-trigger"} href="#modal1" id={row.id} onClick={ () => addLot() }>control_point</span>}
                    {row.index > 0 && row.index == data.length-1 && <span className={css(styles.span_remove)+ " material-icons modal-trigger"} href="#modal1" id={row.id} onClick={ () => onDelete(row.id) }>delete</span>}
                </div>
            </Col>}
        </Row>
    })}
    
    </div>
}

const addDays = (days,date) => {
    date = date ? new Date(date) : new Date();
    date.setDate(date.getDate() + days);
    return date;
}

const InputLot = (props) => {
    const [value,setValue] = useState(0)
    useEffect(() => {
        setValue(props.value)
    },[props.value])

    console.log(value)
    return <CurrencyInput 
        label={"Lote "+(props.index+1)} 
        key={"lot"+props.id}
        onBlur={() => props.onBlur(value)}
        value={value}
        required={true}
        onChange={(event,maskedvalue, floatvalue) => setValue(floatvalue)}
        placeholder={"Valor:"}/>
}

const styles = {
    buttons: { marginTop: "30px"},
    span_add:{ cursor:'pointer',color:'green'},
    span_remove: { cursor:'pointer',color:'red'}
}

export default React.memo(Categoria);