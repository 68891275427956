import React from "react"
import CurrencyInput from 'react-currency-input'

export default (props) => {
    return <div style={style.divcomponent}> 
        <label 
        htmlFor={props.id} 
        className="left-align" 
        style={style.label}>{props.label}</label>
        <CurrencyInput 
            value={props.value}
            prefix={"R$ "}
            decimalSeparator="," 
            thousandSeparator="."
            onChangeEvent={props.onChange}
            onBlur={props.onBlur}
            style={style.input}
            required={props.required}
        />
    </div>
}


const style = {
    label : {
        color: "#000" 
    },
    divcomponent : {
        width: "100%",
        whiteSpace: "break-spaces"
    },
    input: {
        width: "100%"
    }
}