export default {
    picture: "",
    pictureRoute: [],
    description: "",
    name: "",
    email: "",
    city: "",
    state: "",
    id_organizer: "",
    organizer: "",
    site: "",
    dateStart: "",
    dateStart2: "",
    categories: [],
    sportChecked: "",
    lots: [],
    total: "00,00",
    totalNumber: 0,
    patrocinadores: [],
    endDateSubscription: null,
    otherEventsOrganizer: [],
    loadingOtherEvents: true,
    loading: false
}
