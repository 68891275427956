import React, { useState } from "react"
import { Link, useHistory } from "react-router-dom";
import ModalPolitica from "../Politica/ModalPolitica";
import {Cookies, useCookies} from "react-cookie"
import {css} from "emotion"
export default () => {
    const [,,removeCookie] = useCookies()
    const history = useHistory()
    return <div>
        <ul className={"card " + css(styles.subMenu)}>
                <li style={styles.li}> 
                    <Link to={"/atleta/carteira"}  style={styles.linkMenu}> 
                        <span className="material-icons" style={styles.icon}>account_balance_wallet</span> 
                        <span>Carteira</span> 
                    </Link>  
                </li>
                <li style={styles.li}> 
                    <div onClick={() => {removeCookie("access_token"); history.replace("/"); } } style={styles.linkMenu}> 
                        <span className="material-icons" style={styles.icon}>logout</span> 
                        <span>Sair</span> 
                    </div>  
                </li>
            </ul>
    </div>
}

const styles = {
    subMenu: {
        color: "rgb(4, 122, 200)",
        top: 58,
        zindex: 100,
        position: "absolute",
        width: "100%",
        borderRadius: 10,
        paddingTop: 10,
        paddingBottom: 20,
        paddingLeft: "30px !important",
        borderColor: "rgb(4, 122, 178)",
        backgroundColor: "rgb(255, 255, 255)",
        border: "0.75px solid"
    },
    sideMenu: {
        width: 180,
      
    },
    itemMenu: {
        marginTop: 3,
        paddingLeft: 5
    },
    itemMenuText: {
        fontSize: 20,
        fontWeight: 500
    },
    li: {
        color: "#000",
        width:"100%",
        height: 30,
        marginTop: 10,
        lineHeight: "30px"
    },
    icon: {
        verticalAlign: "bottom"
    },
    linkMenu: {
        color: "#000"
    }
}