import React, { useState, useContext, useCallback, useEffect } from "react"
// import {Modal} from "react-materialize"
import ImageUploader from 'react-images-upload';
import {Card, Row, Col } from "react-materialize"
import {Button, Input, DateTimePicker, DatePicker, RichTextEditor, Select, ButtonClean} from "./Form"

import { Modal } from 'react-responsive-modal';
import ModalMobileFullScreen from "./ModalMobileFullScreen";
import { css } from "emotion";
import { BeatLoader } from "react-spinners";

const Component = (props) => {

    const [loading, setLoading] = useState(false)

    const onDelete = async () => {
        setLoading(true)
        if(props.onDelete) {
            await props.onDelete();
        }
        setLoading(false)
    }

    useEffect(() => {
        if(props.open){
            window.scrollTo(0, 0)
        }
    },[props.open])

    return <ModalMobileFullScreen
        open={props.open}
        onClose={props.onClose}
        mainStyle={styles.modal}
        contentStyle={styles.modalContent}
    >
        <div className={css(styles.container)}>
            <div className={css(styles.msg)}>
                <span>{props.children}</span>
                <div className={css(styles.divBtns)}>
                    {loading && <BeatLoader
                        css={{textAlign: "center"}}
                        color={"#6B69CF"}
                        loading={true}
                    /> }
                    {!loading &&
                        <ButtonClean icon={"del"}  cssImg={styles.iconDel} onClick={onDelete} className={css(styles.itemBtnTitle)}/>
                    }
                </div>
            </div>
        </div>
       
    </ModalMobileFullScreen>
}

const styles = {
    container: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "80%"
    },
    modalContent: {
        height: "100%",

    },
    modal: {
       opacity: 0.9,
        //backgroundColor: "rgba(0, 0, 0, 0.1)",
        height: "100%",
       // position: "fixed",
      //  zIndex: 999999
    },
    msg: {
        fontSize: 20,
        width: "70%",
        textAlign: "center",
        color: "#000"
    },
    iconDel: {
        width: 50
    },
    divBtns: {
        marginTop: 15
    }
}

export default React.memo(Component)