import { css } from "emotion";
import { useContext, useState } from "react";
import DataTable from "react-data-table-component";
import { Button, Input } from "../../../../../components/Form";
import ModalDeleteMobile from "../../../../../components/ModalDeleteMobile";
import ModalMobileFullScreen from "../../../../../components/ModalMobileFullScreen"
import config from "../../../../../config";
import JuizCronometroContext, { actions } from "../../../JuizCronometroContext";
import ModalLargadaCategoriaMobile from "../ModalLargadaCategoriaMobile"
import ModalListTimersMobile from "./ModalListTimersMobile";

export default ({
    openDelete, 
    setOpenDelete,
    setIdDel,
    openModalLargadaCategoria, 
    setOpenModalLargadaCategoria,
    handleClickNumberKeyboard,
    handleAdicionar,
    handleClickStart,
    returnScreen,
    handleReview,
    handlePub,
    delTimer
}) => {

    const {state, dispatch} = useContext(JuizCronometroContext)

    const [openModalListTimers, setOpenModalListTimers] = useState(false)

    const columns = [
        {
            selector: 'image',
            sortable: true,
            width: "60px",
            cell: row => <>{row.atleta_imagem && <img src={config.storage_link+`/users/${row.atleta_imagem}`} className={css(styles.image)}/>}</>
        },
        {
            selector: 'numero',
            name: "N",
            sortable: true,
            width: "63px"
        },
        {
            selector: 'atleta',
            name: "Nome",
            sortable: true
        },
        {
            selector: 'faixa_idade',
            name: "Sub-Categoria",
            sortable: true,
            width: "110px"
        },
       
    ];


    return <ModalMobileFullScreen
        open={true} 
       // toggle={onClose}
        // contentStyle={styles.contentModal}
        onClose={() => returnScreen()}
        title={state.name_event_timer}
        btnFooterText={"Revisar"}
        onClickBtnFooter={() => {
            handleReview()
        }}
    > 
    <div className={css(styles.container)}>
        <ModalListTimersMobile 
            open={openModalListTimers} 
            setIdDel={setIdDel}
            setOpenDelete={setOpenDelete}
            onClose={() => setOpenModalListTimers(false)}/>
        <ModalDeleteMobile 
            open={openDelete}
            onDelete={delTimer}
            onClose={() => setOpenDelete(false)}>
                Você realmente deseja excluir esse cronômetro?
         </ModalDeleteMobile>
        <ModalLargadaCategoriaMobile open={openModalLargadaCategoria} toggle={setOpenModalLargadaCategoria}/>
        <div className={css(styles.divBtn)}>
            <Button style={styles.btn} onClick={() => setOpenModalListTimers(true)} disabled={!state.event?.categorias || state.event?.categorias?.length == 0 }>Largadas</Button>
            <Button style={styles.btn} onClick={handleClickStart} disabled={!state.event?.categorias || state.event?.categorias?.length == 0 }>Nova Largada</Button>
        </div>
        <div>
            <label className={css(styles.numberAthText)}>Numero do atleta: </label>
        </div>
        <div>
            <div className={css(styles.numberAtlDiv)}>
                <Input mask="9999" 
                    classNameInput={css(styles.numberAth)}  
                    value={state.numberAthTimer}
                    inputmode={"numeric"}
                    onEnter={handleAdicionar}
                    onChange={(e) => dispatch({type: actions.HANDLE_NUMBER_KEYBOARD_TIMER, payload: e.target.value.replace(/_/g,"")})}
                />
            </div>
        </div>
        <div>
            <DataTable
                columns={columns}
                data={state.athAddedTimer}
                noDataComponent={""}
                className={css(styles.tableAth)}
                responsive={true}
                fixedHeader
                noHeader
                noTableHead
            />
        </div>
        
    </div>
   
    

</ModalMobileFullScreen>
}

const styles = {
    btn: {
        marginRight: 10,
        marginLeft: 10
    },
    tableAth: {
        marginTop: 20
    },
    image: {
        width: 40,
        height: 40,
        borderRadius: "50%"
    },
    container: {
        paddingLeft: 20,
        paddingRight: 20
    },
    divBtn: {
        width: "100%",
        marginBottom: 10,
        display: "flex"
    },
    numberAthText: {
        fontSize: 16,
        marginTop: 10,
        color: "#000"
    },
    numberAtlDiv: {
        border: "1px solid #606060",
        borderRadius: 9,
        height: 30,
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontFamily: "Roboto",
        marginTop: 15
    },
    numberAth: {
        textAlign: "center",
        margin: "0 0 0 0 !important",
        height: "2rem !important",
        borderBottom: "0px !important",
        lineHeight: 2,
        fontSize: "16px !important",
        boxShadow: "none !important",
        letterSpacing: 10,
        textIndent: 10
      },
}