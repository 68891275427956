import { css } from "emotion"
import React from "react"
import { Col, Row } from "react-materialize"

export default ({picture, name, dateStart, city, state}) => {
    return <div>
         <div className={css(styles.viewImageEvento)}>
                {picture != "" && <img src={picture} width={"100%"} height={"100%"} className={css(styles.imgEvento)}/>}
        </div>
        <div className={css(styles.viewContent)}>
        </div>
    </div>
}

const styles = {
    viewImageEvento: {
        height: 180,
        width: "100%"
    },
    imgEvento: {
        height: "100%",
        width: "100%",
        objectFit: 'cover',
        objectPosition: '0 0'
    },
    viewContent: {
        position: 'relative',
        top: -15,
        paddingTop: 15,
        height: "100%",
        backgroundColor: "#fff",
        zIndex: 10,
        borderTopRightRadius: 20,
        borderTopLeftRadius: 20
    }
}