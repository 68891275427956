import React, { useState, useContext, useEffect } from "react"
import FormEventoContext, {actions} from "../../FormEventoContext"
import {Button, Input,  Checkbox, ButtonClean} from "../../../../components/Form"
import { Row, Col } from "react-materialize"
import { Modal } from 'react-responsive-modal';
import DataTable from 'react-data-table-component';
import Api from "../../../../api/Api";
import config from "../../../../config.js"
import { toast } from "react-toastify";
import {BarLoader} from "react-spinners";
import { css } from "emotion";

const ModalTorneios = (props) => {
    const {state, dispatch} = useContext(FormEventoContext)
    const [data, setData] = useState([])
    const [search, setSearch] = useState("");
    const [tournamentSelected, setTournamentSelected] = useState({})
    const [loadingTable, setLoadingTable] = useState(false);
  
    const onPressSearch = async () => {
        await getTournament();
    }

    useEffect(() => {
        setTournamentSelected(state.tournaments)
    },[state.tournaments])

    const getTournament = async () => {
        if(search){
            try {
                setLoadingTable(true)
                const result = await Api().post("/torneios/listar",{nome: search, limit: 5})
                const resultData = result.data.torneios ? result.data.torneios : []
                console.log(resultData)

                let internalData = [];
                resultData.map((value) => {
                    if(!Object.keys(tournamentSelected).includes(`${value.id}`)){
                        
                        internalData.push({
                            id: value.id,
                            regras_pontuacaTorneio: value.regras_pontuacaTorneio,
                            faixa_idade: value.faixa_idade,
                            name: value.nome,
                            edition: value.edicao,
                            categorias: value.categorias,
                            photo: value.imagem ? config.storage_link+`/torneio/${value.id}/${value.imagem}` : "",
                        })
                    }
                })
                internalData = [...Object.values(tournamentSelected),...internalData]
                setData(internalData)
                setLoadingTable(false)
            }catch(e){
                setLoadingTable(false)
                toast.error("Ocorreu um erro ao buscar os torneios")
            }
        }else {
            setData(Object.values(tournamentSelected))
        }
    }

    const onClose = () => {
        props.toggle(false)
    }

    const onPressSave = async  () => {
        const tournamentSelectedCopy = bestCopyEver(tournamentSelected);
        let regras_pontuacaTorneio = []
        let categorias = {};
        (Object.keys(tournamentSelectedCopy).map(async (key) => {
            if(!Object.keys(state.tournaments).includes(`${key}`)){
                console.log(tournamentSelectedCopy[key])
                
                if(tournamentSelectedCopy[key].regras_pontuacaTorneio){
                   
                    let regras = tournamentSelectedCopy[key].regras_pontuacaTorneio.map((item) => {
                        return {...item, 
                            torneio_nome: tournamentSelectedCopy[key].name,
                            esporte: item.esporte_id, 
                            regras: item.regra ? JSON.parse(item.regra) : []}
                    })
                    console.log(regras)
                    regras_pontuacaTorneio.push(...regras)
                   // regras_pontuacaTorneio = regras_pontuacao
                }
               tournamentSelectedCopy[key].categorias.map(async (item) => {
                    categorias[item.id] = item
                    categorias[item.id].torneio_id = key;
                    categorias[item.id].newId = true;
                    categorias[item.id].imagem = item.imagem;
                    categorias[item.id].pictureUrl = config.storage_link+`/categoria/${item.imagem}`
                })
            }
        }))
        console.log(regras_pontuacaTorneio)
        dispatch({type: actions.ADD_CATEGORIES_TOURNAMENT, payload: categorias})
        dispatch({type: actions.ADD_TOURNAMENTS, payload: tournamentSelected})
        dispatch({type: actions.ADD_SCORING_RULES, payload: regras_pontuacaTorneio})
        if(regras_pontuacaTorneio.length > 0){
            dispatch({type: actions.HANDLE_HAS_RULE, payload: '1'})

        }
        props.toggle(false)
    }

    const bestCopyEver = (src) => {
        return JSON.parse( JSON.stringify( src ) )
    }

    const onChangeCheckbox = (id,values) => {
        const tournamentSelectedCopy = bestCopyEver(tournamentSelected);
        if(Object.keys(tournamentSelected).includes(`${id}`)){
            delete tournamentSelectedCopy[id]
        }else {
            tournamentSelectedCopy[id] = values
        }
        
        setTournamentSelected(tournamentSelectedCopy)
    }

    const columns = [
        {
            name: '',
            selector: 'photo',
            cell:(row) => {
                return( 
                    <img src={row.photo} className={css(styles.photo)}/>
                );
            }
        },
        {
            name: 'Nome ',
            selector: 'name',
            sortable: true,
            style:{display: "inline-grid"},
            cell:(row) => {
                return(<div > 
                    <div><span className={css(styles.itemTableSpan)}>{(row.name)}</span></div>
                </div>);
            }
        },
        {
            name: 'Edição',
            selector: 'edition',
            sortable: true,
            style:{display: "inline-grid"},
            cell:(row) => {
                return(<div > 
                    <div><span className={css(styles.itemTableSpan)}>{(row.edition)}</span></div>
                </div>);
            }
        },
 
              {
            name: '',
            maxWidth:"10px",
            ignoreRowClick: true,
            cell:(row) => 
            <div>
                <Checkbox
                    id={"tourna"+row.id}
                    value={Object.keys(tournamentSelected).includes(`${row.id}`)}
                    onChange={() => onChangeCheckbox(row.id,{id: row.id, name: row.name, photo: row.photo, categorias: row.categorias, edition: row.edition, regras_pontuacaTorneio: row.regras_pontuacaTorneio})}
                />
            </div>,
        }
        
    ]

    return <div>
        {!props.id && !props.edit && 
            <ButtonClean onClick={() =>  props.toggle(true)} icon={"plus"}>Adicionar Torneio</ButtonClean>
        }
        <Modal
        open={props.open}
        center
        styles={styles.modal}
        type={"button"}
        onClose={onClose}
        >
            <h5 className="grey-text text-darken-3 center-align">Torneios</h5>
            <form id="formCategoria" type="POST" name="formCategoria"  role="form">
                <Row>
                    <Col s={12} m={9}>
                        <Input
                            label={"Nome do Torneio:"}
                            key="nome_torneio"
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                        />
                    </Col>
                    <Col s={12} m={3}>
                        <Button type={"button"} onClick={onPressSearch} style={{marginTop: 30,}}>Buscar</Button>
                    </Col>
                </Row>
                <Row>
                    <DataTable
                        columns={columns}
                        progressComponent={<BarLoader css={styles.loadingCss} color={"#047AB2"}/>}
                        progressPending={loadingTable}
                        data={data}
                        highlightOnHover={true}
                        pointerOnHover={true}
                        noHeader={true}
                        noDataComponent={""}
                    />
                </Row>
                <Row>
                    <Col s={12}>
                        <Button type={"button"} onClick={onPressSave}>Vincular Torneios</Button>
                    </Col>
                </Row>
            </form>
        </Modal>
    </div>
}

const styles={
    newEvento : {
        float:"left"
    },
    main: {
        paddingBottom: 20
    },
    photoEvent: {
        width: "100%",
        paddingTop: 20,
        position: "relative"
    },
    modal: {
        modal : {
            width: "80%"
        }
    },
    loadingCss: {
        width: "100%"
    },
    photo: {
        width: 40,
        height: 40,
        borderRadius: 250,
        marginTop: 5,
        marginBottom: 5
    },
    itemTableSpan: {
        fontSize: 14
    },
    iconBtn: {
        cursor:'pointer',
        color:'red !important'
   },
}

export default React.memo(ModalTorneios)
