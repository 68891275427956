import React from "react"
import {Checkbox} from "react-materialize"
const Component = (props) => {
    return  <div className={props.className}> 
        <Checkbox 
            label = {props.label}
           
            id={props.id}
            name={props.id} 
            filledIn={true}
            disabled={props.disabled}
            required={props.required}
            onChange={props.onChange} 
            checked={props.value} 
            css={props.css}
          />
          <span>{props.extraLabel}</span>
    </div>
}
export default React.memo(Component)
