import { useEffect, useState } from "react"
import { useCookies } from "react-cookie"
import { useHistory } from "react-router"

export default (minLevel) => {
    const [cookies] = useCookies(['acesso'])
    const [isAuthorization, setIsAuthorization ] = useState(true)
    const history = useHistory()

    useEffect(() => {
        if(cookies.acesso == 0 || cookies.acesso > minLevel ){
            setIsAuthorization(false)
            history.replace("/")
        }
    },[cookies.acesso])

    return isAuthorization;
}