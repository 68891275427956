
const createCardToken = ({cardNumber,brand,cvv,expirationMonth,expirationYear}) => {
    console.log(cardNumber,brand,cvv,expirationMonth,expirationYear)
    return new Promise((resolve, reject) => 
        window.PagSeguroDirectPayment.createCardToken({
            cardNumber: cardNumber.replace(/\s/g,"").replace(/_/g,""), 
            brand, cvv, expirationMonth, 
            expirationYear,
            success: function(response) {
                resolve(response)
            },
            error: function(response) {
                reject(response)
            }
    }));
}

const getBrand = (cardBin) => {
   
    return new Promise((resolve, reject) => {
    
        window.PagSeguroDirectPayment.getBrand({
            cardBin: cardBin.replace(/\s/g,"").replace(/_/g,""),
            success: function(response) {
                resolve(response)
            },
            error: function(response) {
                reject(response)
            },
        })
    }
    );
}

const getInstallments = ({amount,  brand}) => {
   
    return new Promise((resolve, reject) => {
    
        window.PagSeguroDirectPayment.getInstallments({
            amount,
           // noInterestInstallmentQuantity: 2,
            //maxInstallmentNoInterest: 2,
            brand,
            success: function(response){
                resolve(response)
           },
            error: function(response) {
                console.log(response)
                reject(response)
           }
        });
    });
}




const setSessionId = (id) => {
    return window.PagSeguroDirectPayment.setSessionId(id);
}


export { createCardToken, getBrand, setSessionId, getInstallments }