import { useContext, useEffect } from "react"
import { useState } from "react"
import { Col, Row } from "react-materialize"
import { useHistory } from "react-router"
import { toast } from "react-toastify"
import Api from "../../api/Api"
import { Button, Select } from "../../components/Form"
import LoadingOverlayContext from "../../components/LoadingOverlayContext/LoadingOverlayContext";
import useAdminAccess from "../../hooks/useAdminAccess"

export default (props) => {

    useAdminAccess(3)

    const history = useHistory()

    const [users, setUsers] = useState([])
    const [user, setUser] = useState("")
    const [level, setLevel] = useState("")

    const [loading, setLoading] = useState(false)
    
    const loadingOverlay = useContext(LoadingOverlayContext)

    const levels =  [
        { 
            value: 1,
            label: "Nivel 1",
        },
        { 
            value: 2,
            label: "Nivel 2",
        },
        { 
            value: 3,
            label: "Nivel 3",
        },
        { 
            value: 4,
            label: "Nivel 4",
        }
    ]

    useEffect(() => {
        findUsers()
    },[])

    const findUsers = async () => {
        try {
            const result = await Api().get("organizador/listar")
            const resultData = result.data.rows ? result.data.rows : []
            let internalData = [];
            resultData.map((value) => {
                internalData.push({
                    value: value.id,
                    label: value.name,
                })
            })
            setUsers(internalData)
        }catch(e){
            const response = e.response ? e.response : {};
            const data = response.data ? response.data : {};
            const msg = data.msg ? data.msg[0] : "Ocorreu um erro ao buscar os eventos";
            toast.error(msg)
        }
    }

    const onSave = async () => {
        try {
            const result= await Api().post("acesso/alterar",{
                id: user,
                acesso: level
            })

            if(!result.error){
                console.log(result.data)
                toast.success("Acesso definido com sucesso!")
                history.replace("/admin/acessos")

            }else {
                toast.error("Ocorreu um problema ao tentar salvar")
            }
        }
        catch(e){
            console.log(e)
            const response = e.response ? e.response : {};
            const data = response.data ? response.data : {};
            const msg = data.msg ? data.msg[0] : "Ocorreu um problema ao tentar salvar";
            toast.error(msg)
        }
    }

    useEffect(() => {
        (async () => {
            loadingOverlay.activeLoading(true,"Buscando conteúdo")

            let id = props.match.params.id
            if(id){
                const result = await Api().post("acesso/getinfo/"+id)
                const data = result.data ? result.data : {}
                const info = data.info ? data.info[0] : {}
    
                setUser(info.id)
                setLevel(info.acesso)
            }
            
            loadingOverlay.activeLoading(false)

        })()
    },[])


    return <div>
          <Row>
            <Col s={6}>
                <Select
                    label={"Usuario*:"}
                    options={users}
                    value={users.filter(option => option.value == user)}
                    required={true}
                    form="required"
                    onChange={(data) => setUser(data.value)}
                    />
            </Col>
        </Row>
        <Row>
            <Col s={6}>
                <Select
                    label={"Nivel*:"}
                    options={levels}
                    value={levels.filter(option => option.value == level)}
                    required={true}
                    form="required"
                    onChange={(data) => setLevel(data.value)}
                    />
            </Col>
        </Row>
        <Row>
            <Col s={6}>
                <Button loading={loading} onClick={onSave}> Salvar </Button>
            </Col>
        </Row>
</div>

}