import { css } from "emotion"
import React, { useContext } from "react"
import { Col, Row } from "react-materialize"
import { Button } from "../../../components/Form"
import HeaderViewEvento from "../../../layouts/HeaderViewEvento"
import InscricaoContext from "../InscricaoContext"
import InscricaoIngresso from "../InscricaoIngresso"

export default ({onContinue}) => {
    const {state} = useContext(InscricaoContext)

    return  <div className={css(styles.main)}>
        <HeaderViewEvento 
            picture={state.picture} 
            name={state.name} 
            dateStart={state.dateStart} 
            city={state.city} 
            state={state.uf} />
        <div className={css(styles.conteudoEvento)}>
            {state.tickets.map((item,idx) => {
                return <InscricaoIngresso index={idx}/>
            })}
        </div>
        <Row className={css(styles.btnSalvar)}>
            <Col m={12} s={12} l={6} className="offset-l3">
                <Button type="button" onClick={onContinue}>Continuar</Button>
            </Col>
        </Row>      
    </div>
}

const styles = {
    btnSalvar: {
        marginTop: 55
    },
    divCards: {
        display: "flex",
        flexWrap: "wrap"
    },
    lineItem: {
        display: "flex",
        justifyContent: "space-between"
    },
    lineImgDesc: {
        display: "flex",
    },
    labelCard: {
        fontFamily: "Roboto",
        fontSize: 16,
        color: "#606060",
        alignSelf:"center",
        marginLeft: 10
    },
    lineDel: {

    },
    main:{
        position: "relative",
        backgroundColor: "#F2F2F2",
        minHeight: "100vh"
    },
    card: {
        flex: 1,
        "@media(max-width: 700px)": {
            flex: "100%"
        }
    },
    cardRight: {
        "@media(min-width: 700px)": {
            marginLeft: 30
        }
    },
    cardLeft: {
        "@media(min-width: 700px)": {
            marginRight: 30
        }
    },
    titleSection: {
        color: "#606060",
        fontFamily: "Roboto",
        fontSize: 20
    },
    section: {
        marginLeft: '4%',
        marginRight: "4%",
        "@media(max-width: 992px)": {
            marginLeft: "7%",
            marginRight: "7%",
        },
        "@media(max-width: 700px)": {
            marginLeft: 15,
            marginRight: 15,
        },
    },
    
    
    labelDescricao: {
        color: "#606060",
        fontFamily: "Roboto",
        fontSize: 20
    },
    meusEventosLabel: {
        color: "#606060",
        fontFamily: "Roboto",
        fontSize: 20
    },
    organizadorLabel: {
        color: "#606060",
        fontFamily: "Roboto",
        fontSize: 20
    },
    conteudoEvento: {
        marginLeft: 10,
        zIndex: 99,
        position: "relative"
    },
    conteudoEventoTitle: {
        marginLeft: 10,
        position: "absolute",
        left: "40%",
        "@media(max-width: 992px)": {
            position: "relative",
            top: 0,
            left: 0,
        },
        
    },
    imgEventoDiv: {
        height: "25vh"
    },
    imgEvento: {
        objectFit: 'cover',
        objectPosition: '0%',
        "@media(max-width: 992px)": {
            objectFit: 'contain',
            objectPosition: 'center center'
        },
    },
    button: {
        backgroundColor: "transparent",
        border: "1px solid",
        padding: "5px 15px",
        cursor: "pointer",
        borderRadius: 25,
        color: "#fff",
    },
    circleImg: {
        borderRadius: "50%",
        flex: 1,
        width: 40,
        height: 40
    }
}
