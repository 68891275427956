import axios from 'axios';
import config from "../config.js"
import {Cookies} from "react-cookie"

const Api = () => {
    let cookiesApi = new Cookies();
    var api = axios.create({
        baseURL: config.api,
        timeout: 30000,
        headers: {'X-Custom-Header': 'foobar',
        'Authorization': "bearer "+cookiesApi.get("access_token")}
    });
    
    api.interceptors.response.use(
        (response) => {return response},
        (error) => {
            if (error.response && error.response.config && error.response.config.url != "/login" 
            && error.response.status === 401){
                cookiesApi.remove("access_token")
                window.location.href = "/"
            }
            return Promise.reject(error)
        }
    )

    return api;
}

export default Api;
