import React,{ useState, useEffect, useRef, useReducer } from "react"
import { Row, Col, Card} from "react-materialize"
import Api from "../../api/Api"
import config from "../../config.js"
import { Button, ButtonClean, RadioGroup, RadioGroup2, Select } from "../../components/Form"
import moment from "moment"
import { useHistory, useParams, useLocation } from "react-router-dom"
import { useContext } from "react"
import { css } from "emotion"
import { toast } from "react-toastify"
import { useCookies } from "react-cookie"
import ReactToPrint from 'react-to-print';
import HeaderViewEvento from "../../layouts/HeaderViewEvento"
import circle from "../../assets/img/circle.svg"
import ModalAtleta from "./ModalAtleta"
import InscricaoContext, { actions, initialState, InscricaoProvider, reducer } from "./InscricaoContext"
import InscricaoIngresso from "./InscricaoIngresso"
import DataTable from "react-data-table-component"
const Evento = () => {

    const [state, dispatch] = useReducer(reducer, initialState)

    const {id} = useParams(); 
    const [picture,setPicture] = useState("")
    const [name,setName] = useState("")
    const [city, setCity] = useState("")
    const [uf,setState] = useState("")
    const [dateStart, setDateStart] = useState("")
    const [tickets, setTickets] = useState([])

    const history = useHistory()

    const location = useLocation()
    const locationState = location.state
    

    useEffect(() => {
        moment.locale("pt-BR")
        console.log(locationState.tickets)
        if(!locationState || !locationState.tickets)
        {
            history.replace("/")
            return;
        }  
        console.log(locationState.tickets.infos)
        const internalTickets = locationState.tickets.infos.map((item) => {
            console.log(item)
             return JSON.parse(item)
        })
        setTickets(internalTickets)
        getInfoEvento()
    },[])


    const getInfoEvento = async () => {
        setDateStart(locationState.inscricao.dateStart)
        setName(locationState.inscricao.name)
        setState(locationState.inscricao.state)
        setCity(locationState.inscricao.city)
        setPicture(locationState.inscricao.picture)
        locationState.inscricao.lotsSelected.forEach((item) => {
            for(let i = 0; i < item.quantidade; i++ ){
                dispatch({type: actions.ADD_TICKET, payload: { categoria_id: item.id_cat ,categoria_nome: item.nome_cat, tamanho_roupa: item.tamanho_roupa }})
            }
        })
    }

    const onBuy = async () => {
        let ticketsForApi = []
        console.log(state.tickets)
        let error = false;
        state.tickets.map((item) => {
            if(error){
                return;
            }
            else if(!item.athlete){
                error = true
                toast.warn("Atleta é obrigatorio")
                return
            } else if (!item.term){
                error = true
                toast.warn("O aceite do termo é obrigatorio")
                return;
            }
            ticketsForApi.push({
                "atleta_id": item.athlete.id,
                "categoria_id": item.categoria_id,
                "evento_id": id,
                "idade": 18,
                "tamanho_roupa": item.clothes,
                "equipe_id": item.team ? item.team.id : "",
                "termo": item.term
            })
        })

        if(!error){
            try {
                const result = await Api().post("instricao/inscrever",ticketsForApi)
                console.log(result)
                if(!result.data.error){
                    toast.success("Inscrição adicionada")
                    history.replace("/")
                }
            } catch(e){
                console.log(e)
                const response = e.response ? e.response : {};
                const data = response.data ? response.data : {};
                const msg = data.msg ? data.msg[0] : "Ocorreu um problema";
                toast.error(msg)
            }
        }
    }

    const columns = [
        {
            name: 'numero',
            selector: 'numero',
        },
    ]


    return <div>
            <div className={css(styles.main)}>
                <HeaderViewEvento 
                    picture={picture} 
                    name={name} 
                    dateStart={dateStart} 
                    city={city} 
                    state={uf} />
                <div className={css(styles.conteudoEvento)}>
                    <DataTable 
                        columns={columns}
                        data={tickets}
                    />
                </div>
            </div>
        
    </div>
}

const styles = {
    btnSalvar: {
        marginTop: 55
    },
    divCards: {
        display: "flex",
        flexWrap: "wrap"
    },
    lineItem: {
        display: "flex",
        justifyContent: "space-between"
    },
    lineImgDesc: {
        display: "flex",
    },
    labelCard: {
        fontFamily: "Roboto",
        fontSize: 16,
        color: "#606060",
        alignSelf:"center",
        marginLeft: 10
    },
    lineDel: {

    },
    main:{
        position: "relative",
        backgroundColor: "#F2F2F2",
        minHeight: "100vh"
    },
    card: {
        flex: 1,
        "@media(max-width: 700px)": {
            flex: "100%"
        }
    },
    cardRight: {
        "@media(min-width: 700px)": {
            marginLeft: 30
        }
    },
    cardLeft: {
        "@media(min-width: 700px)": {
            marginRight: 30
        }
    },
    titleSection: {
        color: "#606060",
        fontFamily: "Roboto",
        fontSize: 20
    },
    section: {
        marginLeft: '4%',
        marginRight: "4%",
        "@media(max-width: 992px)": {
            marginLeft: "7%",
            marginRight: "7%",
        },
        "@media(max-width: 700px)": {
            marginLeft: 15,
            marginRight: 15,
        },
    },
    
    
    labelDescricao: {
        color: "#606060",
        fontFamily: "Roboto",
        fontSize: 20
    },
    meusEventosLabel: {
        color: "#606060",
        fontFamily: "Roboto",
        fontSize: 20
    },
    organizadorLabel: {
        color: "#606060",
        fontFamily: "Roboto",
        fontSize: 20
    },
    conteudoEvento: {
        marginLeft: 10,
        zIndex: 99,
        position: "relative"
    },
    conteudoEventoTitle: {
        marginLeft: 10,
        position: "absolute",
        left: "40%",
        "@media(max-width: 992px)": {
            position: "relative",
            top: 0,
            left: 0,
        },
        
    },
    imgEventoDiv: {
        height: "25vh"
    },
    imgEvento: {
        objectFit: 'cover',
        objectPosition: '0%',
        "@media(max-width: 992px)": {
            objectFit: 'contain',
            objectPosition: 'center center'
        },
    },
    button: {
        backgroundColor: "transparent",
        border: "1px solid",
        padding: "5px 15px",
        cursor: "pointer",
        borderRadius: 25,
        color: "#fff",
    },
    circleImg: {
        borderRadius: "50%",
        flex: 1,
        width: 40,
        height: 40
    }
}

export default Evento;