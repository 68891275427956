import React, { useState } from "react"
import {Button, Input, RichTextEditor} from "../../components/Form"
import { Row, Col } from "react-materialize"
import { Modal } from 'react-responsive-modal';
import { toast } from "react-toastify";
import api from "../../api/Api";

const ModalCategoria = (props) => {

    const [email,setEmail] = useState("")
    const [subject,setSubject] = useState("")
    const [msg,setMsg] = useState("")
    const [loading,setLoading] = useState(false)

    const onPressSave = async (e) => {
        e.preventDefault()
        try {
            setLoading(true)
            const result = await api().post("torneio/email/faleconosco",{torneio_id: props.tourn_id, email, assunto: subject, msg})
            const data = result.data ? result.data : {}
            setLoading(false)
            if(!data.error){
                toast.success("E-mail enviado com sucesso")
                setEmail("")
                setSubject("")
                setMsg("")
                onClose()
            }
        }catch(e){
            const response = e.response ? e.response : {};
            const data = response.data ? response.data : {};
            const msg = data.msg ? data.msg[0] : "Ocorreu um problema";
            toast.error(msg)
            setLoading(false)
        }
    }

    const onClose = () => {
        props.toggle(false)
    }
    
    return <div>
    <Modal
        open={props.open}
        center
        type={"button"}
        styles={{
            modal: {
                width: 700
            }
        }}
        onClose={onClose}
    >
        <h5 className="grey-text text-darken-3 center-align">Fale com o organizador</h5>
        <form id="formCategoria" type="POST" name="formCategoria" onSubmit={onPressSave}  role="form">
            <Row>
                <Col s={12}>
                    <Row>
                        <Col s={12}>
                            <Input
                                    label={"Seu Email*:"}
                                    key="email"
                                    type={"email"}
                                    value={email}
                                    required={true}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                        </Col>
                    </Row>
                    <Row>
                        <Col s={12}>
                                <Input
                                        label={"Assunto*:"}
                                        key="assunto"
                                        value={subject}
                                        required={true}
                                        onChange={(e) => setSubject(e.target.value)}
                                    />
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row>
                <Col s={12} l={12}>
                    <RichTextEditor
                        label={"Mensagem:"}
                        removeAllButtons
                        value={msg}
                        required={true}
                        onChange={setMsg}
                    />
                </Col>
            </Row>
            <Row>
                <Col m={12} s={12} l={6} className="offset-l3">
                    <Button loading={loading} form="formCategoria">Enviar Email</Button>
                </Col>
            </Row>
        </form>
    </Modal>
</div>
}

export default React.memo(ModalCategoria)