import React, { useState, useEffect, useContext } from "react"
import { Row, Col } from "react-materialize"
import { ButtonClean} from "../../components/Form"
import {
    Link, useHistory 
  } from "react-router-dom";

import DataTable from 'react-data-table-component';
import Api from "../../api/Api"
import config from "../../config.js"
import { toast } from "react-toastify";
import { useCookies} from "react-cookie"
import {BarLoader} from "react-spinners";
import { css } from 'emotion'
import moment from "moment"
import ModalDelete from "../../components/ModalDelete";
import useAdminAccess from "../../hooks/useAdminAccess";

export default () => {

    useAdminAccess(3)

    const [data, setData] = useState([])
    const [loadingTable, setLoadingTable] = useState(false);
    const [idDelete, setIdDelete] = useState()
    const [openDelete, setOpenDelete] = useState()

    const history = useHistory();

    useEffect(() => {
        findData()
    },[])


    const findData = async () => {
        try {
            setLoadingTable(true)
            const result = await Api().get("/acesso/listar")
            const resultData = result.data.organizadores ? result.data.organizadores?.rows : []
            console.log(resultData)
            let internalData = [];
            resultData.map((value) => {
                internalData.push({
                    id: value.id,
                    name: value.name,
                    acesso: value.acesso
                })
            })
            setData(internalData)
            setLoadingTable(false)
        }catch(e){
            const response = e.response ? e.response : {};
            const data = response.data ? response.data : {};
            const msg = data.msg ? data.msg[0] : "Ocorreu um erro ao buscar os acessos";
            toast.error(msg)
        }

    }


    const columns = [
        {
            name: 'Nome',
            selector: 'name',
            sortable: true,
            cell:(row) => {
                return(<div > 
                    <div><span className={css(styles.itemTableSpan)}>{(row.name)}</span></div>
                </div>);
            }
        },
        {
            name: 'Acesso',
            selector: 'acesso',
            sortable: true,
            cell:(row) => {
                return(<div > 
                    <div><span>Nivel {row.acesso}</span></div>
                </div>);
            }
        },
       
        {
            name: 'Editar',
            cell:(row) => 
            <div>
                <span className={"material-icons modal-trigger  "+ css(styles.iconBtn2)} id={row.id} onClick={ () => { onClickLineTable(row.id)} } >edit</span>
            </div>,
        },
        {
            name: 'Excluir',
            cell:(row) => 
            <div>
                <span className={"material-icons modal-trigger  "+ css(styles.iconBtn)} href="#modal1" id={row.id} onClick={ () => { setIdDelete(row.id); setOpenDelete(true);} } >delete</span>
            </div>,
        }
        
    ]

    const onClickLineTable = (id) => {
        history.push(`/admin/acessos/${id}/editar`)
    }

    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    const deleteAccess = async () => {
        const acessos = data.filter((elem) => {
            return elem.id != idDelete
        })

        try {
            const result = await Api().post("acesso/remover/"+idDelete)
            if(!result.data.error){
                toast.success("Acesso deletado com sucesso")
                setData(acessos)
                setOpenDelete(false)
            }
        } catch(e){
            const response = e.response ? e.response : {};
            const data = response.data ? response.data : {};
            const msg = data.msg ? data.msg[0] : "Ocorreu um erro ao tentar deletar o acesso";
            toast.error(msg)
            setOpenDelete(false)
        }
    }


    return <div className={css(styles.main)}>
        <Row>
            <Col s={8} m={9}>
                <h6 className={css(styles.titleEvent)}>Liberar Acessos</h6>
                <div>
                    <span>{capitalizeFirstLetter(moment().format("dddd, DD [de] MMMM [de] YYYY"))}</span>
                </div>
            </Col>
            
        </Row>
        <Row className={css(styles.rowNovoEvento)}>
            <Col s={12} m={4}>
                <Link to={"/admin/acessos/novo"} className={css(styles.linkNovoEvento)}>
                    <ButtonClean icon={"plus"}>
                        Novo Acesso
                    </ButtonClean>
                </Link>
            </Col>
        </Row>
        <DataTable
            progressComponent={<BarLoader css={styles.loadingCss} color={"#110F62"}/>}
            progressPending={loadingTable}
            persistTableHead
            columns={columns}
            data={data}
            noHeader={true}
            overflowY={true}      
            noHeader
            noTableHead  
            noDataComponent={"Não existem dados para mostrar"}
        />
         <ModalDelete 
        open={openDelete}
        onDelete={deleteAccess}
        onClose={() => setOpenDelete(false)}>
            Você realmente deseja excluir esse acesso?
        </ModalDelete>
    </div>
}


const styles={
    input: {
        border: "0.5px solid #9e9e9e !important",
        boxShadow: "none"
    },
    loadingImg: {
        backgroundColor: "#fff"
    },
    titleEvent: {
        fontWeight: "bold"
    },
    loadingCss: {
        width: "100%"
    },
    newEvento : {
        float:"left"
    },
    rowNovoEvento: {
        paddingTop: "20px"
    },
    linkNovoEvento: {
        color: "#000",
    },
    itemTableSpan: {
        fontSize: 14
    },
    novoEventoSpan: {
        fontFamily: "Roboto",
        fontSize: 14,
        marginLeft: 10,
    },
    iconBtn: {
         cursor:'pointer',
         color:'red !important'
    },
    iconBtn2: {
        cursor:'pointer',
   },
    textBox: {
        paddingLeft: 20,
        width: "65%"
    },
    textBoxSpan: {
        fontSize: 12,
        color: "#110F62"
    },
    titleBox: {
        paddingTop: 20,
        paddingLeft: 20
    },
    titleBoxSpan: {
        color: "#110F62",
        fontFamily: "Roboto",
        fontSize: 20,
        marginTop: 20
    },
    main: {
        paddingBottom: 20
    },
    photoEvent: {
        width: 40,
        height: 40,
        borderRadius: 250,
        marginTop: 5,
        marginBottom: 5
    },
    imgBanner: {
        position: "absolute",
        top: -10,
        right: 0
    },
    divNovoEvento: {
        display: "inline-flex",
        alignItems: "center"
    },
    boxEventos: {
        position: "relative",
        width: "95%",
        fontFamily: "Roboto",
        height: 150,
        borderRadius: 20,
        backgroundColor: "#CFCEF2",
        boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.25)",
        "@media(max-width: 850px)": {
            display: "none"
        },
    }
}