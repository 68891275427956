import { css } from "emotion";
import React, { useContext, useState } from "react"
import DataTable from "react-data-table-component";
import { Col, Row } from "react-materialize";
import Modal from "react-responsive-modal";
import { BarLoader } from "react-spinners";
import Api from "../../../../api/Api";
import { Button, ButtonClean, Checkbox, Input } from "../../../../components/Form";
import config from "../../../../config.js"
import { toast } from "react-toastify";
import JuizCronometroContext, { actions } from "../../JuizCronometroContext";
const Component = (props) => {

    const {state, dispatch} = useContext(JuizCronometroContext)
    const [search, setSearch] = useState("")
    const [loadingTable, setLoadingTable] = useState(false)
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([])
    const [judgeSelected, setJudgeSelected] = useState([])

    const onClose = () => {
        props.toggle(false)
    }

    const onPressSearch = async () => {
        // if(search){
            try {
                setLoadingTable(true)
                const result = await Api().get("/users/listar/at",{
                    params: { name: search, limit: 5 }
                })
                const resultData = result.data.atletas ? result.data.atletas : []
                const atletas = resultData.rows ? resultData.rows : []
                let internalData = [];
                atletas.map((value) => {
                    if(!(judgeSelected).includes(value.id)){
                        internalData.push({
                            id: value.id,
                            name: value.name,
                            cidade: value.cidade,
                            uf: value.uf,
                            photo: value.imagem ? config.storage_link+`/users/${value.imagem}` : "",
                        })
                    }
                })
                setData(internalData)
                setLoadingTable(false)
            }catch(e){
                setLoadingTable(false)
                toast.error("Ocorreu um erro ao buscar os torneios")
            }
        // }else {
        //     setData(Object.values(tournamentSelected))
        // }
    }

    const onPressSave = async () => {
        try {
            setLoading(true)
            const result = await Api().post("/eventosjuiz/vinculajuiz",
                {
                    evento_id: state.id_event_access,
                    juizes: judgeSelected
                }
            )
            const resultData = result.data ? result.data : []
            if(!resultData.error){
                toast.success("Juizes vinculados com sucesso")
                dispatch({type: actions.FORCE_UPDATE_JUDGES_ACCESS})
                props.toggle(false)
                
            } else {
                toast.error(resultData.msg[0])
            }
            setLoading(false)
        }catch(e){
            const response = e.response ? e.response : {};
            const data = response.data ? response.data : {};
            const msg = data.msg ? data.msg[0] : "Ocorreu um problema";
            toast.error(msg)
            setLoading(false)
        }
       

    }


    const onChangeCheckbox = (id) => {
        let judgeSelectedCopy;
        if(judgeSelected.includes(id)){
            judgeSelectedCopy = judgeSelected.filter((item) => item != id)
        }else {
            judgeSelectedCopy = [...judgeSelected, id]

        }
        
        setJudgeSelected(judgeSelectedCopy)
    }

    const columns = [
        {
            name: '',
            selector: 'photo',
            cell:(row) => {
                return( 
                    row.photo && <img src={row.photo} className={css(styles.photo)}/>
                );
            }
        },
        {
            name: 'Nome',
            selector: 'name',
            sortable: true,
            style:{display: "inline-grid"},
            cell:(row) => {
                return(<div > 
                    <div><span className={css(styles.itemTableSpan)}>{(row.name)}</span></div>
                </div>);
            }
        },
        {
            name: 'Local',
            selector: 'local',
            sortable: true,
            style:{display: "inline-grid"},
            cell:(row) => {
                return(<div > 
                    <div><span className={css(styles.itemTableSpan)}>{(row.cidade) +  " - " +(row.uf)}</span></div>
                </div>);
            }
        },
 
              {
            name: '',
            maxWidth:"10px",
            ignoreRowClick: true,
            cell:(row) => 
            <div>
                <Checkbox
                    id={"at"+row.id}
                    value={judgeSelected.includes(row.id)}
                    onChange={() => onChangeCheckbox(row.id)}
                />
            </div>,
        }
        
    ]

    return <div>
    {!props.id && !props.edit && 
        <ButtonClean onClick={() =>  props.toggle(true)} icon={"plus"}>Adicionar Juiz</ButtonClean>
    }
    <Modal
        open={props.open}
        center
        styles={styles.modal}
        type={"button"}
        onClose={onClose}
    >
        <h5 className="grey-text text-darken-3 center-align">Juizes</h5>
        <form>
            <Row>
                <Col s={12} m={9}>
                    <Input
                        label={"Nome do juiz:"}
                        key="nome_juiz"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                    />
                </Col>
                <Col s={12} m={3}>
                    <Button type={"button"} onClick={onPressSearch} style={{marginTop: 30,}}>Buscar</Button>
                </Col>
            </Row>
            <Row>
                <DataTable
                    columns={columns}
                    progressComponent={<BarLoader css={styles.loadingCss} color={"#047AB2"}/>}
                    progressPending={loadingTable}
                    data={data}
                    highlightOnHover={true}
                    pointerOnHover={true}
                    noHeader={true}
                    noDataComponent={""}
                />
            </Row>
            <Row>
                <Col s={12}>
                    <Button 
                        loading={loading}
                        type={"button"} 
                        onClick={onPressSave}>Vincular Juizes</Button>
                </Col>
            </Row>
        </form>
    </Modal>
</div>
}

const styles={
    newEvento : {
        float:"left"
    },
    main: {
        paddingBottom: 20
    },
    photoEvent: {
        width: "100%",
        paddingTop: 20,
        position: "relative"
    },
    modal: {
        modal : {
            width: "80%"
        }
    },
    loadingCss: {
        width: "100%"
    },
    photo: {
        width: 40,
        height: 40,
        borderRadius: 250,
        marginTop: 5,
        marginBottom: 5
    },
    itemTableSpan: {
        fontSize: 14
    },
    iconBtn: {
        cursor:'pointer',
        color:'red !important'
   },
}

export default Component;