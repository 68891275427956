import React from "react"
import ImageUploader from 'react-images-upload';
import { css } from "emotion";
import CropImage from "../CropImage"
import { useState } from "react";
const Component = (props) => {
    
    const [open, setOpen] = useState(false);
    const [imagem, setImage] = useState(false);

    const onDrop = (picture, pictureUrl) => {
        if(props.crop && pictureUrl[0]){
            setOpen(true)
            setImage(pictureUrl)
        }else {
            props.onDrop(picture,pictureUrl)
        }
    }

    const onClose = (newFile, newImage) => {
        props.onDrop(newFile,newImage)
        setOpen(false)
    }

    const classWrapper = props.singleImage === false ?
    css(styles().wrapperImageRelative) : 
    css(styles().wrapperImageAbsolute)

    const classFile = css(styles(props.aspect,props).fileImage,classWrapper)

    return <div>
    <ImageUploader
        withIcon={false}
        buttonText={props.buttonText}
        withPreview={true}
        singleImage={true}
        defaultImages={props.defaultImages}
        onChange={onDrop}
        fileSizeError="A imagem é muito grande"
        fileTypeError="Esse arquivo não é suportado"
        label={""}
        className={classFile}
        fileContainerStyle = {styles().fileContainerStyle}
        imgExtension={['.jpg', '.gif', '.png', '.gif']}
        maxFileSize={3002400}
        {...props}
    />
    <CropImage open={open} image={imagem} onClose={onClose} 
        rounded={props.rounded} aspect={props.rounded ? 1 : 16/9}/>
    </div>
}

const styles = (aspect,props = {}) => {
    const {rounded, backgroundColor, color, borderRadius} = props 
    let width;
    let height;
    if(rounded){
       width = 120;
       height = 120;
    }else {
        width = aspect == 16/9 ? 160 : 120;
        height = aspect == 16/9 ? 90 : 135;
    }
  
    return {
            fileImage: {
            ".fileContainer": {
                position: 'relative'
            },
            ".fileContainer .uploadPictureContainer": {
                width,
                height,
                padding: 5,
                background: 'transparent',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                border: 0,
                boxShadow: 'none'
            },
            ".fileContainer .chooseFileButton": {
                /* border-radius: 25; */
                borderRadius:  rounded ? "50%" : (borderRadius ? borderRadius : 0),
                fontWeight: 300,
                fontSize: 14,
                margin: '10px 0',
                fontFamily: "Roboto",
                /* position: absolute; */
                /* z-index: 2; */
                transition: 'all 0.2s ease-in',
                cursor: 'pointer',
                outline: 'none',
                border: 'none',
                width: '100%',
                height: '100%',
                color: color ? color : "#fff",
                backgroundColor: backgroundColor ? backgroundColor : '#C4C4C4',
                height,
                width
            },
            
            ".fileContainer .uploadPictureContainer img.uploadPicture": {
                width,
                height,
                 borderRadius:  rounded ? "50%" : (borderRadius ? borderRadius : 0),
                objectFit: 'cover'
            }
        },
        fileContainerStyle: {
            boxShadow: "0px 0px 0px ",
            backgroundColor: "transparent"
        },
        wrapperImageAbsolute: {
            ".fileContainer .uploadPicturesWrapper":{
                position: "absolute"
            }
        },
        wrapperImageRelative: {
            ".fileContainer .uploadPicturesWrapper":{
                position: "relative"
            },
            ".fileContainer .chooseFileButton":{
                height: "auto",
                width: "auto"
            }
        }
    }
}

export default (Component)
