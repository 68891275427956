import { css } from "emotion"
import React from "react"
import { BrowserView, MobileView } from "react-device-detect"
import { Card, Container } from "react-materialize"
import ResetSenha from "./ResetSenha"

export default () => {
    return <Container className={css(styles.main)}>
            <BrowserView>
                <h4 className="grey-text text-darken-4 center-align">Trocar Senha</h4>
                <Card>
                    <ResetSenha/>
                </Card>
            </BrowserView>
            <MobileView>
                <ResetSenha/>
            </MobileView>
    </Container>
}

const styles={
    main: {
        marginTop: 30,
        paddingBottom: 20
    },
}