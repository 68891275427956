import React,{useState} from 'react'
import LoadingOverlayContext from "./LoadingOverlayContext"
import LoadingOverlay from 'react-loading-overlay';


export default (props) => {

    const [active,setActive] = useState(false)
    const [text,setText] = useState("")
    const { Provider } = LoadingOverlayContext;

    const styleLoadingOverlay = {
    
      spinner: (base) => ({
        ...base,
        width: '100px',
        '& svg circle': {
          stroke: '#6B69CF'
        }
      }
      ),
      wrapper : {
        height: '100vh',
        overflowY: active ? 'hidden' : ''
      },
      content: (base) => ({
        position: "absolute", /* or  */
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)"
      }),
      overlay: (base) => ({
        ...base,
        zIndex: 99999,
        height: "100%",
      })
    
    }

    const activeLoading = (active,text="") => {
        setActive(active)
        setText(text)
    }

    

    return <Provider value={{activeLoading}}>
        <LoadingOverlay
            active={active}
            spinner
            text={text}
            styles={styleLoadingOverlay}
            >
                {props.children}
            </LoadingOverlay>
    </Provider>
}