import React, { useState, useContext, useEffect } from "react"
import DataTable from 'react-data-table-component';
import MinhasEquipesContext, { actions } from "./MinhasEquipesContext";
import { css } from "emotion";
import Api from "../../api/Api";
import { useCookies } from "react-cookie";
import config from "../../config.js"
import { toast } from "react-toastify";
import { BrowserView, MobileView } from "react-device-detect";
import ConviteEquipeBrowser from "./ConviteEquipeLayout/ConviteEquipeBrowser";
import ConviteEquipeMobile from "./ConviteEquipeLayout/ConviteEquipeMobile";

const Component = () => {

    const [cookies] = useCookies(['id']); 
    const [invites, setInvites] = useState([])
    const [loading, setLoading] = useState(false)
    const {state, dispatch, openMembers} = useContext(MinhasEquipesContext)

    useEffect(() => {
        (async () => {
            setLoading(true)
            const teamsResponse = await Api().post("equipe/listarfazparte",{"user_id": cookies.id})
            setLoading(false)
            const data = teamsResponse.data
            let teams = data ? data.lista : []
            teams = teams.map((item) => {
                if(!item.imagem){
                    return {...item, pontos: 0, action: () => openMembers(item.id)}
                }
                return {...item, pontos: 0,
                    image: config.storage_link+`/equipe/${item.id}/${item.imagem}`,
                    action: () => openMembers(item.id)
                }
            })
            setInvites(teams)
        })()
    },[state.updateInvitesForce])

    const onAccept =async  (id) => {
        try {
            const result = await Api().post("/membros/aceitarRecusar", {user_id: cookies.id, equipe_id: id, aceite: true})
            if(!result.data.error){
                dispatch({type: actions.UPDATE_INVITES_FORCE})
                dispatch({type: actions.UPDATE_TEAMS_FORCE})
                toast.success("Convite aceito com sucesso")
            }else {
                toast.error("Ocorreu um problema ao aceitar o convite")
            }
        }catch (e){
            const response = e.response ? e.response : {};
            const data = response.data ? response.data : {};
            const msg = data.msg ? data.msg[0] : "Ocorreu um problema ao tentar aceitar o convite";
            toast.error(msg) 
        }
      
    }

    const onReject = async (id) => {
        try {
            const result = await Api().post("/membros/aceitarRecusar", {user_id: cookies.id, equipe_id: id, aceite: false})
            if(!result.data.error){
                dispatch({type: actions.UPDATE_INVITES_FORCE})
                toast.success("Convite recusado com sucesso")
            }else {
                toast.error("Ocorreu um problema ao recusar o convite")
            }
        }catch (e){
            const response = e.response ? e.response : {};
            const data = response.data ? response.data : {};
            const msg = data.msg ? data.msg[0] : "Ocorreu um problema ao tentar recusar o convite";
            toast.error(msg) 
        }
    }

    return <div>
        <BrowserView>
            <ConviteEquipeBrowser 
                invites={invites}
                onAccept={onAccept}
                onReject={onReject}
            />
        </BrowserView>
        <MobileView>
            <ConviteEquipeMobile 
                invites={invites}
                onAccept={onAccept}
                onReject={onReject}
                loading={loading}
            />
        </MobileView>
    </div>
}



export default  React.memo(Component);