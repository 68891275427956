import React from "react"
import { Row, Col } from 'react-materialize';
import {Button, Input} from "../../../components/Form"
import AppLogin from "../../../layouts/AppLogin"
import { css } from 'emotion'

const EsqueceuSenha = ({
    email, setEmail, loading, refForm, onSubmit
}) => {

    return <AppLogin>
            <h4 className={css(styles.title)+" text-darken-4 center-align"}>Recuperação de Senha</h4>
            <p className={css(styles.text)+ " text-darken-4 center-align"}>Informe o e-mail cadastrado na plataforma para recuperação de senha </p>
            <form ref={refForm} onSubmit={onSubmit}>
                <div>
                    <Input 
                        id={"email"}
                        data-testid={"emailesqsenha"}
                        type={"email"}
                        required={true}
                        value={email}
                        color="rgba(107, 105, 207, 0.5)"
                        title={"E-mail incorreto"}
                        className={css(styles.inputemail)}
                        onChange={(e) => setEmail(e.target.value.toLowerCase())}
                        label={"meu email"}
                    />
                
                    <Row className={css(styles.divBtn)}>
                        <Col m={12} s={12} l={6} className="offset-l3">
                            <Button data-testid="btnEnviar" loading={loading}>Enviar</Button>
                        </Col>
                    </Row>
                
                </div>
            </form>
    </AppLogin> 

}

export default (EsqueceuSenha)
const styles =  {
     container : {
         height: "100vh", 
    },
    spanesqueceusenha: {
        cursor: "pointer"
    },
    logo: {
        width: "25%", 
        textAlign:"center" 
    },
    text:{
        fontFamily: "Roboto",
        color: "#6B69CF",
        fontSize: 20,
        paddingTop: 40
    },
    title: {
        fontFamily: "Roboto",
        fontWeight: "600",
        color: "#6B69CF"
    },
    inputemail: {
        width: "50% !important",
        marginRight: "auto",
        marginLeft: "auto"
    },
    divBtn: {
        marginTop: 55
    }
 
 }