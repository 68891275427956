import React from "react"
import {  useHistory } from "react-router-dom"
import {  CardPanel } from "react-materialize"
import { css } from "emotion"
import config from "../../../../config"


const Component =  ({item, extraParamsItem = {}}) => {
    console.log(item)
    return <CardPanel style={styles.card_panel}>
            <img src={config.storage_link+`/evento/${extraParamsItem.id}/percurso/${item.nome}`} className={css(styles.photo)}/>
    </CardPanel>

}

const styles = {
    box: {
        borderRadius: 20,
        height: 275,
        paddingTop: 10,
        marginTop: 10
    },
    card_panel: {
        cursor: "pointer",
        padding: 0,
        height: 150,
        borderRadius: 15,
        display: 'block',
        overflow: "hidden"
    },
    photo: {
        width: "100%",
        borderRadius: 15,
        height: 150,
        objectFit: "cover",
        display: "block",
    },
}

export default (Component)