import React, { useState, useEffect } from "react"
import moment from "moment"
import { Card, Col, Row } from "react-materialize"
import { Checkbox, InputSearch } from "../../components/Form";
import { css } from "emotion";
import DataTable from "react-data-table-component";
import { BarLoader, BeatLoader } from "react-spinners";
import Api from "../../api/Api";
import { toast } from "react-toastify";
import config from "../../config.js"
import { useRef } from "react";
import useIdOrganizer from "../../hooks/useIdOrganizer";

const Component = () => {
    const [filter, setFilter] = useState("")
    const [loadingValues, setLoadingValues] = useState(false)
    const [loadingTable, setLoadingTable] = useState(false)
    const [data, setData] = useState([])
    const [dataFiltered, setDataFiltered] = useState([])
    const [cancelled, setCancelled] = useState({})
    const [pending, setPending] = useState({})
    const [sold, setSold] = useState({})
    const [eventsCheck, setEventsCheck] = useState([])
    const firstLoad = useRef(true)

    const user_id = useIdOrganizer()

    useEffect(() => {
        findDataEvents()
    },[])

    useEffect(() => {
        findDataWallet()
    },[eventsCheck])


    const updateAllEvents = (checked) => {
     
        if(checked){
            const idsEvent = dataFiltered.map((item) => {
                return item.id
            })
            console.log(idsEvent)
            setEventsCheck(idsEvent)
        } else {
            setEventsCheck([])
        }
    }


    useEffect(() => {
        
        if(data.length > 0){
            const newDate = data.filter((item) => item.name.match(`${filter}.*`))
            setDataFiltered(newDate)
        }

    },[filter])

    useEffect(() => {
        
        if(dataFiltered.length > 0 && firstLoad.current){
            firstLoad.current = false
            updateAllEvents(true)
        }

    },[dataFiltered])

    const findDataWallet = async () => {
        try {
            setLoadingValues(true)
            const result = await Api().get("/card",{
                params: {
                    organizador_id: user_id,
                    eventos: eventsCheck.length > 0 ? eventsCheck : JSON.stringify([])
                }
            })
            const data = result.data
            const cards = data.cards ? data.cards : {}
            
            setSold(cards.vendidos ? cards.vendidos : {})
            setPending(cards.pendente ? cards.pendente : {})
            setCancelled(cards.cancelado ? cards.cancelado : {})
            setLoadingValues(false)

          
        }catch(e){
            const response = e.response ? e.response : {};
            const data = response.data ? response.data : {};
            const msg = data.msg ? data.msg[0] : "Ocorreu um erro ao buscar os eventos";
            toast.error(msg)
            setLoadingValues(false)

        }
    }

    const findDataEvents = async () => {
        try {
            setLoadingTable(true)
            const result = await Api().post("/eventos/listar",{...filter,  user_id,publicado: true })
            const resultData = result.data.eventos ? result.data.eventos : []
            let internalData = [];
            resultData.map((value) => {
                internalData.push({
                    id: value.id,
                    name: value.nome,
                    photo: value.imagem ? config.storage_link+`/evento/${value.id}/${value.imagem}` : "",
                    dateStart: value.data_inicio,
                    street: value.logradouro,
                    city: value.cidade,
                    state: value.uf,
                })
            })
            setData(internalData)
            setDataFiltered(internalData)
            setLoadingTable(false)
        }catch(e){
            const response = e.response ? e.response : {};
            const data = response.data ? response.data : {};
            const msg = data.msg ? data.msg[0] : "Ocorreu um erro ao buscar os eventos";
            toast.error(msg)
        }

    }

    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    const onChangeCheckbox = (id) => {
        let eventsInternal = [...eventsCheck]
       
        if(eventsInternal.includes(id)){
            eventsInternal = eventsInternal.filter((item) => {
                return item != id
            })

            
        }else {
            eventsInternal.push(id)
    
        }

        setEventsCheck(eventsInternal)
    }
   
    

    const columns = [
        {
            name: '',
            selector: 'photo',
            cell:(row) => {

                return <img src={row.photo} className={css(styles.photoEvent)}/>
            }
        },
        {
            name: 'Data',
            selector: 'date',
            sortable: true,
            cell:(row) => {
                return(<div > 
                    <div><span className={css(styles.itemTableSpan)}>{moment(row.dateStart).format("DD/MM/YYYY")}</span></div>
                </div>);
            }
        },
        {
            name: 'Nome',
            selector: 'name',
            sortable: true,
            cell:(row) => {
                return(<div > 
                    <div><span className={css(styles.itemTableSpan)}>{(row.name)}</span></div>
                </div>);
            }
        },
        {
            name: 'Local',
            selector: 'local',
            sortable: true,
            cell:(row) => {
                return(<div > 
                    <div><span className={css(styles.itemTableSpan)}>{row.city} - {row.state}</span></div>
                </div>);
            }
        },
        {
            name: <Checkbox 
                value={dataFiltered.length != 0 && dataFiltered.length == eventsCheck.length}
                onChange={() => { 
                    updateAllEvents(!(dataFiltered.length != 0 && dataFiltered.length == eventsCheck.length))
                }}
            />,
            cell:(row) => 
            <div>
                <Checkbox
                    id={"ath"+row.id}
                    value={eventsCheck.includes(row.id)}
                    onChange={() => onChangeCheckbox(row.id)}
                />
            </div>,
        }

        
    ]

    return <div className={css(styles.main)}>
        <Row>
            <Col s={8} m={9}>
                <h6 className={css(styles.title)}>Minha Carteira</h6>
                <div>
                    <span>{capitalizeFirstLetter(moment().format("dddd, DD [de] MMMM [de] YYYY"))}</span>
                </div>
            </Col>
            <Col s={4} m={3}>
                <InputSearch 
                    id="name"
                    value={filter}
                    onChange={(e) => setFilter(e.target.value)}
                    label={"Buscar"} 
                />
            </Col>
        </Row>
        <Row>
        {loadingValues && data.length > 0 && <BeatLoader css={styles.loadingValuesCss} color={"#110F62"} size={25}/>}
        {(!loadingValues || data.length == 0) && <div className={css(styles.boxes)}>
                <div className={css(styles.box, {backgroundColor: "#00B050"})}>
                    <span className={css(styles.textBoxSpan)}>
                        {sold.qtd}
                    </span>
                    <span className={css(styles.textBoxSpan)}>
                        R$ {parseFloat(sold.valor).toFixed(2).replace(".",",")}
                    </span>
                    <span className={css(styles.textBoxSpan)}>
                        Vendidos
                    </span>
                </div>
                <div className={css(styles.box, {backgroundColor: "#FFC000"})}>
                    <span className={css(styles.textBoxSpan)}>
                        {pending.qtd}
                    </span>
                    <span className={css(styles.textBoxSpan)}>
                        R$ {parseFloat(pending.valor).toFixed(2).replace(".",",")}
                    </span>
                    <span className={css(styles.textBoxSpan)}>
                        Pendentes
                    </span>
                </div>
                <div className={css(styles.box, {backgroundColor: "#C00000"})}>
                    <span className={css(styles.textBoxSpan)}>
                        {cancelled.qtd}
                    </span>
                    <span className={css(styles.textBoxSpan)}>
                        R$ 00,00
                    </span>
                    <span className={css(styles.textBoxSpan)}>
                        Cancelados
                    </span>
                </div>
            </div>}
        </Row>
        <Row>
            <Col>
                <h6 className={css(styles.title)}>Eventos em andamento</h6>
            </Col>
        </Row>
        <Row>
            <DataTable 
                progressComponent={<BarLoader css={styles.loadingCss} color={"#110F62"}/>}
                progressPending={loadingTable}
                data={dataFiltered}
                columns={columns}
                noHeader={true}
                overflowY={true}   
                persistTableHead  
                noDataComponent={"Não existem dados para mostrar"}
            />
        </Row>
    </div>
}

const styles = {
    loadingCss: {
        width: "100%"
    },
    loadingValuesCss: {
        width: "100%",
        textAlign: "center",
        marginTop: 30,
        height: 150,
    },
    photoEvent: {
        width: 40,
        height: 40,
        borderRadius: 250,
        marginTop: 5,
        marginBottom: 5
    },
    main: {
        paddingBottom: 20
    },
    title: {
        fontWeight: "bold"
    },
    textBoxSpan: {
        fontSize: 20,
        fontWeight: 500,
        color: "#fff"
    },
    titleBoxSpan: {
        color: "#110F62",
        fontFamily: "Roboto",
        fontSize: 20,
        marginTop: 20
    },
    boxes: {
        marginTop: 30,
        display: "flex",
    },
    box: {
        position: "relative",
        marginLeft: 5,
        marginRight: 5,
        flex: 1,
        display: "flex",
        justifyContent: "space-evenly",
        flexDirection: "column",
        alignItems: "center",
        fontFamily: "Roboto",
        height: 150,
        borderRadius: 20,
        backgroundColor: "#CFCEF2",
        boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.25)",
        "@media(max-width: 850px)": {
            display: "none"
        },
    }
}

export default Component;