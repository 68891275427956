import React, { useReducer, useEffect } from "react"
import { reducer, initialState, JuizCronometroProvider, actions } from "./JuizCronometroContext"
import Api from "../../api/Api"
import JuizCronometroOrganizador from "./JuizCronometroOrganizador"
import JuizCronometroAtleta from "./JuizCronometroAtleta"
import {  useLocation} from "react-router-dom"
const Component = (props) => {

    const [state, dispatch] = useReducer(reducer, initialState)
    

    const location = useLocation()
    

    useEffect(() => {
        const search = new URLSearchParams(location.search)
        const id_event = search.get("id_event")
        if(id_event){
            dispatch({type: actions.SET_ID_EVENT_TIMER, payload: id_event})
        }
    },[])



    useEffect(() => {
        (async () => {
            if(state.id_event_access){
                dispatch({type: actions.SET_LOADING_TABLE_ACCESS, payload: true})
                const resultJuiz =  await Api().get("/eventosjuiz/listar/"+state.id_event_access)
                const dataJuiz = resultJuiz.data ? resultJuiz.data : {}
                const judges = dataJuiz.juizes ? dataJuiz.juizes : []
                dispatch({type: actions.SET_JUDGES_ACCESS,payload: judges})
                dispatch({type: actions.SET_LOADING_TABLE_ACCESS, payload: false})
            }
        })()
       
    },[state.id_event_access, state.forceJudgeAccess])

    useEffect(() => {
        (async () => {
            if(state.id_event_timer){
                const resultEvento = await Api().get(`eventos/getinfo/${state.id_event_timer}`)
                const dataEvento = resultEvento.data ? resultEvento.data : {} 
                const infoEvento = dataEvento.info ? dataEvento.info : {}
                dispatch({type: actions.SET_EVENT,payload: infoEvento})
            }
        })()
    }, [state.id_event_timer])

    useEffect(() => {
        (async () => {
            if(state.id_event_timer){
                const result = await Api().get(`cronometro/listar/${state.id_event_timer}?publicado=false`)
                const data = result.data ? result.data : {} 
                const cronometros = data.cronometros ? data.cronometros : {}
                const cronometrosRows = cronometros.rows ? cronometros.rows : []
                dispatch({type: actions.SET_ATH_TIMER,payload: cronometrosRows})
            }
        })()
    }, [state.id_event_timer, state.forceTimers])


    useEffect(() => {
        (async () => {
            if(state.id_event_timer){
                const result = await Api().get(`cronometro/listarcronometro/${state.id_event_timer}`)
                const data = result.data ? result.data : {} 
                const cronometros = data.cronometros ? data.cronometros : {}
                const cronometrosRows = cronometros.rows ? cronometros.rows : []
                const cronometrosInternal = []
                cronometrosRows.forEach((item,i) => {
                    cronometrosInternal.push({id: item.id, name: "Largada "+(i+1)})
                })
                dispatch({type: actions.SET_TIMERS,payload: cronometrosInternal})
            }
        })()
    }, [state.id_event_timer, state.forceStarts])

    return <JuizCronometroProvider value={{state,dispatch}}>
       {props.type == "organizador" && <JuizCronometroOrganizador/>}
       {props.type == "atleta" && <JuizCronometroAtleta/>}
    </JuizCronometroProvider>
}

export default Component;