import { Input, Select, InputPassword, ImageUploader, DatePicker, Checkbox} from "../../../../components/Form"
import { css } from "emotion";
import listUf from "../../../../constants/listUf"
import { Link } from "react-router-dom";
import ModalPoliticaAtletaMobile from "../../../Politica/ModalPoliticaAtletaMobile";
import { useState } from "react";

export default ({zipCode, handleZipCode, 
    street, setStreet, number, setNumber, 
    complement, setComplement,
    neighborhood, setNeighborhood,
    city, setCity, theme, state, setState,
    term, setTerm
}) => {

    const [openPolicy, setOpenPolicy] = useState(false)

    return <>
        <div className={css(styles.row)}>
            <Input
                id="cep"
                inputmode="numeric"
                label={"Cep*:"}
                placeholder={"Cep*:"}
                value={zipCode}
                mask={"99999-999"}
                required={true}
                color="#fff"
                radius
                backgroundColor={"#fff"}
                onChange={(e) => handleZipCode(e.target.value)}
            />
        </div>
        <div className={css(styles.row)}>
            <Input
                id="street"
                label={"Logradouro*:"}
                color="#fff"
                radius
                backgroundColor={"#fff"}
                value={street}
                required={true}
                maxLength={100}
                onChange={(e) => setStreet(e.target.value)}
            />
        </div>
        <div className={css(styles.row)}>
            <Input
                id="number"
                label={"Número*:"}
                color="#fff"
                radius
                inputmode="numeric"
                backgroundColor={"#fff"}
                value={number}
                type={"number"}
                required={true}
                onChange={(e) => setNumber(e.target.value)}
            />
        </div>
        <div className={css(styles.row)}>
            <Input
                id="complement"
                label={"Complemento:"}
                color="#fff"
                radius
                backgroundColor={"#fff"}
                maxLength={100}
                value={complement}                                                
                onChange={(e) => setComplement(e.target.value)}
            />
        </div>
        <div className={css(styles.row)}>
            <Input
                id="neighborhood"
                label={"Bairro*:"}
                color="#fff"
                radius
                backgroundColor={"#fff"}
                value={neighborhood}
                maxLength={100}
                required={true}
                onChange={(e) => setNeighborhood(e.target.value)}
            />
        </div>
        <div className={css(styles.row)}>
            <Input
                id="city"
                label={"Cidade*:"}
                color="#fff"
                radius
                backgroundColor={"#fff"}
                value={city}
                maxLength={100}
                required={true}
                onChange={(e) => setCity(e.target.value)}
            />
        </div>
        <div className={css(styles.row)}>
            <Select 
                label={"UF*:"}
                menuPlacement={"top"}
                labelStyle={styles.labelStyle}
                theme={theme}
                color="#fff"
                borderRadius={10}
                backgroundColor={"#fff"}
                value={listUf.filter(option => option.value == state)}
                onChange={(data) => setState(data.value)}
                required={true}
                options={listUf}
            />
        </div>
        <div className={css(styles.row)}>
            <Checkbox 
                label={"Li e concordo com os "}
                value={term}
                required={true}
                className={css(styles.term)}
                onChange={() => setTerm(!term)}
                extraLabel={<span style={styles.linkTerm} onClick={() => setOpenPolicy(true)}> Termos de Uso de Politica de Privacidade</span>}
            />
        </div>
        <div className={css(styles.lastComponent)}></div>
        <ModalPoliticaAtletaMobile open={openPolicy} setOpen={setOpenPolicy}/>
    </>
}

const styles = {
    row: {
        paddingRight: 15,
        paddingLeft: 15,
        display: "flex",
        marginTop: 10
    },
    flex1: {
        flex: 1,
    },
    labelStyle: {
        color: "#fff",
        width: "100%"
    },
    lastComponent: {
        height: 70
    },
    term: {
        "& span": {
            color: "#fff"
        }
    },
    linkTerm: {
       // textDecoration: "none",
       color: 'blue'
    }
    
}