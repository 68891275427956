import React,{ useState } from "react"
import { Row, Col, Card} from "react-materialize"
import { Button, ButtonClean,  Checkbox,  RadioGroup2 } from "../../components/Form"
import { useContext } from "react"
import { css } from "emotion"
import { toast } from "react-toastify"
import { useCookies } from "react-cookie"
import ModalAtleta from "./ModalAtleta"
import InscricaoContext, { actions} from "./InscricaoContext"
import ModalEquipe from "./ModalEquipe"
import { BrowserView, MobileView } from "react-device-detect"

const Component = (props) => {

    const {state, dispatch} = useContext(InscricaoContext)
    const [cookies] = useCookies(["id"])
    const [openModalAtl, setOpenModalAtl] = useState(false)
    const [openModalEquipe, setOpenModalEquipe] = useState(false)

    let optionsClothes = [];

    const tamanho_roupa = state.tickets[props.index]?.tamanho_roupa ? state.tickets[props.index].tamanho_roupa : [] 
    tamanho_roupa.map((item) => {
        if(item != "0"){
            optionsClothes.push ({
                label: item.toUpperCase(),
                value: item
            })
        }

    })
   

    const onDeleteAth = () => {
        if(state.tickets[props.index]?.team){
            toast.warn("Delete a equipe primeiro")
            return;
        }

        dispatch({type: actions.DELETE_ATHLETE_TICKET, index: props.index})
    }

    const onDeleteTeam = () => {
        dispatch({type: actions.DELETE_TEAM_TICKET, index: props.index})
    }

    const openModalTeam = () => {
        if(!state.tickets[props.index].athlete){
            toast.warn("Selecione o atleta primeiro")
            return;
        }
        setOpenModalEquipe(true)
    }

    const onSign = () => {
        dispatch({type: actions.SET_TERM_TICKET, index: props.index})
    }

    const onChangeClothes = (value) => {
        dispatch({type: actions.SET_CLOTHES, index: props.index, payload: value})
    }


    return  <div className={css(styles.section)}>
                <ModalAtleta index={props.index} open={openModalAtl} toggle={setOpenModalAtl}/>
                <ModalEquipe index={props.index} open={openModalEquipe} toggle={setOpenModalEquipe}/>
                <BrowserView>
                    <h5 className={css(styles.titleSection)}>{state.tickets[props.index]?.categoria_nome}:</h5>
                </BrowserView>
                <div className={css(styles.divCards)}>
                    <Card className={css(styles.card, styles.cardLeft)}>
                        <Row>
                            <span className={css(styles.labelCard)}>Selecionar atleta</span>
                        </Row>
                        <Row>
                            {!state.tickets[props.index]?.athlete &&
                                <ButtonClean icon={"plus"} onClick={() => setOpenModalAtl(true)}> Adicionar um atleta </ButtonClean>}
                            {state.tickets[props.index]?.athlete &&
                                <div className={css(styles.lineItem)}>
                                    <div className={css(styles.lineImgDesc)}>
                                        {!(!state.tickets[props.index]?.athlete.imagem) && <img src={state.tickets[props.index]?.athlete.imagem} className={css(styles.circleImg)}/>}
                                        <label className={css(styles.labelCard)}>{state.tickets[props.index]?.athlete.name}</label>
                                    </div>
                                    <div className={css(styles.lineDel)}>
                                        <ButtonClean icon={"del"} onClick={onDeleteAth}>  </ButtonClean>
                                        {/* <img src={circle} className={css(styles.circleImg)}/> */}
                                    </div>
                                </div>
                            }
                        </Row>
                    </Card>
                    <Card className={css(styles.card, styles.cardRight)}>
                        <Row>
                            <span className={css(styles.labelCard)}>Selecionar equipe</span>
                        </Row>
                        <Row>
                            {!state.tickets[props.index]?.team &&
                                <ButtonClean icon={"plus"} onClick={openModalTeam}> Adicionar equipe </ButtonClean>}
                            {state.tickets[props.index]?.team &&
                                <div className={css(styles.lineItem)}>
                                    <div className={css(styles.lineImgDesc)}>
                                        <img src={state.tickets[props.index].team.imagem} className={css(styles.circleImg)}/>
                                        <label className={css(styles.labelCard)}>{state.tickets[props.index].team.nome}</label>
                                    </div>
                                    <div className={css(styles.lineDel)}>
                                        <ButtonClean icon={"del"} onClick={onDeleteTeam}>  </ButtonClean>
                                    </div>
                                </div>
                            }
                        </Row>
                    </Card>
                </div>
                <BrowserView>
                    <div className={css(styles.divCards)}>
                        <Card className={css(styles.card, styles.cardLeft)}>
                            <Row>
                                <span className={css(styles.labelCard)}>Medida do kit</span>
                            </Row>
                            <Row>
                                <RadioGroup2
                                    id={"clothes"+props.index}
                                    options={optionsClothes}
                                    value={state.tickets[props.index]?.clothes}
                                    onChange={(e) => onChangeClothes(e.target.value)}
                                />
                            </Row>
                        </Card>
                        <Card className={css(styles.card, styles.cardRight)}>
                            {state.tickets[props.index]?.athlete?.id == cookies.id && <><Row>
                                <span className={css(styles.labelCard)}>Li e concordo com os Termos de responsabilidade na participação da prova</span>
                            </Row>
                            <Row>
                                <Col m={12} s={12} l={6} className="offset-l3">
                                    {!state.tickets[props.index]?.term && 
                                        <Button onClick={onSign}>Assinar</Button>
                                    }
                                    {state.tickets[props.index]?.term && 
                                        <label className={css(styles.labelCard, {marginLeft: "25%",textAlign: "center"})}>Termo assinado</label>
                                    }
                                </Col>
                            </Row></>}
                            {state.tickets[props.index]?.athlete?.id != cookies.id && 
                                <Row>
                                    <span className={css(styles.labelCard)}>
                                        A assinatura dos Termos de responsabilidade na participação da prova desse ingresso deve ser assinado pelo atleta participante.
                                    </span>
                                </Row>
                            }
                            </Card>
                        </div>   
                    </BrowserView>
                    <MobileView>
                        <div className={css(styles.divCards)}>
                            <Card className={css(styles.card, styles.cardLeft)}>
                                <Row>
                                    <span className={css(styles.labelCard)}>Medida do kit</span>
                                </Row>
                                <Row>
                                    <RadioGroup2
                                        id={"clothes"+props.index}
                                        options={optionsClothes}
                                        value={state.tickets[props.index]?.clothes}
                                        onChange={(e) => onChangeClothes(e.target.value)}
                                    />
                                </Row>
                            </Card>
                            {state.tickets[props.index]?.athlete?.id != cookies.id && 
                                <Row className={css(styles.rowTem)}>
                                    <span className={css(styles.labelCard2)}>
                                        A assinatura dos Termos de responsabilidade na participação da prova desse ingresso deve ser assinado pelo atleta participante.
                                    </span>
                                </Row>
                            }
                            {state.tickets[props.index]?.athlete?.id == cookies.id && <>
                                <Checkbox 
                                    label={"Li e concordo com os Termos de responsabilidade na participação da prova"}
                                   value={state.tickets[props.index]?.term}
                                    required={true}
                                    className={css(styles.term)}
                                    onChange={onSign}
                                    // extraLabel={<Link style={styles.linkTerm}   to={"termo"}> Termos de Uso de Politica de Privacidade</Link>}
                                />
                            </>}
                        </div>
                    </MobileView>
                   
             </div>
 
}

const styles = {
    divCards: {
        display: "flex",
        flexWrap: "wrap"
    },
    lineItem: {
        display: "flex",
        justifyContent: "space-between"
    },
    lineImgDesc: {
        display: "flex",
    },
    rowTem: {
        marginTop: 10
    },
    labelCard: {
        fontFamily: "Roboto",
        fontSize: 16,
        color: "#606060",
        alignSelf:"center",
        marginLeft: 10
    },
    labelCard2: {
        fontFamily: "Roboto",
        fontSize: 16,
        color: "#606060",
        alignSelf:"center",
        textAlign: "justify"
    },
    lineDel: {

    },
    main:{
        position: "relative",
        backgroundColor: "#F2F2F2",
        minHeight: "100vh"
    },
    card: {
        flex: 1,
        "@media(max-width: 700px)": {
            flex: "100%"
        }
    },
    cardRight: {
        "@media(min-width: 700px)": {
            marginLeft: 30
        }
    },
    cardLeft: {
        "@media(min-width: 700px)": {
            marginRight: 30
        }
    },
    titleSection: {
        color: "#606060",
        fontFamily: "Roboto",
        fontSize: 20
    },
    section: {
        marginLeft: '4%',
        marginRight: "4%",
        "@media(max-width: 992px)": {
            marginLeft: "7%",
            marginRight: "7%",
        },
        "@media(max-width: 700px)": {
            marginLeft: 15,
            marginRight: 15,
        },
    },
    
    
    labelDescricao: {
        color: "#606060",
        fontFamily: "Roboto",
        fontSize: 20
    },
    meusEventosLabel: {
        color: "#606060",
        fontFamily: "Roboto",
        fontSize: 20
    },
    organizadorLabel: {
        color: "#606060",
        fontFamily: "Roboto",
        fontSize: 20
    },
    conteudoEvento: {
        marginLeft: 10,
        zIndex: 99,
        position: "relative"
    },
    conteudoEventoTitle: {
        marginLeft: 10,
        position: "absolute",
        left: "40%",
        "@media(max-width: 992px)": {
            position: "relative",
            top: 0,
            left: 0,
        },
        
    },
    imgEventoDiv: {
        height: "25vh"
    },
    imgEvento: {
        objectFit: 'cover',
        objectPosition: '0%',
        "@media(max-width: 992px)": {
            objectFit: 'contain',
            objectPosition: 'center center'
        },
    },
    button: {
        backgroundColor: "transparent",
        border: "1px solid",
        padding: "5px 15px",
        cursor: "pointer",
        borderRadius: 25,
        color: "#fff",
    },
    circleImg: {
        borderRadius: "50%",
        flex: 1,
        width: 40,
        height: 40
    },
    term: {
        paddingTop: 10,
        "& span": {
            fontSize: 12,
            color: "#040404"
        }
    },
}

export default (Component);