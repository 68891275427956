import React from "react"
import Select from 'react-select'
import { css } from 'emotion'

const Component = (props) => {
    var selectRef = null;

    
const customStylesSelect = {
    container: (provided, state) => ({
      ...provided,
    //   paddingBottom: 50
    }),
    valueContainer: (provided, state) => ({
        ...provided,
        height: 35,
        position: "normal"
    }),
    input: (provided, state) => ({
        ...provided,
        margin: '2px',
      }),
    control: (provided) => ({
        ...provided,
        borderRadius: props.borderRadius ? props.borderRadius : 0,
        backgroundColor: props.backgroundColor ? props.backgroundColor : "transparent",
        marginTop: 10,
        height: 35,
    }),
    menu: (provided, state) => ({
        ...provided,
        zIndex: 999
      }),
  }

    return  <div className={css(style.divcomponent)}> 
        <label 
            htmlFor={props.id} 
            className={css(style.label) +" left-align"} 
            style={props.labelStyle}
           >{props.label}</label>
        <Select ref={(r) => selectRef = r} 
        placeholder={"Selecione"} 
        {...props} 
        
        styles={customStylesSelect} />
        {props.required && (
            <input tabIndex={-1} 
            autoComplete={"off"}
            className={css(style.input)}
            required={true}
            onFocus={() => selectRef.focus()} 
            value={props.value}/>
        )}
    </div>
}


const style = {
    label : {
        color: "#000" 
    },
    divcomponent : {
         width: "100%"
    },
    input: {
        opacity: 1,
        width: "0px !important",
        height: "0px !important",
        position: "absolute"
    }
}

export default React.memo(Component)
