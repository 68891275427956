import React, { useContext, useEffect, useState } from "react"
import { css } from "emotion";
import { Col, Row } from "react-materialize";
import { Button, RadioGroup, Select } from "../../../../components/Form";
import SimpleRule from "./SimpleRule"
import AdvancedRule from "./AdvancedRule"
import FormTorneioContext, { actions } from "../../FormTorneioContext"

const Component = () => {

    const {state, dispatch} = useContext(FormTorneioContext)

    const optionsTypeRule = [
        {
            label: 'Simples',
            value: "simples"
          },
          {
            label: 'Avançada',
            value: "avancada"
          },
    ]

    useEffect(() => {
        const internalRules = [...state.scoringRules]

        Object.values(state.categories).forEach((item) => {
            const rule = internalRules.find((item2 => item2.esporte == item.esporte))
            if(!rule){
                internalRules.push(item)
                dispatch({
                    type: actions.ADD_SCORING_RULES,
                    payload: {
                        esporte: item.esporte,
                        regra_tipo: "simples"
                    }
                })
            }
        })
    },[state.categories])

    
    const nextPage = (e) => {
        e.preventDefault()
        dispatch({type: actions.CHANGE_PAGE,payload: 4})
    }

    return <div>
        <Row>
            <Col>
                <span>Defina as regras de pontuacao desse torneio</span>
            </Col>
        </Row>
        <Row className={css(styles.cardsWrap)}>
            {state.scoringRules.map((rule,index) => 
            <Col s={12} m={6} className={css(styles.card)}>
                <Row>
                    <Col s={6} >
                    
                        <label className={css(styles.text)}>{state.listSports.find((item) => item.value == rule.esporte)?.label}</label>
                        <Select 
                            options={optionsTypeRule}
                            onChange={(item) => { 
                                let internalValues = {...rule}
                                internalValues.regra_tipo = item.value
                                if(item.value == "simples"){
                                    internalValues.regras = [{}]
                                }else {
                                    internalValues.regras = [{posicao: 1}]
                                }
                                dispatch({
                                    type: actions.HANDLE_SCORING_RULES, 
                                    index,
                                    payload: internalValues
                                })
                                
                            }}
                            value={optionsTypeRule.filter((item) => item.value === (rule['regra_tipo']))}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col s={12} >
                        { rule.regra_tipo === "simples" && <SimpleRule 
                            index={index}
                        />}
                        { rule.regra_tipo === "avancada" && <AdvancedRule 
                            index={index}
                        />}
                    </Col>
                </Row>
            </Col>)}
        </Row>
        <Row className={css(styles.btns)}>
            <Col m={12} s={12} l={6} className="offset-l3">
                <Button loading={state.loadingPub}>Publicar Torneio</Button>
            </Col>
        </Row>
       
    </div>
}

const styles = {
    container: {
        display: 'flex'
    },
    text: {
        color: "#C4C4C4",
        fontSize: 16
    },
    btns: {
        marginTop: 55
    },
    cardsWrap: {
        display: "flex",
        flexWrap: "wrap"
    },
    card: {
        marginLeft: "unset !important"
    }
}


export default Component;