import React, { useContext, useState } from "react"
import { Row, Col } from "react-materialize"
import { Checkbox, ImageUploader, DateTimePicker, RichTextEditor, Input, Select, Button } from "../../../../components/Form"
import FormEventoContext, {actions} from "../../FormEventoContext"
import listUf from "../../../../constants/listUf"
import { css } from "emotion"
import { toast } from "react-toastify"
import ModalTorneios from "./ModalTorneios"
import TableTorneios from "./TableTorneios"

export default (props) => {

    const { dispatch, ref_form} = useContext(FormEventoContext)
    const [openModal, setOpenModal] = useState(false)

    const nextPage = (e) => {
        e.preventDefault()
        if(!ref_form.current.reportValidity()){
            return
        }
        dispatch({type: actions.CHANGE_PAGE,payload: 2})
    }

    return <div>
           <Row>
                <Col s={12} l={4}>
                    <ModalTorneios open={openModal} toggle={setOpenModal}/>
                </Col>
            </Row>
            <Row>
                <Col s={12} l={12}>
                    <TableTorneios />
                </Col>
            </Row>
            <Row className={css(styles.btnProxDiv)}>
                <Col m={12} s={12} l={6} className="offset-l3">
                    <Button onClick={nextPage}>Próximo</Button>
                </Col>
            </Row>
    </div>
}

const styles = {
    btnProxDiv: {
        marginTop: 55
    },
}