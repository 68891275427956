import React, { useState, useContext, useEffect } from "react"
import {Button, Input, ImageUploader, Select, Checkbox, RichTextEditor, ButtonClean} from "../../../../components/Form"
import { Row, Col } from "react-materialize"
import FormEventoContext from "../../FormEventoContext/FormEventoContext" 
import { Modal } from 'react-responsive-modal';
import moment from "moment"
import TableLotes from "./TableLotes";
import { toast } from "react-toastify";
import api from "../../../../api/Api";
import config from "../../../../config.js";
import { actions } from "../../FormEventoContext";
import { css } from "emotion";

const ModalCategoria = (props) => {

  const {state, dispatch} = useContext(FormEventoContext);

  const [picture, setPicture] = useState([])
  const [pictureUrl, setPictureUrl] = useState([])
  const [name, setName] = useState("")
  const [distance, setDistance] = useState("")
  const [gratuito, setGratuito] = useState(false)
  const [description, setDescription] = useState("")
  const [sport,setSport] = useState([]) 
  const [classificationAge, setClassificationAge] = useState([])
  const [clothingSize, setClothingSize] = useState([])
  const [torneioId,setTorneioId] = useState([]) 
  const [loadingPubCat, setLoadingPubCat] = useState(false)
  const idLot = moment().valueOf()

  const [lots,setLots] = useState({ [idLot] : {id : idLot, index: 0}})

  const onDrop = (picture,pictureDataURLs) => {
       setPicture(picture)
       setPictureUrl(pictureDataURLs)
  }


  useEffect(() => {
    if(props.id){
      const oldValues = state.categories[props.id]
      if(oldValues){

        if(oldValues.picture){
          setPicture([oldValues.picture])
        }else {
          setPicture([])
        }

        if(oldValues.pictureUrl){
          setPictureUrl([oldValues.pictureUrl])
        }else {
          setPictureUrl([])
        }
        setName(oldValues.nome)
        setClassificationAge(oldValues.faixa_idade ? oldValues.faixa_idade : [])
        setDistance(oldValues.distancia)
        setSport(oldValues.esporte)
        setGratuito(oldValues.gratuito)
        setDescription(oldValues.description)
        setTorneioId(oldValues.torneio_id)

        if((oldValues.tamanho_roupa && oldValues.tamanho_roupa.length > 0) || (oldValues.tamanho_roupa && oldValues.tamanho_roupa.length == 1 && oldValues.tamanho_roupa[0] != "") ){
          setClothingSize(oldValues.tamanho_roupa ? oldValues.tamanho_roupa : [])
        }else {
          setClothingSize([])
        }

        if(oldValues.lote && Object.values(oldValues.lote).length > 0){
          setLots(oldValues.lote)
        }else {
          const idLot = moment().valueOf()
          setLots({ [idLot] : {id : idLot, index: 0}})
        }
      }
    }
  },[props.id])


  useEffect(() => {
    dispatch({type: actions.PREVENT_SUBMIT, payload: true})
  },[])

  useEffect(() => {
    if(gratuito){
      const lotsId = Object.keys(lots)
      const firstLotId = lotsId[0];
      const lotsInternal =  {[firstLotId] : lots[firstLotId]}
      lotsInternal[firstLotId].preco = 0
      setLots(lotsInternal)
    }
    
  },[gratuito])

  useEffect(() => {
    dispatch({type: actions.PREVENT_SUBMIT, payload: props.open})

  },[props.open])

  const onPressSave = async  (e) => {
    e.preventDefault();
    if(!classificationAge[0]){
      toast.error('É necessario inserir a classificação por idade para continuar.')
      return;
    }
    let withPriceWithoutDates = false;
    await Object.values(lots).map((lot) => {
      if(lot.preco > 0 && (!lot.dataInicio || !lot.dataFim)){
        withPriceWithoutDates = true
      }
    });

    if(withPriceWithoutDates && !gratuito){
      toast.error('Não é possivel inserir uma categoria sem datas quando o valor do lote é maior que R$ 0')
      return;
    }

    if(distance < 0){
      toast.error('A distância não pode ser negativa')
      return;
    }

    let id;
    let result = {};
    if(props.id && state.idEvent && state.categories[props.id].torneio_id && state.categories[props.id].newId){
      result = await saveCategoryApi()
      if(!result){setLoadingPubCat(false); return}
      delete state.categories[props.id];
      id = result
      uploadImageCategory(id)
    }
    else if(props.id && state.idEvent){
      result = await updateCategoryApi();
      id = props.id
      uploadImageCategory(id)
    } 
    else if(state.idEvent){
      result = await saveCategoryApi()
      if(!result){setLoadingPubCat(false); return}
      id = result
      uploadImageCategory(id)
    }else {
      id = props.id ? props.id : moment().valueOf()
    }

    if(!result){ setLoadingPubCat(false); return}
    const newId = !props.id;
    const payload = {
      ...state.categories,
      [id] : { 
        description: description,
        esporte: sport, 
        id, 
        picture: picture[0] ? picture[0] : null, 
        pictureUrl: pictureUrl[0] ? pictureUrl[0] : null,
        nome:  name,
        imagem: state.categories[props.id] && state.categories[props.id].imagem && picture.length == 0 ? state.categories[props.id].imagem : null,
        distancia: distance,
        new: newId,
        lote: lots,
        torneio_id: torneioId,
        faixa_idade: classificationAge,
        tamanho_roupa: clothingSize,
        gratuito 
      }
    }

    dispatch({type: actions.ADD_CATEGORIES, payload})

    onClose()

    if(newId) {
      const idLot = moment().valueOf()
      setPicture([])
      setPictureUrl([])
      setName("")
      setClassificationAge([])
      setClothingSize([])
      setGratuito(false)
      setDescription("")
      setDistance(0)
      setSport("")
      setTorneioId()
      setLots({ [idLot] : {id : idLot, index: 0}})
    }
  }

  const updateCategoryApi = async () => {
    try {
      setLoadingPubCat(true)

      let lotes;
      if(gratuito) {
        lotes = Object.values(lots).filter((lot) => {
          return lot.dataInicio != null
        })
      }else {
        lotes = Object.values(lots).filter((lot) => {
          return lot.preco > 0 && !isNaN(lot.preco)
        })
      }

      const lote = lotes.map((lot) => {
        return {...lot, dataInicio: lot.dataInicio ? moment(lot.dataInicio).format("DD/MM/YYYY") : "",  dataFim: lot.dataFim ?  moment(lot.dataFim).format("DD/MM/YYYY") : ""}
      });
      const result = await api().post("categorias/editar/"+props.id,{
        nome: name,
        distancia: distance,
        esporte: sport,
        faixa_idade: classificationAge,
        tamanho_roupa: clothingSize,
        gratuito,
        descricao: description,
        lote
      })
      setLoadingPubCat(false)
      return result;
    } catch (e){
      console.log(e)
      setLoadingPubCat(false)
      const response = e.response ? e.response : {};
      const data = response.data ? response.data : {};
      const msg = data.msg ? data.msg[0] : "Ocorreu um problema ao tentar atualizar a categoria";
      toast.error(msg)
      return false;
    }
  }

  const saveCategoryApi = async () => {
    try {
      setLoadingPubCat(true)

       let lotes;
      if(gratuito) {
        lotes = Object.values(lots).filter((lot) => {
          return lot.dataInicio != null
        })
      }else {
        lotes = Object.values(lots).filter((lot) => {
          return lot.preco > 0 && !isNaN(lot.preco)
        })
      }

      const lote = lotes.map((lot) => {
        return {...lot, dataInicio: lot.dataInicio ? moment(lot.dataInicio).format("DD/MM/YYYY") : "",  dataFim: lot.dataFim ?  moment(lot.dataFim).format("DD/MM/YYYY") : ""}
      });
      const result = await api().post("categorias/salvar",{
        nome: name,
        distancia: distance,
        esporte: sport,
        faixa_idade: classificationAge,
        tamanho_roupa: clothingSize,
        imagem: picture.length == 0 && pictureUrl.length > 0 ? pictureUrl[0].replace(config.storage_link+"/categoria/","") : "",
        lote,
        gratuito,
        origin_torneio: state.categories[props.id] && state.categories[props.id].torneio_id ? state.categories[props.id].torneio_id : null,
        evento_id: state.idEvent ? state.idEvent : "",
        descricao: description,
        ativo: true
      })
      const idCat = result.data.msg.categoria_id
      setLoadingPubCat(false)
      return idCat;
    } catch (e){
      setLoadingPubCat(false)
      console.log(e)
      const response = e.response ? e.response : {};
      const data = response.data ? response.data : {};
      const msg = data.msg ? data.msg[0] : "Ocorreu um problema ao tentar criar a categoria";
      toast.error(msg)

      return false;
    }
  }

  const uploadImageCategory = async (id) => {
    let formData = new FormData();
    if(picture[0]){
        formData.append("categoria_id",id)
        formData.append("file",picture[0])
        await api().post("categorias/uploadimagens",formData)
    }
  }

  const onClose = () => {
    dispatch({type: actions.PREVENT_SUBMIT, payload: false})
    
    props.toggle(false)
  }

  const onChangeClassificationAge = (value) =>{
    const isChecked = classificationAge.includes(value)
    let classificationAgeInternal = classificationAge
    if(isChecked){
      classificationAgeInternal = classificationAge.filter((item) => item != value)
    }else {
      classificationAgeInternal.push(value)
    }
    setClassificationAge(classificationAgeInternal)
  }

  const onChangeClothingSize = (value) => {
    const isChecked = clothingSize.includes(value)
    let clothingSizeInternal = clothingSize.filter((item) => {
      return item != ""
    })
    console.log("Roupa")
    console.log(clothingSizeInternal)
    if(isChecked){
      clothingSizeInternal = clothingSize.filter((item) => item != value)
    }else {
      clothingSizeInternal.push(value)
    }
    setClothingSize(clothingSizeInternal)
  }

  return <div>
{!props.id && !props.edit && 
  <ButtonClean onClick={() =>  props.toggle(true)} icon={"plus"}>Adicionar Categoria</ButtonClean>
}
<Modal
  open={props.open}
  center
  type={"button"}
  onClose={onClose}
  styles={{
    modal: styles.modal
  }}
>
  <h5 className="grey-text text-darken-3 center-align">Categoria</h5>
  <form id="formCategoria" type="POST" name="formCategoria" onSubmit={onPressSave}  role="form">
    <div>
      <Row>
          <Col s={12} l={3}>
              <ImageUploader
                  buttonText='Escolher Imagem'
                  defaultImages={pictureUrl}
                  aspect={16/9}
                  crop
                  onDrop={onDrop}
              />
          </Col>
          <Col  s={12} l={9}>
              <Row>
                  <Col s={12}>
                      <Input
                          label={"Nome da Categoria*:"}
                          key="nome_cat"
                          value={name}
                          disabled={torneioId != null && Object.keys(state.tournaments).includes(`${torneioId}`)}
                          required={true}
                          onChange={(e) => setName(e.target.value)}
                        />
                  </Col>
              </Row>
              <Row>
                <Col s={12} l={6}>
                    <Select
                        label={"Esporte*:"}
                        options={state.listSports}
                        isDisabled={torneioId != null && Object.keys(state.tournaments).includes(`${torneioId}`)}
                        value={state.listSports.filter(option => option.value == sport)}
                        required={true}
                        form="required"
                        onChange={(data) => setSport(data.value)}
                      />
                </Col>
                <Col s={12} l={6}>
                    <Input
                      label={"Distância*:"}
                      disabled={torneioId != null && Object.keys(state.tournaments).includes(`${torneioId}`)}
                      type={"number"}
                      placeholder={"Metros"}
                      value={distance}
                      required={true}
                      onChange={(e) => setDistance(e.target.value)}

                    />
                </Col>
              </Row>
          </Col>
      </Row>
      <Row>
          <Col s={9} push={"l3"}>
            <div className={css(styles.divClassification)}>
                <label className={css(styles.labelClass)}>Classificações por idade*:</label>
            </div>
            <div className={css(styles.classficationBoxes)}>
              <Checkbox 
                    id={"kids"}
                    label={"Kids"}
                    disabled={torneioId != null && Object.keys(state.tournaments).includes(`${torneioId}`)}
                    value={classificationAge.includes("kids")}
                    className={css(styles.classCheckBox)}
                    onChange={() => onChangeClassificationAge('kids')}
              />
              <Checkbox 
                    id={"juvenil"}
                    label={"Juvenil"}
                    disabled={torneioId != null && Object.keys(state.tournaments).includes(`${torneioId}`)}
                    value={classificationAge.includes("juvenil")}
                    className={css(styles.classCheckBox)}
                    onChange={() => onChangeClassificationAge('juvenil')}
              />
              <Checkbox 
                    id={"open"}
                    label={"Open"}
                    disabled={torneioId != null && Object.keys(state.tournaments).includes(`${torneioId}`)}
                    value={classificationAge.includes("open")}
                    className={css(styles.classCheckBox)}
                    onChange={() => onChangeClassificationAge('open')}
              />
              <Checkbox 
                    id={"master"}
                    label={"Master"}
                    disabled={torneioId != null && Object.keys(state.tournaments).includes(`${torneioId}`)}
                    value={classificationAge.includes("master")}
                    className={css(styles.classCheckBox)}
                    onChange={() => onChangeClassificationAge('master')}
              />
              <Checkbox 
                    id={"super_master"}
                    label={"Super Master"}
                    disabled={torneioId != null && Object.keys(state.tournaments).includes(`${torneioId}`)}
                    value={classificationAge.includes("super_master")}
                    className={css(styles.classCheckBox)}
                    onChange={() => onChangeClassificationAge('super_master')}
              />
              <Checkbox 
                    id={"legend"}
                    label={"Legend"}
                    disabled={torneioId != null && Object.keys(state.tournaments).includes(`${torneioId}`)}
                    value={classificationAge.includes("legend")}
                    className={css(styles.classCheckBox)}
                    onChange={() => onChangeClassificationAge('legend')}
              />
            </div>
          </Col>
      </Row>
      <Row>
          <Col s={9} push={"l3"}>
            <div className={css(styles.divClassification)}>
                <label className={css(styles.labelClass)}>Tamanho da roupa:</label>
            </div>
            <div className={css(styles.classficationBoxes)}>
                <Checkbox 
                      id={"p"}
                      label={"P"}
                      value={clothingSize.includes("p")}
                      className={css(styles.classCheckBox)}
                      onChange={() => onChangeClothingSize('p')}
                />
                <Checkbox 
                      id={"m"}
                      label={"M"}
                      value={clothingSize.includes("m")}
                      className={css(styles.classCheckBox)}
                      onChange={() => onChangeClothingSize('m')}
                />
                <Checkbox 
                      id={"g"}
                      label={"G"}
                      value={clothingSize.includes("g")}
                      className={css(styles.classCheckBox)}
                      onChange={() => onChangeClothingSize('g')}
                />
                <Checkbox 
                      id={"gg"}
                      label={"GG"}
                      value={clothingSize.includes("gg")}
                      className={css(styles.classCheckBox)}
                      onChange={() => onChangeClothingSize('gg')}
                />
            </div>
          </Col>
      </Row>
      <Row>
          <Col s={12} l={12}>
              <RichTextEditor
                  label={"Descrição:"}
                  value={description}
                  onChange={setDescription}
              />
          </Col>
      </Row>
      <Row>
        <Col s={12}>
          <Checkbox 
            id={"gratuito"+idLot}
            label={"Gratuito?"}
            value={gratuito}
            onChange={() => setGratuito(!gratuito)}
          />
        </Col>
      </Row>
      <Row>
        <Col s={12}>
          <TableLotes setLots={setLots} lots={lots} gratuito={gratuito}/>
        </Col>
      </Row>
      <Row>
        <Col m={12} s={12} l={6} className="offset-l3">
          <Button loading={loadingPubCat} form="formCategoria">Publicar Categoria</Button>
        </Col>
      </Row>
      </div>
    </form>
</Modal>

</div>
}

const styles = {
  modal: {
    width: "100%"
  },
  classCheckBox: {
    marginRight: 10
  },
  classficationBoxes: {
    display: "flex",
    flexWrap: "wrap"
  },
  labelClass: {
      color: "#000",
      fontSize: 16,
      fontFamily: "Roboto"
  },
  divClassification: {
      marginBottom: 30
  }
}

export default React.memo(ModalCategoria)