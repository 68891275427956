import { css } from "emotion"
import React, { useState, useEffect, useCallback, useContext } from "react"
import { Container } from "react-materialize"
import Carousel from "../../../components/Carousel"
import { InputSearch } from "../../../components/Form"
import ItemEventoLista from "../ItemEventoLista"
import HomeContext from "../HomeContext"
import ItemEventoMobileLista from "../ItemEventoMobileLista"
import ItemEventoMobileTable from "../ItemEventoMobileTable"
import icon_search from "../../../assets/icons/filter.svg"
import { BeatLoader } from "react-spinners"
import FilterMobile from "../FilterMobile"

export default () => {
    const contextHome = useContext(HomeContext)


    const onSearch = (e) => {
        contextHome.setFilterName(e.target.value)
        contextHome.setFilter({
            ...contextHome.filter,
            nome: e.target.value,
        })
    }

    return <div className={css(styles.container)}>
        <Container>
            <div className={css(styles.searchDivFilter)}>
                <InputSearch
                    cssStyle={styles.search}
                    className={css(styles.searchField)}
                    onSearch={onSearch}
                    label={"buscar"}
                />
                <div className={css(styles.filterDiv)} onClick={() => contextHome.setFiltering(true)}>
                    <span className={css(styles.iconFilter) + " material-icons"}>filter_alt</span>
                </div>

            </div>
            <div className={css(styles.title)}>
                <span>Eventos</span>
            </div>
            {contextHome.loadingTable && <div>
                <BeatLoader css={styles.loadingCss} color={"#110F62"} size={25}/>
            </div>}
            <div>
                {!contextHome.loadingTable && contextHome.eventos.map((item,i) => {
                    return <ItemEventoMobileTable key={"eb"+i} item={item}/>
                })}
            </div>
            <FilterMobile/>
        </Container>
    </div>
}

const styles = {
    filterDiv: {
        marginTop: 25,
        marginLeft: 5
    },
    searchDivFilter: {
        display: "flex"
    },
    loadingCss: {
        width: "100%",
        textAlign: "center",
        //marginLeft: "50%",
    },
    iconFilter: {
        fontSize: 30
    },
    container: {
        backgroundColor: "#fff"
    },
    search: {
        borderRadius: 10,
        paddingLeft: "0px !important",
        textIndent: 10,
        border: "0.5px solid #9e9e9e !important",
        boxShadow: "none"

    },
    searchField: {
        marginTop: 20,
    },
    title: {
        fontFamily: "Destaques",
        fontSize: 15,
        paddingTop: 5,
        paddingBottom: 10,
        color: "#8E8E92",
        fontWeight: "bold"
    },
   
}