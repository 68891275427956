export default {
    currentPage: 0,
    events: [],
    allEvents: [],
    event: {},
    id_event_timer: 0,
    id_event_access: 0,
    loadingTableAccess: false,
    timeStart: "",
    judges_event_access: [],
    numberAthTimer: "",
    forceJudgeAccess: false,
    forceTimers: false,
    forceStarts: false,
    isStartedTimer: false,
    loadingAllEvents: true,
    name_event_timer: "",
    athAddedTimer: [],
    timers: []
}