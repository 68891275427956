import React, { useRef } from "react"
import { Row, Col } from "react-materialize"
import {Button, InputPassword} from "../../../components/Form"
import {useCookies} from "react-cookie"
import ModalMobileFullScreen from "../../../components/ModalMobileFullScreen"
import { useHistory } from "react-router-dom"

export default ({
    onSubmit, 
    loadingPub, 
    oldPassword, 
    setOldPassword,
    newPasssword,
    setNewPassword,
    regexPassword,
    messageErroPassword,
    newPasswordRepeat,
    setNewPasswordRepeat,
}) => {

    const history = useHistory()

    const ref_form = useRef()

   
    return <ModalMobileFullScreen
        open={true} 
        contentStyle={styles.contentModal}
        onClose={() => history.goBack()}
        title={"Pagamento Ingresso"}
        btnFooterText={"Atualizar"}
        loading={loadingPub}
        onClickBtnFooter={async (e) => {
            if(!ref_form.current.reportValidity()){
                return
            }
            onSubmit(e)
        }}
    >
    <form  id="evento" type="POST" ref={ref_form}>
                    <Row>
                        <Col s={12} l={6}>
                            <InputPassword
                                id="old_password"
                                label={"Senha Atual*:"}
                                type={"password"}
                                color="#000"
                                backgroundColor="#FFFCFC"
                                coloricon="#6B69CF"
                                radius={true}
                                required={true}
                                value={oldPassword}
                                onChange={(e) => setOldPassword(e.target.value)}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col s={12} l={6}>
                                <InputPassword
                                    id="new_password"
                                    label={"Nova Senha*:"}
                                    type={"password"}
                                    required={true}
                                    color="#000"
                                    backgroundColor="#FFFCFC"
                                    coloricon="#6B69CF"
                                    radius={true}
                                    pattern={`${regexPassword}`}
                                    messageError={(!newPasssword || newPasssword.match(regexPassword) == null) && messageErroPassword}
                                    title={messageErroPassword}
                                    value={newPasssword}
                                    onChange={(e) => setNewPassword(e.target.value)}
                                />
                        </Col>
                        <Col s={12} l={6}>
                                <InputPassword
                                    id="new_password_repeat"
                                    label={"Confirmar Nova Senha*:"}
                                    type={"password"}
                                    required={true}
                                    color="#000"
                                    backgroundColor="#FFFCFC"
                                    coloricon="#6B69CF"
                                    radius={true}
                                    pattern={`^${newPasswordRepeat}$`}
                                    messageError={!newPasswordRepeat || newPasswordRepeat != newPasssword && "As senhas devem ser iguais"}
                                    title="As senhas devem ser iguais"
                                    value={newPasswordRepeat}
                                    onChange={(e) => setNewPasswordRepeat(e.target.value)}
                                />
                        </Col>
                    </Row>
              
                </form>
    </ModalMobileFullScreen>
}

const styles={
    newEvento : {
        float:"left"
    },
    main: {
        paddingBottom: 20
    },
    labelImg:{
        color: "#000",
    },
    messageInfo: {
        color: "#000",
        fontWeight: 700,
        fontSize: 16
    },
    photoEvent: {
        width: "100%",
        paddingTop: 20,
        position: "relative"
    }
}