export default {
    ADD_LIST_TEAMS: 'ADD_LIST_TEAMS',
    ADD_LIST_TEAM: 'ADD_LIST_TEAM',
    SET_LIST_TEAMS: 'SET_LIST_TEAMS',
    HANDLE_CHANGE: 'HANDLE_CHANGE',
    HANDLE_ZIP_CODE: 'HANDLE_ZIP_CODE',
    SET_CURRENT_ELEMENT: 'SET_CURRENT_ELEMENT',
    UPDATE_LOADING_TEAMS: 'UPDATE_LOADING_TEAMS',
    SET_TEAM_ID: 'SET_TEAM_ID',
    UPDATE_MEMBERS_FORCE: 'UPDATE_MEMBERS_FORCE',
    UPDATE_TEAMS_FORCE: 'UPDATE_TEAMS_FORCE',
    UPDATE_INVITES_FORCE: 'UPDATE_INVITES_FORCE'
}