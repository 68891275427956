import { css } from "emotion";
import { useContext } from "react";
import DataTable from "react-data-table-component"
import ModalMobileFullScreen from "../../../../../components/ModalMobileFullScreen"
import JuizCronometroContext from "../../../JuizCronometroContext";

export default ({open, onClose, setIdDel, setOpenDelete}) => {

    const {state} = useContext(JuizCronometroContext)

    const columns = [
        {
            selector: 'id',
            name: "ID",
            sortable: true,
        },
        {
            selector: 'name',
            name: "Nome",
            sortable: true
        },
        {  
            selector: 'acoes',
            name: "Ações",
            cell: (row) => <div>
                <span className={css(styles.btnDel) + " material-icons"} onClick={() => {
                            setIdDel(row.id);
                            setOpenDelete(true)
                }}>delete</span>
            </div>
        }
    ];
    
   return <ModalMobileFullScreen
        open={open}
        onClose={onClose}
        title={"Largadas"}
   >
       <DataTable
            columns={columns}
            data={state.timers}
            noDataComponent={""}
            responsive={false}
            fixedHeader
            fixedHeaderScrollHeight={"223px"}
            noHeader
       />
   </ModalMobileFullScreen>
}

const styles = {
    btnDel: {
        cursor: "pointer",
        color: "red"
    }
}

