import React from "react"
import { Row, Col } from 'react-materialize';
import {Button, Input, Checkbox, Select, InputPassword, ImageUploader, DatePicker} from "../../../components/Form"
import {
    Link
  } from "react-router-dom";
import listUf from "../../../constants/listUf"
import AppLogin from "../../../layouts/AppLogin"
import { css } from 'emotion'
import listCountries from "../../../constants/listCountries";
import { useState } from "react";
import ModalPoliticaAtletaBrowser from "../../Politica/ModalPoliticaAtletaBrowser";

export default ({
    email,setEmail,
    password,setPassword,
    confpassword,setConfpassword,
    name,setName,
    type,
    pictureUrl,
    cpf_passport, setCpfPassport,
    validCPFText,
    phone,setPhone,
    cellphone,setCellphone,
    zipCode,
    street,setStreet,
    number,setNumber,
    complement,setComplement,
    neighborhood,setNeighborhood,
    city,setCity,
    state,setState,
    term,setTerm,
    validEmail,
    messageErroCPFCNPJ,
    messageErrorEmail,
    loading,
    gender, setGender,
    birthdate, setBirthdate,
    regexPassword, messageErroPassword,
    options, optionsGender, onSubmit, changeType,
    onDrop, handleZipCode,
    handleCPFPassport, validExistsEmail,
    country, setCountry
}) => {
    const [openPolicy, setOpenPolicy] = useState(false)

    const theme =  (theme) => ({
        ...theme,
        borderRadius: 0,
        colors: {
            ...theme.colors,
            primary25: '#6B69CF',
            primary: '#6B69CF',
            neutral80: "#6B69CF",
            neutral20: "#6B69CF"
        },
    })

    return <AppLogin>
                <h5 className={css(styles.title)+" text-darken-3 center-align"}>Cadastro de Atleta</h5>
                <form onSubmit={onSubmit}>
                    <div>
                        <Row>
                            <Col s={12} l={6}>
                            <ImageUploader
                                buttonText='Escolher Imagem'
                                defaultImages={pictureUrl}
                                onDrop={onDrop}
                            />
                            </Col>
                            <Col s={12} l={6}>
                                <Row>
                                    <Col s={12}>
                                        <Select
                                            id="type"
                                            label={"Tipo de nacionalidade*:"}
                                            labelStyle={styles.labelStyle}
                                            theme={theme}
                                            value={type}
                                            isSearchable={false}
                                            color="rgba(107, 105, 207, 0.5)"
                                            required={true}
                                            onChange={changeType}
                                            options={options}
                                        /> 
                                            
                                    </Col>
                                </Row>
                                <Row>
                                    <Col s={12}>
                                    
                                        <Input
                                            id="cpf_passport"
                                            label={type.value == "nativo" ? "CPF*:" : "Passaporte*:"}
                                            mask={type.value == "nativo" ? "999.999.999-99" : ""}
                                            pattern={validCPFText}
                                            validate={false}
                                            classNameInput={validCPFText ? "valid" : ( cpf_passport.length > 0 ? "invalid" : "") }
                                            title="CPF está incorreto"
                                            color="rgba(107, 105, 207, 0.5)"
                                            value={cpf_passport}
                                            required={true}
                                            messageError={messageErroCPFCNPJ}
                                            onBlur={(e) => handleCPFPassport(e.target.value)}
                                            onChange={(e) => setCpfPassport(e.target.value)}
                                        />
                                    
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col s={12}>
                                    <Input
                                            id="email"
                                            label={"E-mail*:"}
                                            value={email}
                                            validate={false}
                                            classNameInput={validEmail ? "valid" : ( email.length > 0 ? "invalid" : "") }
                                            title={"E-mail incorreto"}
                                            color="rgba(107, 105, 207, 0.5)"
                                            required={true}
                                            pattern={validEmail}
                                            messageError={messageErrorEmail}
                                            onBlur={(e) => validExistsEmail(e.target.value)}
                                            onChange={(e) => setEmail(e.target.value.toLowerCase())}
                                            type="email"
                                        />
                            </Col>
                        </Row>  
                        <Row>
                            <Col s={12}>
                                    <Input
                                            id="name"
                                            label={"Nome*:"}
                                            color="rgba(107, 105, 207, 0.5)"
                                            value={name}
                                            maxLength={100}
                                            required={true}
                                            onChange={(e) => setName(e.target.value)}
                                        />
                            </Col>
                        </Row> 
                        <Row>
                            <Col s={12}>
                                <Select
                                    id="pais"
                                    label={"País de Origem*:"}
                                    theme={theme}
                                    value={country}
                                    labelStyle={styles.labelStyle}
                                    isSearchable={true}
                                    required={true}
                                    onChange={setCountry}
                                    options={listCountries}
                                /> 
                            </Col>
                        </Row>
                        <Row>
                            <Col s={12}>
                                <Select
                                    id="genero"
                                    label={"Gênero:"}
                                    color="rgba(107, 105, 207, 0.5)"
                                    labelStyle={styles.labelStyle}
                                    theme={theme}
                                    value={type}
                                    isSearchable={false}
                                    required={true}
                                    value={gender}
                                    options={optionsGender}
                                    onChange={(value) => setGender(value)}
                                />
                            </Col>
                        </Row> 
                        <Row>
                            <Col s={12} l={6}>
                                    <Input
                                            id="cellphone"
                                            label={"Celular*:"}
                                            pattern=".{0}|.{15,}"
                                            title="O celular está incorreto"
                                            color="rgba(107, 105, 207, 0.5)"
                                            value={cellphone}
                                            required={true}
                                            mask={"(99) 99999-9999"}
                                            onChange={(e) => setCellphone(e.target.value)}
                                        />
                            </Col>
                            <Col s={12} l={6}>
                                    <Input
                                        id="phone"
                                        label={"Telefone:"}
                                        pattern=".{0}|.{14,}"
                                        title="O telefone está incorreto"
                                        color="rgba(107, 105, 207, 0.5)"
                                        value={phone}
                                        mask={phone.length >= 15 ? "(99) 99999-9999" : "(99) 9999-99999"}
                                        onChange={(e) => setPhone(e.target.value)}
                                    />
                            </Col>
                        </Row> 
                        <Row>
                            <Col s={12}>
                                <DatePicker 
                                    color="rgba(107, 105, 207, 0.5)"
                                    label={"Data de Nascimento*:"}
                                    maxDate={new Date()}
                                    value={birthdate}
                                    onChange={setBirthdate}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col s={12} l={6}>
                                    <InputPassword
                                            id="password"
                                            label={"Senha*:"}
                                            type={"password"}
                                            value={password}
                                            color="rgba(107, 105, 207, 0.5)"
                                            coloricon="#6B69CF"
                                            pattern={`${regexPassword}`}
                                            messageError={!password || password.match(regexPassword) == null && messageErroPassword}
                                            title={messageErroPassword}
                                            maxLength={30}
                                            required={true}
                                            onChange={(e) => setPassword(e.target.value)}
                                        />
                            </Col>
                            <Col s={12} l={6}>
                                    <InputPassword
                                            id="conf_password"
                                            label={"Confirmar Senha*:"}
                                            type={"password"}
                                            color="rgba(107, 105, 207, 0.5)"
                                            coloricon="#6B69CF"
                                            pattern={`^${password}$`}
                                            messageError={!confpassword || confpassword != password && "As senhas devem ser iguais"}
                                            title="As senhas devem ser iguais"
                                            required={true}
                                            value={confpassword}
                                            maxLength={30}
                                            onChange={(e) => setConfpassword(e.target.value)}
                                        />
                            </Col>
                        </Row> 
                        <Row>
                            <Col s={12} l={6}>
                                    <Input
                                            id="cep"
                                            label={"Cep*:"}
                                            color="rgba(107, 105, 207, 0.5)"
                                            value={zipCode}
                                            mask={"99999-999"}
                                            required={true}
                                            onChange={(e) => handleZipCode(e.target.value)}
                                        />
                            </Col>
                        </Row> 
                        <Row>
                            <Col s={12} l={9}>
                                    <Input
                                            id="street"
                                            label={"Logradouro*:"}
                                            color="rgba(107, 105, 207, 0.5)"
                                            value={street}
                                            required={true}
                                            maxLength={100}
                                            onChange={(e) => setStreet(e.target.value)}
                                        />
                            </Col>
                            <Col s={12} l={3}>
                                    <Input
                                            id="number"
                                            label={"Número*:"}
                                            color="rgba(107, 105, 207, 0.5)"
                                            value={number}
                                            type={"number"}
                                            required={true}
                                            onChange={(e) => setNumber(e.target.value)}
                                        />
                            </Col>
                        </Row> 
                        <Row>
                            <Col s={12} l={6}>
                                    <Input
                                            id="complement"
                                            label={"Complemento:"}
                                            color="rgba(107, 105, 207, 0.5)"
                                            maxLength={100}
                                            value={complement}                                                
                                            onChange={(e) => setComplement(e.target.value)}
                                        />
                            </Col>
                            <Col s={12} l={6}>
                                    <Input
                                            id="neighborhood"
                                            label={"Bairro*:"}
                                            color="rgba(107, 105, 207, 0.5)"
                                            value={neighborhood}
                                            maxLength={100}
                                            required={true}
                                            onChange={(e) => setNeighborhood(e.target.value)}
                                        />
                            </Col>
                        </Row> 
                        <Row>
                            <Col s={12} l={9}>
                                    <Input
                                            id="city"
                                            label={"Cidade*:"}
                                            color="rgba(107, 105, 207, 0.5)"
                                            value={city}
                                            maxLength={100}
                                            required={true}
                                            onChange={(e) => setCity(e.target.value)}
                                        />
                            </Col>
                            <Col s={12} l={3}>
                                    <Select 
                                        label={"UF*:"}
                                        menuPlacement={"top"}
                                        labelStyle={styles.labelStyle}
                                        theme={theme}
                                        color="rgba(107, 105, 207, 0.5)"
                                        value={listUf.filter(option => option.value == state)}
                                        onChange={(data) => setState(data.value)}
                                        required={true}
                                        options={listUf}
                                    />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Checkbox 
                                    label={"Li e concordo com os "}
                                    value={term}
                                    required={true}
                                    onChange={() => setTerm(!term)}
                                    extraLabel={<Link to={"#"} onClick={() => setOpenPolicy(true)}> Termos de Uso de Politica de Privacidade</Link>}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col m={12} s={12} l={6} className="offset-l3">
                                <Button loading={loading}>Cadastrar</Button>
                            </Col>
                        </Row>
                    </div>
                </form>
                <ModalPoliticaAtletaBrowser open={openPolicy} setOpen={setOpenPolicy}/>
            </AppLogin> 
   
}

const styles =  {
    title: {
        color: "#6B69CF",
        fontFamily: "Roboto",
        fontWeight: "600"
    },
    labelStyle: {
        color: "#6B69CF",
    }
}