import actions from "./actions";

export default (state, action) => {
    switch(action.type){
        case actions.START_INFO_EVENT: {
            return {...state, ...action.payload}
        }
        case actions.SET_LOTS: {
            return {...state, lots: [...action.payload]}
        }
        case actions.SET_TOTAL: {
            return {...state, total: action.payload}
        }
        case actions.SET_TOTAL_NUMBER: {
            return {...state, totalNumber: action.payload}
        }
        case actions.SET_OTHER_EVENTS_ORGANIZER: {
            return {...state, otherEventsOrganizer: action.payload}
        }
        case actions.SET_LOADING_OTHER_EVENTS: {
            return {...state, loadingOtherEvents: action.payload}
        }
        case actions.SET_LOADING: {
            return {...state, loading: action.payload}
        }
    }
   
}