import React from "react"
import InputMask from 'react-input-mask';
import { css } from 'emotion'

const Component =({onChange, label, cssStyle,color, value, className, onSearch = () => {}}) => {

    const divComponent = {
        ...style.divcomponent, 
        "& label": {
            color: color ? color : "#000" 
        },
    };


    return  <div className={css(divComponent, className)}> 
    <div className={css(style.inputField)+ " "}>
       <input 
            value={value}
            onChange={onChange}
            type={"search"}
            ref={element=>(element||{}).onsearch=onSearch}
            placeholder={label} 
            className={css(style.input, cssStyle)}/>
    </div>

    </div>
}

const style = {
    label : {
        color: "#000" 
    },
    divcomponent : {
        width: "100%",
        whiteSpace: "break-spaces"
    },
    inputField: {
        marginBottom: 0
    },
    input: {
        color: "#000",
        width: "100%",
        backgroundColor: "#fff !important",
        boxSizing: "revert !important",
        borderRadius: "12px !important",
        paddingLeft: "10px !important",
        fontFamiliy: "Roboto",
        borderBottom: "0.5px solid #9e9e9e !important",
        boxShadow: "0 1px 1px rgba(0, 0, 0, 0.15)  !important"
    },
    messageError: {
        
        color: "#f00 !important"
    }
}

export default React.memo(Component)
