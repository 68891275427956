import { css } from "emotion";
import React from "react"
import { useState } from "react";
import { useContext } from "react";
import { Col, Row } from "react-materialize";
import { toast } from "react-toastify";
import Api from "../../../../api/Api";
import cronometroImg from "../../../../assets/img/cronometro.png"
import { Button } from "../../../../components/Form"
import FormEventoContext, { actions } from "../../FormEventoContext";
import ModalAlertaCompra from "./ModalAlertaCompra";

const Component = () => {

    const { state, ref_pub, ref_loading_btn, dispatch } = useContext(FormEventoContext)

    const [openModal, setOpenModal] = useState(false)

    const review = () => {
        window.open(`/evento/apresentacao/${state.idEvent}`)
    }

    const save = () => {
        ref_loading_btn.current = "save"
    }

    const pub = () => {
        ref_loading_btn.current = "pub"
        ref_pub.current = true
    }

    const buy = () => {
       setOpenModal(true)
    }

    const buyFinish = async () => {
        setOpenModal(false)
        // cronometro/comprar/
        try {
            if(state.idEvent){
                const result = await Api().get(`cronometro/comprar/${state.idEvent}`)
               
                if(!result.data.error){
                    dispatch({type: actions.HANDLE_CHANGE,
                        payload: {name: "cronometro", value: true}})
                } else {
                    toast.error("Não foi possivel realizar a compra")
                }
            } else {
                dispatch({type: actions.HANDLE_CHANGE,
                        payload: {name: "cronometro", value: true}})
                toast.warn("Ação será efetuado quando o evento for criado")
            }
        } catch (e){
            const response = e.response ? e.response : {};
            const data = response.data ? response.data : {};
            const msg = data.msg ? data.msg[0] : "Ocorreu um problema";
            toast.error(msg)
        }
        
    }

    const onClose = () => {
        setOpenModal(false)
    }
    
    

    return <div>
        <Row>
            <Col>
                <span className={css(styles.title)}>Ferramenta de cronometragem digital</span>
            </Col>
        </Row>
        <Row>
            <Col>
                A ferramenta de cronometragem te auxilia na cronometragem digital da sua prova, possibilitando registar a largada e chegada dos atletas identificados pelo numero de atleta gerado na plataforma formando a classificacao automatica e opcao de empressao reduzindo toda a borocracia de anotar na folha de papel.
            </Col>
        </Row>
        <Row>
            <Col>
                O valor vai ser descontado na venda das inscricoes. 
            </Col>
        </Row>
        <Row>
            <Col>
                <img src={cronometroImg}
                    width={150}
                />
            </Col>
            <Col>
                <div>
                    <div className={css(styles.text)}>Ferramenta de cronometragem</div>
                    <div className={css(styles.text, {marginTop: 10})}> R$ 150,00   </div>
                    {!state.cronometro && <Button type={"button"} onClick={buy} className={css({marginTop: 10})}>
                        Comprar
                    </Button>}
                    {state.cronometro && state.idEvent && <span>Ferramenta adquirida</span>}
                    {state.cronometro && !state.idEvent && <span>Pendente criação do evento</span>}
                </div>
            </Col>
            
        </Row>
        <Row className={css(styles.btns)}>
            <Col m={12} s={12} l={8} className={"offset-l2" }>
                <Col s={state.idEvent ? 4 : 6}>
                    <Button loading={state.loadingPub && ref_loading_btn.current === 'save'} onClick={save}> Salvar </Button>
                </Col>
                {state.idEvent && <Col s={4}>
                    <Button type={'button'} onClick={review}> Revisão </Button>
                </Col>}
                {!state.block && <Col  s={state.idEvent ? 4 : 6}>
                    <Button  onClick={pub} loading={state.loadingPub && (ref_loading_btn.current === "pub")}>Publicar</Button>
                </Col>}
            </Col>
        </Row>
        <ModalAlertaCompra open={openModal} onClick={buyFinish} onClose={onClose}/>
    </div>
}

const styles = {
    title: {
        fontSize: 20,
        textAlign: "justify"
    },
    text: {
        fontSize: 20,
    },
    btns: {
        marginTop: 55
    },
}

export default Component;
