import React, { useState, useEffect, useContext } from "react"
import { Modal } from 'react-responsive-modal';
import { Row, Col } from "react-materialize";
import { Button, Input, Checkbox, ButtonClean } from "../../components/Form";

import DataTable from 'react-data-table-component';
import {BarLoader} from "react-spinners";
import { css } from "emotion";
import { toast } from "react-toastify";
import Api from "../../api/Api";
import config from "../../config.js"
import InscricaoContext, { actions } from "./InscricaoContext";
import { BrowserView, MobileView } from "react-device-detect";
import ModalEquipeBrowser from "./ModalEquipeLayout/ModalEquipeBrowser";
import ModalEquipeMobile from "./ModalEquipeLayout/ModalEquipeMobile";
const Component = (props) => {

    const [search, setSearch] = useState("")
    const [data, setData] = useState([])
    const [teamSelected, setTeamSelected] = useState([])
    const [loadingTable, setLoadingTable] = useState(false);
    const [loadingBtn, setLoadingBtn] = useState(false);
    const {state, dispatch} = useContext(InscricaoContext)

    useEffect(() => {

    },[])

    const onPressSearch = () => {
        getTeams()
    }

    const getTeams = async () => {
        if(search){
            try {
                setLoadingTable(true)
                // const result = await Api().get("/users/listar/at", {params: {cpf_cnpj: search, limit: 5 }})
                const result = await Api().post("equipe/listarfazparte",{"user_id": state.tickets[props.index].athlete.id, aceite: true, nome: search})
                console.log(result)
                const data = result.data ? result.data : []
                const equipes = data.lista ? data.lista : {}
                let rowEquipes = equipes.map((item) => {
                    return {...item, 
                        imagem: item.imagem ? config.storage_link+"/equipe/"+item.id+"/"+item.imagem : ""} 
                })
                setData(rowEquipes)
                setLoadingTable(false)
            }catch(e){
                setLoadingTable(false)
                toast.error("Ocorreu um erro ao buscar os atletas")
            }
        } 
    }
            


    const onClose = () => {
        props.toggle(false)
    }

    const bestCopyEver = (src) => {
        return JSON.parse( JSON.stringify( src ) )
    }

    const addAthletes = async () => {
    
        dispatch({type: 
            actions.SET_TEAM_TICKET, 
            index: props.index,
            payload: teamSelected
        })
        onClose()
    }

    const onChangeCheckbox = (values) => {
        setTeamSelected(values)
    }

    const columns = [
        {
            name: '',
            selector: 'photo',
            cell:(row) => {
                return( 
                   <> {row.imagem && <img src={row.imagem} className={css(styles.photo)}/>} </>
                );
            }
        },
        {
            name: 'Nome ',
            selector: 'nome',
            sortable: true,
            style:{display: "inline-grid"},
            cell:(row) => {
                return(<div > 
                    <div><span className={css(styles.itemTableSpan)}>{(row.nome)}</span></div>
                </div>);
            }
        },
        {
            name: '',
            maxWidth:"10px",
            ignoreRowClick: true,
            cell:(row) => 
            <div>
                <Checkbox
                    id={"ath"+row.id}
                    value={teamSelected.id == `${row.id}`}
                    onChange={() => onChangeCheckbox(row)}
                />
            </div>,
        }
        
    ]

    return <>
        <BrowserView>
            <ModalEquipeBrowser 
                open={props.open}
                data={data}
                teamSelected={teamSelected}
                loadingTable={loadingTable}
                onPressSearch={onPressSearch}
                onClose={onClose}
                addAthletes={addAthletes}
                onChangeCheckbox={onChangeCheckbox}
                search={search}
                setSearch={setSearch}
            />
        </BrowserView>
        <MobileView>
            <ModalEquipeMobile 
                open={props.open}
                data={data}
                teamSelected={teamSelected}
                loadingTable={loadingTable}
                onPressSearch={onPressSearch}
                onClose={onClose}
                addAthletes={addAthletes}
                onChangeCheckbox={onChangeCheckbox}
                search={search}
                setSearch={setSearch}
            />
        </MobileView>
    </>
}

const styles={
    btnSalvar: {
        marginTop: 55
    },
    newEvento : {
        float:"left"
    },
    main: {
        paddingBottom: 20
    },
    photoEvent: {
        width: "100%",
        paddingTop: 20,
        position: "relative"
    },
    modal: {
        modal : {
            width: "80%"
        }
    },
    loadingCss: {
        width: "100%"
    },
    photo: {
        width: 40,
        height: 40,
        borderRadius: 250,
        marginTop: 5,
        marginBottom: 5
    },
    itemTableSpan: {
        fontSize: 14
    },
    iconBtn: {
        cursor:'pointer',
        color:'red !important'
   }
}

export default React.memo(Component);