import { css } from "emotion"
import React, { useState, useEffect, useCallback, useContext } from "react"
import { Container } from "react-materialize"
import Carousel from "../../../components/Carousel"
import { InputSearch } from "../../../components/Form"
import ItemEventoLista from "../ItemEventoLista"
import HomeContext from "../HomeContext"
import ItemEventoMobileLista from "../ItemEventoMobileLista"
import ItemEventoMobileTable from "../ItemEventoMobileTable"
import { BeatLoader } from "react-spinners"

export default () => {
    const contextHome = useContext(HomeContext)

    const [searching, setSearching] = useState(false)

    const onSearch = (e) => {
        //alert(e.target.value)
        if(e.target.value.length > 0){
            setSearching(true)
            contextHome.setFilter({
                typeFilter: "geral",
                nome: e.target.value,
            })
        } else {
            setSearching(false)
            contextHome.setFilter({
                typeFilter: "geral",
                nome: "",
            })
        }
       
    }

    return <div className={css(styles.container)}>
        <Container>
            <InputSearch
                cssStyle={styles.search}
                className={css(styles.searchField)}
                onSearch={onSearch}
                label={"buscar"}
            />
            {contextHome.eventosDestaque.length > 0 && <div className={css(styles.title)}>
                <span>Destaques</span>
            </div>}
            {contextHome.loadingTable && <div>
                <BeatLoader css={styles.loadingCss} color={"#110F62"} size={25}/>
            </div>}
            {!searching && !contextHome.loadingTable && <Carousel 
                infinite={false}
                items={contextHome.eventosDestaque}
                controls={false}
                dots={true}
                template={ItemEventoMobileLista}
            />}
            {!searching &&  !contextHome.loadingTable && contextHome.eventosSemana.length > 0 && <div className={css(styles.title)}>
                <span>Eventos da Semana</span>
            </div>}
            <div>
                {!searching && !contextHome.loadingTable && contextHome.eventosSemana.map((item,i) => {
                    return <ItemEventoMobileTable key={"es"+i} item={item}/>
                })}
            </div>
            <div>
                {searching && !contextHome.loadingTable && contextHome.eventosDestaque.map((item,i) => {
                    return <ItemEventoMobileTable key={"ed"+i} item={item}/>
                })}
            </div>
        </Container>
    </div>
}

const styles = {
    loadingCss: {
        width: "100%",
        textAlign: "center",
        //marginLeft: "50%",
    },
    container: {
        backgroundColor: "#fff"
    },
    search: {
        borderRadius: 10,
        paddingLeft: "0px !important",
        textIndent: 10,
        border: "0.5px solid #9e9e9e !important",
        boxShadow: "none"

    },
    searchField: {
        marginTop: 20,
          },
    title: {
        fontFamily: "Destaques",
        fontSize: 15,
        paddingTop: 5,
        paddingBottom: 10,
        color: "#8E8E92",
        fontWeight: "bold"
    },
   
}