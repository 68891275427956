import React, { useState, useEffect, useContext } from "react"
import { Row, Col } from "react-materialize"
import {InputSearch, ButtonClean} from "../../components/Form"
import {
    Link, useHistory 
  } from "react-router-dom";

import DataTable from 'react-data-table-component';
import Api from "../../api/Api"
import config from "../../config.js"
import { toast } from "react-toastify";
import { useCookies} from "react-cookie"
import {BarLoader} from "react-spinners";
import { css } from 'emotion'
import moment from "moment"
import MainContext, { actionsMain } from "../MainContext";
import ModalDelete from "../../components/ModalDelete";

export default () => {
    const { dispatch} = useContext(MainContext)
    const [cookies,setCookie] = useCookies(['id','nome']); 
    const [filter,setFilter] = useState("")
    const [data, setData] = useState([])
    
    const [dataFiltered, setDataFiltered] = useState([])
    const [loadingTable, setLoadingTable] = useState(false);

    const history = useHistory();
    
    const [idDelete, setIdDelete] = useState()
    const [openDelete, setOpenDelete] = useState()

    const [idDesBlock, setIdDesBlock] = useState()
    const [openDesblock, setOpenDesblock] = useState()
    


    useEffect(() => {
        findData()
    },[])

    useEffect(() => {
        if(data){
            const newDate = data.filter((item) => item.name.match(`${filter}.*`))
            setDataFiltered(newDate)
        }

    },[filter])

    const findData = async () => {
        try {
            setLoadingTable(true)
            const result = await Api().get("organizador/listar")
            const resultData = result.data.rows ? result.data.rows : []
            let internalData = [];
            resultData.map((value) => {
                internalData.push({
                    id: value.id,
                    name: value.name,
                    photo: value.imagem ? config.storage_link+`/users/${value.imagem}` : "",
                    state: value.uf,
                    city: value.cidade,
                    block: value.block
                })
            })
            setData(internalData)
            setDataFiltered(internalData)
            setLoadingTable(false)
        }catch(e){
            const response = e.response ? e.response : {};
            const data = response.data ? response.data : {};
            const msg = data.msg ? data.msg[0] : "Ocorreu um erro ao buscar os eventos";
            toast.error(msg)
        }

    }

    const deleteBlockOrganizer = async () => {
   

        try {
            const result = await Api().post("eventos/bloquear/"+idDelete)
            if(!result.data.error){
                toast.success("Organizador bloqueado com sucesso")
                setOpenDelete(false)
                findData()
            }
        } catch(e){
            const response = e.response ? e.response : {};
            const data = response.data ? response.data : {};
            const msg = data.msg ? data.msg[0] : "Ocorreu um erro ao tentar bloquear";
            toast.error(msg)
            setOpenDelete(false)
        }
    }

    const deleteDesBlockOrganizer = async () => {

        try {
            const result = await Api().post("eventos/desbloquear/"+idDesBlock)
            if(!result.data.error){
                toast.success("Organizador desbloqueado com sucesso")
                setOpenDesblock(false)
                findData()
            }
        } catch(e){
            console.log(e)
            const response = e.response ? e.response : {};
            const data = response.data ? response.data : {};
            const msg = data.msg ? data.msg[0] : "Ocorreu um erro ao tentar desbloquear";
            toast.error(msg)
            setOpenDesblock(false)
        }
    }


    const columns = [
        {
            name: '',
            selector: 'photo',
            cell:(row) => {

                return row.photo && <img src={row.photo} className={css(styles.photoEvent)}/>
            }
        },
        {
            name: 'Data',
            selector: 'date',
            sortable: true,
            cell:(row) => {
                return(<div > 
                    <div><span className={css(styles.itemTableSpan)}>{moment(row.dateStart).format("DD/MM/YYYY")}</span></div>
                </div>);
            }
        },
        {
            name: 'Nome',
            selector: 'name',
            sortable: true,
            cell:(row) => {
                return(<div > 
                    <div><span className={css(styles.itemTableSpan)}>{(row.name)}</span></div>
                </div>);
            }
        },
        {
            name: 'Local',
            selector: 'local',
            sortable: true,
            cell:(row) => {
                return(<div > 
                    <div><span className={css(styles.itemTableSpan)}>{row.city} - {row.state}</span></div>
                </div>);
            }
        },
       
        {
            name: 'Editar',
            cell:(row) => 
            <div>
                <span className={"material-icons modal-trigger  "+ css(styles.iconBtn2)} id={row.id} onClick={ () => { onClickLineTable(row.id, row.name)} } >edit</span>
            </div>,
        },

        {
            name: 'Bloquear',
            cell:(row) => 
            <div>
                {!row.block && <span className={"material-icons modal-trigger  "+ css(styles.iconBtn)} id={row.id} onClick={ () => { setIdDelete(row.id); setOpenDelete(true); } } >block</span>}
                {row.block && <span className={"material-icons modal-trigger  "+ css(styles.iconBtn3)} id={row.id} onClick={ () => { setIdDesBlock(row.id); setOpenDesblock(true); } } >check</span>}
            </div>,
        },
        
    ]

    const onClickLineTable = (id,name) => {
        setCookie("nome_admin",cookies.nome)
        setCookie("nome",name)
        dispatch({type: actionsMain.SET_ID_ORGANIZER, payload: id})
        history.push(`/eventos`)
    }

    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    const customStyles = {
        rows: {
            style: {
                marginTop: 30,
                backgroundColor:"#fff",
                boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.15)",
                borderRadius: 9
            }
        },
        table: {
            style: {
                backgroundColor:"transparent",
            }
        }
    };

    return <div className={css(styles.main)}>
        <Row>
            <Col s={8} m={9}>
                <h6 className={css(styles.titleEvent)}>Buscar Organizador</h6>
                <div>
                    <span>{capitalizeFirstLetter(moment().format("dddd, DD [de] MMMM [de] YYYY"))}</span>
                </div>
            </Col>
            
        </Row>
        <Row>
            <Col s={6}>
                <InputSearch 
                    id="name"
                    value={filter}
                    cssStyle={styles.input}
                    onChange={(e) => setFilter(e.target.value)}
                    label={"Buscar"} 
                />
            </Col>
        </Row>
        <DataTable
            progressComponent={<BarLoader css={styles.loadingCss} color={"#110F62"}/>}
            progressPending={loadingTable}
            persistTableHead
            columns={columns}
            data={dataFiltered}
            customStyles={customStyles}
            noHeader={true}
            overflowY={true}      
            noHeader
            noTableHead  
            noDataComponent={"Não existem dados para mostrar"}
        />
         <ModalDelete 
        open={openDelete}
        title={"Bloquear"}
        onDelete={deleteBlockOrganizer}
        onClose={() => setOpenDelete(false)}>
            Você realmente deseja bloquear esse organizador?
        </ModalDelete>

        <ModalDelete 
        open={openDesblock}
        title={"Desbloquear"}
        onDelete={deleteDesBlockOrganizer}
        onClose={() => setOpenDesblock(false)}>
            Você realmente deseja desbloquear esse organizador?
        </ModalDelete>
    </div>
}


const styles={
    input: {
        border: "0.5px solid #9e9e9e !important",
        boxShadow: "none"
    },
    loadingImg: {
        backgroundColor: "#fff"
    },
    titleEvent: {
        fontWeight: "bold"
    },
    loadingCss: {
        width: "100%"
    },
    newEvento : {
        float:"left"
    },
    rowNovoEvento: {
        paddingTop: "20px"
    },
    linkNovoEvento: {
        color: "#000",
    },
    itemTableSpan: {
        fontSize: 14
    },
    novoEventoSpan: {
        fontFamily: "Roboto",
        fontSize: 14,
        marginLeft: 10,
    },
    iconBtn: {
         cursor:'pointer',
         color:'red !important'
    },
    iconBtn2: {
        cursor:'pointer',
    },
    iconBtn3: {
        cursor:'pointer',
        color:'green !important'
    },
    textBox: {
        paddingLeft: 20,
        width: "65%"
    },
    textBoxSpan: {
        fontSize: 12,
        color: "#110F62"
    },
    titleBox: {
        paddingTop: 20,
        paddingLeft: 20
    },
    titleBoxSpan: {
        color: "#110F62",
        fontFamily: "Roboto",
        fontSize: 20,
        marginTop: 20
    },
    main: {
        paddingBottom: 20
    },
    photoEvent: {
        width: 40,
        height: 40,
        borderRadius: 250,
        marginTop: 5,
        marginBottom: 5
    },
    imgBanner: {
        position: "absolute",
        top: -10,
        right: 0
    },
    divNovoEvento: {
        display: "inline-flex",
        alignItems: "center"
    },
    boxEventos: {
        position: "relative",
        width: "95%",
        fontFamily: "Roboto",
        height: 150,
        borderRadius: 20,
        backgroundColor: "#CFCEF2",
        boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.25)",
        "@media(max-width: 850px)": {
            display: "none"
        },
    }
}