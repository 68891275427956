import React, {  useState, useRef } from "react"
import {
   useLocation
  } from "react-router-dom";

import Api from "../../api/Api";
import { toast } from "react-toastify";
import {
    BrowserView,
    MobileView,
} from "react-device-detect";
import EsqueceuSenhaBrowser from "./EsqueceuSenhaLayout/EsqueceuSenhaBrowser";
import EsqueceuSenhaMobile from "./EsqueceuSenhaLayout/EsqueceuSenhaMobile";


const EsqueceuSenha = () => {

    const [email,setEmail] = useState("")
    const [loading,setLoading] = useState(false)
    const refForm = useRef(null);

    let {search} = useLocation()

    const query = new URLSearchParams(search)
    let tipo = (query.get("type"))
    tipo = tipo ? tipo : "or"
    const onSubmit = async (e) => {
        e.preventDefault()
        if(!refForm.current.reportValidity()){
            toast.error("Campos obrigatórios não foram preenchidos");
            return
        }
        try {
            setLoading(true)
            const result = await Api().post("/enviaremail",{email,tipo})
            const data = result.data;
            if(!data.error){
                toast.success("Enviamos um e-mail pra você, confere lá! Ah! Não esqueça de verificar a caixa de spam.")
            }else {
                toast.error("Ocorreu um problema")
            }
            setLoading(false)
        }catch(e){
            setLoading(false)
            const response = e.response ? e.response : {};
            const data = response.data ? response.data : {};
            const msg = data.msg ? data.msg[0] : "Ocorreu um problema ao tentar realizar essa ação";
            toast.error(msg)
        }
        

    }

    return <>
        <BrowserView>
            <EsqueceuSenhaBrowser
                email={email}
                setEmail={setEmail}
                loading={loading}
                refForm={refForm}
                onSubmit={onSubmit}
            />
        </BrowserView>
        <MobileView>
            <EsqueceuSenhaMobile
                email={email}
                setEmail={setEmail}
                loading={loading}
                refForm={refForm}
                onSubmit={onSubmit}
            />
    </MobileView>
</>

}

export default (EsqueceuSenha)