import React, { useContext } from "react"
import { css } from "emotion";
import { Button, Input } from "../../../../../components/Form";
import DataTable from 'react-data-table-component';
import JuizCronometroContext, { actions } from "../../../JuizCronometroContext";
import ModalLargadaCategoria from "../ModalLargadaCategoria"
import ModalDelete from "../../../../../components/ModalDelete";
const Component = ({
    openDelete, 
    setOpenDelete,
    setIdDel,
    openModalLargadaCategoria, 
    setOpenModalLargadaCategoria,
    handleClickNumberKeyboard,
    handleAdicionar,
    handleClickStart,
    returnScreen,
    handleReview,
    handlePub,
    delTimer
}) => {

    const {state, dispatch} = useContext(JuizCronometroContext)

    const columns = [
        {
            selector: 'numero',
            name: "N",
            sortable: true,
            width: "63px"
        },
        {
            selector: 'atleta',
            name: "Nome",
            sortable: true
        },
        {
            selector: 'categoria',
            name: "Categoria",
            sortable: true,
        },
        {
            selector: 'faixa_idade',
            name: "Sub-Categoria",
            sortable: true,
            width: "110px"
        },
        {
            selector: 'tempo',
            name: "Tempo",
            sortable: true,
            width: "87px"
        }
    ];
    
    

    return <div className={css(styles.containers)}>
        {state.timers.length > 0 && <div className={css(styles.minhasLargadas)}>
            <div className={css(styles.lineLargadas,styles.lineSelected)}>
                <span>Largadas</span>
            </div>
            {state.timers.map((item,i) => 
                <div 
                    key={i}
                    className={css(styles.lineLargadas)}>
                    <span>{item.name}</span>
                    <div>
                        {/* <span className={css(styles.btnInfo) + " material-icons"}>info</span> */}
                        <span className={css(styles.btnDel) + " material-icons"} onClick={() => {
                            setIdDel(item.id);
                            setOpenDelete(true)
                        }}>delete</span>
                    </div>
                </div>
            )}
        </div>}
        <div className={css(styles.keyboard)}>
            <i className={"material-icons "+css(styles.returnIcon)} onClick={returnScreen}>keyboard_backspace</i>

           <div className={css(styles.topKeyboard)}>
                <Button className={css(styles.btnLargada,styles.itemTopKeyboard)} onClick={handleClickStart} disabled={!state.event?.categorias || state.event?.categorias?.length == 0 }>Largada</Button>
                <ModalLargadaCategoria open={openModalLargadaCategoria} toggle={setOpenModalLargadaCategoria}/>
           </div>
            <div className={css(styles.numberAtlDiv)}>
                <Input mask="9999" 
                    classNameInput={css(styles.numberAth)}  
                    maskChar="_"
                    value={state.numberAthTimer}
                    onChange={(e) => dispatch({type: actions.HANDLE_NUMBER_KEYBOARD_TIMER, payload: e.target.value.replace(/_/g,"")})}
                    alwaysShowMask = {true}/>
            </div>
            <div className={css(styles.keyboardNumbers)}>
                <div className={css(styles.line)}>
                    <div className={css(styles.number)} onClick={() => handleClickNumberKeyboard(1)}>
                        <span>1</span>
                    </div>
                    <div className={css(styles.number)} onClick={() => handleClickNumberKeyboard(2)}>
                        <span>2</span>
                    </div>
                    <div className={css(styles.number)} onClick={() => handleClickNumberKeyboard(3)}>
                        <span>3</span>
                    </div>
                </div>
                <div className={css(styles.line)}>
                    <div className={css(styles.number)} onClick={() => handleClickNumberKeyboard(4)}>
                        <span>4</span>
                    </div>
                    <div className={css(styles.number)} onClick={() => handleClickNumberKeyboard(5)}>
                        <span>5</span>
                    </div>
                    <div className={css(styles.number)} onClick={() => handleClickNumberKeyboard(6)}>
                        <span>6</span>
                    </div>
                </div>
                <div className={css(styles.line)}>
                    <div className={css(styles.number)} onClick={() => handleClickNumberKeyboard(7)}>
                        <span>7</span>
                    </div>
                    <div className={css(styles.number)} onClick={() => handleClickNumberKeyboard(8)}>
                        <span>8</span>
                    </div>
                    <div className={css(styles.number)} onClick={() => handleClickNumberKeyboard(9)}>
                        <span>9</span>
                    </div>
                </div>
                <div className={css(styles.line)}>
                    <div className={css(styles.flex_normal)}>
                    </div>
                    <div className={css(styles.number)} onClick={() => handleClickNumberKeyboard(0)}>
                        <span>0</span>
                    </div>
                    <div className={css(styles.flex_normal)}>
                    </div>
                </div>
            </div>
            <Button disabled={state.timers.length == 0 || !state.numberAthTimer} onClick={handleAdicionar} className={css(styles.btnSalvar)}>Salvar</Button>
        </div>
        <div className={css(styles.table)}>
            <div className={css(styles.result)}>
                <span>Resultado</span>
            </div>
            <div className={css(styles.tableDiv)}>
                <DataTable
                    columns={columns}
                    data={state.athAddedTimer}
                    noDataComponent={""}
                    className={css(styles.tableAth)}
                    responsive={false}
                    fixedHeader
                    fixedHeaderScrollHeight={"223px"}
                    noHeader
                />
            </div>
            <div className={css(styles.btnsPub)}>
                <Button className={css(styles.btnPub)} onClick={handleReview}>Revisão</Button>
                <Button className={css(styles.btnPub)} onClick={handlePub} disabled={state.athAddedTimer.length == 0}>Publicar</Button>
            </div>
            <ModalDelete 
                open={openDelete}
                onDelete={delTimer}
                onClose={() => setOpenDelete(false)}>
                    Você realmente deseja excluir esse cronômetro?
            </ModalDelete>
        </div>
    </div>
}

const styles = {
    btnInfo: {
        cursor: "pointer",
        paddingRight: 10,
    },
    btnDel: {
        cursor: "pointer",
        color: "red"
    },
    minhasLargadas: {
        flex: 1,
        margin: -24,
        border: "1px solid #C4C4C4",
        fontFamily: "Roboto",
        "@media(max-width: 500px)": {
            display: "none"
        },
       
    },
    lineSelected: {
        backgroundColor: "rgba(107, 105, 207, 0.8)",
        color: "#fff",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        paddingLeft: 0,
        fontSize: 16
    },
    lineLargadas: {
        height: 40,
        display: "flex",
        paddingLeft: 10,
        alignItems: "center",
        justifyContent: "space-between",
        paddingRight: 10,
        fontSize: 14
    },
    returnIcon: {
        position: "absolute",
        cursor: "pointer",
        left: 0,
        top: 0
      //  marginLeft: 170
    },
    btnsPub: {
        display: "flex",
        marginBottom: 25,
        marginRight: 25,
        marginLeft: 25,
        "@media(max-width: 992px)": {
            marginRight: 0,
            marginLeft: 0,
        },
    },
    btnPub: {
        marginRight: 5,
        marginLeft: 5,
        fontSize: 12
    },
    time: {
        border: "1px solid #606060",
        borderRadius: 9,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontFamily: "Roboto"
    },
    numberAth: {
      textAlign: "center",
      margin: "0 0 0 0 !important",
      height: "2rem !important",
      borderBottom: "0px !important",
      lineHeight: 2,
      fontSize: "16px !important",
      boxShadow: "none !important",
      letterSpacing: 10,
      textIndent: 10

    },
    numberAtlDiv: {
        border: "1px solid #606060",
        borderRadius: 9,
        height: 30,
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontFamily: "Roboto",
        marginTop: 15
    },
    btnLargada: {
        fontSize: 12
    },
    btnSalvar: {
        marginTop: 20
    },
    containers: {
        display: 'flex',
        flexWrap: "wrap"
    },
    keyboardNumbers: {
        marginTop: 10,
        width: "100%"
    },
    line: {
        display: "flex",
        justifyContent: "center",
        marginTop: 10,
        width: "100%"
    },
    topKeyboard: {
        display: "flex",
        width: "100%"
    },
    flex_normal: {
        flex: "33%",
        marginRight: 10,
        marginLeft: 10,
    },
    number: {
        cursor: "pointer",
        fontFamily: "Roboto",
        color: "#606060",
        flex: "33%",
        width: "100%",
        // maxWidth: 60,
        height: 35,
        borderRadius: 9,
        textAlign: "center",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginRight: 10,
        marginLeft: 10,
        border: "1px solid #606060",
        // height: "auto"
    },
    itemTopKeyboard: {
        marginRight: 5,
        marginLeft: 5,
        flex: 1
    },
    keyboard: {
        paddingTop: 40,
        "@media(min-width: 500px)": {
            marginRight: 40,
        },
        marginLeft: 40,
        "@media(max-width: 500px)": {
            marginLeft: -12,
        },
        flex: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        position: "relative"
    },
    table: {
        flex: 3,
        display: "flex",
        flexDirection: "column",
        paddingTop: 15,
    },
    result: {
        color: "#000000",
        fontFamily: "Roboto",
        fontSize: 18,
        paddingTop: 10,
        fontWeight: 600
    },
    tableDiv: {
        display: "inline-grid",
       
        height: 280
    },
    tableAth: {
        overflowY: "hidden",
        overflowX: "auto",
        marginBottom: 10,
    }
}

export default React.memo(Component);