import React from "react"
import { DatePickerCalendar } from 'react-nice-dates'
import { ptBR } from 'date-fns/locale'

const Component = (props) => {
    return <DatePickerCalendar
        locale={ptBR}
    
    />
}

export default React.memo(Component)