import React, { useEffect, useState } from "react"
import { Row, Col } from 'react-materialize';
import {Button, Input, Checkbox, Select, InputPassword, ImageUploader, DatePicker} from "../../components/Form"
import {
    Link, useHistory, useLocation 
  } from "react-router-dom";
import ApiCep from "../../api/ApiCep"
import Api from "../../api/Api"
import { toast } from 'react-toastify';
import validCPF from "../../components/Valid/CPF"
import listUf from "../../constants/listUf"
import AppLogin from "../../layouts/AppLogin"
import { css } from 'emotion'
import moment from "moment"
import {
    BrowserView,
    MobileView,
} from "react-device-detect";
import FormCreateAtletaBrowser from "./FormCreateAtletaLayout/FormCreateAtletaBrowser";
import FormCreateAtletaMobile from "./FormCreateAtletaLayout/FormCreateAtletaMobile";
export default () => {

    const [email,setEmail] = useState("")
    const [password,setPassword] = useState("");
    const [confpassword,setConfpassword] = useState("");
    const [name,setName] = useState("")
    const [type,setType] = useState({ value: "nativo", label: 'Nativo' });
    const [picture,setPicture] = useState();
    const [pictureUrl,setPictureUrl] = useState([]);
    const [cpf_passport, setCpfPassport] = useState("")
    const [validCPFText, setValidCPFText] = useState("")
    const [phone,setPhone] = useState("")
    const [cellphone,setCellphone] = useState("")
    const [idFacebook,setIdfacebook] = useState("")
    const [zipCode,setZipCode] = useState("")
    const [street,setStreet] = useState("")
    const [number,setNumber] = useState("")
    const [complement,setComplement] = useState("")
    const [neighborhood,setNeighborhood] = useState("")
    const [city,setCity] = useState("")
    const [state,setState] = useState("")
    const [term,setTerm] = useState(false)
    const [validEmail,setValidEmail] = useState("")
    const [messageErroCPFCNPJ,setMessageErrorCPFCNPJ] = useState("")
    const [messageErrorEmail,setMessageErrorEmail] = useState("")
    const [loading,setLoading] = useState(false)
    const [oldEmail,setOldEmail] = useState("")
    const [oldCpfcnpj,setOldCpfCnpj] = useState("")
    const [gender, setGender] = useState("")
    const [birthdate, setBirthdate] = useState(null)
    const [country,setCountry] = useState({ value: "BR", label: 'Brasil' });

    const history = useHistory()
    let location = useLocation();
    
    const regexPassword = '^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&])[A-Za-z\\d@$!%*?&]{8,}$'
    const messageErroPassword = "A senha deve conter caracteres especiais, números, letras maiúsculas e minúsculas e ter no mínimo 8 caracteres."

    const options = [
        { value: 'nativo', label: 'Nativo' },
        { value: 'estrangeiro', label: 'Estrangeiro' },
    ]

    const optionsGender = [
        { value: 'm', label: 'Masculino' },
        { value: 'f', label: 'Feminino' },
    ]

    useEffect(() => {
        const locationState = location.state ? location.state : {};
        console.log(locationState)
        setEmail(locationState.email ? locationState.email : "")
        setName(locationState.name)
        setIdfacebook(locationState.fb_id)
        if(locationState.imagem){
            setPictureUrl([locationState.imagem])
        }
    },[])

    const onSubmit = async (e) => {
        e.preventDefault()
        if(!term){
            toast.error("O termo deve ser selecionado")
        }else {
            console.log(picture)
            setLoading(true)
            try {
              const data = {
                    name,
                    email,
                    password,
                    genero: gender.value,
                    tipo_nacionalidade: type.value,
                    telefone: phone ? phone.replace(/\(|\)|\s|-/g,"") : "",
                    celular: cellphone ? cellphone.replace(/\(|\)|\s|-/g,"") : "",
                    cpf_passaporte: cpf_passport ? cpf_passport.replace(/\.|-|\//g,"") : "",
                    endereco : street,
                    cep: zipCode ? zipCode.replace(/-/g,"") : "",
                    bairro: neighborhood,
                    cidade: city,
                    numero: number,
                    data_nascimento: moment(birthdate).format("YYYY-MM-DD"),
                    imagem: pictureUrl ? pictureUrl[0] : "",
                    complemento: complement,
                    assinatura: term,
                    fb_id: idFacebook,
                    uf: state,
                    pais: country.value,
                    ativo: true
                }
                const result = await Api().post("atleta/salvar",data)
                if(result.data['error']){
                    toast.error(result.data.msg[0] ? result.data.msg[0] : "Erro")
                    setLoading(false)
                }else {
                    toast.success("Usuário cadastrado com sucesso")
                    if(picture){
                        uploadimagem(result.data.msg['user_id'])
                    }
                    setLoading(false)
                    history.replace("/atleta/login")
                    
                }

            } catch (e){
                console.log(e)
                setLoading(false)
                const response = e.response ? e.response : {};
                const data = response.data ? response.data : {};
                const msg = data.msg ? data.msg[0] : "Ocorreu um erro";
                toast.error(msg)
                return false;
            }
        }
    }

    const changeType = (type) => {
        setCpfPassport("")
        setType(type)
    }



    const uploadimagem = async (id) => {
        var formData = new FormData();
        formData.append("id_usuario",id)
        formData.append("file", picture);
        try {
            const resultImage = await Api().post('/usuario/uploadimagem', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            if(resultImage.data['error']){
                toast.error(resultImage.data.msg[0])
            }
        } catch(e) {
            console.log(e)
            const response = e.response ? e.response : {};
            const data = response.data ? response.data : {};
            const msg = data.msg ? data.msg[0] : "Ocorreu um erro ao enviar a imagem do organizador";
            toast.error(msg)
        }
        
    }

    const onDrop = (picture, pictureUrl) => {
        setPicture(picture[0])
    }
    
    const  handleZipCode = async (value) => {
        setZipCode(value)
        if(value.length == 9){
            try {
                const address = await ApiCep.get(`/${value}/json`);
                const data = address.data
                setStreet(data.logradouro)
                setNeighborhood(data.bairro)
                setCity(data.localidade)
                setState(data.uf)

            } catch {
                toast.error("Ocorreu um erro ao tentar buscar o endereço")
            }
        }else {
            setStreet("")
            setNeighborhood("")
            setCity("")
            setState("")
        }
    }

    const handleCPFPassport = async (value) => {
        setCpfPassport(value)
        if(oldCpfcnpj != value){
            setOldCpfCnpj(value);
           

            if(type.value == "nativo" && validCPF(value)){
                if(await validExistsCPFText(value)){
                    setValidCPFText(value)
                    setMessageErrorCPFCNPJ("")
                }
            }else if (type.value == "estrangeiro"){
                setValidCPFText(value)
                setMessageErrorCPFCNPJ("")
            } else {
                setMessageErrorCPFCNPJ(`CPF INVÁLIDO`)
                setValidCPFText("")
            }
        }
    }

    const validExistsEmail = async (value) => {
        try {  
            if(value != oldEmail){
               setOldEmail(value);      
               const result = await Api().get(`/users/validaemail?email=${value}&tipo=at`)
                if(result.data.msg['Atleta']){
                    toast.error(`O E-MAIL já existe`)
                    setMessageErrorEmail(`O E-MAIL JÁ EXISTE`);
                    setValidEmail("")
                    return false;
                }
                setMessageErrorEmail("")
                setValidEmail(value)
                return true;
            }
         
        } catch (e){
            console.log(e)
            const response = e.response ? e.response : {};
            const data = response.data ? response.data : {};
            const msg = data.msg ? data.msg[0] : "Ocorreu um erro ao tentar validar o E-mail";
            toast.error(msg)
            return false;
        }
    }

    const validExistsCPFText = async (value) => {
        try {
            const cpfcnpj = value.replace(/\.|-|\//g,"")

            const result = await Api().get(`/users/validacpfcnpj/${cpfcnpj}?tipo=at`)
            if(result.data['error']){
                toast.error(`CPF ou Passaporte já existe`)
                setMessageErrorCPFCNPJ(`CPF ou Passaporte JÁ EXISTE`);
                return false;
            }
            return true;
            
         
        } catch (e){
            console.log(e)
            toast.error("Ocorreu um erro ao tentar validar o CPF/Passaporte")
            return false;
        }
    }


    return <>
        <BrowserView>
            <FormCreateAtletaBrowser
                 email={email}
                 setEmail={setEmail}
                 password={password}
                 setPassword={setPassword}
                 confpassword={confpassword}
                 setConfpassword={setConfpassword}
                 name={name}
                 setName={setName}
                 type={type}
                 pictureUrl={pictureUrl}
                 cpf_passport={cpf_passport} 
                 setCpfPassport={setCpfPassport}
                 validCPFText={validCPFText}
                 phone={phone}
                 setPhone={setPhone}
                 cellphone={cellphone}
                 setCellphone={setCellphone}
                 zipCode={zipCode}
                 street={street}
                 setStreet={setStreet}
                 number={number}
                 setNumber={setNumber}
                 complement={complement}
                 setComplement={setComplement}
                 neighborhood={neighborhood}
                 setNeighborhood={setNeighborhood}
                 city={city}
                 setCity={setCity}
                 state={state}
                 setState={setState}
                 term={term}
                 setTerm={setTerm}
                 validEmail={validEmail}
                 messageErroCPFCNPJ={messageErroCPFCNPJ}
                 messageErrorEmail={messageErrorEmail}
                 loading={loading}
                 gender={gender}
                 setGender={setGender}
                 birthdate={birthdate}
                 setBirthdate={setBirthdate}
                 regexPassword={regexPassword}
                 messageErroPassword={messageErroPassword}
                 options={options}
                 optionsGender={optionsGender}
                 onSubmit={onSubmit}
                 changeType={changeType}
                 onDrop={onDrop}
                 handleZipCode={handleZipCode}
                 handleCPFPassport={handleCPFPassport}
                 validExistsEmail={validExistsEmail}
                 country={country}
                 setCountry={setCountry}
            />
        </BrowserView>
        <MobileView>
            <FormCreateAtletaMobile
                email={email}
                setEmail={setEmail}
                password={password}
                setPassword={setPassword}
                confpassword={confpassword}
                setConfpassword={setConfpassword}
                name={name}
                setName={setName}
                type={type}
                pictureUrl={pictureUrl}
                cpf_passport={cpf_passport} 
                setCpfPassport={setCpfPassport}
                validCPFText={validCPFText}
                phone={phone}
                setPhone={setPhone}
                cellphone={cellphone}
                setCellphone={setCellphone}
                zipCode={zipCode}
                street={street}
                setStreet={setStreet}
                number={number}
                setNumber={setNumber}
                complement={complement}
                setComplement={setComplement}
                neighborhood={neighborhood}
                setNeighborhood={setNeighborhood}
                city={city}
                setCity={setCity}
                state={state}
                setState={setState}
                term={term}
                setTerm={setTerm}
                validEmail={validEmail}
                messageErroCPFCNPJ={messageErroCPFCNPJ}
                messageErrorEmail={messageErrorEmail}
                loading={loading}
                gender={gender}
                setGender={setGender}
                birthdate={birthdate}
                setBirthdate={setBirthdate}
                regexPassword={regexPassword}
                messageErroPassword={messageErroPassword}
                options={options}
                optionsGender={optionsGender}
                onSubmit={onSubmit}
                changeType={changeType}
                onDrop={onDrop}
                handleZipCode={handleZipCode}
                handleCPFPassport={handleCPFPassport}
                validExistsEmail={validExistsEmail}
                country={country}
                setCountry={setCountry}
            />
        </MobileView>
    </>
   
}

const styles =  {
    title: {
        color: "#6B69CF",
        fontFamily: "Roboto",
        fontWeight: "600"
    },
    labelStyle: {
        color: "#6B69CF",
    }
}