import React from "react"
import DatePicker from "react-datepicker";
import InputMask from 'react-input-mask';
import { css } from "emotion";

const Component = (props) => {

    const divComponent = {
        ...style.divcomponent, 
        "& label": {
            color: props.color ? props.color : "#000" 
        }
    };

    let value = props.value;
    if(typeof(props.value) == "string" && props.value){
        let [day,month,year] = value.split('/');
        value = new Date();
        value.setDate(day); 
        value.setMonth(month-1); 
        value.setYear(year);
    }
    return <div className={css(divComponent)}> 
            <label 
            htmlFor={props.id} 
            className={"left-align" + css(style.label)}>{props.label}</label>
            <div>
                <DatePicker
                    locale="pt-BR"
                    timeIntervals={5}
                    dateFormat="dd/MM/yyyy"
                    selected={value}
                    id={props.id}
                    className={css(style.input, props.style)}
                    required={props.required}
                    isClearable={true}
                    placeholder={props.placeholder}
                    onChange={props.onChange}
                    popperModifiers={{
                        preventOverflow: {
                          enabled: true,
                        },
                    }}
                    {...props}
                    customInput={<InputMask
                        mask={props.mask ? props.mask : "99/99/9999"}
                        placeholder={props.placeholder ? props.placeholder : "__/__/____"}
                        className={css(style.input, props.style)}
                    />}
                /> 
            </div>
       
    </div>
}

const style = {
    label : {
        color: "#000" 
    },
    divcomponent : {
        width: "100%"
    },
    input: {
        width: "100%"
    }
}

export default React.memo(Component)
