import React, { useState, useEffect, useContext } from "react"
import config from "../config.js"
import {  Container  } from 'react-materialize';
import { Link, useHistory } from "react-router-dom";
import { useCookies} from "react-cookie"
import M from "materialize-css/dist/js/materialize.min.js";
import ModalPolitica from "../containers/Politica/ModalPolitica";
import { css } from 'emotion'
import logo from "../assets/img/logo.png"
import sair from "../assets/icons/sair.svg"
import evento from "../assets/icons/evento.svg"
import juiz from "../assets/icons/juiz.svg"
import torneio from "../assets/icons/torneio.svg"
import carteira from "../assets/icons/carteira.svg"
import Calendar from "../components/Calendar"
import MainContext from "../containers/MainContext/index.js";
import ModalDelete from "../components/ModalDelete.jsx";
import { toast } from "react-toastify";
import Api from "../api/Api.js";
import useIdOrganizer from "../hooks/useIdOrganizer.jsx";

export default (props) => {

    const [cookies,setCookie,removeCookie] = useCookies(['id','access_token','nome',"imagem", "mode","admin"]);
    const history = useHistory()

    const [openPolicy,setOpenPolicy] = useState(false)
    const [forceReloadInvites, setForceReloadInvites] = useState(false)
    const [invites, setInvites] = useState([])
    const [openModalAlertInvite, setOpenModalAlertInvite] = useState(false)
    const [idInvite, setIdInvite] = useState(0)
    const nome = cookies.nome
    const imagem = cookies.imagem
    const user_id = useIdOrganizer();

    
    if(!cookies.access_token){
        history.replace("/")
    }

    useEffect(() => {
        // var elems = document.querySelectorAll('.sidenav');

        // M.Sidenav.init(elems, {});
        (async () => {
            try {
                const result = await Api().get("torneios/aprovacao/"+user_id)
                const data = result.data
                const aprovacoes = data.aprovacoes ? data.aprovacoes : []
                setInvites(aprovacoes)
            } catch(e){
                const response = e.response ? e.response : {};
                const data = response.data ? response.data : {};
                const msg = data.msg ? data.msg : "Ocorreu um problema ao buscar aprovações";
                toast.error(msg)
            }
         
        })()
        var elems = document.querySelectorAll('.tooltipped');
        M.Tooltip.init(elems, {});
    },[forceReloadInvites])

    const changeMode = () => {
        setCookie("mode",cookies.mode === "admin" ? "organizador" : "admin", { path: '/' })
        history.push("/admin/organizadores")
    }

    const decline = async () => {
        try {
            const result = await Api().get("torneios/aprovacao/reprovar/"+idInvite)
            if(!result.errors){
                toast.success("Solicitação recusada com sucesso")
                setForceReloadInvites(!forceReloadInvites)
            }
        } catch (e){
            const response = e.response ? e.response : {};
            const data = response.data ? response.data : {};
            const msg = data.msg ? data.msg : "Ocorreu um problema";
            toast.error(msg)
        }
        setOpenModalAlertInvite(false)
    }

    const accept = async (id) => {
        try {
            const result = await Api().get("torneios/aprovacao/aprovar/"+id)
            if(!result.errors){
                toast.success("Solicitação aceita com sucesso")
                setForceReloadInvites(!forceReloadInvites)
            }
        } catch (e){
            const response = e.response ? e.response : {};
            const data = response.data ? response.data : {};
            const msg = data.msg ? data.msg : "Ocorreu um problema";
            toast.error(msg)
        }
    }


    return   <div>
    <div className={"sidenav  " + css(styles.sideMenu)}>
        <div className={css(styles.divLogo)+" center-align"}>
            <img src={logo} style={styles.logo} className="center-align"/>
        </div>
        <div className={css(styles.itemsMenu)}>
            <Link to={"/eventos"}>
                <div className={css(styles.itemMenuDiv)+" center-align"}>
                    <img src={evento} style={styles.itemMenuIcon} className="center-align"/>
                    <div className={css(styles.itemText)}>Eventos</div>
                </div>
            </Link>
            <Link to={"/torneios"}>
                <div className={css(styles.itemMenuDiv)+" center-align"}>
                    <img src={torneio} style={styles.itemMenuIcon} className="center-align"/>
                    <div className={css(styles.itemText)}>Torneios</div>
                </div>
            </Link>
            <Link to={"/juiz/cronometro"}>
                <div className={css(styles.itemMenuDiv)+" center-align"}>
                    <img src={juiz} style={styles.itemMenuIcon} className="center-align"/>
                    <div className={css(styles.itemText)}>Cronômetro</div>
                </div>
            </Link>
            <Link to={"/organizador/carteira"}>
                <div className={css(styles.itemMenuDiv)+" center-align"}>
                    <img src={carteira} style={styles.itemMenuIcon} className="center-align"/>
                    <div className={css(styles.itemText)}>Carteira</div>
                </div>
            </Link>
           
        </div>
            <div className={css(styles.divLogout)+" center-align"} 
            onClick={() => { removeCookie("access_token", { path: '/' }); history.replace("/") }}>
            <img src={sair} style={styles.logout} className="center-align"/>
            <div className={css(styles.logoutText)}>Sair</div>
        </div>
    </div>
    <ModalPolitica open={openPolicy} setOpen={setOpenPolicy}/>
    <div className={css(styles.main)}>
        <Container className={css(styles.container)}>
            {props.children}
        </Container>
    </div>
    <div className={css(styles.rightMenu)}>
        <div className={css(styles.headerMenuRight)+" center-align"}>
            {imagem && imagem != "null" && <img src={config.storage_link+"/users/"+imagem}
            className={css(styles.fotoPerfil) +" center-align"}/>}
            <div style={styles.name}>{nome}</div>
        </div>
        <div className={css(styles.itemsMenuRight)}>
            <Link to={"/organizador/editar"}>
                <div className={css(styles.itemMenuDivRight)+" center-align"}>
                    <span className={css(styles.itemSpan)}>Editar Perfil</span>
                </div>
            </Link>
            <Link to={"/organizador/trocarsenha"}>
                <div className={css(styles.itemMenuDivRight)+" center-align"}>
                    <span className={css(styles.itemSpan)}>Trocar Senha</span>
                </div>
            </Link>
            <div className={css(styles.itemMenuDivRight)+" center-align"} onClick={() => setOpenPolicy(true)}>
                <span className={css(styles.itemSpan)}>Política</span>
            </div>
            { cookies.admin == "true" && <div onClick={changeMode} className={css(styles.itemMenuDivRight)+" center-align"}>
                <span className={css(styles.itemSpan, styles.spanChangeMode)}>Painel do Administrador</span>
            </div>}
        </div>
        
        <div className={css(styles.divCalendarMenuRight)}>
            {!invites || invites.length == 0 &&  <Calendar  />}
          
            {invites.map((item) => {
                return <div>
                    <div className={css(styles.cardInvite)}>
                        <div className={css(styles.lineCardInvite)}>
                            <span> Torneio: {item.torneio} </span>
                        </div>
                        <div className={css(styles.lineCardInvite)}>
                            <span> Evento: {item.evento} </span>
                        </div>
                        <div className={css(styles.lineCardInvite, styles.flexBtnsCardInvite)}>
                            <span onClick={() => { setIdInvite(item.id); setOpenModalAlertInvite(true)}} className={"material-icons modal-trigger " + css(styles.itemFlex, {color: "red"})}>close</span>
                            <span onClick={() => accept(item.id)} className={"material-icons modal-trigger " + css(styles.itemFlex, {color: "green"})}>check</span>
                        </div>
                    </div>
                </div>
            })}
           <ModalDelete 
                open={openModalAlertInvite}
                onDelete={decline}
                onClose={() => setOpenModalAlertInvite(false)}>
                    Realmente deseja recusar essa solicitação?
            </ModalDelete>
        </div>
    </div>
    <div className={css(styles.footerMenu)}>
        <div className={css(styles.itemsMenuFooter)+" center-align"}>
            <Link to={"/eventos"}>
                <div>
                    <img src={evento} style={styles.itemMenuIconFooter} className="center-align"/>
                </div>
            </Link>
            <Link to={"/torneios"}>
                <div>
                    <img src={torneio} style={styles.itemMenuIconFooter} className="center-align"/>
                </div>
            </Link>
            <Link to={"/organizador/carteira"}>
                <div>
                    <img src={carteira} style={styles.itemMenuIconFooter} className="center-align"/>
                </div>
            </Link>
            <Link to={"/configuracoes/menu"}>
                <div>
                    <img src={evento} style={styles.itemMenuIconFooter} className="center-align"/>
                </div>
            </Link>
        </div>
    </div>
  </div>
}

const styles = {
    flexBtnsCardInvite: {
        display: "flex",
        justifyContent: "space-between",
        width: "100%"
    },
    itemFlex: {
        paddingRight: 10,
        paddingLeft: 10,
        marginTop: 10,
        cursor: "pointer"
    },
    lineCardInvite: {
        marginTop: 5
    },
    cardInvite: {
        boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.15)",
        height: 120,
        fontSize: 14,
        fontWeight: "bold",
        paddingTop: 10,
        paddingLeft: 10,
        fontFamily: "Roboto"
    },
    spanChangeMode: {
        fontWeight: "700", 
        fontSize: 18,

    },
    divCalendarMenuRight:{
      position: "absolute",
      bottom: 50,
      width: "90%",
      marginLeft: "5%",
      maringRight: "5%",
      maxHeight: 500,
      overflowY: "scroll"
    },
    calendarMenuRight: {
        margin: 5,
        "--fc-neutral-bg-color": "#000",
        ":root ": {
            fontSize: "1.5em",
            "-fc-border-color": "#5c005c"
          },
        fontSize: 12
    },
    perfil : {
        borderRadius: 20,
        width: 50,
        height: 50,
        marginRight: 5,
        marginTop: 5
    },
    itemsMenuRight: {
        marginTop: 50
    },
    itemMenuDivRight: {
        marginBottom: 20,
        cursor: "pointer",
        width: "100%",
        paddingRight: 10,
        paddingLeft: 10,
        textAlign: "center"
    },
    itemSpan: {
        color: "#000",
        fontSize: 16
    },
    name :{
        fontFamily: "Roboto",
        marginTop: 5,
        fontSize: 16
    },
    divPerfil: {
        display: "inline"
    },
    container: {
        marginTop: 30,
    },
    main: {
        "@media(min-width: 992px)": {
            marginLeft: 0,
            marginRight: 100,
        },
        "@media(max-width: 992px)": {
             marginLeft: "10%",
             marginRight: "19%",
        },
        "@media(max-width: 850px)": {
            marginLeft: 0,
            marginRight: 0,
       },
        height: "100vh",
    },
    subMenu: {
        color: "rgb(4, 122, 200)",
        top: 58,
        zindex: 100,
        position: "absolute",
        right: 1,
        width: 200,
        borderRadius: 10,
        paddingTop: 10,
        paddingBottom: 20,
        paddingLeft: 30,
        borderColor: "rgb(4, 122, 178)",
        backgroundColor: "rgb(255, 255, 255)",
        border: "0.75px solid"
    },
    sideMenu: {
        width: 80,
        top: 30,
        borderRadius: 9,
        backgroundColor: "#6B69CF",
        left: "40px !important",
        bottom: "30px !important",
        position: "fixed",
        height: "auto", 
        transform: "translateX(0%)",
        "@media(max-width: 850px)": {
            display: "none"
        }
    },
    itemMenu: {
        marginTop: 3,
        paddingLeft: 5
    },

    itemMenuText: {
        fontSize: 20,
        fontWeight: 500
    },
    li: {
        color: "#000",
        width:"100%",
        height: 30,
        marginTop: 10,
        lineHeight: "30px"
    },
    icon: {
        verticalAlign: "bottom"
    },
    linkMenu: {
        color: "#000"
    },
    divLogo: {
        marginTop: 10,
        width: "100%",
    },
    logo: {
        width: 70, 
        textAlign:"center" 
    },
    fotoPerfil: {
        marginTop: 30,
        borderRadius: 20,
        width: 90, 
        textAlign:"center" 
    },
    divLogout: {
        width: "100%",
        position: "absolute",
        bottom: 20,
        cursor: "pointer"

    },
    logout: {
        width: 40, 
        textAlign:"center",
    },
   
    itemMenuIcon: {
        width: 40, 
        textAlign:"center",
    },
    itemMenuIconFooter: {
        marginTop: 5,
        width: 40, 
        textAlign:"center",
    },
    itemText: {
        color: "#fff",
        fontFamily: "Roboto",
        textAlign:"center",
        fontSize: 14
    },
    logoutText: {
        color: "#fff",
        fontFamily: "Roboto",
        paddingRight: 10
    },
    itemsMenu: {
        width: "100%",
        position: 'absolute',
        top: "50%",
        "-ms-transform": "translateY(-50%)",
        "transform": "translateY(-50%)"
    },
    itemMenuDiv: {
        width: "100%",
        marginBottom: 40
    },
    rightMenu: {
        position: "fixed",
        right: 0,
        top: 0,
        height: "100%",
        width: 230,
        backgroundColor: "#F8F8F8",
        "@media(max-width: 850px)": {
            display: "none"
        }
    },
    headerMenuRight: {
        marginTop: 10
    },
    footerMenu: {
        position: "fixed",
        height: 50,
        bottom: 0,
        width: "100%",
        zIndex: 10,
        backgroundColor: "#6B69CF",
        "@media(min-width: 850px)": {
            display: "none"
        }
    },
    itemsMenuFooter: {
        display: "flex",
        justifyContent: "space-evenly"
    }
}