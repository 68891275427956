import { css } from "emotion"
import React from "react"
import { RadioGroup} from "react-materialize"
const Component = (props) => {
    return  <div className={props.className}> 
        <RadioGroup 
            label = {props.label}
            id={props.id}
            options={props.options}
            name={props.id} 
            required={props.required}
            onChange={props.onChange} 
            value={props.value} 
            radioClassNames={css(styles.radio)}
            withGap
          />
          <span>{props.extraLabel}</span>
    </div>
}

const styles = {
    radio: {
        '[type="radio"]:checked+span:after, [type="radio"].with-gap:checked+span:before, [type="radio"].with-gap:checked+span:after': {
            border: "2px solid #6B69CF",
        },
        '[type="radio"]:checked+span:after, [type="radio"].with-gap:checked+span:after': {
            backgroundColor: "#6B69CF",
        },
        '[type="radio"]:checked+span:after, [type="radio"].with-gap:checked+span:before, [type="radio"].with-gap:checked+span:after': {
            border: "2px solid #6B69CF",
        },       
        marginRight: 10
    }
}

export default React.memo(Component)
