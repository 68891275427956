import React, { useState } from "react"
import { toast } from 'react-toastify';
import Api from "../../api/Api"
import { BrowserView, isMobile, MobileView } from "react-device-detect";
import ResetSenhaBrowser from "./ResetSenhaLayout/ResetSenhaBrowser";
import ResetSenhaMobile from "./ResetSenhaLayout/ResetSenhaMobile";
import { useHistory } from "react-router-dom";
import useIdOrganizer from "../../hooks/useIdOrganizer";

export default (props) => {
    const user_id = useIdOrganizer();

    const [loadingPub,setLoadingPub] = useState(false)
    const [oldPassword, setOldPassword] = useState("")
    const [newPasssword, setNewPassword] = useState("")
    const regexPassword = '^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&])[A-Za-z\\d@$!%*?&]{8,}$'
    const messageErroPassword = "A senha deve conter caracteres especiais, números, letras maiúsculas e minúsculas e ter no mínimo 8 caracteres."

    const [newPasswordRepeat, setNewPasswordRepeat] = useState("")

    const history = useHistory()

    const onSubmit = async (e) => {
        e.preventDefault()
        setLoadingPub(true)
        const params = {
            user_id,
            old_password: oldPassword,
            password: newPasssword
        }

        try {
            const result = await Api().patch("senha/updateLogado",params)

            if(result.data.error){
                toast.error(result.data.msg[0])
            }else {
                toast.success("Senha alterada com sucesso :)")
                if(isMobile){
                    history.goBack()
                }
            }
            setLoadingPub(false)
        }catch(e){
            const response = e.response ? e.response : {};
            const data = response.data ? response.data : {};
            const msg = data.msg ? data.msg[0] : "Ocorreu um problema ao tentar salvar";
            toast.error(msg)
            setLoadingPub(false)
        }
     
    }

    return <div>
        <BrowserView>
            <ResetSenhaBrowser 
                onSubmit={onSubmit} 
                loadingPub={loadingPub}
                oldPassword={oldPassword}
                setOldPassword={setOldPassword}
                newPasssword={newPasssword}
                setNewPassword={setNewPassword}
                regexPassword={regexPassword}
                messageErroPassword={messageErroPassword}
                newPasswordRepeat={newPasswordRepeat}
                setNewPasswordRepeat={setNewPasswordRepeat}
            />
        </BrowserView>
        <MobileView>
            <ResetSenhaMobile 
                onSubmit={onSubmit} 
                loadingPub={loadingPub}
                oldPassword={oldPassword}
                setOldPassword={setOldPassword}
                newPasssword={newPasssword}
                setNewPassword={setNewPassword}
                regexPassword={regexPassword}
                messageErroPassword={messageErroPassword}
                newPasswordRepeat={newPasswordRepeat}
                setNewPasswordRepeat={setNewPasswordRepeat}
            />
        </MobileView>
    </div>

}

const styles={
    newEvento : {
        float:"left"
    },
    main: {
        paddingBottom: 20
    },
    labelImg:{
        color: "#000",
    },
    messageInfo: {
        color: "#000",
        fontWeight: 700,
        fontSize: 16
    },
    photoEvent: {
        width: "100%",
        paddingTop: 20,
        position: "relative"
    }
}