import { css } from "emotion"
import { useEffect, useState } from "react"
import { Row } from "react-materialize"
import { useHistory } from "react-router"
import { toast } from "react-toastify"
import Api from "../../api/Api"
import { Button, RichTextEditor } from "../../components/Form"

export default () => {

    const history = useHistory()
    const [policyOrg, setPolicyOrg] = useState()
    const [policyAth, setPolicyAth] = useState()

    const onSave = async () => {
        try {
            const result = await Api().post("politicas/salvar",{
                atleta: policyAth,
                organizador: policyOrg
            })

            if(!result.error){
                toast.success("Politica atualizada com sucesso!")
                history.replace("/admin/organizadores")
            }else {
                toast.error("Ocorreu um problema ao tentar salvar")
            }
        }catch(e){
            console.log(e)
            const response = e.response ? e.response : {};
            const data = response.data ? response.data : {};
            const msg = data.msg ? data.msg[0] : "Ocorreu um problema ao tentar salvar";
            toast.error(msg)
        }
    }

    useEffect(() => {
        (async () => {
            const result = await Api().get("politicas/getinfo")
            const data = result.data?.politicas
            const politicas = data[0] ? data[0] : {};
            const atleta = politicas['atleta']
            const organizador = politicas['organizador']
            setPolicyAth(atleta)
            setPolicyOrg(organizador)
        })()
    },[])

    return <div className={css(styles.main)}>
        <label className={css(styles.title)}>Definição de Politicas</label>
        <Row>
            <RichTextEditor
                label={"Politica Organizador*:"}
                value={policyOrg}
                required={true}
                onChange={(value) => { setPolicyOrg(value)}}
            />
        </Row>
        <Row>
            <RichTextEditor
                label={"Politica Atleta*:"}
                value={policyAth}
                required={true}
                onChange={(value) => { setPolicyAth(value)}}
            />
        </Row>
        <Row className={css(styles.btnProxDiv)}>
            <Button onClick={onSave}>Salvar</Button>
        </Row>
        
    </div>
}

const styles = {
    title: {
        fontFamily: "Roboto",
        fontSize: 16,
        color: "#000",
        fontWeight: "normal"
    },
    newEvento : {
        float:"left"
    },
    main: {
        paddingBottom: 20
    },
}